import axios from "axios";
import {API_ENDPOINT} from "../constants/config";

const VocabularyAPI = {

    async getUserWords(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/vocabulary/user/${userId}`)).data;
        return pld;
    }


}

export default VocabularyAPI;