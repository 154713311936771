import React, {PropTypes} from 'react';
import UploadAPI from "../../../../api/UploadAPI";

import styled from 'styled-components'
import {BlueButton, GreenButton} from "../../../ira/ui/Buttons";
import Spin from "arui-feather/spin";

class CreateVideoPreviewPanel extends React.Component {

    static defaultProps = {
        url: 'https://drive.sabir.pro/videos/Ila26VkBEsYquL6UG560.mp4',

        width: 600,
        height: 315,

        onSave: (screenshotUrl) => {
            console.log('onSave: screenshotUrl = ', screenshotUrl);
        }
    }

    static propTypes = {}

    state = {
        loading: false,
        screenshotUrl: undefined,

        loadedVideoData: undefined,

        previewUrl: undefined,
        saving: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    makeScreenshot = () => {
        let {url, width, height} = this.props;
        if (this.video == undefined) {
            return;
        }
        let currentTime = this.video.currentTime;
        console.log('current time = ', currentTime);
        console.log('this.video = ', this.video);
        console.log('this.video.videoHeight = ', this.video.videoHeight);
        console.log('this.video.videoWidth = ', this.video.videoWidth);

        let canvas = document.createElement("canvas");
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        canvas.getContext('2d')
            .drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);

        this.setState({
            previewUrl: canvas.toDataURL('image/jpeg')
        });
    }

    onSave = () => {
        let {previewUrl} = this.state;
        this.setState({
            loading: true
        });
        fetch(previewUrl).then(res => res.blob()).then(
            blob => {
                UploadAPI.uploadImage(blob, p => {

                }).then(pld => {
                    console.log('pld: uploaded screenshot = ', pld);
                    this.props.onSave(pld.mini_url);
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    console.log('error occured: err = ', err);
                });
                // XHRHelper.uploadImageAsPromise(blob)
                //     .then(pld => {
                //         console.log('pld: uploaded screenshot = ', pld);
                //         this.props.onSave(pld.medium_url);
                //         this.setState({
                //             loading: false
                //         });
                //     }).catch(err => {
                //     console.log('error occured: err = ', err);
                // });

            }
        )
    }

    render = () => {
        let {url, onSave} = this.props;
        let {loading, screenshotUrl, previewUrl} = this.state;

        return (
            <Wrapper className={'create_video_preview_panel'}>

                <TopPlaceholder className="top_placeholder">

                    <VideoPlaceholder className="video_placeholder">
                        <Video crossOrigin={'anonymous'} controls={true} ref={video => {
                            this.video = video;
                            // this.video.onloadeddata = (d) => {
                            //         console.log('onloadeddata: d = ', d);
                            // }
                        }}>
                            <source src={url}/>
                        </Video>
                    </VideoPlaceholder>

                    {previewUrl == undefined ? null :
                        <PreviewPlaceholder className="screenshot_placeholder">
                            <PreviewImg src={previewUrl}/>
                        </PreviewPlaceholder>
                    }

                </TopPlaceholder>


                <ControlsPlaceholder className="controls_placeholder">
                    <BlueButton style={{marginRight: 20}} className={'ui primary button'} onClick={() => {
                        this.makeScreenshot();
                    }}>
                        выбрать кадр
                    </BlueButton>
                    {previewUrl == undefined ? null :
                        <GreenButton className={'ui basic button'} onClick={() => {
                            this.onSave();
                        }}>
                            <Spin visible={loading}/>
                            {loading == true ? null : 'Save'}
                        </GreenButton>
                    }
                </ControlsPlaceholder>

            </Wrapper>
        )
    }

}

const ControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const PreviewImg = styled.img`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const TopPlaceholder = styled.div`
  width: 100%;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
`;

const PreviewPlaceholder = styled.div`

`;

//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//CreateVideoPreviewPanel = connect(mapStateToProps, mapDispatchToProps)(CreateVideoPreviewPanel)

export default CreateVideoPreviewPanel