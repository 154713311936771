import React, { useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const getGroupOptions = (groups) => {
    if (groups == undefined) {
        return [];
    }

    let options = [];
    for (let group of groups) {
        options.push({ value: group.id, label: group.name })
    }
    return options;
}

const getGroupsIds = (groups) => {
    if (groups == undefined) {
        return [];
    }
    let ids = [];
    for (let group of groups) {
        if (ids.includes(group.id) == false) {
            ids.push(group.id);
        }
    }
    return ids;
}

export default function PostTypeTool(props) {
    const {
        currentUser,
        groups = [],
        isAdmin = false,
        type,
        setType = d => {

        },
        setGroups = d => {

        }
    } = props;

    let groupsOptions = getGroupOptions(groups);
    const [chosenGroups, setChosenGroups] = useState([]);
    const [allGroups, setAllGroups] = useState(false);

    return (
        <Wrapper>
            {isAdmin == false ? null :
                <RadioPlaceholder>
                    <Radio
                        id='new_features_radio'
                        type='checkbox'
                        checked={type == 'new_feature'}
                        onChange={() => { setType('new_feature'); }}
                    />
                    <label htmlFor='new_features_radio'>Сообщение о системных нововведениях</label>
                </RadioPlaceholder>
            }
            {isAdmin == false ? null :
                <RadioPlaceholder>
                    <Radio
                        id='bug_fixes_radio'
                        type='checkbox'
                        checked={type == 'bug_fixes'}
                        onChange={() => { setType('bug_fixes'); }}
                    />
                    <label htmlFor='bug_fixes_radio'>Сообщение об исправлении багов</label>
                </RadioPlaceholder>
            }
            
            {isAdmin == false ? null :
                <RadioPlaceholder>
                    <Radio
                        id='module_radio'
                        type='checkbox'
                        checked={type == 'module'}
                        onChange={() => { setType('module'); }}
                    />
                    <label htmlFor='module_radio'>Сообщение о публикации модуля</label>
                </RadioPlaceholder>
            }
                
            <RadioPlaceholder>
                <Radio
                    id='group_radio'
                    type='checkbox'
                    checked={type == 'group'}
                    onChange={() => { setType('group'); }}
                />
                <label htmlFor='group_radio'>Сообщение для группы</label>
            </RadioPlaceholder>

            {type != 'group' ? null :
                <GroupSelector>
                    <Label>Выберите группы</Label>
                    <div>
                        <Checkbox
                            id='all_groups_checkbox'
                            type='checkbox'
                            checked={allGroups}
                            onChange={evt => {
                                setAllGroups(evt.target.checked);
                                if (evt.target.checked == true) {
                                    setGroups(getGroupsIds(groups));
                                }
                            }}
                        />
                        <label htmlFor='all_groups_checkbox'>Все группы</label>
                    </div>
                    <SelectPlaceholder>
                        <Select
                            isDisabled={allGroups}
                            isMulti={true}
                            options={groupsOptions}
                            onChange={evt => {
                                setChosenGroups(evt)
                                let newGroups = [];
                                for (let gr of evt) {
                                    newGroups.push(gr.value);
                                }
                                setGroups(newGroups);
                            }}
                        />
                    </SelectPlaceholder>
                </GroupSelector>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`

`;

const RadioPlaceholder = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 8px;
`;

const Label = styled.span`
    font-weight: bold;
`;

const GroupSelector = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 10px;
    margin-bottom: 8px;
`;

const Checkbox = styled.input`
    cursor: pointer;

    & + label {
        cursor: pointer;
        margin-left: 5px;
    }
`;

const Radio = styled.input`
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;

    & + label {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    & + label::before {
        content: '';
        outline: 1px solid gray;
        outline-offset: 3px;
        border-radius: 12px;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        transition: all 0.2s ease-in-out;
    }

    &:checked + label::before {
        content: '';
        background-color: #FFA71B;
        width: 8px;
        height: 8px;
    }
`;

const SelectPlaceholder = styled.div`
    min-width: 250px;
`;