import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ExercisesAPI from "../../../api/ExercisesAPI";
import ExerciseViewTool from "../tools/ExerciseViewTool";
import {useMappedState} from "redux-react-hook";

export default function ExercisePanel(props) {
    const {
        canShare = false,
        isPreview = false,
        id,
        userId // user that answers on this exercise
    } = props;
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [answersLoading, setAnswersLoading] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [answering, setAnswering] = useState(false);

    const {
        currentUser,
        isTeacher
    } = useMappedState(useCallback(state => {
        let user = state.users.usersMap.get(state.users.currentUserId);
        let isTeacher = (user?.userRole == 'teacher');
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isTeacher: isTeacher
        }
    }));

    useEffect(() => {
        setLoading(true);
        ExercisesAPI.getExercise(id).then(d => {
            console.log('got exercise: d = ', d);
            setData(d);
            setLoading(false);
            if (isPreview == true) {
                return;
            }
            setAnswersLoading(true);
            ExercisesAPI.getExerciseAnswers(id, userId).then(a => {
                setAnswersLoading(false);
                setUserAnswers(a);
            });
        });
    }, [id]);

    if (data == undefined && loading == true) {
        return (
            <div style={{padding: 10}}>
                <Code/>
            </div>
        )
    }

    if (data == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <ExerciseViewTool
                {...data}
                isTeacher={isTeacher}
                canShare={canShare && (data?.creatorId == currentUser?.id)}
                userAnswers={userAnswers}
                currentUser={currentUser}
                userId={userId}
                loading={answering || answersLoading || loading}
                isPreview={isPreview}
                onAnswer={async d => {
                    console.log('ExerciseViewTool: onAnswer: d = ', d);
                    setAnswering(true);
                    let ans = await ExercisesAPI.saveExerciseAnswer(id, d.cardId, d.answer, {
                        correctAnswer: d.correctAnswer,
                        cardType: d.type,
                        isCorrect: d.isCorrect
                    });
                    // let newAnswers = userAnswers.map(xx => (xx.cardId == ans.cardId ? ans : xx));
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}
                onFinish={async d => {
                    console.log('ExerciseViewTool: onFinish: d = ', d);
                    setAnswering(true);
                    let ans = await ExercisesAPI.saveExerciseAnswer(id, id, undefined);
                    // let newAnswers = userAnswers.map(xx => (xx.cardId == ans.cardId ? ans : xx));
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}
                onMarkUpdate={async d => {
                    console.log('ExerciseViewTool: onMarkUpdate: d = ', d);
                    setAnswering(true);
                    let ans = await ExercisesAPI.updateTeacherAnswer(d);
                    let pld = await ExercisesAPI.saveExerciseAnswer(id, id, undefined, { userId: userId });
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    newAnswers = newAnswers.filter(xx => xx?.isGrand == false).concat([pld]);

                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}
                onDeleteExerciseAnswers={async () => {
                    try {
                        let studentId = userId == undefined ? currentUser.id : userId;
                        await ExercisesAPI.deleteExerciseAnswers(id, studentId);
                        setUserAnswers([]);
                    } catch (exc) {

                    }
                }}

            />
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;