import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';

import styled from 'styled-components';
import {TINY_MCE_API_KEY} from "../../../constants/config";
import {Editor} from "@tinymce/tinymce-react";
import {GreenButton, RedButton} from "../../ira/ui/Buttons";

export default function UpdateTextItemTool(props) {

    const {
        canDelete = false,
        isFinished = false,
        isPreview = false,
        onDelete = () => {

        },
        onSave = d => {

        }
    } = props;

    const [text, setText] = useState(props.text ? props.text : '');
    const editorRef = useRef();
    const [loaded, setLoaded] = useState(false);

    if (!text && props.text) {
        setText(props.text);
    }

    if (isFinished && props.text != text) {
        setText(props.text);
    }

    return (
        <Wrapper>
            <EditorPlaceholder>
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setTimeout(() => {
                            setLoaded(true);
                        }, 100);
                    }}
                    initialValue={text}
                    apiKey={TINY_MCE_API_KEY}
                    contextmenu_never_use_native={true}
                    init={{
                        height: 300,
                        menubar: false,
                        contextmenu_never_use_native: true,
                        contextmenu: "paste | link image inserttable | cell row column deletetable",
                        plugins: [
                            'contextmenu advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic forecolor backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'table | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </EditorPlaceholder>

            {isFinished === true ? null :
            <BottomRow className="field">
                {isPreview === true ? null :
                    <GreenButton className={'ui primary button'} onClick={() => {
                        let content = editorRef.current.getContent();
                        console.log('content = ', content);
                        onSave({
                            text: content
                        });
                        // onSave({
                        //     text: text
                        // });
                    }}>
                        <i className={'icon save'}></i> Save
                    </GreenButton>
                }
                {canDelete == false ? null :
                    <RedButton className={'ui button basic'} onClick={() => {
                        onDelete();
                    }}>
                        <i className={'icon remove'}></i> удалить
                    </RedButton>
                }
            </BottomRow>
            }

        </Wrapper>
    );
}

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditorPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;