import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'
import BubbleLottieLoader from "../../loaders/BubbleLottieLoader";

// const jitsiDivId = 'jitsi_placeholder';

const getUName = u => {
    if (u == undefined) {
        return `No name`;
    }
    let ie = x => (x == undefined || x == '');
    if (ie(u.firstName) || ie(u.lastName)) {
        return u.email;
    }
    return `${u.firstName} ${u.lastName}`;
}

export default function SimpleInteractiveCommutePanel(props) {
    const {
        classId,
        roomId = 'sabir',
        participants = [],
        fakeLoaderDelay = 3600,
        muted = false
    } = props;
    const [loaderVisible, setLoaderVisible] = useState(true);

    const jitsiDivId = `jitsi_${roomId}`;
    const jitsiRef = useRef();

    useEffect(() => {
        try {
            jitsiRef.current.api = new window.JitsiMeetExternalAPI(window.JITSI_SERVER_URL || 'videoconf2.sabir.pro', {
                parentNode: document.querySelector(`#${jitsiDivId}`),
                roomName: `room_${roomId}`,
                userInfo: {
                    email: (window.CURRENT_USER == undefined ? '' : window.CURRENT_USER.email),
                    displayName: getUName(window.CURRENT_USER)
                },
                muted: muted,
                interfaceConfigOverwrite: {
                    disableDeepLinking: true,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    DEFAULT_REMOTE_DISPLAY_NAME: 'Собеседник',
                    DISABLE_VIDEO_BACKGROUND: true,
                    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                    DISABLE_FOCUS_INDICATOR: true,
                    SHOW_BRAND_WATERMARK: true,
                    SHOW_JITSI_WATERMARK: false,
                    DEFAULT_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    APP_NAME: 'ТГУ',
                    NATIVE_APP_NAME: 'ТГУ',
                    PROVIDER_NAME: 'ТГУ',
                    AUTHENTICATION_ENABLE: false,
                    MOBILE_APP_PROMO: false,
                    TOOLBAR_BUTTONS: [
                        'desktop',
                        'microphone', 'camera', 'tileview', 'fullscreen', 'fodeviceselection', 'raisehand',
                        'etherpad', 'select-background', 'settings', 'videoquality', 'filmstrip', 'shortcuts'
                    ],
                    SETTINGS_SECTIONS: ['devices']
                },
                configOverwrite: {
                    ignoreStartMuted: true,
                    muted: muted,
                    disableAudioLevels: true,
                    disableDeepLinking: true,
                },


            });

        } catch (exc) {

        }

    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoaderVisible(false);
        }, fakeLoaderDelay);
    }, []);

    // useEffect(() => {
    //     if (selectedPgn == undefined) {
    //         return;
    //     }
    //     console.log('selectedPgn changed! selectedPgn = ', selectedPgn);
    //
    // }, [selectedPgn]);

    // const layout = [
    //     {i: 'a', x: 0, y: 0, w: 15, h: 30, minW: 10},
    //     {i: 'b', x: 16, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
    // ];


    return (
        <OuterWrapper>
            {loaderVisible == false ? null :
                <PreloaderPlaceholder>
                    <BubbleLottieLoader/>
                </PreloaderPlaceholder>
            }
            <Wrapper id={jitsiDivId}>
            </Wrapper>
        </OuterWrapper>
    );
}

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const PreloaderPlaceholder = styled.div`
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  z-index: 10;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

function getFromLS(key) {
    let ls = {};
    if (window.localStorage) {
        try {
            ls = JSON.parse(window.localStorage.getItem("rgl-7")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}