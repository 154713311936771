export const A1 = [
    "To be",
    "Cardinal and ordinal numbers.",
    "Dates and numbers.",
    "Time and time prepositions.",
    "Word order",
    "HAVE GOT, positive, negative, question",
    "Personal pronouns (I, he, she, he, it, we you, they)",
    "Possessive pronouns (my, your, his, her, its, our, your, their)",
    "Possessive with ‘s",
    "Object pronouns (me, you, him, her, it, us, you, them)",
    "Demonstrative pronouns (that, those, this, these)",
    "Pronouns: something, anything",
    "Prepositions of place",
    "Adjectives vs adverbs, word formation",
    "Degrees of comparison of adjectives",
    "Irregular adjectives",
    "Countable and Uncountable Nouns",
    "Singular and plural nouns",
    "Present Simple",
    "Present Simple. General questions.",
    "Interrogative pronouns: Where, Whose, When, Who, How long, Whose, How, What time, Which, What",
    "Present Simple. Special questions.",
    "Present Simple. Who/What questions",
    "Present Simple, Tag questions.",
    "Past Simple of TO BE",
    "Past simple. Regular Verbs.",
    "Past Simple. Irregular Verbs",
    "Past Simple. Questions.",
    "Future with will: sudden decision.",
    "Future with going to: making plans.",
    "Will for asking for help.",
    "Imperatives",
    "Verbs followed by infinitive or gerund (like, love, want, would like, etc.)",
    "Present progressive: actions happening now",
    "Present progressive. Questions.",
    "Stative verbs",
    "Present perfect with since and for.",
    "Present perfect with ever and neverPresent perfect with already and yet",
    "A, An, The, 0 article",
    "Can or can’t for abilities",
    "Past simple of can or can’t for abilities",
    "Polite request with could and couldn’t",
    "Can for asking for permission",
    "Can for possibility",
    "Obligation with must and mustn’t",
    "Prohibition with mustn’t",
    "Should for giving advice",
    "Zero conditional"
]

export const A2 = [
    "To be (word order)",
    "Demonstrative pronouns & the use of “there is/ there are”",
    "Personal pronouns",
    "Object pronouns",
    "Possessive pronouns",
    "Rules for formation and use of numerals",
    "Present Simple (affirmative, negative)",
    "Present Simple (questions)",
    "Present Simple Adverbs of frequency",
    "Adjectives – comparative – use of than and",
    "Adjectives – superlative – use of definite article",
    "Adjectives vs adverbs, word formation, word order",
    "Adverbial phrases of time, place and frequency – including word order",
    "Articles – with countable and uncountable nouns",
    "Countables and Uncountables: much/many",
    "Future Simple (types of sentences)",
    "Present continuous",
    "Stative verbs i.e. like, know, belong, love, hate, suppose, mean, want, understand, seem, prefer, etc.",
    "Present continuous for future",
    "Future Time (will and going to)",
    "Interrogative pronouns: Where, Whose, When, Who, How long, Whose, How, What time, Which, What",
    "Past simple",
    "Major irregular verbs",
    "Past continuous",
    "Present perfect",
    "Present perfect with just, already and yet, i.e. I haven’t been to Europe yet. I have already done that.",
    "Present perfect with ever and never, i.e. I have never smoked. Have you ever been to Britain?",
    "Verb + ing/infinitive: like/",
    "Gerunds",
    "Imperatives",
    "want-would like/would rather",
    "Can or can’t for abilities i.e. I can’t swim. I can cook.",
    "Could couldn’t for abilities in the past",
    "Past simple of can or can’t for abilities i.e. I could swim when I was five. I couldn’t sing as a child.",
    "Polite request with could and couldn’t i.e. Could you help me to find the purse? Couldn’t you be quicker?",
    "Obligation with must and mustn’t i.e. I must study. You mustn’t clean the dishes.",
    "Polite request with could and couldn’t i.e. Could you post this letter for me?",
    "Modals – must/have to",
    "Necessity with need and needn’t and have to",
    "Modals – should",
    "Possessives – use of ‘s, s’",
    "Prepositional phrases (place, time and movement)",
    "Prepositions of time: on/in/at",
    "where, when, whose, why, whose, who, that",
    "Basic compound sentences i.e. I went out when it was raining.",
    "Questions",
    "Phrasal verbs – common",
    "Zero and 1st conditional"];

export const B1 = [
    "Present simple and continuous",
    "Present perfect",
    "Present perfect continuous",
    "Present continuous for future",
    "Past simple and progressive",
    "Past perfect",
    "Past perfect progressive",
    "Future simple and continuous",
    "Reported speech",
    "All main irregular verbs",
    "Passive voice",
    "Conditions (from 0 to 4)",
    "Modal Verbs",
    "Complex questions",
    "Gerund and infinitive",
    "Articles and quantifiers",
    "Adjectives with -ed",
    "Adverbs of frequency",
    "Comparative and superlative of irregular adjectives",
    "Same as, the same",
    "Like, alike, slightly",
    "Pronouns",
    "Conjunctions",
    "Prepositions and prepositional phrases",
    "Participial clauses"];


export const B2 = [
    "Present simple vs present progressive",
    "Present simple, Present progressive exceptions (for future, exaggeration, stative verbs, verbs of sense perception)",
    "Present perfect vs Present perfect progressive",
    "Present Perfect vs past simple",
    "Past simple vs Past progressive",
    "Past perfect vs Past simple",
    "Past perfect vs Past perfect progressive",
    "Future simple vs Future progressive",
    "Future perfect vs Future perfect progressive",
    "Passive voice of all tenses",
    "Reported speech",
    "Used to, be used to, get used to",
    "To have something done",
    "Conditionals 0, 1, 2, 3, mixed types",
    "Wish clauses",
    "Modal verbs (can, may, must, have to, ought to, should,",
    "Modals in the past (must have done)",
    "may, might for probability, polite request, deduction or speculation",
    "Can, could for polite request, for probability, ability, probability, for deduction or speculation",
    "Must vs Have to vs Need",
    "Must/Can’t for deduction",
    "Ought to vs should, vs must",
    "Shall for polite request, suggestions",
    "Indirect questions",
    "Complex question tags",
    "either, neither, So do I, I hope so",
    "Verbs followed by gerund",
    "Verbs followed by infinitives",
    "Verbs followed by bare infinitives",
    "Verbs followed by to + gerund such (look forward, etc.)",
    "Articles",
    "Adjectives with -ed vs -ing",
    "Word order of adverbs of frequency",
    "Comparative and superlative of irregular adjectives",
    "Same as, the same , As… as",
    "Like, alike, slightly,",
    "As soon as, still, so far, not only, whatsoever,",
    "Pronouns: something, anything, someone, anyone, something, anything, somewhere, anywhere, etc.",
    "Reflexive pronouns: myself, himself, herself, himself, ourselves, yourself, themselves",
    "Relative pronouns used for relative clauses: which, who, whose, whom, that, where, when, etc.",
    "Connecting words expressing cause and effect, contrast",
    "Prepositional phrases with in, for, from, to, at, to, in, about, with, from, of, etc.",
    "Among, Until, On, At, In, In case, By, Of, With, About, To, For, About, From, Out of"]


export const C1 = [
    "Present tenses",
    "Past tenses",
    "Future tenses",
    "Conditionals",
    "Wish, if only, expressing regrets",
    "Mixed conditionals in past, present and future",
    "Modal verbs",
    "Modals in the past i.e. could have done, may have seen, should have done, could have found",
    "Questions",
    "Gerund and infinitive",
    "Articles and quantifiers",
    "Adjectives and adverbs",
    "Inversion with negative adverbials",
    "Pronouns",
    "Prepositions",
    "Sequence of Tenses",
    "Reported Speech",
    "The Passive Voice",
    "Articles",
    "Relative Clauses",
    "Conjunctions"
]