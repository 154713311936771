import * as types from '../ActionTypes.js'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    postsMap: Map(),

    selectedPostId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const PostsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_POST:
        case types.UPDATE_POST:
        case types.DELETE_POST:
        case types.LOAD_POSTS:
            return startLoading(state, action)

        case types.CREATE_POST_FAIL:
        case types.UPDATE_POST_FAIL:
        case types.DELETE_POST_FAIL:
        case types.LOAD_POSTS_FAIL:
            return stopLoading(state, action)

        case types.CREATE_POST_SUCCESS:
        case types.UPDATE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                postsMap: state.postsMap.set(action.post.id, action.post)
            }

        case types.DELETE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                postsMap: state.postsMap.delete(action.id)
            }

        case types.LOAD_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                postsMap: state.postsMap.merge(action.posts.reduce((mp, ex) => mp.set(ex.id, ex), Map()))
            }

        default:
            return state;

    }

}

export default PostsReducer;