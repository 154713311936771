import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'

import {Input, Textarea} from "../../ira/ui/Inputs";
import toast from "react-hot-toast";
import {BlueButton} from "../../ira/ui/Buttons";
import Select from "react-select";


const SIZES_OPTIONS = [
    {
        value: 'square',
        label: 'Square',
        width: 768,
        height: 768
    },
    {
        value: 'horizontal',
        label: 'Horizontal',
        width: 768,
        height: 512
    },
    {
        value: 'vertical',
        label: 'Vertical',
        width: 512,
        height: 768
    }
];

export default function GenerateImageTool(props) {
    const {
        onSave = v => {

        },
        canSave = false
    } = props;

    const [text, setText] = useState('');

    const [resultUrl, setResultUrl] = useState(undefined);

    const [voice, setVoice] = useState('horizontal');
    const [loading, setLoading] = useState(false);

    let selectedSizes = SIZES_OPTIONS.find(x => x.value == voice);

    return (
        <Wrapper>

            <div className={'mb-2'} style={{marginBottom: 5}}>
                <Select
                    options={SIZES_OPTIONS}
                    placeholder={'Select voice'}
                    value={SIZES_OPTIONS.find(x => x.value == voice)}
                    onChange={selected => {
                        setVoice(selected.value);
                        setResultUrl(undefined);
                    }}
                />
            </div>


            <div className={'mb-2'} style={{marginBottom: 5}}>
                <Textarea
                    value={text}
                    placeholder={'Text'}
                    style={{lineHeight: '24px', minHeight: '140px'}}
                    onChange={evt => {
                        setText(evt.target.value);
                        setResultUrl(undefined);
                    }}
                />
            </div>

            {loading == false ? null :
                <div>
                    Please wait... It can take up to 30 seconds
                </div>
            }

            {resultUrl != undefined ? null :
                <div className={'mb-2'}>
                    <button className={'ui button primary'} onClick={async () => {
                        if (text == undefined || text.length < 10){
                            return toast.error('Text is too short');
                        }
                        setLoading(true);
                        setResultUrl(undefined);
                        try{
                            let pld = (await axios.post(`https://files.entsu.ru/grid4/generate-image`, {
                                prompt: text,
                                ...selectedSizes
                            })).data;
                            setResultUrl(pld.url);
                        }catch (exc){
                            console.error('error while generating image:', exc);
                            toast.error('Error while generating image. Please try again later.');
                        }
                        setLoading(false);
                    }}>
                        {loading == true ? 'Loading...' : 'Generate'}
                    </button>
                </div>
            }

            {resultUrl == undefined ? null :
                <div className={'mb-2'} style={{
                    marginTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <img style={{width: '100%'}} src={resultUrl} onClick={() => {
                            window.open(resultUrl, '_blank');
                        }}/>
                    </div>
                    <div style={{marginLeft: 10}}>
                        <span className={'text-red cursor-pointer'} onClick={() => {
                            setResultUrl(undefined);
                        }}>
                            delete
                        </span>
                    </div>
                </div>
            }

            {canSave == false || resultUrl == undefined ? null :
                <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <BlueButton onClick={() => {
                        onSave(resultUrl)
                    }}>
                        Save
                    </BlueButton>
                </div>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ModalInnerPlaceholder = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 10px;
`;