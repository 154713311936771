import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import { useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import InstructionsPanel from "../instructions/panels/InstructionsPanel";
import ConsentText from "../consent/ConsentText";

export default function TeacherLegalApp(props) {

    const { currentUser } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    return (
        <TeacherTemplate active={'legal'}>

            <Wrapper>
                <ConsentText
                    userRole={currentUser?.userRole}
                />
            </Wrapper>

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 40px;
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;