import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import uuid from 'uuid';
import styled from 'styled-components';
import OneOptionEditorTool from "./OneOptionEditorTool";

import crossImg from '../../../../assets/img/x.svg'

export default function SimpleOptionsEditor(props) {
    const {
        onSave = opts => {

        },
        options = [],
        loading = false
    } = props;


    return (
        <Wrapper>
            <ListPlaceholder>
                {options.map((opt, i) => {
                    return (
                        <OptItem key={opt.id}>
                            <CrossImg src={crossImg} onClick={() => {
                                let newOpts = options.filter(x => (x.id != opt.id));
                                onSave(newOpts);
                            }}/>
                            <div style={{flex: 1}}>
                                <OneOptionEditorTool {...opt} onChange={x => {
                                    let newOpts = options.map((q, j) => (q.id == opt.id ? {...q, ...x} : q));
                                    onSave(newOpts);
                                }}/>
                            </div>
                        </OptItem>
                    )
                })}
            </ListPlaceholder>

            <div>
                <AddSpan onClick={() => {
                    onSave(options.concat([
                        {
                            id: uuid(),
                            text: '',
                            isMultiple: true
                        }
                    ]))
                }}>
                    добавить вариант ответа
                </AddSpan>
            </div>

        </Wrapper>
    );
}

const CrossImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

const AddSpan = styled.span`
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Wrapper = styled.div`

`;

const ListPlaceholder = styled.div`

`;

const OptItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;