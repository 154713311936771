import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const COURSE_ID = 'tI1YgNDpmY';

const InstructionsAPI = {

    async getSlides() {
        let pld = (await axios.get(`${API_ENDPOINT}/data/course/${COURSE_ID}`)).data.slides;
        return pld;
    },

    async getTeacherSlides() {
        let pld = (await axios.get(`https://api.entsu.ru/v3/container/rIXaXNTGzj/slides`)).data.result; // todo: change to some constants
        pld = fixSlides(pld);
        console.log('fixed pld = ', pld);
        return pld;
    },

    async getStudentSlides() {
        let pld = (await axios.get(`https://api.entsu.ru/v3/container/EHu8EyxEgX/slides`)).data.result; // todo: change to some constants
        pld = fixSlides(pld);
        return pld;
    }
}

function fixSlides(slides = []){
    let goodSlides = [];
    for (let i in slides){
        let sl = slides[i];
        let {items = []} = sl;
        let goodItems = [];
        for (let j in items){
            let item = items[j];
            let {type} = item;
            if (type === 'text'){
                if (item?.content?.text != undefined){
                    item.content.text = item.content.text.replace(/#\/share\/modules/g, 'https://omega.entsu.ru/#/share/modules');
                }
            }
            goodItems.push(item);
        }
        goodSlides.push({
            ...sl,
            items: goodItems
        });
    }
    return goodSlides;
}

export default InstructionsAPI;
