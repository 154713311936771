import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import LottieLoader from "../loaders/LottieLoader";

import beta_loader from '../../assets/lottie/beta-loader.json'

export default function FullPagePreloader(props) {
    let visible = (props.visible == undefined) ? false : props.visible;
    if (visible == false) {
        return null;
    }

    return (
        <Wrapper>

            <Inner>

                <CenterPlaceholder>
                    <ImagePlaceholder>
                        {/*<Img src={require('../../assets/images/sub_logo_.svg')}/>*/}

                        <LottieLoader
                            json={beta_loader}
                        />

                    </ImagePlaceholder>
                </CenterPlaceholder>

            </Inner>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  overflow: hidden !important;
  opacity: 0.95;
`;

const Inner = styled.div`
  box-sizing: border-box;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CenterPlaceholder = styled.div`
  height: 20vh;
`;

const ImagePlaceholder = styled.div`
  height: calc(100% - 0px);
  text-align: center;
`;

const SubImagePlaceholder = styled.div`
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 18px;
  color: #474d53;
`;

const Img = styled.img`
  height: 100%;
  display: inline-block;
`;
