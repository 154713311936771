import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import axios from 'axios'
import moment from 'moment'
import {Input} from "../ira/ui/Inputs";
import {YellowButton} from "../ira/ui/Buttons";
import {API_ENDPOINT} from "../../constants/config";

export default function FilmsSearchTool(props) {
    const {
        onSelect = d => {

        }
    } = props;

    const [text, setText] = useState('');
    const [items, setItems] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const [info, setInfo] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [directors, setDirectors] = useState([]);

    useEffect(() => {
        if (selectedId == undefined) {
            return;
        }
        setLoading(true);
        setDirectors([]);
        setInfo(undefined);
        axios.get(`${API_ENDPOINT}/v2/films/${selectedId}/info`).then(d => d.data).then(info_ => {
            if (info_ != undefined) {
                axios.get(`${API_ENDPOINT}/v2/films/${selectedId}/translations/ru`).then(d => d.data).then(locInfo => {

                    axios.get(`${API_ENDPOINT}/v2/films/${selectedId}/directors`).then(d => d.data).then(dirs => {
                        let dirsString = (dirs.length == 0) ? `` : `director${dirs.length == 1 ? '' : 's'} ${dirs.map(z => z.name).join(', ')}, `
                        setInfo(info_);
                        setLoading(false);
                        let releaseDate = info_?.release_date;
                        let original_title = info_?.original_title;
                        let title = (locInfo?.title == undefined || locInfo?.title == '') ? info_?.title : locInfo?.title;
                        // let quoteUrl = (locInfo?.homepage == undefined || locInfo?.homepage == '') ? `https://www.themoviedb.org/movie/${info_.id}` : locInfo?.homepage;
                        let quoteUrl = `https://www.themoviedb.org/movie/${info_.id}`;
                        let productionCompaniesString = (info_?.production_companies || []).map(x => x.name).join(', ');
                        // let quoteUrl = (info_ == undefined) ? '' : `https://www.themoviedb.org/movie/${info_.id}`;
                        let movieQuote = (info_ == undefined) ? `` : `"${title}", Original title: "${original_title}": [Film] / ${dirsString}${`${releaseDate}`.split('-')[0]} // TMDB : [website]. – URL: ${quoteUrl} (date of access: ${moment().format('DD.MM.YYYY')})`;
                        onSelect({
                            quote: movieQuote,
                            url: quoteUrl
                        });
                    });


                });
            }
        });
    }, [selectedId]);

    return (
        <Wrapper>

            <TopField>

                <Input value={text}
                       placeholder={'Поиск по фильмам'}
                       onChange={evt => {
                           setText(evt.target.value);
                           setItems([]);
                       }}/>

                <YellowButton style={{marginLeft: 15}}  onClick={async () => {
                    setItems([]);
                    setSelectedId(undefined);
                    setLoading(true);
                    let pld = (await axios.get(`${API_ENDPOINT}/v2/films/find`, {
                        params: {
                            text: text
                        }
                    })).data;
                    setItems(pld.results);
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : 'Найти фильм'}
                </YellowButton>

            </TopField>


            <div>
                {items.map((x, i) => {
                    return (
                        <Row selected={(selectedId == x.id)} key={x.id} onClick={() => {
                            console.log('selecting ', x.id);
                            setSelectedId(x.id);
                        }}>
                            <h3>
                                {`${x.title} [${x.release_date}]`}
                            </h3>
                            <div>
                                {x.overview}
                            </div>

                            {/*{info == undefined || selectedId != x.id ? null :*/}
                            {/*    <div style={{padding: 40}}>*/}
                            {/*        <pre dangerouslySetInnerHTML={{__html: JSON.stringify(info, undefined, 2)}}>*/}

                            {/*        </pre>*/}
                            {/*    </div>*/}
                            {/*}*/}

                        </Row>
                    )
                })}
            </div>


        </Wrapper>
    );
}

const TopButton = styled.div`
  background: #8C63A9;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  text-align: center;
  min-width: 180px;
  font-weight: bold;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  padding: 10px;
`;

const Row = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopField = styled.div`
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;
