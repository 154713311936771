import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {GreenButton} from "../../ira/ui/Buttons";
import cit_img from "../../../assets/img/citation.svg";
import {Textarea} from "../../ira/ui/Inputs";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import Sidebar from "arui-feather/sidebar";

export default function UpdateAudioItemTool(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;
    const [url, setUrl] = useState(props.url);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription);

    const [citationModalVisible, setCitationModalVisible] = useState(false);

    return (
        <Wrapper>

            {url == undefined ? null :
                <div key={url}>
                    <audio src={url} controls={true}></audio>
                </div>
            }

            <Row>
                <SimpleFileUploader
                    onSave={s => {
                        setUrl(s);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    Информация об источниках
                    <CitImg src={cit_img} onClick={() => {
                        setCitationModalVisible(true);
                    }}/>
                </Label>
                <Textarea value={copyrightDescription} onChange={evt => {
                    setCopyrightDescription(evt.target.value);
                }}/>
            </Row>

            <ButtonPlaceholder>

                <Spin visible={loading}/>

                {loading == true || url == undefined ? null :
                    <GreenButton onClick={() => {
                        onSave({
                            url: url,
                            copyrightDescription: copyrightDescription
                        });
                    }}>
                        Save
                    </GreenButton>
                }
            </ButtonPlaceholder>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const ButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;