import React, {useState} from 'react';

import styled from 'styled-components'
import UserConversationsPanel from "../panels/UserConversationsPanel";

const DraggableAiWindow = ({
                               children, onClose = () => {

    }
                           }) => {

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({x: 100, y: 100});

    const handleMouseDown = (e) => {
        setIsDragging(true);
        // Store the initial position for calculating movement
        setPosition((prevPosition) => ({
            ...prevPosition,
            initialX: e.clientX - prevPosition.x,
            initialY: e.clientY - prevPosition.y,
        }));
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newX = e.clientX - position.initialX;
            const newY = e.clientY - position.initialY;
            setPosition({x: newX, y: newY});
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        // Remove initial positions to avoid stale values
        setPosition(({x, y}) => ({x, y}));
    };

    return (
        <Wrapper
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}

        >
            <TopPlaceholder style={{
                position: 'relative',
                cursor: isDragging ? 'grabbing' : 'grab'
            }}

            >
                AI Assistant
                <div style={{position: 'absolute', right: '5px', top: '5px'}}>
                    close
                </div>
            </TopPlaceholder>
            <ContentPlaceholder>
                <UserConversationsPanel

                />
            </ContentPlaceholder>
        </Wrapper>
    );
};

const TopPlaceholder = styled.div`
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 5px;
`;

const ContentPlaceholder = styled.div`
  background: white;
`;

const Wrapper = styled.div`
  box-shadow: whitesmoke;
  border-radius: 5px;
  width: 600px;
  height: calc(100vh - 200px);
`;

export default DraggableAiWindow;