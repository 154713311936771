import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
import GroupsPanel from "../groups/panels/GroupsPanel";

export default function TeacherIndexApp(props) {
    
    return (
        <TeacherTemplate active={'index'}>
            <GroupsPanel/>
            {/*<ContainersTreePanel/>*/}
        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;