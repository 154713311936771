import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import TimePicker from "./TimePicker";
import MonthCalendar from "../calendars/MonthCalendar";
import { translate } from '../../utils/Translate';


export default function DateTimePicker(props) {
    let {
        timestamp, onChange = t => {
        },
        showBottomSection = true,
        hasTimePicker = true
    } = props;
    const momTz = moment(timestamp);
    let h = +momTz.format('H');
    let m = +momTz.format('m');
    let additionalMilliseconds = (60 * h + +m) * 60 * 1000;

    return (
        <Wrapper>

            <CalendarPlaceholder>
                <MonthCalendar selectedTimestamp={timestamp} dayContent={t => {
                    let isSelected = (+moment(t).startOf('day') == +moment(timestamp).startOf('day'))
                    return (
                        <Cell selected={isSelected} onClick={() => {
                            let newT = +moment(t).startOf('day') + +additionalMilliseconds;
                            onChange(newT);
                        }}>

                        </Cell>
                    )
                }}/>
            </CalendarPlaceholder>

            {hasTimePicker == false ? null :
                <TimePickerPlaceholder>
                    <TimePicker hour={h} minute={m} onChange={({minute, hour}) => {
                        console.log('TimePicker: minute, hour = ', minute, hour);
                        let newT = +moment(timestamp).startOf('day') + +((60 * hour + +minute) * 60 * 1000);
                        onChange(newT);
                    }}/>
                </TimePickerPlaceholder>

            }


            {showBottomSection == false ? null :
                <BottomSection>
                    {`${translate("date")}: ${momTz.format('DD.MM.YYYY')}`} {' '}
                    {`Время: ${momTz.format('HH:mm')}`}
                </BottomSection>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

`;

const TimePickerPlaceholder = styled.div`
  //height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
`;

const CalendarPlaceholder = styled.div`
  flex: 1;
`;

const Cell = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => (props.selected == true ? '#FFA71C' : 'white')};
  color: ${props => (props.selected == true ? '#FFFFFF' : 'grey')};
  cursor: pointer;
`;

const BottomSection = styled.div`
  text-align: center;
  padding: 5px;
  font-size: 12px;
  opacity: 0.7;
`;
