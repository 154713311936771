import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'
import PaywallFormTool from "./PaywallFormTool";
import toast from "react-hot-toast";

function getHostName() {
    try {
        return document.location.hostname;
    } catch (exc) {

    }
    return 'none';
}

export default function PaywallQuestionnaireTool(props) {
    const {
        userId
    } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        let hostname = getHostName();
        setLoading(true);
        axios.get(`https://api.langplat.com/my-paywall-questionnaire`, {
            params: {
                userId: userId,
                hostname: hostname
            }
        }).then(x => {
            setData(x.data);
            setLoading(false);
        });
    }, [userId]);

    if (data == undefined || data?.finished == true) {
        return null;
    }

    return (
        <Wrapper>

            <InnerPlaceholder>
                <BoxPlaceholder>
                    <PaywallFormTool
                        data={data}
                        onSave={async d => {
                            let hostname = getHostName();
                            setLoading(true);
                            await axios.post(`https://api.langplat.com/my-paywall-questionnaire`, {
                                ...d,
                                finished: true
                            }, {
                                params: {
                                    userId: userId,
                                    hostname: hostname
                                }
                            });
                            setLoading(false);
                            setData({
                                ...data,
                                finished: true
                            });
                            toast.success('Спасибо за заполнение анкеты!');
                            window.location.reload();
                        }}
                    />
                    {/*{JSON.stringify(data, null, 2)}*/}
                </BoxPlaceholder>
            </InnerPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: white;
`;

const InnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  max-height: 100vh;
  overflow-y: auto;
`;

const BoxPlaceholder = styled.div`
  width: 820px;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
`;