import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import Lottie from 'react-lottie-player'

import useDimensions from "react-cool-dimensions";

import lottieJson from '../../assets/lottie/bubble_lottie.json'

export default function BubbleLottieLoader(props) {
    const {
        fraction = 0.6
    } = props;

    const {observe, unobserve, width, height, entry} = useDimensions({
        onResize: ({observe, unobserve, width, height, entry}) => {

        }
    });

    return (
        <Wrapper ref={observe}>
            <Lottie
                loop
                animationData={lottieJson}
                play
                style={{width: width * fraction, height: height * fraction}}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;