import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AssistantsAPI from "../../../api/AssistantsAPI";
import axios from 'axios'

import NiceModal from "../../modals/NiceModal";
import {Input, Textarea} from "../../ira/ui/Inputs";
import toast from "react-hot-toast";
import Select from "react-select";
import {BlueButton} from "../../ira/ui/Buttons";

const VOICES_OPTIONS = [
    {
        value: 'alloy',
        label: 'Alloy'
    },
    {
        value: 'echo',
        label: 'Echo'
    },
    {
        value: 'fable',
        label: 'Fable'
    },
    {
        value: 'onyx',
        label: 'Onyx'
    },
    {
        value: 'nova',
        label: 'Nova'
    },
    {
        value: 'shimmer',
        label: 'Shimmer'
    }
];

export default function TextToSpeechTool(props) {
    const {
        onSave = v => {

        },
        canSave = false
    } = props;

    const [text, setText] = useState('');

    const [resultUrl, setResultUrl] = useState(undefined);

    const [voice, setVoice] = useState('alloy');
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>

            <div className={'mb-2'} style={{marginBottom: 5}}>
                <Select
                    options={VOICES_OPTIONS}
                    placeholder={'Select voice'}
                    value={VOICES_OPTIONS.find(x => x.value == voice)}
                    onChange={selected => {
                        setVoice(selected.value);
                        setResultUrl(undefined);
                    }}
                />
            </div>

            <div className={'mb-2'} style={{marginBottom: 5}}>
                <Textarea
                    value={text}
                    placeholder={'Text'}
                    style={{lineHeight: '24px', minHeight: '140px'}}
                    onChange={evt => {
                        setText(evt.target.value);
                        setResultUrl(undefined);
                    }}
                />
            </div>


            {resultUrl != undefined ? null :
                <div className={'mb-2'}>
                    <button className={'ui button primary'} onClick={async () => {
                        setLoading(true);
                        setResultUrl(undefined);
                        let pld = (await axios.post(`https://patient-api.simbox.online/gpt/text-to-speech`, {
                            text: text,
                            voice: voice
                        })).data;
                        setResultUrl(pld.url);
                        setLoading(false);
                        toast.success('Ready');
                    }}>
                        {loading == true ? 'Loading...' : 'Generate'}
                    </button>
                </div>
            }

            {resultUrl == undefined ? null :
                <div className={'mb-2'} style={{
                    marginTop: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <audio controls={true} src={resultUrl}/>
                    </div>
                    <div style={{marginLeft: 10}}>
                        <span className={'text-red cursor-pointer'} onClick={() => {
                            setResultUrl(undefined);
                        }}>
                            delete
                        </span>
                    </div>
                </div>
            }

            {canSave == false ? null :
                <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <BlueButton onClick={() => {
                        onSave({url: resultUrl})
                    }}>
                        Save
                    </BlueButton>
                </div>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ModalInnerPlaceholder = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 10px;
`;