import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import {GreenButton} from "../../ira/ui/Buttons";
import {Input, Textarea} from "../../ira/ui/Inputs";
import {translate} from "../../../utils/Translate";
import AccessGroupsDropdownPanel from "../../access_groups/panels/AccessGroupsDropdownPanel";

import ch_empty from '../../../assets/img/checkbox.svg'
import checkbox_tick from '../../../assets/img/checkbox_tick.svg'
import NiceModal from "../../modals/NiceModal";
import AccessGroupsPanel from "../../access_groups/panels/AccessGroupsPanel";
import UIHelper from "../../../helpers/UIHelper";
import ThemesSelectorTool from "../../prouni/tools/ThemesSelectorTool";
import VocWordsEditor from "../../prouni/tools/VocWordsEditor";
import SmartGrammarSelector from "../../prouni/tools/SmartGrammarSelector";

export default function SlideForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        canDelete = false,
        canRecover = false,
        onDelete = () => {

        },
        onRecover = () => {

        },
        canEdit = true

    } = props;

    const [groupsSettingsModalVisible, setGroupsSettingsModalVisible] = useState(false);
    const [proUniKeyWords, setProUniKeyWords] = useState(props.proUniKeyWords || []);

    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);
    const [name, setName] = useState(props?.name ? props.name : '');
    const [isLimbo, setIsLimbo] = useState(props?.isLimbo == undefined ? false : props?.isLimbo);
    const [allowCloning, setAllowCloning] = useState(props?.allowCloning == undefined ? false : props?.allowCloning);
    const [allowUsageOnLesson, setAllowUsageOnLesson] = useState(props?.allowUsageOnLesson == undefined ? false : props?.allowUsageOnLesson);

    const [prouni, setProuni] = useState(props.prouni || {});

    const [proUniWords, setProUniWords] = useState(props.proUniWords || []);

    const [proUniWordsText, setProUniWordsText] = useState(props.proUniWordsText || '');
    // const [proUniGrammarConstructionsText, setProUniGrammarConstructionsText] = useState(props.proUniGrammarConstructionsText || '');
    const [proUniGoalsText, setProUniGoalsText] = useState(props.proUniGoalsText || '');

    const [description, setDescription] = useState(props?.description ? props.description : '');
    const [usedMaterialsDescription, setUsedMaterialsDescription] = useState(props?.usedMaterialsDescription ? props.usedMaterialsDescription : '');

    let proUniGrammarConstructionsText = (prouni?.grammars || []).join(', ');

    console.log('SlideForm: prouni = ', prouni);
    console.log('SlideForm: props.prouni = ', props.prouni);
    let unitName = prouni?.unit || '';

    useEffect(() => {
        setProuni(props.prouni || {});
    }, [props?.prouni]);

    return (
        <Wrapper>

            {UIHelper.isProUniMode() == false ? null :
                <React.Fragment>
                    <div style={{padding: 15, borderRadius: 6, background: 'whitesmoke', marginBottom: 20}}>
                        <h2>
                            ProUni Section
                        </h2>
                        <p>
                            Данный раздел доступен только на платформе EnglishProUni.com
                        </p>

                        <ThemesSelectorTool
                            {...prouni}
                            onSave={d => {
                                setProuni(d);
                            }}
                        />

                        <Row>
                            <Label>
                                Список из 20 целевых тематических слов:
                            </Label>
                            {/*<Textarea disabled={canRecover || !canEdit} value={proUniWordsText}*/}
                            {/*          onChange={evt => {*/}
                            {/*              setProUniWordsText(evt.target.value);*/}
                            {/*          }}/>*/}
                            {/*          */}
                            {/*          */}
                            <VocWordsEditor
                                hasTypes={true}
                                canEdit={true}
                                items={proUniWords}
                                onChange={arr => {
                                    setProUniWords(arr);
                                    setProUniGoalsText(`By the end of the unit, you will be able to recognise, understand and apply topical vocabulary units (${arr.map(x => x.word).join(', ')}) and grammar patterns (${proUniGrammarConstructionsText}) in the context of speculating about "${unitName}"`);
                                }}
                            />

                        </Row>

                        <Row>
                            <Label>
                                Ключевые слова юнита:
                            </Label>
                            <VocWordsEditor
                                hasTypes={false}
                                canEdit={true}
                                items={proUniKeyWords}
                                onChange={arr => {
                                    setProUniKeyWords(arr);
                                }}
                            />
                        </Row>



                        <Row>
                            <Label>
                                Целевые грамматические конструкции:
                            </Label>
                            <SmartGrammarSelector
                                level={prouni?.level}
                                value={prouni?.grammars || []}
                                onChange={x => {
                                    console.log('SmartGrammarSelector: onChange: x = ', x);
                                    let prU = {...prouni, grammars: x};
                                    let grCT = (x || []).join(', ');
                                    setProUniGoalsText(`By the end of the unit, you will be able to recognise, understand and apply topical vocabulary units (${proUniWords.map(x => x.word).join(', ')}) and grammar patterns (${grCT}) in the context of speculating about "${prU?.unit || ''}"`);
                                    setProuni(prU);
                                }}
                            />
                            {/*<Textarea disabled={canRecover || !canEdit} value={proUniGrammarConstructionsText}*/}
                            {/*          onChange={evt => {*/}
                            {/*              setProUniGrammarConstructionsText(evt.target.value);*/}
                            {/*          }}/>*/}

                        </Row>



                        <Row>
                            <Label>
                                Цели и задачи юнита:
                            </Label>
                            <Textarea disabled={canRecover || !canEdit} value={proUniGoalsText}
                                      onChange={evt => {
                                          setProUniGoalsText(evt.target.value);
                                      }}/>
                        </Row>

                    </div>

                </React.Fragment>
            }

            <Row>
                <Label style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <span>
                        {translate('module_name')}
                    </span>
                    {props.id == undefined ? null :
                        <span style={{fontSize: 10, opacity: 0.5, fontWeight: 'normal'}}>
                            {`ID: ${props.id}`}
                        </span>
                    }
                </Label>
                <Input disabled={canRecover || !canEdit} value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Row>

            {/*<Row>*/}
            {/*    <Label>*/}
            {/*        Инструкция для преподавателей*/}
            {/*    </Label>*/}
            {/*    <Textarea disabled={canRecover || !canEdit} value={description} onChange={evt => {*/}
            {/*        setDescription(evt.target.value);*/}
            {/*    }}/>*/}
            {/*</Row>*/}

            {/*<Row>*/}
            {/*    <Label>*/}
            {/*        Используемые материалы*/}
            {/*    </Label>*/}
            {/*    <Textarea disabled={canRecover || !canEdit} value={usedMaterialsDescription} onChange={evt => {*/}
            {/*        setUsedMaterialsDescription(evt.target.value);*/}
            {/*    }}/>*/}
            {/*</Row>*/}

            {/*{canRecover == true || canEdit == false ? null :*/}
            {/*    <Row>*/}
            {/*        <Label>*/}
            {/*            Access groups*/}
            {/*        </Label>*/}

            {/*        <AccessGroupsDropdownPanel*/}
            {/*            selectedIds={accessGroupsIds}*/}
            {/*            onChange={x => {*/}
            {/*                if (canEdit == false) {*/}
            {/*                    return;*/}
            {/*                }*/}
            {/*                setAccessGroupsIds(x);*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </Row>*/}
            {/*}*/}

            {/*{canRecover == true || canEdit == false ? null :*/}
            {/*    <React.Fragment>*/}
            {/*        <Row>*/}
            {/*            <TopFlexPlaceholder>*/}
            {/*                <ChImage*/}
            {/*                    src={isLimbo ? checkbox_tick : ch_empty}*/}
            {/*                    onClick={() => {*/}
            {/*                        if (canEdit == false) {*/}
            {/*                            return;*/}
            {/*                        }*/}
            {/*                        setIsLimbo(!isLimbo);*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                <span>*/}
            {/*            Показывать в Limbo*/}
            {/*        </span>*/}
            {/*            </TopFlexPlaceholder>*/}
            {/*        </Row>*/}
            {/*        {isLimbo != true ? null :*/}
            {/*            <React.Fragment>*/}
            {/*                <Row>*/}
            {/*                    <TopFlexPlaceholder>*/}
            {/*                        <ChImage*/}
            {/*                            src={allowUsageOnLesson ? checkbox_tick : ch_empty}*/}
            {/*                            onClick={() => {*/}
            {/*                                if (canEdit == false) {*/}
            {/*                                    return;*/}
            {/*                                }*/}
            {/*                                setAllowUsageOnLesson(!allowUsageOnLesson);*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <span>*/}
            {/*                    Разрешить использовать на уроке*/}
            {/*                </span>*/}
            {/*                    </TopFlexPlaceholder>*/}
            {/*                </Row>*/}

            {/*                <Row>*/}
            {/*                    <TopFlexPlaceholder>*/}
            {/*                        <ChImage*/}
            {/*                            src={allowCloning ? checkbox_tick : ch_empty}*/}
            {/*                            onClick={() => {*/}
            {/*                                if (canEdit == false) {*/}
            {/*                                    return;*/}
            {/*                                }*/}
            {/*                                setAllowCloning(!allowCloning);*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <span>*/}
            {/*                    Разрешить клонировать*/}
            {/*                </span>*/}
            {/*                    </TopFlexPlaceholder>*/}
            {/*                </Row>*/}

            {/*            </React.Fragment>*/}
            {/*        }*/}


            {/*    </React.Fragment>*/}
            {/*}*/}

            {canRecover == true || canEdit == false ? null :
                <BottomPlaceholder>
                    <GreenButton onClick={() => {
                        onSave({
                            name: name,
                            prouni: prouni || {},
                            description: description,
                            usedMaterialsDescription: usedMaterialsDescription,
                            accessGroupsIds: accessGroupsIds,
                            isLimbo: isLimbo,
                            allowUsageOnLesson: allowUsageOnLesson,
                            allowCloning: allowCloning,
                            hasChanges: false,

                            proUniGoalsText: proUniGoalsText,
                            proUniKeyWords: proUniKeyWords,
                            // proUniWordsText: proUniWordsText,

                            proUniWords: proUniWords,
                            proUniGrammarConstructionsText: proUniGrammarConstructionsText
                        });
                    }}>
                        <Spin visible={loading}/>
                        {!loading && translate('save')}
                    </GreenButton>
                </BottomPlaceholder>
            }

            {canDelete == false || canEdit == false ? null :
                <DelBottomPlaceholder>
                    <DeleteSpan onClick={() => {
                        onDelete();
                    }}>
                        Delete module
                    </DeleteSpan>
                </DelBottomPlaceholder>
            }
            {canRecover == false ? null :
                <DelBottomPlaceholder>
                    <RecoverSpan onClick={() => {
                        onRecover({});
                    }}>
                        Recover module
                    </RecoverSpan>
                </DelBottomPlaceholder>
            }


        </Wrapper>
    );
}

const SubHeading = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const GrContent = styled.div`

`;

const SlideInnerSettingsGroups = styled.div`
  padding: 10px;
  box-sizing: border-box;
  width: calc(100vw - 120px);
  max-height: calc(100vh - 100px);
`;

const ChImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 7px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 0.8;
  }
`;

const TopFlexPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  color: red;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const RecoverSpan = styled.span`
  cursor: pointer;
  color: green;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DelBottomPlaceholder = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;