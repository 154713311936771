import axios from "axios";
import {API_ENDPOINT} from "../constants/config";

const QuestionnairesAPI = {

    async getAllQuestionnaires() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/questionnaires/all`)).data.result;
        return pld;
    },

    async getQuestionnaire(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/questionnaire/${id}`)).data.result;
        return pld;
    },


    async createQuestionnaire(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/questionnaires`, {
            ...data
        })).data.result;
        return pld;
    },

    async updateQuestionnaire(data) {
        console.log('api: updateQuestionnaire: data = ', data);
        let pld = (await axios.put(`${API_ENDPOINT}/v3/questionnaires`, {
            ...data
        })).data.result;
        return pld;
    },

    async deleteQuestionnaire(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/questionnaires/${id}`)).data.result;
        return pld;
    },


}

export default QuestionnairesAPI;