import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Input} from "../../ui/Input";
import EmailsListEditorTool from "../tools/EmailsListEditorTool";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import checkbox_tick from "../../../assets/img/checkbox_tick.svg";
import ch_empty from "../../../assets/img/checkbox.svg";

export default function UpdateAccessGroupForm(props) {
    const {
        onSave = d => {

        },

        onDelete = () => {

        },

        onLeave = () => {

        },

        loading = false,
        canLeave = false,
        canDelete = false,
        canEdit = true
    } = props;

    const [allowEdit, setAllowEdit] = useState(props?.allowEdit == undefined ? false : props?.allowEdit);
    const [name, setName] = useState(props?.name || '');
    const [emails, setEmails] = useState(props.emails || []);

    return (
        <Wrapper>

            <Field>
                {canEdit == false ? name :
                    <Input value={name} placeholder={'Название группы'} onChange={evt => {
                        setName(evt.target.value);
                    }}/>
                }
            </Field>


            <Field>
                <TopFlexPlaceholder>
                    <ChImage
                        src={allowEdit ? checkbox_tick : ch_empty}
                        onClick={() => {
                            setAllowEdit(!allowEdit);
                        }}
                    />
                    <span style={{fontSize: 14}} >
                        Разрешить редактировать материалы
                    </span>
                </TopFlexPlaceholder>
            </Field>

            <Field>
                <Label>
                    Участники
                </Label>
                <EmailsListEditorTool
                    emails={emails}
                    canEdit={canEdit}
                    onChange={arr => {
                        setEmails(arr);
                    }}
                />
            </Field>


            <BottomField>

                {canEdit == false ? null :
                    <Button onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSave({
                            emails: emails,
                            name: name,
                            allowEdit
                        })
                    }}>
                        <Spin visible={loading}/>
                        Save
                    </Button>
                }

                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        if (window.confirm('Вы уверены, что хотите удалить группу доступа?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        Удалить группу
                    </DeleteSpan>
                }

                {canLeave == false ? null :
                    <DeleteSpan onClick={() => {
                        if (window.confirm('Вы уверены, что хотите выйти из группы?') == false) {
                            return;
                        }
                        onLeave();
                    }}>
                        Выйти из группы
                    </DeleteSpan>
                }


            </BottomField>

        </Wrapper>
    );
}

const Label = styled.div`
  font-weight: bold;
`;

const ChImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 7px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`

`;

const TopFlexPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  opacity: 0.75;
  color: red;

  :hover {
    opacity: 1;
  }
`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const BottomField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;