import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

//todo: refactor
const DialogAPI = {

    getAllDialogs() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dialogs/all`).then(d => d.data).then(dialogs => {
                resolve(dialogs)
            }).catch(err => reject(err))
        })
    },

    getDialog(id, userId) {
        return new Promise((resolve, reject) => {
            let getUrl = () => {
                let url = `${API_ENDPOINT}/dialog/${id}`;
                if (userId != undefined) {
                    url = `${url}/${userId}`;
                }
                return url;
            }
            axios.get(getUrl()).then(d => d.data).then(d => {
                if (d.dialog == undefined) {
                    resolve({
                        dialog: d,
                        answer: undefined
                    })
                } else {
                    resolve(d)
                }
            }).catch(err => reject(err))
        });
    },

    createDialog(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/dialogs/create`, data).then(d => d.data).then(d => {
                resolve(d)
            }).catch(err => reject(err))
        });
    },

    updateDialog(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/dialogs/update`, data).then(d => d.data).then(d => {
                resolve(d)
            }).catch(err => reject(err))
        });
    },

    deleteDialog(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/dialogs/delte/${id}`).then(d => d.data).then(d => {
                resolve(d)
            }).catch(err => reject(err))
        });
    },

    saveDialogAnswer(userId, dialogExerciseId, cardNumber) {
        return new Promise((resolve, reject) => {

        });
    }

}

export default DialogAPI
