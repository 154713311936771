import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import {BlueButton} from "../../ira/ui/Buttons";
import {Input, Textarea} from "../../ira/ui/Inputs";
import NiceConfirm from "../../modals/NiceConfirm";
import * as actions from "../../../redux/actions/GroupsActions";
import CommonHelper from "../../../helpers/CommonHelper";
import {translate} from "../../../utils/Translate";

export default function UpdateGroupForm(props) {
    const {
        loading = false,

        onSave = d => {

        },

        canDelete = false,

        onDelete = () => {

        }

    } = props;

    const [name, setName] = useState(props?.name || '');
    const [description, setDescription] = useState(props?.description || '');

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);


    return (
        <Wrapper>

            <Row>
                <Label>
                    {translate('group_name')}
                </Label>
                <Input placeholder={''} value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                    {translate('group_description')}
                </Label>
                <Textarea placeholder={''} value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Row>

            <BottomPlaceholder>
                <BlueButton onClick={() => {
                    onSave({
                        name: name,
                        description: description
                    })
                }}>
                    <Spin theme={'alfa-on-color'} visible={loading}/>
                    {loading == true ? null :
                        translate('save')
                    }

                </BlueButton>
            </BottomPlaceholder>

            {canDelete == false ? null :
                <BottomPlaceholder style={{marginTop: 20, textAlign: 'center'}}>
                    <DeleteSpan onClick={() => {
                        setDeleteModalVisible(true);
                    }}>
                        {translate('delete_group')}
                    </DeleteSpan>
                </BottomPlaceholder>
            }

            {deleteModalVisible == false ? null :
                <NiceConfirm
                    loading={loading}
                    confirmText={'Delete'}
                    cancelText={'Cancel'}
                    heading={'Delete group'}
                    subHeading={'Please, confirm that you want to delete'}
                    onCancel={() => {
                        setDeleteModalVisible(false);
                    }}
                    onConfirm={async () => {
                        if (loading == true) {
                            return;
                        }
                        onDelete();
                        setDeleteModalVisible(false);
                    }}
                >

                </NiceConfirm>
            }

        </Wrapper>
    );
}

const DeleteSpan = styled.span`
  opacity: 0.8;
  color: red;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`

`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  margin-bottom: 10px;
`;