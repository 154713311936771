const themes_text = `True identity | Who you are vs. What you do | A1 | This is me
True identity | Who you are vs. What you do | A1 | So what do you do all day?
True identity | Who you are vs. What you do | A1 | Track your feelings!
True identity | Who you are vs. What you do | A2 | What is personality?
True identity | Who you are vs. What you do | A2 | Study tips
True identity | Who you are vs. What you do | A2 | Don't worry! Be happy!
True identity | Who you are vs. What you do | B1 | It really happened to me!
True identity | Who you are vs. What you do | B1 | Are you new to living independently?
True identity | Who you are vs. What you do | B1 | Where does my money go?
True identity | Who you are vs. What you do | B1 | GO for it!
True identity | Who you are vs. What you do | B2 | How is your mind wired?
True identity | Who you are vs. What you do | B2 | Any fears of uni life?
True identity | Who you are vs. What you do | B2 | I feel good, I knew that I would, now
True identity | Who you are vs. What you do | C1 | The key ingredient of success
True identity | Who you are vs. What you do | C1 | Social-Emotional Learning: what to know?
True identity | Who you are vs. What you do | C1 | What is a peer pressure?

True identity | Studentship | A1 | Let's build a picture of a modern student
True identity | Studentship | A1 | Together forever!
True identity | Studentship | A1 | Networking
True identity | Studentship | A2 | Are you a responsible student?
True identity | Studentship | A2 | The great divide
True identity | Studentship | A2 | You are not alone!
True identity | Studentship | B1 | Sorting Hat
True identity | Studentship | B1 | How is it in a melting pot?
True identity | Studentship | B1 | Getting through the dogs and the quicksand
True identity | Studentship | B2 | Are you a single or a team player?
True identity | Studentship | B2 | Be active and proactive!
True identity | Studentship | B2 | Talent show
True identity | Studentship | C1 | Can you represent your university?
True identity | Studentship | C1 | Unity community
True identity | Studentship | C1 | The Triple Helix

True identity | Internship | A1 | W(ork)-factor?
True identity | Internship | A1 | Do we live to work or work to live?
True identity | Internship | A1 | Working round the clock
True identity | Internship | A2 | Welcome on board!
True identity | Internship | A2 | What's the right job for you?
True identity | Internship | A2 | Getting through paper work
True identity | Internship | B1 | The best company ever!
True identity | Internship | B1 | Do's and Don'ts at work
True identity | Internship | B1 | Climbing the ladder
True identity | Internship | B2 | Want a stunning career?
True identity | Internship | B2 | Job application: ins and outs
True identity | Internship | B2 | Interview with flying colours
True identity | Internship | C1 | Great expectations
True identity | Internship | C1 | Ever Heard of VUCA job market?
True identity | Internship | C1 | Emerging jobs: now or never!

True identity | Unity of soul and body | A1 | Are you a coach potato?
True identity | Unity of soul and body | A1 | The best work of art
True identity | Unity of soul and body | A1 | Fill my prescription!
True identity | Unity of soul and body | A2 | Fit feels great!
True identity | Unity of soul and body | A2 | Our digital selves
True identity | Unity of soul and body | A2 | A good appointment!
True identity | Unity of soul and body | B1 | The first wealth is health.
True identity | Unity of soul and body | B1 | Born to stand out!
True identity | Unity of soul and body | B1 | Don't hurt my avatar!
True identity | Unity of soul and body | B1 | When to visit an emergency room?
True identity | Unity of soul and body | B2 | A healthy outside starts from inside.
True identity | Unity of soul and body | B2 | Trapped in metauniverse.
True identity | Unity of soul and body | B2 | Are you a Hypochondriac?
True identity | Unity of soul and body | C1 | Are you an anti-vaxer?
True identity | Unity of soul and body | C1 | It's time for digital detox!
True identity | Unity of soul and body | C1 | The "spare parts person"?

Student habitat | Housing | A1 | Dormitory-Land
Student habitat | Housing | A1 | You are in the Dorm now!
Student habitat | Housing | A1 | Can I cook?
Student habitat | Housing | A2 | Renting a flat?
Student habitat | Housing | A2 | I'm still loving chores!
Student habitat | Housing | A2 | Shopping animal
Student habitat | Housing | B1 | Why did I leave my parents house?
Student habitat | Housing | B1 | Inconvenience or Emergency?
Student habitat | Housing | B1 | Dorm: life or survival?
Student habitat | Housing | B2 | Make your home in the dorm
Student habitat | Housing | B2 | A dorm outlaw or a wallflower?
Student habitat | Housing | B2 | Ready for Co-Living?
Student habitat | Housing | C1 | Student Housing: Trends, Preferences and Needs
Student habitat | Housing | C1 | Apartment therapy
Student habitat | Housing | C1 | Odd or Adorable?

Student habitat | Nutrition | A1 | We are what we eat
Student habitat | Nutrition | A1 | Food-story
Student habitat | Nutrition | A1 | Fuel for students
Student habitat | Nutrition | A2 | A front burner
Student habitat | Nutrition | A2 | Great Food Love
Student habitat | Nutrition | A2 | Food habits
Student habitat | Nutrition | B1 | Nitty-gritty of foods
Student habitat | Nutrition | B1 | Word of mouth
Student habitat | Nutrition | B1 | Food 4 Future
Student habitat | Nutrition | B2 | Cornucopia of diets
Student habitat | Nutrition | B2 | Don't fall for this diet scam
Student habitat | Nutrition | B2 | A Mindful diet
Student habitat | Nutrition | C1 | Food engineering
Student habitat | Nutrition | C1 | Go bananas
Student habitat | Nutrition | C1 | Food security

Student habitat | University premises | A1 | Map yourself!
Student habitat | University premises | A1 | Campus Infrastructure
Student habitat | University premises | A1 | The Victorious spirit!
Student habitat | University premises | A2 | Lost inside
Student habitat | University premises | A2 | Find the right fit
Student habitat | University premises | A2 | Let's soar together
Student habitat | University premises | B1 | Empower yourself!
Student habitat | University premises | B1 | A smart place!
Student habitat | University premises | B1 | The beauty of the past
Student habitat | University premises | B2 | A welcoming site!
Student habitat | University premises | B2 | #We'veGotYouWhereverYouAre
Student habitat | University premises | B2 | Amazing University Buildings
Student habitat | University premises | C1 | Time, place and manner. Codes of Conduct
Student habitat | University premises | C1 | Inspire, innovate, achieve
Student habitat | University premises | C1 | Green university

Student habitat | City life | A1 | Find me in the city!
Student habitat | City life | A1 | Hit the Road
Student habitat | City life | A1 | City navigation
Student habitat | City life | A2 | Lost in the city
Student habitat | City life | A2 | A dream city!
Student habitat | City life | A2 | Can I give you a ride?
Student habitat | City life | B1 | Big City Life
Student habitat | City life | B1 | A trip to the downtown
Student habitat | City life | B1 | A smart city!
Student habitat | City life | B2 | I'm a happy city dweller
Student habitat | City life | B2 | A city from scratch?
Student habitat | City life | B2 | Green cities
Student habitat | City life | C1 | Do you feel safe in the city?
Student habitat | City life | C1 | A helpful city
Student habitat | City life | C1 | Future of cities

Study habits and skills | Academic life/Environment | A1 | Academics first!
Study habits and skills | Academic life/Environment | A1 | Living in a multicultural world
Study habits and skills | Academic life/Environment | A1 | The meaning of academic life
Study habits and skills | Academic life/Environment | A2 | The education melting pot
Study habits and skills | Academic life/Environment | A2 | People rather than nations
Study habits and skills | Academic life/Environment | A2 | Cheat! What’s the problem?
Study habits and skills | Academic life/Environment | B1 | The ethics of faculty behavior
Study habits and skills | Academic life/Environment | B1 | Cultural Diversity in the Classroom
Study habits and skills | Academic life/Environment | B1 | Why Students Plagiarize / Is plagiarism really on the rise?
Study habits and skills | Academic life/Environment | B2 | Staying on top of your exam nerves
Study habits and skills | Academic life/Environment | B2 | The thief of time: procrastination
Study habits and skills | Academic life/Environment | B2 | Reimagine education
Study habits and skills | Academic life/Environment | C1 | In Person and Remote at the Same Time?
Study habits and skills | Academic life/Environment | C1 | Rethinking academic traditions of the 21st century
Study habits and skills | Academic life/Environment | C1 | Outperform yourself!

Study habits and skills | Learning outside /Additional Education / Satellite Education | A1 | Learning outside the Uni
Study habits and skills | Learning outside /Additional Education / Satellite Education | A1 | Workshops: Ready for something new?
Study habits and skills | Learning outside /Additional Education / Satellite Education | A1 | Creating a Portfolio
Study habits and skills | Learning outside /Additional Education / Satellite Education | A2 | E-learning: Access granted
Study habits and skills | Learning outside /Additional Education / Satellite Education | A2 | Dream team skills
Study habits and skills | Learning outside /Additional Education / Satellite Education | A2 | Co-learning
Study habits and skills | Learning outside /Additional Education / Satellite Education | B1 | Self-directed learning
Study habits and skills | Learning outside /Additional Education / Satellite Education | B1 | Taking on a teacher's role
Study habits and skills | Learning outside /Additional Education / Satellite Education | B1 | Summer schools and winter courses
Study habits and skills | Learning outside /Additional Education / Satellite Education | B2 | What kind of learner are you?
Study habits and skills | Learning outside /Additional Education / Satellite Education | B2 | What is your calling?
Study habits and skills | Learning outside /Additional Education / Satellite Education | B2 | Believe In Your  Skills But Never Stop Improving
Study habits and skills | Learning outside /Additional Education / Satellite Education | C1 | The science of learning / How to tune your brain?
Study habits and skills | Learning outside /Additional Education / Satellite Education | C1 | Enhancing learning strengths
Study habits and skills | Learning outside /Additional Education / Satellite Education | C1 | From Boomers to Zoomers

Study habits and skills | Go global | A1 | Exchange opportunities, looking for suitable programs and scholarships
Study habits and skills | Go global | A1 | I am going abroad!
Study habits and skills | Go global | A1 | Traveler's fever
Study habits and skills | Go global | A2 | Do I meet the requirements?
Study habits and skills | Go global | A2 | At the students' center
Study habits and skills | Go global | A2 | Making new friends
Study habits and skills | Go global | B1 | University ranking
Study habits and skills | Go global | B1 | Adapting to international study, research and life environment/context
Study habits and skills | Go global | B1 | Taking first steps
Study habits and skills | Go global | B2 | How to design a personal education track
Study habits and skills | Go global | B2 | What are my academic ambitions?
Study habits and skills | Go global | B2 | Job fairs
Study habits and skills | Go global | C1 | University 2035
Study habits and skills | Go global | C1 | A would-be scientist.
Study habits and skills | Go global | C1 | A whizz kid

Study habits and skills | Not a rocket science | A1 | Welcome to a scientific community!
Study habits and skills | Not a rocket science | A1 | Scientific identity
Study habits and skills | Not a rocket science | A1 | What makes a discoverer?
Study habits and skills | Not a rocket science | A2 | Where does science come from?
Study habits and skills | Not a rocket science | A2 | The voice of a scientist
Study habits and skills | Not a rocket science | A2 | Getting specific
Study habits and skills | Not a rocket science | B1 | Beyond the numbers
Study habits and skills | Not a rocket science | B1 | The power of visual
Study habits and skills | Not a rocket science | B1 | How magic really works
Study habits and skills | Not a rocket science | B2 | Participants or consumers
Study habits and skills | Not a rocket science | B2 | Flagman initiative
Study habits and skills | Not a rocket science | B2 | Thinking in global terms
Study habits and skills | Not a rocket science | C1 | Nuts and bolts of public speaking
Study habits and skills | Not a rocket science | C1 | Academic freedom
Study habits and skills | Not a rocket science | C1 | In the crystal ball

The world around | Environment | A1 | What’s the weather like?
The world around | Environment | A1 | It’s a wonderful world!
The world around | Environment | A1 | Dear, Wildlife!
The world around | Environment | A2 | Places to live
The world around | Environment | A2 | It's getting better
The world around | Environment | A2 | Natural wonders
The world around | Environment | B1 | Going eco-friendly.
The world around | Environment | B1 | Preventing the disaster.
The world around | Environment | B1 | It's time to talk about climate change
The world around | Environment | B2 | Nature needs you
The world around | Environment | B2 | Hope for the future
The world around | Environment | B2 | Mindful activism
The world around | Environment | C1 | Inspired by nature
The world around | Environment | C1 | Live and let others live!
The world around | Environment | C1 | Are we leaving the planet?

The world around | Global challenges | A1 | Good vs Bad
The world around | Global challenges | A1 | Social issues?
The world around | Global challenges | A1 | Say NO to wars!
The world around | Global challenges | A2 | Are there too many of us on the planet?
The world around | Global challenges | A2 | Can we feed the world?
The world around | Global challenges | A2 | Peace forever!
The world around | Global challenges | B1 | Going global?
The world around | Global challenges | B1 | Under pressure
The world around | Global challenges | B1 | New wave of war
The world around | Global challenges | B2 | Hybrid wars
The world around | Global challenges | B2 | Welcomed or abandoned?
The world around | Global challenges | B2 | Culture of tolerance
The world around | Global challenges | C1 | Artificial intelligence- friend or foe?
The world around | Global challenges | C1 | Cyber insecurity: we are watching you 
The world around | Global challenges | C1 | Shape the future!

The world around | Politics and Policy | A1 | Do you watch the news?
The world around | Politics and Policy | A1 | News stories
The world around | Politics and Policy | A1 | Who is who
The world around | Politics and Policy | A2 | Breaking political news
The world around | Politics and Policy | A2 | Believe me if you can!
The world around | Politics and Policy | A2 | Political agenda: urgent political issues
The world around | Politics and Policy | B1 | Propaganda and fake news
The world around | Politics and Policy | B1 | The labyrinth of power
The world around | Politics and Policy | B1 | Actions speak louder than words.
The world around | Politics and Policy | B2 | Global players
The world around | Politics and Policy | B2 | We are the shapers of the future!
The world around | Politics and Policy | B2 | Excersise your rights or follow the flow.
The world around | Politics and Policy | C1 | Getting the upper hand
The world around | Politics and Policy | C1 | Political games we play
The world around | Politics and Policy | C1 | Power brokers: who rules the world?

The world around | Economics and Economy? Money and Finance | A1 | Exchange me!
The world around | Economics and Economy? Money and Finance | A1 | Shopping live
The world around | Economics and Economy? Money and Finance | A1 | Are you happy with your purchase?
The world around | Economics and Economy? Money and Finance | A2 | Cashless world
The world around | Economics and Economy? Money and Finance | A2 | What is it like to live on $1 a day?
The world around | Economics and Economy? Money and Finance | A2 | He has done the trick!
The world around | Economics and Economy? Money and Finance | B1 | Control your finances!
The world around | Economics and Economy? Money and Finance | B1 | Working student
The world around | Economics and Economy? Money and Finance | B1 | Where would the money come from?
The world around | Economics and Economy? Money and Finance | B2 | Be your own boss?
The world around | Economics and Economy? Money and Finance | B2 | Online money
The world around | Economics and Economy? Money and Finance | B2 | What a deal!
The world around | Economics and Economy? Money and Finance | C1 | Guarantee a better future?
The world around | Economics and Economy? Money and Finance | C1 | Are you a cryptocurrency expert?
The world around | Economics and Economy? Money and Finance | C1 | Financial crisis
`

export default themes_text;