import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import * as exercisesActions from '../../../redux/actions/QuestionnairesActions'
import * as dialogsActions from '../../../redux/actions/DialogsActions'
import NiceModal from "../../modals/NiceModal";
import Tabs, {TabItem} from "../../ui/Tabs";
import {Code} from "react-content-loader";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";

import ava from '../../../assets/img/online-learning-using-tablet.png'
import toast from "react-hot-toast";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";
import {translate} from "../../../utils/Translate";
import UpdateQuestionnaireForm from "../forms/UpdateQuestionnaireForm";
import QuestionnairePanel from "./QuestionnairePanel";

import confused_man from '../../../assets/lottie/confused-man-unable-to-find-the-file.json'
import LottieLoader from "../../loaders/LottieLoader";
import QuestionsEditorTool from "../tools/QuestionsEditorTool";
import {Input} from "../../ira/ui/Inputs";
import tick_img from "../../../assets/img/green_check.svg";
import {Set} from "immutable";
import {hasIntersection} from "../../exercises/panels/AllExercisesPanel";
import ExercisesFoldersPanel from "../../smart_folders/panels/ExecisesFoldersPanel";

export default function AllQuestionnairesPanel(props) {
    const {
        mode = 'my',
        pageSize = 100,
        selectMode = false,
        onSelect = x => {

        },
        creatorId,
        selectedSet = Set()
    } = props;

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState('');

    const {
        currentUser,
        exercises = [],
        loading,
        currentUserId,
        isModerator
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allExercises = state.questionnaires.questionnairesMap.toArray();
        let exercises = (mode == 'my') ? allExercises.filter(x => (x.creatorId == currentUserId)) : allExercises;

        let aGroups = state.accessGroups.accessGroupsMap.toArray().filter(x => ((x?.emails || []).indexOf(currentUser.email) == -1 && x?.creatorId != currentUserId));
        let aGroupsIds = aGroups.map(xx => xx?.id).filter(x => (x != undefined));
        if (mode == 'shared') {
            exercises = exercises.filter(x => hasIntersection((x?.accessGroupsIds || []), aGroupsIds));
        }
        if (mode == 'public') {
            exercises = exercises.filter(x => (x?.access == 'public' || x?.access == undefined));
            // exercises = exercises;
        }

        exercises = exercises.sort((a, b) => (+b.timestamp - +a.timestamp));
        if (creatorId != undefined) {
            exercises = exercises.filter(x => (x?.creatorId == creatorId));
        }
        return {
            currentUser: currentUser,
            isModerator: (currentUser != undefined && currentUser.isModerator == true),
            exercises: exercises,
            loading: state.questionnaires.loading,
            currentUserId: currentUserId
        }
    }, [selectedId]));

    useEffect(() => {
        dispatch(exercisesActions.loadAllQuestionnaires());
    }, []);

    let selectedExercise = (selectedId == undefined) ? undefined : exercises.filter(x => (x.id == selectedId))[0];

    let xExercises = exercises;
    if (searchText.length > 2) {
        xExercises = xExercises.filter(x => {
            let s = `${x.name || ''} ${x.description || ''} ${x.transcript || ''}`.toLowerCase();
            return (s.indexOf(searchText.toLowerCase()) > -1);
        });
    }

    let visExercises = xExercises.slice(0, (+page + 1) * pageSize);

    return (
        <Wrapper>

            {!(exercises.length == 0 && loading == true) ? null :
                <Code/>
            }


            {mode == 'my' ? null :
                <TopPlacehodler>
                    {/*{mode != 'my' ? null :*/}
                    {/*    <div>*/}
                    {/*        <GreenButton onClick={() => {*/}
                    {/*            setAddModalVisible(true);*/}
                    {/*        }}>*/}
                    {/*            {translate('create_questionnaires')}*/}
                    {/*        </GreenButton>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <SearchPlaceholder>
                        <Input
                            placeholder={translate('search')}
                            onChange={evt => {
                                let s = evt.target.value;
                                setSearchText(s);
                                setPage(0);
                            }}
                        />
                    </SearchPlaceholder>

                </TopPlacehodler>
            }

            {mode != 'my' ? null :
                <>
                    <ExercisesFoldersPanel
                        type={'questionnaire'}

                        exerciseSelectMode={selectMode}
                        selectedExerciseSet={selectedSet}
                        onExerciseSelect={onSelect}

                        onAddExerciseClick={() => {
                            setAddModalVisible(true);
                        }}
                        onEditExerciseClick={(id) => {
                            setSelectedId(id);
                        }}

                    />
                </>
            }

            {mode != 'public' ? null :
                <>
                    <ItemsPlaceholder>
                        {visExercises.map((a, i) => {
                            let isSelected = selectedSet.has(a.id);
                            return (
                                <ExItem key={`ex_${a.id}_${i}`} onClick={() => {
                                    setSelectedId(a.id);
                                }}>
                                    <AvatarImage src={a.avatar || a.imageUrl || ava}/>
                                    <Name>
                                        {a.name}
                                    </Name>
                                    {/*<Description>*/}
                                    {/*    {a.description}*/}
                                    {/*</Description>*/}
                                    {selectMode == false ? null :
                                        <div style={{
                                            marginTop: 10,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            {isSelected == false ? null :
                                                <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                            }
                                            <SelectSpan onClick={() => {
                                                onSelect(a);
                                            }}>
                                                {translate('select')}
                                            </SelectSpan>
                                        </div>
                                    }
                                </ExItem>
                            )
                        })}
                    </ItemsPlaceholder>

                    {loading == true || exercises.length < (pageSize * (+page + 1)) ? null :
                        <BottomPlaceholder>
                            <BlueButton onClick={() => {
                                setPage(+page + 1);
                            }}>
                                Загрузить еще
                            </BlueButton>
                        </BottomPlaceholder>
                    }
                </>
            }

            {selectedExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <Tabs
                            tabs={[
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Информация',
                                        content: (
                                            <TabItem>
                                                <UpdateQuestionnaireForm
                                                    {...selectedExercise}
                                                    loading={loading}
                                                    onSave={async d => {
                                                        await dispatch(exercisesActions.updateQuestionnaire({
                                                            ...d,
                                                            id: selectedExercise?.id
                                                        }))
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Редактор',
                                        content: (
                                            <TabItem>
                                                <QuestionsEditorTool
                                                    loading={loading}
                                                    {...selectedExercise}
                                                    onChange={async arr => {
                                                        await dispatch(exercisesActions.updateQuestionnaire({
                                                            questions: arr,
                                                            id: selectedExercise?.id
                                                        }))
                                                    }}
                                                />
                                                {/*<div style={{width: '100%', height: '60vh', boxSizing: 'border-box'}}>*/}
                                                {/*    <LottieLoader*/}
                                                {/*        json={confused_man}*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                            </TabItem>
                                        )
                                    },
                                {
                                    label: 'Превью',
                                    content: (
                                        <TabItem>
                                            <ExPreviewPlaceholder>

                                                <QuestionnairePanel
                                                    id={selectedExercise?.id}
                                                />

                                                <ShareExecisePlaceholder>
                                                    <ShareLinkSpan onClick={() => {
                                                        CommonHelper.linkTo(`/share/questionnaires/${selectedExercise?.id}`, true);
                                                    }}>
                                                        <ShareImg src={share_img}/>
                                                        {translate('share_questionnaire')}
                                                    </ShareLinkSpan>
                                                </ShareExecisePlaceholder>


                                            </ExPreviewPlaceholder>

                                        </TabItem>
                                    )
                                }
                            ].filter(x => (x != undefined))}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <UpdateQuestionnaireForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(exercisesActions.createQuestionnaire({
                                    creatorId: currentUserId,
                                    ...d
                                }));
                                setAddModalVisible(false);
                                setSelectedId(pld?.exercise?.id);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const SearchPlaceholder = styled.div`
  width: 240px;
`;


const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareExecisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  margin-top: 10px;
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const ExPreviewPlaceholder = styled.div`
  width: 720px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`

`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const TopPlacehodler = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InnerPlaceholder = styled.div`
  width: 1080px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: calc(100vw - 60px);
  }

  @media (max-width: 720px) {
    width: 95vw;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Name = styled.div`
  font-weight: bold;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;