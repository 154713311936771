import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import {getCurrentItem, useInterval} from "../../treadmills_exercises/treadmill/tools/TreadmillPlayerTool";

const getInfoForSubs = (subtitles, id) => {
    let k;
    for (let i in subtitles) {
        if (subtitles[i].id == id) {
            k = i;
            break;
        }
    }
    return {
        currentItem: (k == undefined || id == undefined) ? undefined : subtitles[k],
        nextItem: (k == undefined || id == undefined || +k >= subtitles.length - 1) ? undefined : subtitles[+k + 1],
        prevItem: (k == undefined || id == undefined || +k == 0) ? undefined : subtitles[+k - 1]
    }
}

export default function SubtitlesPlayer(props) {
    const {
        url,
        subtitles = [],
        avatar,
        currentStyle = {},
        sideStyle = {}
    } = props;

    const videoRef = useRef();
    const [videoDuration, setVideoDuration] = useState(undefined);
    const [currentItemId, setCurrentItemId] = useState();

    useInterval(() => {
        if (videoDuration == undefined || window.isNaN(videoDuration)) {
            try {
                let dd = videoRef.current.duration;
                console.log('videoDuration = ', videoDuration);
                if (dd != undefined) {
                    setVideoDuration(dd);
                }
            } catch (exc0) {

            }
        }
        try {
            let currPosition = videoRef.current.currentTime;
            let item = getCurrentItem(subtitles, currPosition);
            // console.log('--->> item = ', item);
            if (item?.id != currentItemId) {
                setCurrentItemId(item?.id)
            }
        } catch (exc) {

        }
    }, 100);

    const {currentItem, prevItem, nextItem} = getInfoForSubs(subtitles, currentItemId);
    console.log('currentItem = ', currentItem);

    if (url == undefined) {
        return null;
    }


    return (
        <Wrapper>
            <Player controls={true}
                    poster={avatar || DEFAULT_VIDEO_PLACEHOLDER}
                    ref={videoRef}
            >
                <source src={url}/>
            </Player>
            <RightPlaceholder>

                {prevItem == undefined ? null :
                    <SideItem style={sideStyle}>
                        {prevItem?.text}
                    </SideItem>
                }

                {currentItem == undefined ? null :
                    <CurrItem style={currentStyle}>
                        {currentItem?.text}
                    </CurrItem>
                }

                {nextItem == undefined ? null :
                    <SideItem style={sideStyle}>
                        {nextItem?.text}
                    </SideItem>
                }

            </RightPlaceholder>
        </Wrapper>
    );
}

const CurrItem = styled.div`
  font-weight: bold;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px dashed whitesmoke;
  border-bottom: 1px dashed whitesmoke;
  text-align: center;
  width: 100%;
  color: darkred;
`;

const RightPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex: 1;
`;

const SideItem = styled.div`
  font-size: 18px;
  padding: 10px;
  opacity: 0.6;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: whitesmoke;
  padding: 10px;
  border-radius: 4px;
`;

const Player = styled.video`
  width: 400px;
  height: 320px;
  background: white;
  margin-right: 20px;
`;