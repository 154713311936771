import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function DownloadSpanTool(props) {
    const {
        url,
        fileName
    } = props;

    const [loading, setLoading] = useState(false);
    if (url == undefined || url == '') {
        return null;
    }

    return (
        <Wrapper onClick={async () => {
            if (loading == true) {
                return;
            }
            try {
                setLoading(true);
                let response = await axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob'
                });
                setLoading(false);
                const url_ = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url_;
                let extArr = url.split('.');
                let extension = (extArr.length < 2) ? undefined : extArr[extArr.length - 1];
                link.setAttribute('download', `${fileName}${extension == undefined ? '' : `.${extension}`}`);
                document.body.appendChild(link);
                link.click();
            } catch (exc) {
                console.log('caught exc = ', exc);
            }
        }}>
            {loading == true ? 'скачивание...' : `скачать файл`}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  cursor: pointer;
`;