import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const Histogram = ({ data, bins, chartWidth, chartHeight }) => {
    // Function to calculate bins
    const calculateBins = (data, bins) => {
        const max = Math.max(...data.map(item => item.number));
        const min = Math.min(...data.map(item => item.number));
        const binSize = (max - min) / bins;

        let binArray = new Array(bins).fill(0);

        data.forEach(item => {
            const binIndex = Math.floor((item.number - min) / binSize);
            binArray[binIndex < bins ? binIndex : bins - 1]++;
        });

        return binArray.map((count, index) => ({
            bin: `${(min + binSize * index).toFixed(2)} - ${(min + binSize * (index + 1)).toFixed(2)}`,
            count
        }));
    };

    const histogramData = useMemo(() => calculateBins(data, bins), [data, bins]);

    return (
        <BarChart width={chartWidth} height={chartHeight} data={histogramData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="bin" />
            <YAxis scale="sqrt" domain={['auto', 'auto']} />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
    );
};

export default Histogram;
