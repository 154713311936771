import {Map} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    selectedDrochkaExerciseId: undefined,

    drochkaExercisesMap: Map(),
    error: undefined

}

const DrochkaExercisesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_DROCHKA_EXERCISE:
        case types.UPDATE_DROCHKA_EXERCISE:
        case types.DELETE_DROCHKA_EXERCISE:
        case types.LOAD_DROCHKA_EXERCISES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_DROCHKA_EXERCISE_FAIL:
        case types.UPDATE_DROCHKA_EXERCISE_FAIL:
        case types.DELETE_DROCHKA_EXERCISE_FAIL:
        case types.LOAD_DROCHKA_EXERCISES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_DROCHKA_EXERCISE_SUCCESS:
        case types.UPDATE_DROCHKA_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                drochkaExercisesMap: getGracefullyMergedContainersMap(state.drochkaExercisesMap, [action.exercise]),
                selectedDrochkaExerciseId: action?.exercise?.id
            }


        case types.DELETE_DROCHKA_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                drochkaExercisesMap: state.drochkaExercisesMap.delete(action.id),
            }


        case types.LOAD_DROCHKA_EXERCISES_SUCCESS:
            return {
                ...state,
                loading: false,
                drochkaExercisesMap: getGracefullyMergedContainersMap(state.drochkaExercisesMap, action.exercises)
            }

        // case types.SELECT_EXERCISE:
        //     return {
        //         ...state,
        //         selectedDrochkaExerciseId: action.id
        //     }

        case types.CREATE_LESSON:
        case types.DELETE_LESSON:
        case types.CREATE_GROUP:
        case types.DELETE_GROUP:
        case types.SELECT_LESSON:
            return {
                ...state,
                selectedDrochkaExerciseId: undefined
            }


        default:
            return state;
    }

}

export default DrochkaExercisesReducer;
