import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'

import Spin from 'arui-feather/spin'

import close_image from '../../../assets/img/x.svg'
import {Input} from "../../ui/Input";

import {BlueButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";

export default function ElementsEditorForm(props) {
    const {
        elements = [],
        onChange = arr => {

        },
        canEdit = true
    } = props;


    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [errorMessage, setErrorMessage] = useState(undefined);

    return (
        <Wrapper>

            <EmailsList>
                {elements.map((a, i) => {
                    return (
                        <EmailItem>
                            <NamePlaceholder>
                                {a?.value}
                            </NamePlaceholder>
                            <ControlsPlaceholder>
                                {canEdit == false ? null :
                                    <CloseImg src={close_image} onClick={() => {
                                        let newEmails = elements.filter(xx => (xx != a));
                                        onChange(newEmails);
                                    }}/>
                                }
                            </ControlsPlaceholder>
                        </EmailItem>
                    )
                })}
            </EmailsList>

            {errorMessage == undefined ? null :
                <div style={{color: 'red'}}>
                    {errorMessage}
                </div>
            }

            {canEdit == false ? null :
                <BottomPlaceholder>
                    <Input value={text}
                           placeholder={'Новый элемент'}
                           onChange={evt => {
                               setText(evt.target.value);
                               setErrorMessage(undefined);
                           }}/>

                    <BlueButton style={{marginLeft: 10, height: 30}}
                                onClick={async () => {
                                    setLoading(true);
                                    setErrorMessage(undefined);
                                    let newEmails = elements.filter(x => (x != text)).concat([{value: text, t: +new Date()}]);
                                    onChange(newEmails);
                                    setText('');
                                    setLoading(false);
                                }}>
                        <Spin visible={loading}/>
                        добавить
                    </BlueButton>
                </BottomPlaceholder>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const CloseImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const EmailItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NamePlaceholder = styled.div`

`;

const ControlsPlaceholder = styled.div`

`;

const EmailsList = styled.div`

`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
