import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import BulkDndPlaceholder from "../../bulk_uploader/BulkDndPlaceholder";
import * as materialsActions from "../../../redux/actions/MaterialsActions";
import UpdateMaterialForm from "../forms/UpdateMaterialForm";
import NewBulkUploader from "../../bulk_uploader/NewBulkUploader";

export default function BulkMaterialsPanel(props) {
    const [selectedLocalVideo, setSelectedLocalVideo] = useState();
    let selectedUrl = selectedLocalVideo?.url;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(materialsActions.getMyMaterials());
    }, []);

    const {
        currentUser,
        materialsMap,
        patientMaterial,
        loading
    } = useMappedState(useCallback(state => {
        let patientMaterial = (selectedUrl == undefined) ? undefined : state.materials.materialsMap.toArray().filter(xx => (xx.url == selectedUrl))[0];
        return {
            materialsMap: state.materials.materialMap,
            loading: state.materials.loading,
            patientMaterial: patientMaterial
        }
    }, [selectedUrl]));

    let isUpdateMode = (patientMaterial != undefined);
    let isCreateMode = (patientMaterial == undefined && selectedLocalVideo != undefined);


    return (
        <Wrapper>

            <Left>
                {/*<BulkDndPlaceholder*/}
                {/*    selectable={true}*/}
                {/*    selectedUrl={selectedUrl}*/}
                {/*    onSelectVideo={x => {*/}
                {/*        console.log('onSelectVideo: x = ', x);*/}
                {/*        setSelectedLocalVideo(x);*/}
                {/*    }}*/}
                {/*    onClean={() => {*/}
                {/*        setSelectedLocalVideo(undefined);*/}
                {/*    }}*/}
                {/*/>*/}

                <NewBulkUploader
                    selectable={true}
                    selectedUrl={selectedUrl}
                    onSelectVideo={x => {
                        console.log('onSelectVideo: x = ', x);
                        setSelectedLocalVideo(x);
                    }}
                />


            </Left>

            {selectedLocalVideo == undefined ? null :
                <Right key={`${selectedLocalVideo?.hash}_${patientMaterial?.id}`}>
                    {isCreateMode == false ? null :
                        <div>
                            <SecHeading>
                                Создание
                            </SecHeading>
                            <UpdateMaterialForm
                                loading={loading}
                                {...selectedLocalVideo}
                                onSave={async d => {
                                    await dispatch(materialsActions.createMaterial(d));
                                }}
                            />
                        </div>
                    }
                    {isUpdateMode == false ? null :
                        <div>
                            <SecHeading>
                                Edit
                            </SecHeading>
                            <UpdateMaterialForm
                                loading={loading}
                                {...patientMaterial}
                                onSave={async d => {
                                    await dispatch(materialsActions.updateMaterial({
                                        id: patientMaterial?.id,
                                        ...d
                                    }));
                                }}
                            />
                        </div>
                    }
                </Right>
            }

        </Wrapper>
    );
}

const SecHeading = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 18px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  height: 100%;
`;

const Left = styled.div`
  width: 520px;
  margin-right: 20px;
  min-width: 520px;
  max-height: calc(100vh - 100px);
  overflow: auto;
`;

const Right = styled.div`
  //flex: 1;
  padding: 10px;
  box-sizing: border-box;
  width: calc(100% - 600px);
`;