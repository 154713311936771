import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Textarea} from "../../ira/ui/Inputs";
import {YellowButton} from "../../ira/ui/Buttons";

import sel_box from '../../../assets/img/yellow_checkbox.svg'
import not_sel_box from '../../../assets/img/black_empty_checkbox.svg'

let getSortedStringsArr = arr => {
    return arr.sort((a, b) => {
        if (`${a}`.toLowerCase() > `${b}`.toLowerCase()) {
            return 1;
        }
        if (`${a}`.toLowerCase() < `${b}`.toLowerCase()) {
            return -1;
        }
        return 0;
    })
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function SelectAnswerTool(props) {
    const {
        onSave = s => {

        },
        loading = false,
        isPreview = false,

        selectOptions = [],
        answer = '',
        showIfCorrect = false,

        autoSave = false,
        canAnswer = true,
        showCorrectAnswer = false

    } = props;
    // const [answer, setAnswer] = useState(props.answer || '');
    // useEffect(() => {
    //     setAnswer(props.answer);
    // }, [props.answer]);

    console.log('SelectAnswerTool: render: answer = ', answer);

    const [text, setText] = useState(props?.answer || '');

    useEffect(() => {
        setText(answer || '');
    }, [answer]);

    console.log('selectOptions = ', selectOptions);

    let ansItems = getSortedStringsArr((text == undefined ? '' : text).split('|').filter(x => (x != '')));
    // console.log('ansItems = ', ansItems);

    let correctAnswerString = getSortedStringsArr(selectOptions.filter(x => (x.isCorrect)).map(x => x.text)).join('|');

    return (
        <Wrapper>
            <SelectOptionsPlaceholder>
                {selectOptions.map((a, i) => {
                    let isSelected = (ansItems.indexOf(a.text) > -1);
                    let {isCorrect} = a;
                    let color = 'black';
                    if (showCorrectAnswer == true){
                        // if (isCorrect == true && isSelected == true){
                        //     color = 'green';
                        // }else{
                        //     // color = 'red';
                        // }
                        if (isCorrect == true){
                            color = 'green';
                        }
                    }
                    return (
                        <SelectOption key={a.id}>
                            <SelBoxImg
                                src={isSelected ? sel_box : not_sel_box}
                                onClick={() => {
                                    if (canAnswer == false){
                                        return;
                                    }
                                    let newText = '';
                                    if (isSelected == true) {
                                        newText = getSortedStringsArr(ansItems.filter(x => (x != a.text))).join('|');
                                    } else {
                                        newText = getSortedStringsArr(ansItems.concat([a.text])).join('|');
                                    }
                                    setText(newText);
                                    if (autoSave == true) {
                                        onSave(newText, (correctAnswerString == newText))
                                    }
                                }}
                            />
                            <span style={{marginLeft: 5, color: color}}
                                  dir={isHebrew(a.text) ? 'rtl' : 'ltr'}>
                                {a.text}
                            </span>
                        </SelectOption>
                    )
                })}
            </SelectOptionsPlaceholder>

            {(isPreview == true || autoSave == true) ? null :
                <AnswerButtonPlaceholder>
                    <YellowButton onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        // console.log('correctAnswerString = ', correctAnswerString);
                        onSave(text, (correctAnswerString == text)); // todo: refactor this breach
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null : 'Ответить'}
                    </YellowButton>
                </AnswerButtonPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 5px;
`;

const SelectOptionsPlaceholder = styled.div`

`;

const SelectOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const SelBoxImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const TextareaPlaceholder = styled.div`

`;

const AnswerButtonPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;