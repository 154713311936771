import * as types from '../ActionTypes.js'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    oxwordsMap: Map(),

    selectedOxwordId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const OxwordsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_OXWORD:
        case types.UPDATE_OXWORD:
        case types.DELETE_OXWORD:
        case types.LOAD_OXWORDS:
            return startLoading(state, action)

        case types.CREATE_OXWORD_FAIL:
        case types.UPDATE_OXWORD_FAIL:
        case types.DELETE_OXWORD_FAIL:
        case types.LOAD_OXWORDS_FAIL:
            return stopLoading(state, action)

        case types.CREATE_OXWORD_SUCCESS:
        case types.UPDATE_OXWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                oxwordsMap: state.oxwordsMap.set(action.oxword.id, action.oxword)
            }

        case types.DELETE_OXWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                oxwordsMap: state.oxwordsMap.delete(action.id)
            }

        case types.LOAD_OXWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                oxwordsMap: state.oxwordsMap.merge(action.oxwords.reduce((mp, ex) => mp.set(ex.id, ex), Map()))
            }

        default:
            return state;

    }

}

export default OxwordsReducer;