import QuestionnairesAPI from '../../api/QuestionnairesAPI'

import * as types from '../ActionTypes'

let loadQuestionnaires_ = () => {
    return {
        type: types.LOAD_QUESTIONNAIRES
    }
}
let loadQuestionnairesSuccess = (questionnaires) => {
    return {
        type: types.LOAD_QUESTIONNAIRES_SUCCESS,
        questionnaires: questionnaires
    }
}
let loadQuestionnairesFailed = (error) => {
    return {
        type: types.LOAD_QUESTIONNAIRES_FAIL,
        error: error
    }
}

//thunk
export function loadAllQuestionnaires() {
    return (dispatch, getState) => {
        dispatch(loadQuestionnaires_());
        return QuestionnairesAPI.getAllQuestionnaires().then(
            questionnaires => dispatch(loadQuestionnairesSuccess(questionnaires)),
            error => dispatch(loadQuestionnairesFailed(error))
        )
    }
}

export function loadQuestionnaireById(id, userId) {
    return (dispatch, getState) => {
        dispatch(loadQuestionnaires_());
        return QuestionnairesAPI.getQuestionnaire(id, userId).then(
            questionnaire => dispatch(loadQuestionnairesSuccess([questionnaire])),
            error => dispatch(loadQuestionnairesFailed(error))
        )
    }
}

let createQuestionnaire_ = () => {
    return {
        type: types.CREATE_QUESTIONNAIRE
    }
}
let createQuestionnaireSuccess = (questionnaire) => {
    return {
        type: types.CREATE_QUESTIONNAIRE_SUCCESS,
        questionnaire: questionnaire
    }
}
let createQuestionnaireFailed = (error) => {
    return {
        type: types.CREATE_QUESTIONNAIRE_FAIL,
        error: error
    }
}

//thunk
export function createQuestionnaire(data) {
    return (dispatch, getState) => {
        dispatch(createQuestionnaire_());
        return QuestionnairesAPI.createQuestionnaire(data).then(
            questionnaire => dispatch(createQuestionnaireSuccess(questionnaire)),
            error => dispatch(createQuestionnaireFailed(error))
        )
    }
}


let updateQuestionnaire_ = () => {
    return {
        type: types.UPDATE_QUESTIONNAIRE
    }
}
let updateQuestionnaireSuccess = (questionnaire) => {
    return {
        type: types.UPDATE_QUESTIONNAIRE_SUCCESS,
        questionnaire: questionnaire
    }
}
let updateQuestionnaireFailed = (error) => {
    return {
        type: types.UPDATE_QUESTIONNAIRE_FAIL,
        error: error
    }
}

//thunk
export function updateQuestionnaire(data) {
    return (dispatch, getState) => {
        dispatch(updateQuestionnaire_());
        return QuestionnairesAPI.updateQuestionnaire(data).then(
            questionnaire => dispatch(updateQuestionnaireSuccess(questionnaire)),
            error => dispatch(updateQuestionnaireFailed(error))
        )
    }
}

let deleteQuestionnaire_ = () => {
    return {
        type: types.DELETE_QUESTIONNAIRE
    }
}
let deleteQuestionnaireSuccess = (id) => {
    return {
        type: types.DELETE_QUESTIONNAIRE_SUCCESS,
        id: id
    }
}
let deleteQuestionnaireFailed = (error) => {
    return {
        type: types.DELETE_QUESTIONNAIRE_FAIL,
        error: error
    }
}

//thunk
export function deleteQuestionnaire(id) {
    return (dispatch, getState) => {
        dispatch(deleteQuestionnaire_());
        return QuestionnairesAPI.deleteQuestionnaire(id).then(
            () => dispatch(deleteQuestionnaireSuccess(id)),
            error => dispatch(deleteQuestionnaireFailed(error))
        )
    }
}
