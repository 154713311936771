import {useDispatch, useMappedState} from "redux-react-hook";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import CommonHelper from "../../../helpers/CommonHelper";
import {FACELESS_AVATAR} from "../../../constants/config";
import LottieLoader from "../../loaders/LottieLoader";
import team_json from "../../../assets/lottie/team-discussion.json";
import moment from "moment/moment";
import Spin from "arui-feather/spin";
import chevron_icon from "../../../assets/img/chevron.svg";
import * as actions from "../../../redux/actions/LessonsActions";
import * as tasksActions from "../../../redux/actions/TasksActions";
import LessonTasksMarksList from "./LessonTasksMarksList";
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import * as usersActions from "../../../redux/actions/UsersActions";
import * as lessonsActions from "../../../redux/actions/LessonsActions";
import {isMobile} from "polotno/utils/screen";
import loupe from '../../../assets/img/loupe_diary.svg'
import NiceModal from "../../modals/NiceModal";
import StudentGroupLessonsPanel from "../../lessons/panels/StudentGroupLessonsPanel";
import {Map, Set} from 'immutable'
import * as drochkaExercisesActions from "../../../redux/actions/DrochkaExercisesActions";
import * as exercisesActions from "../../../redux/actions/ExercisesActions";
import * as testsActions from "../../../redux/actions/TestsActions";
import * as treadmillsActions from "../../../redux/actions/TreadmillsActions";
import {translate} from "../../../utils/Translate";
import {getLessonTasks} from "../../../redux/actions/TasksActions";
import TasksHelper from "../../../helpers/TasksHelper";
import woman_waiting_json from "../../../assets/img/woman_waiting.json";

function getAvgLessonMark(lesson, userId, data, tasks, teacherMarks) {
    if (lesson === undefined || data === undefined)
        return 0;

    let mItems = [];
    for (let i in tasks) {
        if (tasks[i].lessonId !== lesson.id)
            continue;

        let task = tasks[i];
        let avr = TasksHelper.getTaskAverageMark(data, task, userId);
        let info = teacherMarks?.filter(x => x?.taskId === task.id)[0];

        if (info?.data?.mark !== undefined && info?.data?.mark !== '')
            mItems.push(info?.data?.mark)
        else if (avr !== undefined && avr > 0)
            mItems.push(avr);

    }
    let tAvr = (mItems.length === 0) ? 0 : (1.0 * mItems.reduce((sum, x) => (+sum + +x), 0) / mItems.length);

    return tAvr;
}

export default function GroupDiaryPanel(props) {
    const {
        userRole = 'student',
        studentId = undefined,
        id = undefined
    } = props;

    const {
        lessons = [],
        currentUser,
        loading,
        selectedLessonId,
        selectedLessonTasks,
        tasks
    } = useMappedState(useCallback(state => {
        return {
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: props.studentId == undefined ? state.users.usersMap.get(state.users.currentUserId) : props.studentId,
            loading: state.lessons.loading,
            selectedLessonId: state.lessons.selectedLessonId,
            tasks: state.tasks.tasksMap.toArray().filter(x => x.classId === id)
        }
    }));

    useEffect(() => {
        dispatch(usersActions.loadUserById(currentUser?.id));
        dispatch(tasksActions.getGroupTasks(id)).then(pld => {
            let {tasks = []} = pld;
            let allExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.exercisesIds || tsk?.exercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allDrochkaExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.drochkaExercisesIds || tsk?.drochkaExercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allTestsIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.testsIds || tsk?.testsIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allTreadmillsIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.treadmillsIds || tsk?.treadmillsIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            dispatch(drochkaExercisesActions.getExercisesByIds(allDrochkaExercisesIds));
            dispatch(exercisesActions.getExercisesByIds(allExercisesIds));
            dispatch(testsActions.getTestsByIds(allTestsIds));
            dispatch(treadmillsActions.loadTreadmillsByIds(allTreadmillsIds));
        });
        dispatch(lessonsActions.getMyLessons());
    }, [id]);

    const {email, avatar} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    const dispatch = useDispatch();

    let lessonId = undefined;
    let sortedLessons = lessons.sort((a, b) => (+b.timestamp - +a.timestamp));

    const [selectedLessonsArr, setSelectedLessonsArr] = useState('');
    const [lastSelectedLessonId, setLastSelectedLessonId] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [marksInfo, setMarksInfo] = useState(undefined);
    const [recalcMarks, setRecalcMarks] = useState(undefined);
    const [selectedModalData, setSelectedModalData] = useState(undefined);

    const onLessonSelect = lessonId => {
        dispatch(actions.selectLesson(lessonId));
    }

    const onToggle = useCallback((a) => {
        if (a.id == selectedLessonId) {
            onLessonSelect(undefined);
        } else {
            onLessonSelect(a.id);
        }
    });

    useEffect(() => {
        GroupStatsAPI.getAllStudentMarks(currentUser.id).then(x => {
            if (x !== undefined) {
                setMarksInfo(x);
            }
        })
    }, [recalcMarks])

    useEffect(() => {
        if (id != undefined) {
            GroupStatsAPI.getGroupAnswersMap(id).then(x => {
                setData(x);
            })
        }
    }, [id]);

    useEffect(() => {
        if (selectedLessonId != undefined) {
            dispatch(tasksActions.getLessonTasks(selectedLessonId));
        }
    }, [selectedLessonId]);

    if (loading == true && selectedLessonId === undefined) {
        return (
            <div style={{width: '100%', height: `calc(100vh - 120px)`, backgroundColor: 'white'}}>
                <LottieLoader json={woman_waiting_json}/>
            </div>
        )
    }

    return (
        <Wrapper>
            <Content>
                <Box>
                    <UserWrapper>

                        <CurrentUserAvatarPlaceholder src={ava} />

                            <CurrentUserInfo>
                                <UserInfoLeft>

                                    <div>
                                        {CommonHelper.getUserName(currentUser)}
                                    </div>

                                    <div>
                                        {translate('student')}
                                    </div>

                                    <div>
                                        {email}
                                    </div>

                                </UserInfoLeft>
                            </CurrentUserInfo>
                    </UserWrapper>

                    <ListOfLessons>

                        {!(lessons.length == 0 && loading == false) ? null :
                            <LottiePlaceholder>

                                <LottieLoader json={team_json} />

                                <div style={{textAlign: 'center', opacity: 0.5}}>
                                    В этой группе ещё нет уроков.
                                    <br/>
                                    Преподаватель создаст их в ближайшее время.
                                </div>

                            </LottiePlaceholder>
                        }

                        {sortedLessons.map((a, i) => {
                            let isSelected = (selectedLessonsArr.includes(a.id))
                            return (
                                <LessonItem key={a.id} selected={isSelected}>

                                    <LessonItemTopPlaceholder
                                        selected={isSelected}
                                        style={{borderBottom: (isSelected) ? `1px solid lightgrey` : 'none'}}
                                        onClick={() => {
                                            if (selectedLessonsArr.includes(a.id)) {
                                                setSelectedLessonsArr(
                                                    selectedLessonsArr.filter(b => b !== a.id));
                                            }
                                            else {
                                                setSelectedLessonsArr([
                                                    ...selectedLessonsArr,
                                                a.id
                                                ]);
                                            }
                                        }}>

                                        <LessonItemInfo>

                                            <div>
                                                {(a?.name == undefined || a?.name == '') ? 'Без названия' : a.name}
                                            </div>

                                            <div style={{display: !selectedLessonsArr.includes(a.id) ? "none" : "inline-block"}}>
                                                {isMobile() ? null : moment(a.startTimestamp).format('DD.MM.YYYY HH:mm')}
                                            </div>

                                            <LoupeButton src={loupe}
                                                 onClick={b => {
                                                     b.stopPropagation();
                                                     setSelectedModalData(a.id);
                                                     setLastSelectedLessonId(selectedLessonId);
                                                     onLessonSelect(a.id);
                                                 }}>
                                            </LoupeButton>

                                        </LessonItemInfo>

                                        <div style={{display: 'flex', height: '100%'}}>

                                            <SmthPlaceholder style={{display: !isSelected || isMobile() ? "none" : "flex"}}>
                                                {translate("date")}
                                            </SmthPlaceholder>

                                            <SmthPlaceholder style={{display: !isSelected || isMobile() ? "none" : "flex"}}>
                                                {translate("deadline")}
                                            </SmthPlaceholder>

                                            <SmthPlaceholder>
                                                <span>
                                                    <Spin visible={loading}/>
                                                </span>

                                                {loading || data === undefined ? null :
                                                    <div>
                                                        {getAvgLessonMark(a, currentUser.id, data, tasks, marksInfo).toFixed(2) <= 0 ? null
                                                            :
                                                         getAvgLessonMark(a, currentUser.id, data, tasks, marksInfo).toFixed(2)}
                                                    </div>
                                                }
                                            </SmthPlaceholder>

                                            <SmthPlaceholder style={{display: isMobile() ? 'none' : 'flex'}}>
                                                    <ChevronIcon
                                                        src={chevron_icon}
                                                        style={{
                                                            transform: `rotate(${isSelected == true ? 180 : 0}deg)`
                                                        }}
                                                        onClick={() => {

                                                        }}/>
                                            </SmthPlaceholder>
                                        </div>
                                    </LessonItemTopPlaceholder>

                                    {!isSelected ? null :
                                        <LessonTasksMarksList
                                            classId={id}
                                            currentUser={currentUser}
                                            lessonId={a.id}
                                            loading={loading}
                                            data={data}
                                            lessons={lessons}
                                            userRole={userRole}
                                            marksInfo={marksInfo}
                                            recalcMarks={() => setRecalcMarks(Math.random())}
                                            />
                                    }

                                </LessonItem>
                            )
                        })}

                    </ListOfLessons>
                </Box>
            </Content>

            {selectedModalData == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedModalData(undefined);
                    onLessonSelect(lastSelectedLessonId);
                }}>
                    <SelectedModalInnerPlaceholder>

                        <StudentGroupLessonsPanel
                            id={id}
                            studentId={currentUser}
                            selectedLessonIdM={selectedModalData}
                        />

                    </SelectedModalInnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>

    );
}

const Wrapper = styled.div`
  user-select: none;
  
  @media (max-width: 660px) {
    height: 100vh;
    //overflow: auto;
  }
  
  
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const ListOfLessons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Box = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  box-sizing: content-box;
  padding: 30px;
  margin: 20px;
  width: 50%;
  min-width: 600px;
  height: 100%;
  
  @media (max-width: 660px) {
    width: 90%;
    min-width: 30vh;
    //height: 100vh;
    overflow-x: auto;
  }
`;

const UserWrapper = styled.div`
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  padding-bottom: 0px;

  /*@media (max-width: 720px) {
    margin-bottom: 15px;
  }*/
`;

const CurrentUserAvatarPlaceholder = styled.div`
  height: 100px;
  width: 100px;
  background-color: lightgrey;
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
  
  @media (max-width: 720px) {
    display: none;
  }
`;

const CurrentUserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 100px;
  font-size: 14px;
  line-height: 24px;
  color: #767676;
  padding-left: 30px;
  
  @media (max-width: 720px) {
    padding-left: 0px;
    justify-content: center;
  }
`;

const UserInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  color: #767676;
  font-weight: bold;
`;

const LottiePlaceholder = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LessonItem = styled.div`
  background: white;
  border-bottom: ${props => (props.selected == true ? '1px solid #FFC02C' : '1px solid lightgrey')};
  border-top: ${props => (props.selected == true ? '1px solid #FFC02C' : '1px solid lightgrey')};
`;

const LessonItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 15px;
  background: white;
  color: #767676;
  font-weight: bold;
  cursor: pointer;
  
  @media (max-width: 720px) {
    //width: 100%; 
  }
`;

const LessonItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  
  cursor: pointer;

  @media (max-width: 720px) {
    width: 150px;
  }
  
  :hover {
    opacity: 0.8;
  }
`;

const ChevronIcon = styled.img`
  cursor: pointer;
  opacity: 0.3;
  height: 28px;
  
  :hover {
    opacity: 0.85;
  }
`;

const SmthPlaceholder = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-left: 15px;
  
  border-left: 1px solid lightgrey;
  height: 100%;
  
  @media (max-width: 720px) {
    border-bottom: 1px solid lightgrey;
  }
`;

const SelectedModalInnerPlaceholder = styled.div`
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  width: 920px;
  @media (max-width: 940px) {
    width: 80vw;
  }
`;

const LoupeButton = styled.img`
  height: 20px;
  
  @media (max-width: 720px) {
    display: flex;
  }
`;

