import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from "axios";
import {API_ENDPOINT} from "../../constants/config";
import {BlueButton} from "../ira/ui/Buttons";
import Spin from "arui-feather/spin";


export default function RecoverPasswordApp(props) {
    const {
        recovery_code = ''
    } = props;
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>

            <InnerPlaceholder>

                <CentralBox>
                    <InputPlaceholder>
                        <Input
                            autoFocus={true}
                            value={password} placeholder={'New password'} onChange={evt => {
                            setPassword(evt.target.value);
                        }}/>
                    </InputPlaceholder>

                    <div>
                        <BlueButton onClick={async () => {
                            if (loading == true) {
                                return;
                            }
                            setLoading(true);
                            await axios.post(`${API_ENDPOINT}/recover/${recovery_code}`, {
                                password: password
                            });
                            setLoading(false);
                            window.alert('Password has been changed!');
                            window.location.href = '/';
                        }}>
                            {loading == true ? <Spin visible={true}/> : 'Change password'}
                        </BlueButton>
                    </div>

                </CentralBox>

            </InnerPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background: whitesmoke;
`;

const InnerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CentralBox = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 20px;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

const InputPlaceholder = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  border: 1px solid whitesmoke;
  outline: none;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  
  :focus {
    border-color: blue;
  }
`;