import * as types from '../ActionTypes.js'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    accessGroupsMap: Map(),

    selectedAccessGroupId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const AccessGroupsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_ACCESS_GROUP:
        case types.UPDATE_ACCESS_GROUP:
        case types.DELETE_ACCESS_GROUP:
        case types.LOAD_ACCESS_GROUPS:
            return startLoading(state, action)

        case types.CREATE_ACCESS_GROUP_FAIL:
        case types.UPDATE_ACCESS_GROUP_FAIL:
        case types.DELETE_ACCESS_GROUP_FAIL:
        case types.LOAD_ACCESS_GROUPS_FAIL:
            return stopLoading(state, action)

        case types.CREATE_ACCESS_GROUP_SUCCESS:
        case types.UPDATE_ACCESS_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                accessGroupsMap: state.accessGroupsMap.set(action.accessGroup.id, action.accessGroup)
            }

        case types.DELETE_ACCESS_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                accessGroupsMap: state.accessGroupsMap.delete(action.id)
            }

        case types.LOAD_ACCESS_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                accessGroupsMap: state.accessGroupsMap.merge(action.accessGroups.reduce((mp, ex) => mp.set(ex.id, ex), Map()))
            }

        default:
            return state;

    }

}

export default AccessGroupsReducer;