import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TextProcessingAPI = {

    async getWordsFromText(text) {
        let key = `xx_w_e_${text}`;
        if (window[key] != undefined) {
            return window[key];
        }
        let pld = (await axios.post(`${API_ENDPOINT}/nlp/get-words`, {text: text, with_sentences: 'yes'})).data.result;
        let {info, sentences = []} = pld;
        console.log('getWordsFromText: info = ', info);
        let arr = Object.keys(info).map(xx => ({
            word: xx, ...info[xx]?.info,
            number: info[xx]?.number
        })).sort((a, b) => (+b.number - +a.number));
        sentences = JSON.parse(JSON.stringify(sentences));
        let enhSentences = [];
        for (let i in sentences) {
            let sent = sentences[i];
            let {words = []} = sent;
            for (let j in words) {
                let w = words[j];
                let d = info[w.lemma]?.info;
                let level = d?.level;
                let {token} = w;
                sentences[i].words[j] = {
                    level: level,
                    token: token
                };
            }
        }

        window[key] = arr;
        console.log('arr = ', arr);
        return {
            words: arr,
            sentences: sentences
        };
        // arr = arr.sort((a, b) => (+b.timestamp - +a.timestamp));
        // return arr;
    },


}

export default TextProcessingAPI;
