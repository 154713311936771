import axios from 'axios'
import {IMAGE_UPLOAD_ENDPOINT, VIDEO_UPLOAD_BASE} from "../constants/config";

const UploadAPI = {

    async uploadImage(blob, onProgress = p => {

    }) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(IMAGE_UPLOAD_ENDPOINT, formData, {
            onUploadProgress: progressEvent => {
                try {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                } catch (exc) {

                }
            }
        })).data;
        return pld;
    },

    async uploadVideo(blob, onProgress = p => {

    }) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(`${VIDEO_UPLOAD_BASE}/video/upload-and-transcode`, formData, {
            onUploadProgress: progressEvent => {
                try {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                } catch (exc) {

                }
            }
        })).data;
        return pld;
    },


    async uploadFile(blob, onProgress = p => {

    }) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(`${VIDEO_UPLOAD_BASE}/file/upload`, formData, {
            onUploadProgress: progressEvent => {
                try {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                } catch (exc) {

                }
            }
        })).data;
        return pld;
    },

    async uploadUserFile(blob, onProgress = p => {

    }) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(`${VIDEO_UPLOAD_BASE}/users-file/upload`, formData, {
            onUploadProgress: progressEvent => {
                try {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                } catch (exc) {

                }
            }
        })).data;
        return pld;
    },


    async getVideoUploadStatus(hash) {
        let pld = (await axios.get(`${VIDEO_UPLOAD_BASE}/video/${hash}/status`)).data;
        return pld;
    },

    async getMp3Url(videoUrl) {
        try {
            let pld = (await axios.get(`${VIDEO_UPLOAD_BASE}/video/get-mp3`, {
                params: {
                    url: videoUrl
                }
            })).data.url;
            return pld;
        } catch (exc) {

        }
    },


}

export default UploadAPI;
