import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ExercisesAPI = {

    async getExercise(id) { // full
        let pld = (await axios.get(`${API_ENDPOINT}/testing/quize/${id}`)).data.result;
        return pld;
    },

    async getAllTests() {
        let pld = (await axios.get(`${API_ENDPOINT}/testing/quizes/all`)).data.result;
        return pld;
    },

    async getShortQuizesByIds(ids) { // full
        let pld = (await axios.post(`${API_ENDPOINT}/v3/quizes-by-ids/short`, {
            ids: ids
        })).data.result;
        return pld;
    },

    async createQuiz(data) { // full
        let pld = (await axios.post(`${API_ENDPOINT}/testing/quizes`, {
            ...data
        })).data.result;
        return pld;
    },

    async updateQuiz(data) { // full
        let pld = (await axios.put(`${API_ENDPOINT}/testing/quizes`, {
            ...data
        })).data.result;
        return pld;
    },

    async deleteQuiz(id) { // full
        let pld = (await axios.delete(`${API_ENDPOINT}/testing/quizes`, {
            data: {
                id: id
            }
        }));
        return pld;
    },

    async saveExerciseAnswer(exerciseId, cardId, answer, isCorrect) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers/card/${cardId}`, {
            answer: answer,
            isCorrect: isCorrect
        })).data.result;
        return pld;
    },

    async getExerciseAnswers(exerciseId, userId) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers`, {
            params: {
                userId: userId
            }
        })).data.result;
        return arr;
    }


}

export default ExercisesAPI;
