import TasksAPI from '../../api/TasksAPI'

import * as types from '../ActionTypes'

let loadTasks_ = () => {
    return {
        type: types.LOAD_TASKS
    }
}
let loadTasksSuccess = (tasks) => {
    console.log('reducer: loadTasksSuccess: tasks = ', tasks);
    return {
        type: types.LOAD_TASKS_SUCCESS,
        tasks: tasks
    }
}
let loadTasksFailed = (error) => {
    return {
        type: types.LOAD_TASKS_FAIL,
        error: error
    }
}

//thunk
export function getGroupTasks(groupId) {
    console.log('ACTIONS: getGroupTasks: groupId = ', groupId);
    return (dispatch, getState) => {
        dispatch(loadTasks_());
        return TasksAPI.getClassTasks(groupId).then(
            tasks => dispatch(loadTasksSuccess(tasks)),
            error => dispatch(loadTasksFailed(error))
        )
    }
}

export function getLessonTasks(lessonId) {
    return (dispatch, getState) => {
        dispatch(loadTasks_());
        return TasksAPI.getLessonTasks(lessonId).then(
            tasks => dispatch(loadTasksSuccess(tasks)),
            error => dispatch(loadTasksFailed(error))
        )
    }
}

export function getStudentTasks(userId) {
    return (dispatch, getState) => {
        dispatch(loadTasks_());
        return TasksAPI.getStudentTasks(userId).then(
            tasks => dispatch(loadTasksSuccess(tasks)),
            error => dispatch(loadTasksFailed(error))
        )
    }
}

export function getStudentTasksWithStats(userId) {
    return (dispatch, getState) => {
        dispatch(loadTasks_());
        return TasksAPI.getStudentTasksWithStats(userId).then(
            tasks => dispatch(loadTasksSuccess(tasks)),
            error => dispatch(loadTasksFailed(error))
        )
    }
}



export function loadTaskById(id) {
    return (dispatch, getState) => {
        dispatch(loadTasks_());
        return TasksAPI.getTask(id).then(
            task => dispatch(loadTasksSuccess([task])),
            error => dispatch(loadTasksFailed(error))
        )
    }
}

let createTask_ = () => {
    return {
        type: types.CREATE_TASK
    }
}
let createTaskSuccess = (task) => {
    return {
        type: types.CREATE_TASK_SUCCESS,
        task: task
    }
}
let createTaskFailed = (error) => {
    return {
        type: types.CREATE_TASK_FAIL,
        error: error
    }
}

//thunk
export function createTask(lessonId, data) {
    return (dispatch, getState) => {
        dispatch(createTask_());
        return TasksAPI.createTask(lessonId, data).then(
            task => dispatch(createTaskSuccess(task)),
            error => dispatch(createTaskFailed(error))
        )
    }
}

export function selectTask(id) {
    return {
        type: types.SELECT_TASK,
        id: id
    }
}

let updateTask_ = () => {
    return {
        type: types.UPDATE_TASK
    }
}
let updateTaskSuccess = (task) => {
    return {
        type: types.UPDATE_TASK_SUCCESS,
        task: task
    }
}
let updateTaskFailed = (error) => {
    return {
        type: types.UPDATE_TASK_FAIL,
        error: error
    }
}

//thunk
export function updateTask(data) {
    return (dispatch, getState) => {
        dispatch(updateTask_());
        return TasksAPI.updateTask(data).then(
            task => dispatch(updateTaskSuccess(task)),
            error => dispatch(updateTaskFailed(error))
        )
    }
}

export function copyDataToTaskFromSlide(taskId, slideId) {
    return (dispatch, getState) => {
        dispatch(updateTask_());
        return TasksAPI.copyDataToTaskFromSlide(taskId, slideId).then(
            task => dispatch(updateTaskSuccess(task)),
            error => dispatch(updateTaskFailed(error))
        )
    }
}


let deleteTask_ = () => {
    return {
        type: types.DELETE_TASK
    }
}
let deleteTaskSuccess = (id) => {
    return {
        type: types.DELETE_TASK_SUCCESS,
        id: id
    }
}
let deleteTaskFailed = (error) => {
    return {
        type: types.DELETE_TASK_FAIL,
        error: error
    }
}

//thunk
export function deleteTask(id) {
    return (dispatch, getState) => {
        dispatch(deleteTask_());
        return TasksAPI.deleteTask(id).then(
            () => dispatch(deleteTaskSuccess(id)),
            error => dispatch(deleteTaskFailed(error))
        )
    }
}
