import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Lottie from "react-lottie-player";
import lottieJson from "../../assets/lottie/upload_lottie.json";
import uploadJson from "../../assets/lottie/database-upload.json";
import {Map, Set} from 'immutable'
import SimpleAutoVideoUploader from "./SimpleAutoVideoUploader";

import ls from 'local-storage'
import ReactPlayer from "react-player";

export const getUploadedBeforeFiles = () => {
    let items = Object.entries(window.localStorage).map(x => x[0]).filter(x => (x.indexOf('u-file') > -1))
        .map(x => ls(x));
    return items;
}

export function selectFiles(type) {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        if (type == 'image') {
            input.accept = 'image/png, image/gif, image/jpeg';
        }
        // if (type == 'video'){
        //     input.accept = 'video/mp4, video/mp4, video/vob';
        // }
        input.multiple = true;
        input.onchange = _ => {
            let files = Array.from(input.files);
            resolve(files);
        };
        input.click();
    });
}

const getMergedFiles = (oldFiles, newFiles, contentType = 'video') => {
    let arr = oldFiles.concat(newFiles);
    // arr = arr.filter(x => (x?.type != undefined && x?.type.indexOf(contentType) > -1));
    arr = arr.reduce((mp, x) => mp.set(`${x.name}_${x.type}_${x.size}`, x), Map()).toArray();
    return arr;
}

const getSortedByName = arr => {
    try {
        return arr.sort((a, b) => {
            let s1 = `${a.name}`.toLowerCase();
            let s2 = `${b.name}`.toLowerCase();
            if (s1 > s2) {
                return 1;
            }
            if (s1 < s2) {
                return -1;
            }
            return 0;
        })
    } catch (exc) {
        return arr;
    }
}

export default function BulkDndPlaceholder(props) {
    const {
        onClose = () => {

        },
        filesType,
        closeButtonVisible = false,
        singleMode = false,

        onSingleFileUploaded = d => {

        },

        selectable = false,
        selectedUrl = undefined,
        onSelectVideo = d => {

        },

        onClean = () => {

        }

    } = props;

    const [files, setFiles] = useState([]);
    // const [oldUploads, setOldUploads] = useState(getUploadedBeforeFiles());
    const [oldUploads, setOldUploads] = useState(getUploadedBeforeFiles());
    console.log('BulkDndPlaceholder: render: files = ', files);
    console.log('BulkDndPlaceholder: render: oldUploads = ', oldUploads);

    // let visibleFiles =
    let uplSet = oldUploads.reduce((mp, x) => mp.add(x.name), Set());
    // let xFiles = files.filter(x => !uplSet.has(x.name));
    let xFiles = files;
    xFiles = xFiles.sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
    let filesAndUploads = xFiles.concat(oldUploads);
    console.log('xFiles = ', xFiles);

    return (
        <Wrapper onDrop={ev => {
            ev.preventDefault();
            let myFiles = [];
            if (ev.dataTransfer.items) {
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        let file = ev.dataTransfer.items[i].getAsFile();
                        myFiles = myFiles.concat([file]);
                    }
                }
            } else {
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    myFiles = myFiles.concat([ev.dataTransfer.files[i]]);
                }
            }
            let newFiles = getMergedFiles(files, myFiles);
            if (singleMode && newFiles.length > 0) {
                newFiles = [newFiles[0]];
            }
            setFiles(newFiles);
        }}
                 onDragOver={ev => {
                     ev.preventDefault();
                 }}
        >
            <DndInnerPlaceholder>

                {files.length > (singleMode ? 0 : 1000) ? null :
                    <DropMessage onClick={async () => {
                        let myFiles = await selectFiles(filesType);
                        let newFiles = getMergedFiles(files, myFiles);
                        if (singleMode && newFiles.length > 0) {
                            newFiles = [newFiles[0]];
                        }
                        setFiles(newFiles);
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: 200}}>
                            <Lottie
                                loop
                                animationData={uploadJson}
                                play
                                style={{width: '100%%', height: 200}}
                            />
                        </div>
                        <DropMessageText>
                            {singleMode == false ? 'Перетащите видео-файлы в эту область для загрузки' : 'Перетащите видео-файл в эту область для загрузки'}
                        </DropMessageText>
                    </DropMessage>
                }

                {files.concat(oldUploads).length == 0 ? null :
                    <React.Fragment>
                        {!singleMode && <SubHeading>
                            Загруженные файлы
                        </SubHeading>}
                        <FilesListPlaceholder>
                            {xFiles.map((f, i) => {
                                return (
                                    <FileItemRow key={`ff_${f.name}_${f.type}_${f.size}_${i}`}
                                                 selected={(selectable && selectedUrl != undefined && (selectedUrl == f.url))}
                                                 style={{border: (singleMode ? 'none' : undefined)}}
                                                 onClick={() => {
                                                     console.log('FileItemRow: onClick:');
                                                     if (selectable == false) {
                                                         return;
                                                     }
                                                     onSelectVideo(f);
                                                 }}
                                    >
                                        <SimpleAutoVideoUploader
                                            file={f}
                                            showName={!singleMode}
                                            onReady={d => {
                                                if (singleMode) {
                                                    onSingleFileUploaded(d);
                                                }
                                                let uploads = getUploadedBeforeFiles();
                                                // let xFiles = files.filter(fl => (oldUploads.map(x => x.hash).indexOf(fl.hash) == -1));
                                                setOldUploads(uploads);
                                                // setFiles(xFiles);
                                            }}
                                        />
                                    </FileItemRow>
                                )
                            })}
                            {getSortedByName((singleMode ? [] : oldUploads)).map((f, i) => {
                                return (
                                    <FileItemRow key={`${f.hash}_${i}`}
                                                 selectable={selectable}
                                                 selected={(selectable && selectedUrl != undefined && (selectedUrl == f.url))}
                                                 onClick={() => {
                                                     console.log('FileItemRow: onClick:');
                                                     if (selectable == false) {
                                                         return;
                                                     }
                                                     onSelectVideo(f);
                                                 }}
                                    >
                                        <OldFilePlaceholder>
                                            <OldFilePlayerPlaceholder>
                                                <ReactPlayer
                                                    width={240}
                                                    height={'100%'}
                                                    light={f.avatar}
                                                    playing={true}
                                                    controls={true}
                                                    url={f.url}/>
                                            </OldFilePlayerPlaceholder>
                                            <div style={{wordBreak: 'break-all', paddingLeft: 10}}>
                                                {f.name}
                                            </div>
                                        </OldFilePlaceholder>

                                    </FileItemRow>
                                )
                            })}

                        </FilesListPlaceholder>


                    </React.Fragment>
                }

                {(closeButtonVisible == false || singleMode) ? null :
                    <CloseMessagePlaceholder onClick={() => {
                        onClose();
                    }}>
                        Закрыть
                    </CloseMessagePlaceholder>
                }

            </DndInnerPlaceholder>

            {oldUploads.length == 0 || singleMode ? null :
                <div style={{textAlign: 'center', padding: 10}}>
                    <DeleteSpan onClick={() => {
                        if (window.confirm('Уверены?') == false) {
                            return;
                        }
                        let keys = Object.entries(window.localStorage).map(x => x[0]).filter(x => (x.indexOf('u-file') > -1));
                        for (let i in keys) {
                            ls.remove(keys[i]);
                        }
                        setFiles([]);
                        setOldUploads(getUploadedBeforeFiles());
                        onClean();
                    }}>
                        очистить загруженные файлы
                    </DeleteSpan>
                </div>
            }


        </Wrapper>
    );
}

const DeleteSpan = styled.span`
  color: red;
  opacity: 0.75;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const OldFilePlayerPlaceholder = styled.div`
  width: 240px;
  height: 180px;
`;

const OldFilePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DropMessageText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
`;

const SubHeading = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
`;

const FilesListPlaceholder = styled.div`
  display: grid;
  //grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
`;

const FileItemRow = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${props => (props.selected == true ? 'blue' : 'lightgrey')};
  border-radius: 6px;
  cursor: ${props => (props.selectable == true ? 'pointer' : 'default')};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  //background: pink;
  box-sizing: border-box;
  background: white;
  //margin-top: 20px;
`;

const DndInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
`;

const DropMessage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed lightgrey;
  cursor: pointer;
`;

const CloseMessagePlaceholder = styled.div`
  cursor: pointer;
  font-size: 28px;
  text-decoration: underline;
  margin-bottom: 200px;
`;
