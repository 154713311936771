// const Diff = require('diff');
// import {diffChars} from 'diff'

const ddF = require('diff');

const DiffHelper = {

    getDiff(s1, s2) {

        // console.log('getDiff: s1, s2 = ', s1, s2);

        let d = ddF.diffChars(s1, s2, {
        // let d = diffChars(s1, s2, {
            ignoreWhitespace: true,
            newlineIsToken: false
        });

        return d;
    },

    replaceSymbols(s) {
        if (s == undefined || s == '') {
            return '';
        }
        let s2 = `${s}`.replace(/\s+/g, ' ').replace(/,/g, '').replace(/\./g, '').replace(/\'/g, '').replace(/`/g, '')
            .replace(/\!/g, '').replace(/ё/g, 'е').replace(/“/g, '').replace(/”/g, '').replace(/-/g, '').replace(/:/g, '')
            .replace(/\?/g, '').toLowerCase().trim();
        return s2;
    },

    getScore(s1, s2) {
        try {
            let s1_ = this.replaceSymbols(s1);
            let s2_ = this.replaceSymbols(s2);
            // console.log('s1 = ', s1_);
            // console.log('s2 = ', s2_);

            // let xTotalNumber = Math.max(s)

            let arr = this.getDiff(s1_, s2_);
            let correctNumber = arr.filter(x => (x.removed != true && x.added != true)).reduce((sum, x) => (+sum + +x.count), 0)

            let removedNumber = arr.filter(x => (x.removed == true)).reduce((sum, x) => (+sum + +x.count), 0);
            let addedNumber = arr.filter(x => (x.added == true)).reduce((sum, x) => (+sum + +x.count), 0);

            let hasTurb = (removedNumber > 0 || addedNumber > 0);
            let minTurb = Math.min(removedNumber, addedNumber);
            let maxTurb = Math.max(removedNumber, addedNumber);
            // let turbFrac = 10.0;
            let turbFrac = 0.0;
            let turbScore = (s1_.length < 30 || s2_.length < 30) ? 0 : Math.min(100.0 * maxTurb / Math.max(s1_.length, s2_.length), turbFrac);

            let dLen = Math.abs(s1_.length - s2_.length);

            let dLenFrac = 0.05 * s1_.length;
            // let lenScore = Math.min(dLen, dLenFrac)

            dLen = (dLen == 0) ? 0.05 * s1_.length : dLen;

            let dx = Math.abs(addedNumber, removedNumber);

            let replaced__ = Math.min(removedNumber, addedNumber);
            let totalChars = Math.max(s1_.length, s2_.length);

            let resultScore = (totalChars == 0) ? 0 : 100.0 * (+correctNumber / totalChars) - turbScore;
            resultScore = Math.min(100.0, resultScore);

            let n1 = Math.max(s1_.length, s2_.length);
            let n2 = Math.min(s1_.length, s2_.length);
            if (n1 == 0 || n2 == 0) {
                resultScore = 0;
            }
            // console.log('n1, n2 = ', n1, n2);
            if (n2 < 0.4 * n1) {
                resultScore = 50.0 * n2 / n1;
            }

            let score = {
                totalChars: totalChars,
                correctNumber: correctNumber,
                removedNumber: removedNumber,
                addedNumber: addedNumber,
                replaced: replaced__,
                resultScore: Math.max(0, Math.floor(resultScore))
            }
            return score;
        } catch (exc) {
            return {
                totalChars: 11,
                correctNumber: 11,
                removedNumber: 2,
                addedNumber: 2,
                replaced: 3,
                resultScore: 90
            }
        }

    },

    getMarkFromScore(score) {
        if (+score >= 97) {
            return 5;
        }
        if (+score >= 92) {
            return 4;
        }
        if (+score >= 85) {
            return 3;
        }
        if (+score >= 75) {
            return 2;
        }
        return 1;
    }

}

export default DiffHelper;