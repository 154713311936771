import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Lottie from "react-lottie-player";
import uploadJson from "../../assets/lottie/database-upload.json";
import UploadAPI from "../../api/UploadAPI";
import ReactHelper from "../../helpers/ReactHelper";

function getFileId(file) {
    console.log('getFileId: file = ', file);
    if (file == undefined) {
        return '';
    }
    return `file-${file.name}_${file.type}_${file.size}`;
}

async function emitSingleStatus(id, hash) {
    if (id == undefined || hash == undefined) {
        return;
    }
    let pld = await UploadAPI.getVideoUploadStatus(hash);
    let ev = new CustomEvent('single_video_file_uploaded', {detail: {...pld, id: id}});
    window.dispatchEvent(ev);
}

async function emitStatuses(xFiles) {
    console.log('emitStatuses: xFiles = ', xFiles);
    await Promise.all(xFiles.map(a => emitSingleStatus(a.id, a.hash)));
}


function uploadVideAndEmitStatuses(file) {
    let xId = getFileId(file);
    file.id = xId;
    return new Promise((resolve, reject) => {
        UploadAPI.uploadVideo(file, p => {
            let ev = new CustomEvent('single_video_file_progress_change', {detail: {id: file.id, progress: p}});
            window.dispatchEvent(ev);
        }).then(pld => {
            let ev = new CustomEvent('single_video_file_uploaded', {detail: {...pld, id: file.id}});
            window.dispatchEvent(ev);
            resolve();
        });
    })
}

export function selectFiles(type) {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.onchange = _ => {
            let files = Array.from(input.files);
            resolve(files);
        };
        input.click();
    });
}

const getSortedByName = arr => {
    try {
        return arr.sort((a, b) => {
            let s1 = `${a.name}`.toLowerCase();
            let s2 = `${b.name}`.toLowerCase();
            if (s1 > s2) {
                return 1;
            }
            if (s1 < s2) {
                return -1;
            }
            return 0;
        })
    } catch (exc) {
        return arr;
    }
}

export default function NewBulkUploader(props) {
    const {
        selectedId = undefined,
        onSelect = () => {

        },
        singleMode = false,
        selectable = false,
        onSelectVideo = f => {

        },
        selectedUrl,
        closeButtonVisible = false,
        onClose = () => {

        },
        onSingleFileUploaded = d => {

        }
    } = props;
    const [files, setFiles] = useState([]);
    const [uploadedMap, setUploadedMap] = useState({});
    const [uploadingProgressMap, setUploadingProgressMap] = useState({});
    const [namesMap, setNamesMap] = useState({});
    let xFiles = files;
    xFiles = xFiles.map(f => {
        let xId = getFileId(f);
        let exMap = uploadedMap[xId];
        f.id = xId;
        return {...exMap, ...f, name: namesMap[xId]};
    }).sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    ReactHelper.useEventListener('single_video_file_uploaded', (a, b, c) => {
        console.log('single_video_file_uploaded: a = ', a);
        let id = a?.detail?.id;
        let map = window.UPLOADED_VIDEOS_MAP || {};
        map[id] = a?.detail;
        window.UPLOADED_VIDEOS_MAP = map;
        console.log('UPLOADED_VIDEOS_MAP = ', map);
        setUploadedMap({...map});
        let dd = a?.detail;
        if (dd?.status == 'finished'){
            onSingleFileUploaded(dd)
        }
    });

    ReactHelper.useEventListener('single_video_file_progress_change', (a, b, c) => {
        console.log('single_video_file_progress_change: a = ', a);
        let id = a?.detail?.id;
        let map = window.UPLOADING_PROGRESS_VIDEOS_MAP || {};
        map[id] = a?.detail;
        window.UPLOADING_PROGRESS_VIDEOS_MAP = map;
        console.log('UPLOADING_PROGRESS_VIDEOS_MAP = ', map);
        setUploadingProgressMap({...map});
        let dd = a?.detail;
        if (dd?.status == 'finished'){
            onSingleFileUploaded(dd)
        }
    });

    ReactHelper.useInterval(() => {
        let rFiles = xFiles.map(f => {
            let exMap = uploadedMap[f?.id];
            return {...exMap, ...f};
        }).filter(x => (x?.status != 'finished'));
        let hasNotFinished = rFiles.reduce((f, x) => (f || (x?.status != 'finished')), false);
        if (hasNotFinished) {
            emitStatuses(rFiles).then(() => {

            });
        }
    }, 2000);

    // console.log('render: uploadingProgressMap = ', uploadingProgressMap);
    // console.log('render: uploadedMap = ', uploadedMap);
    // console.log('render: namesMap = ', namesMap);

    return (
        <Wrapper onDrop={async ev => {
            ev.preventDefault();
            let myFiles = [];
            if (ev.dataTransfer.items) {
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        let file = ev.dataTransfer.items[i].getAsFile();
                        myFiles = myFiles.concat([file]);
                    }
                }
            } else {
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    myFiles = myFiles.concat([ev.dataTransfer.files[i]]);
                }
            }
            setFiles(myFiles);
            setNamesMap(myFiles.reduce((mp, xx) => ({...mp, [getFileId(xx)]: xx.name}), {}));
            await Promise.all(myFiles.map(xx => uploadVideAndEmitStatuses(xx)));
        }}
                 onDragOver={ev => {
                     ev.preventDefault();
                 }}
        >

            <DndInnerPlaceholder>

                {files.length > (singleMode ? 0 : 1000) ? null :
                    <DropMessage onClick={async () => {
                        let myFiles = await selectFiles();
                        let newFiles = myFiles;
                        if (singleMode && newFiles.length > 0) {
                            newFiles = [newFiles[0]];
                        }
                        setFiles(newFiles);
                        setNamesMap(newFiles.reduce((mp, xx) => ({...mp, [getFileId(xx)]: xx.name})))
                        await Promise.all(newFiles.map(xx => uploadVideAndEmitStatuses(xx)));
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: 200}}>
                            <Lottie
                                loop
                                animationData={uploadJson}
                                play
                                style={{width: '100%%', height: 200}}
                            />
                        </div>
                        <DropMessageText>
                            {singleMode == false ? 'Перетащите файлы в эту область для загрузки' : 'Перетащите файл в эту область для загрузки'}
                        </DropMessageText>
                    </DropMessage>
                }

                {xFiles.length == 0 ? null :
                    <React.Fragment>
                        {!singleMode && <SubHeading>
                            Загруженные файлы
                        </SubHeading>}
                        <FilesListPlaceholder>
                            {xFiles.map((f, i) => {
                                let progress = uploadingProgressMap[f.id];
                                let {url, status, hash} = f;
                                let perc = progress?.progress;
                                let isConverting = (status == 'new' && hash != undefined);
                                return (
                                    <FileItemRow key={`ff_${f.name}_${f.type}_${f.size}_${i}`}
                                                 selected={(selectable && selectedUrl != undefined && (selectedUrl == f.url))}
                                                 style={{border: (singleMode ? 'none' : undefined)}}
                                                 onClick={() => {
                                                     console.log('FileItemRow: onClick:');
                                                     if (selectable == false) {
                                                         return;
                                                     }
                                                     onSelectVideo(f);
                                                 }}
                                    >
                                        <LeftHalf>
                                            {perc == undefined ? null :
                                                <ProgressPlaceholder>
                                                    <div>
                                                        {`${perc}%`}
                                                    </div>
                                                    {isConverting == false ? null :
                                                        <div style={{fontSize: 12}}>
                                                            converting...
                                                        </div>
                                                    }
                                                </ProgressPlaceholder>
                                            }
                                            {((url == undefined) || (isConverting == true)) ? null :
                                                <PlayerPlaceholder>
                                                    <Video controls={true}>
                                                        <source src={url}/>
                                                    </Video>
                                                </PlayerPlaceholder>
                                            }
                                        </LeftHalf>

                                        <RightHalf>
                                            <Name>
                                                {f.name}
                                            </Name>
                                            {/*<div>*/}
                                            {/*    {JSON.stringify(f)}*/}
                                            {/*</div>*/}
                                        </RightHalf>

                                    </FileItemRow>
                                )
                            })}


                        </FilesListPlaceholder>


                    </React.Fragment>
                }

                {(closeButtonVisible == false || singleMode) ? null :
                    <CloseMessagePlaceholder onClick={() => {
                        onClose();
                    }}>
                        Закрыть
                    </CloseMessagePlaceholder>
                }

            </DndInnerPlaceholder>

        </Wrapper>
    );
}

const LeftHalf = styled.div`
  height: 160px;
  width: 180px;
  margin-right: 5px;
  position: relative;
`;

const ProgressPlaceholder = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
`;

const PlayerPlaceholder = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 15px;
  max-width: 300px;
`;

const Video = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const RightHalf = styled.div`
  flex: 1;
`;


const DeleteSpan = styled.span`
  color: red;
  opacity: 0.75;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const OldFilePlayerPlaceholder = styled.div`
  width: 240px;
  height: 180px;
`;

const OldFilePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DropMessageText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
`;

const SubHeading = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
`;

const FilesListPlaceholder = styled.div`
  display: grid;
  //grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
`;

const FileItemRow = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${props => (props.selected == true ? 'blue' : 'lightgrey')};
  border-radius: 6px;
  cursor: ${props => (props.selectable == true ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  //background: pink;
  box-sizing: border-box;
  background: white;
  //margin-top: 20px;
`;

const DndInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
`;

const DropMessage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed lightgrey;
  cursor: pointer;
`;

const CloseMessagePlaceholder = styled.div`
  cursor: pointer;
  font-size: 28px;
  text-decoration: underline;
  margin-bottom: 200px;
`;
