import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import QuestionEditorTool from "./QuestionEditorTool";

export default function QuestionsEditorTool(props) {
    const {
        loading = false,
        questions = [],
        onChange = newQuestions => {

        }
    } = props;

    const [selectedId, setSelectedId] = useState(undefined);
    const selectedQuestion = questions.filter(x => (x.id == selectedId))[0];

    useEffect(() => {
        if (questions.length > 0){
            setSelectedId(questions[0].id);
        }
    }, []);

    return (
        <Wrapper>

            <TopPlaceholder>
                {questions.map((a, i) => {
                    let isSelected = (selectedId == a.id);
                    return (
                        <BoxPlaceholder key={a.id} selected={isSelected} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            {+i + 1}
                        </BoxPlaceholder>
                    )
                })}
                <BoxPlaceholder onClick={() => {
                    let arr = questions.concat([{
                        id: `t${+new Date()}`,
                        number: +new Date(),
                        answers: []
                    }]);
                    onChange(arr);
                }}>
                    {'+'}
                </BoxPlaceholder>
            </TopPlaceholder>

            {selectedQuestion == undefined ? null :
                <ContentPlaceholder key={`${selectedId}`}>
                    <QuestionEditorTool {...selectedQuestion}
                                        loading={loading}
                                        onSave={d => {
                        let arr = questions.map((a, i) => (a.id == selectedId ? ({...a, ...d}) : a));
                        onChange(arr);
                    }}/>
                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  /*display: flex;
  flex-direction: column;
  align-items: center;*/
`;

const ContentPlaceholder = styled.div`

`;

const TopPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const BoxPlaceholder = styled.div`
  border: 1px solid whitesmoke;
  border-radius: 4px;
  display: flex;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 14px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;