import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import x_img from '../../assets/img/x.svg'
import { useDispatch } from 'redux-react-hook';
import * as displayIcons from '../../redux/actions/DisplayHelpersActions';
import { isMobile } from 'polotno/utils/screen';


export default function NiceModal(props) {
    const {
        onClose = () => {

        },
        zIndex = 10
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isMobile()) {
            dispatch(displayIcons.switchDisplayIconsState(true));
        }
        return () => {
            if (isMobile()) {
                dispatch(displayIcons.switchDisplayIconsState(false))
            }
        }
    }, [])

    return (
        <Wrapper zIndex={zIndex}>

            <Box>
                <TopBox>
                    <CloseIcon src={x_img} onClick={() => {
                        onClose();
                    }}/>
                </TopBox>

                <ContentPlaceholder>
                    {props.children}
                </ContentPlaceholder>

            </Box>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: ${props => (props.zIndex == undefined ? 10 : props.zIndex)};
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(51, 51, 51, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
`;

const Box = styled.div`
  box-sizing: border-box;
  background: white;
  position: relative;
  border-radius: 4px;
  padding: 30px;
  
  @media (max-width: 720px){
    width: 100%;
    height: 100%;
    padding: 1px;
  }
`;

const TopBox = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  
  @media (max-width: 720px) {
    z-index: 11;
  }
`;

const ContentPlaceholder = styled.div`

`;
