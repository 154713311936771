import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const AccessGroupsAPI = {

    async getAllAccessGroups() {
        let pld = (await axios.get(`${API_ENDPOINT}/v2/access_groups`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getAccessGroup(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v2/access_groups/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getAccessGroupUsers(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v2/access_groups/${id}/users`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async createAccessGroup(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v2/access_groups`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateAccessGroup(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/v2/access_groups`, data)).data;
        return pld.result;
    },

    async deleteAccessGroup(id) {
        await axios.delete(`${API_ENDPOINT}/v2/access_groups/${id}`, {
            data: {id: id}
        })
    }


}

export default AccessGroupsAPI;
