import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TranslatableParagraph from "../../translate/panels/TranslatableParagraph";
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";
import WrappedTranslatableParagraph from "../../translate/panels/WrappedTranslatableParagraph";
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";

let renderRole = (a, k, onRoleSelect) => {
    return (
        <RolePlaceholder>
            <AvaPlaceholder>
                <Avatar src={getFixedUrl(a?.avatar)} onClick={() => {
                    onRoleSelect(k);
                }}/>
            </AvaPlaceholder>
            <RoleNamePlaceholder>
                {a?.name}
            </RoleNamePlaceholder>
        </RolePlaceholder>
    )
}

export default function DialogViewTool(props) {
    const {
        userId,
        avatar,
        videoUrl,
        firstRole,
        secondRole,
        messages = [],
        onRoleSelect = n => {

        }
    } = props;

    console.log('DialogViewTool: render: userId = ', userId);

    return (
        <Wrapper>

            <VideoPlaceholder>
                {/*<Video controls={true}>*/}
                {/*    <source src={videoUrl}/>*/}
                {/*</Video>*/}

                {(videoUrl == undefined || videoUrl == '') ?
                    null :
                    <ReactPlayer
                        width={'100%'}
                        height={'100%'}
                        light={avatar || DEFAULT_VIDEO_PLACEHOLDER}
                        controls={true}
                        playing={true}
                        url={getFixedUrl(videoUrl)}/>
                }

            </VideoPlaceholder>

            <Heading>
                Выберите роль
            </Heading>

            <BottomPlaceholder>
                <Half>
                    {renderRole(firstRole, 0, onRoleSelect)}
                </Half>
                <Half>
                    {renderRole(secondRole, 1, onRoleSelect)}
                </Half>
            </BottomPlaceholder>

            <MessagesList>
                {messages.map((msg, k) => {
                    let roleNumber = (k % 2);
                    let avaSrc = (roleNumber == 0) ? firstRole?.avatar : secondRole?.avatar;
                    let isLeft = (roleNumber == 0);
                    return (
                        <MessageItem key={k}>
                            {isLeft == false ? null :
                                <MessageAvatarPlaceholder>
                                    <Avatar size={50} src={getFixedUrl(avaSrc)}/>
                                </MessageAvatarPlaceholder>
                            }

                            {(msg?.text == undefined || msg?.text == '') ? null :
                                <MessageTextPlaceholder roleNumber={roleNumber}>
                                    <WrappedTranslatableParagraph
                                        text={msg?.text}
                                        userId={props.userId}
                                    />
                                </MessageTextPlaceholder>
                            }

                            {isLeft == true ? null :
                                <MessageAvatarPlaceholder>
                                    <Avatar size={50} src={getFixedUrl(avaSrc)}/>
                                </MessageAvatarPlaceholder>
                            }
                        </MessageItem>
                    )
                })}
            </MessagesList>



        </Wrapper>
    );
}


const boxSize = 640;
const videoHeight = 360;

const Wrapper = styled.div`
  width: ${boxSize}px;
  margin: 0 auto;
  background: white;
  padding: 5px;
  @media (max-width: ${boxSize}px) {
    width: 100%;
  }
`;

const VideoPlaceholder = styled.div`
  height: ${videoHeight}px;
  width: 100%;
  background: lightgrey;
  margin-bottom: 10px;
`;

const Video = styled.video`
  background: lightgray;
  width: 100%;
  height: 100%;
`;

const TopPlaceholder = styled.div`
  padding: 5px;
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 10px;
`;

const BottomPlaceholder = styled.div`
  padding: 5px;
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 10px;
`;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
`;

const RolePlaceholder = styled.div`
  text-align: center;
`;

const AvaPlaceholder = styled.div`

`;

const avaSize = 90;

const Avatar = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${props => (props.size == undefined ? avaSize : props.size)}px;
  height: ${props => (props.size == undefined ? avaSize : props.size)}px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  border-radius: 1000px;
  background-color: lightgrey;
  cursor: pointer;
  border: 2px solid wheat;

  :hover {
    opacity: 0.8;
  }
`;

const RoleNamePlaceholder = styled.div`
  padding: 5px;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
`;

const MessagesList = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  box-sizing: border-box;
`;

const MessageItem = styled.div`
  padding: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const messageAvaWidth = 60;

const MessageAvatarPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${messageAvaWidth}px;
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
`;

const MessageTextPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${messageAvaWidth}px);
  box-sizing: border-box;
  text-align: ${props => (props.roleNumber == 0 ? 'left' : 'right')};
  padding: 5px;
`;

const Heading = styled.div`
  text-align: center;
  padding: 5px;
  font-size: 24px;
  line-height: 32px;
`;