import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import Webcam from 'react-webcam';

import Tabs, {TabItem} from "../../ui/Tabs";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {GreenButton} from "../../ira/ui/Buttons";
import UploadAPI from "../../../api/UploadAPI";

import Spin from 'arui-feather/spin'

export default function UpdateAvatarTool(props) {
    const {
        onChange = url => {

        }
    } = props;
    const [avatar, setAvatar] = useState(props.avatar);
    const camRef = useRef();
    const [camReady, setCamReady] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>

            {/*{avatar == undefined ? null :*/}
            {/*    <TopPlaceholder>*/}
            {/*        <Avatar src={avatar}/>*/}
            {/*    </TopPlaceholder>*/}
            {/*}*/}

            <Tabs tabs={[
                {
                    label: 'Загрузить файл',
                    content: (
                        <TabItem>
                            <TabInner>
                                <SimpleFileUploader
                                    onSave={u => {
                                        setAvatar(u);
                                        onChange(u);
                                    }}
                                />
                            </TabInner>
                        </TabItem>
                    )
                },
                {
                    label: 'Сделать фото',
                    content: (
                        <TabItem>
                            <TabInner>
                                {camReady == true ? null :
                                    <CamLoaderPlaceholder>
                                        <Spin visible={true}/>
                                    </CamLoaderPlaceholder>
                                }
                                <Webcam
                                    audio={false}
                                    height={280}
                                    width={280}
                                    ref={camRef}
                                    onUserMedia={() => {
                                        setCamReady(true);
                                    }}
                                    screenshotFormat="image/jpeg"

                                />
                                {camReady == false ? null :
                                    <GreenButton onClick={async () => {
                                        if (loading == true) {
                                            return;
                                        }
                                        setLoading(true);
                                        const imageSrc = camRef.current.getScreenshot();
                                        let blob = await (await fetch(imageSrc)).blob();
                                        let pld = await UploadAPI.uploadImage(blob);
                                        console.log('pld = ', pld);
                                        setLoading(false);
                                        setAvatar(pld.mini_url);
                                        onChange(pld.mini_url);
                                    }}>
                                        <Spin visible={loading}/>
                                        {loading == true ? null : 'Сделать снимок'}
                                    </GreenButton>
                                }
                            </TabInner>

                        </TabItem>
                    )
                }
            ]}/>

        </Wrapper>
    );
}

const CamLoaderPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 280px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`;

const Avatar = styled.div`
  width: 220px;
  height: 220px;
  background-color: whitesmoke;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const AvatarPlaceholder = styled.div`

`;