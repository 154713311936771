import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {DEFAULT_VF_ANALYTICS_ENDPOINT} from "../../../api/TrackerAPI";
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";

import {LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend} from 'recharts'

function getDailyPoints(points = []) {
    let map = {};
    for (let i in points) {
        let p = points[i];
        let key = `t-${moment(+p.t).startOf('day')}`;
        if (map[key] == undefined) {
            map[key] = {
                number: 0,
                t: +moment(+p.t).startOf('day')
            }
        }
        map[key].number = +map[key].number + 1;
    }
    return Object.keys(map).map(x => map[x]).sort((a, b) => (+a.t - +b.t));
}

export default function TeachersAnalyticsTool(props) {
    const [items, setItems] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [monthTimestamp, setMonthTimestamp] = useState(+moment().startOf('day'));
    const [points, setPoints] = useState([]);
    const [pointsLoading, setPointsLoading] = useState(false);
    const [expandedUserId, setExpandedUserId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        axios.get(`${DEFAULT_VF_ANALYTICS_ENDPOINT}/user-activities?userRole=teacher`).then(d => d.data).then(arr => {
            setItems(arr);
            axios.get(`${API_ENDPOINT}/teachers/all`).then(d => d.data.result).then(arr2 => {
                setTeachers(arr2);
                setLoading(false);
            });
        })
    }, []);

    useEffect(() => {
        setPointsLoading(true);
        let from = +moment(+monthTimestamp).startOf('month').startOf('day');
        let to = +moment(+monthTimestamp).endOf('month').endOf('day');
        axios.get(`${DEFAULT_VF_ANALYTICS_ENDPOINT}/sessions-in-range/${from}/${to}?userRole=teacher`).then(d => d.data).then(arr => {
            setPoints(arr);
            setPointsLoading(false);
        });
    }, [monthTimestamp]);

    const teachersMap = teachers.reduce((mp, u) => ({...mp, [u.id]: u}), {});
    let pointsMap = {};
    for (let i in points) {
        let p = points[i];
        if (pointsMap[p.userId] == undefined) {
            pointsMap[p.userId] = {
                number: 0,
                userId: p.userId
            }
        }
        pointsMap[p.userId].number = +pointsMap[p.userId].number + 1;
    }

    let sortedItems = items.map(x => {
        return {
            ...x,
            user: teachersMap[x.userId],
            number: pointsMap[x.userId]?.number
        }
    }).filter(x => (x?.user != undefined && x?.number != undefined)).sort((a, b) => (+b.number - +a.number));
    console.log('render: sortedItems = ', sortedItems);

    let somethingIsLoading = (pointsLoading || loading);

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopSide onClick={() => {
                    setMonthTimestamp(moment(monthTimestamp).add(-1, 'months'));
                }}>
                    {'<<<'}
                </TopSide>
                <TopCenter>
                    {moment(monthTimestamp).format('MMMM, YYYY')}
                </TopCenter>
                <TopSide onClick={() => {
                    setMonthTimestamp(moment(monthTimestamp).add(1, 'months'));
                }}>
                    {'>>>'}
                </TopSide>
            </TopPlaceholder>

            <ContentPlaceholder>

                {somethingIsLoading == false ? null :
                    <Code/>
                }

                {somethingIsLoading == true ? null :
                    <>
                        {sortedItems.map((a, i) => {
                            let u = teachersMap[a.userId];
                            let isExpanded = (expandedUserId == a.userId);
                            let dPoints = (isExpanded == false) ? [] : getDailyPoints(points.filter(xx => (xx.userId == a.userId)));
                            return (
                                <Row key={`${a.id}_${i}`}
                                     style={{
                                         background: (isExpanded == true ? 'whitesmoke' : 'white'),
                                         padding: (isExpanded == true ? '10px' : '0px'),
                                         marginBottom: (isExpanded == true ? '20px' : '0px'),
                                     }}
                                     onClick={() => {
                                         setExpandedUserId(a.userId);
                                     }}>
                                    <TopRow>
                                        <Left style={{fontWeight: (isExpanded == true ? 'bold' : 'normal')}}>
                                            {`${+i + 1}) ${CommonHelper.getUserName(u)} [${a.number}]`}
                                        </Left>

                                        <Right>
                                            {`last activity: ${moment(a.lastActivityTimestamp).format('DD.MM.YYYY hh:mm:ss')}`}
                                        </Right>
                                    </TopRow>
                                    {isExpanded == false ? null :
                                        <BottomRow>

                                            <LineChart width={680} height={300} data={dPoints}
                                                       margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                                <XAxis dataKey="t"
                                                       tickFormatter={a => {
                                                           return (moment(a).format('DD.MM'))
                                                       }}
                                                />
                                                <YAxis/>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip/>
                                                <Legend/>
                                                <Line type="monotone" dataKey="number" stroke="#8884d8"
                                                      activeDot={false}/>
                                            </LineChart>
                                            {/*{JSON.stringify(dPoints)}*/}
                                        </BottomRow>
                                    }
                                </Row>
                            )
                        })}
                    </>
                }


                <div style={{marginTop: 20}}>
                    {`total: ${sortedItems.length}`}
                </div>

            </ContentPlaceholder>


        </Wrapper>
    );
}

const Left = styled.div`

`;

const Right = styled.div`

`;

const TopRow = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const BottomRow = styled.div`
  padding: 10px;
  background: whitesmoke;
  width: 100%;
`;

const Row = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
`;

const ContentPlaceholder = styled.div`
  width: 720px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
  background: white;
  border-radius: 4px;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const TopCenter = styled.div`

`;

const TopSide = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;