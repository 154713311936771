import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'

import moment from 'moment'

import {Map, Set} from 'immutable'

import ls from 'local-storage'

import Spin from 'arui-feather/spin'
import MoodleLoginForm from "../forms/MoodleLoginForm";

import {Code} from 'react-content-loader'
import {API_ENDPOINT} from "../../../constants/config";

import ch_box_image from '../../../assets/img/ch_box.svg'

export default function MoodleSelectUserPanel(props) {
    const {
        selectedEmailsSet = Set(),
        onNewSelect = u => {

        }
    } = props;

    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);

    const [moodleToken, setMoodleToken] = useState((ls('moodle_token') == undefined || ls('moodle_token') == 'undefined') ? undefined : ls('moodle_token'));


    const [loadingSet, setLoadingSet] = useState(Set());

    const [selectedCoursesSet, setSelectedCoursesSet] = useState(Set());
    const [coursesUsersMap, setCoursesUsersMap] = useState(Map());


    useEffect(() => {
        if (moodleToken == undefined) {
            return;
        }
        setLoading(true);
        axios.get(`${API_ENDPOINT}/moodle/courses`, { // todo: move to API file
            params: {
                token: moodleToken
            }
        }).then(d => d.data.result).then(arr => {
            setCourses(arr);
            setLoading(false);
        });
    }, [moodleToken]);

    useEffect(() => {

    }, [selectedCoursesSet]);

    if (moodleToken == undefined) {
        return (
            <MoodleLoginForm onSave={d => {
                setMoodleToken(ls('moodle_token'));
            }}/>
        )
    }

    console.log('Render! courses = ', courses);

    if (courses == undefined) {
        return null;
    }

    if (loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>
            <ListPlaceholder>
                {courses.map((c, i) => {
                    let {startdate, enddate} = c;
                    let isLoading = (loadingSet.has(c.id) == true);
                    let users = coursesUsersMap.get(`${c.id}`);
                    if (users == undefined) {
                        users = [];
                    }
                    return (
                        <CourseItem key={`${i}_${c.id}`}>
                            <CourseName style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{flex: 1}}>
                                    {c.shortname}
                                </div>
                                <div style={{marginLeft: 15, cursor: 'pointer', fontSize: 12, opacity: 0.7}}
                                     onClick={async () => {
                                         if (isLoading == true) {
                                             return;
                                         }

                                         setLoadingSet(loadingSet.add(c.id));
                                         let arr = (await axios.get(`${API_ENDPOINT}/moodle/courses/${c.id}/users`, {
                                             params: {
                                                 token: moodleToken
                                             }
                                         })).data.result;
                                         console.log('git students arr = ', arr);
                                         setCoursesUsersMap(coursesUsersMap.set(`${c.id}`, arr));
                                         setLoadingSet(loadingSet.delete(c.id));
                                     }}>
                                    <Spin visible={isLoading}/>
                                    {isLoading == true ? null :
                                        (users.length == 0 ? 'показать пользователей' : `${users.length} пользователей`)
                                    }
                                </div>
                            </CourseName>
                            {(startdate == undefined || enddate == undefined) ? null :
                                <div>
                                    {`${moment(startdate * 1000).format('DD.MM.YYYY')} - ${moment(enddate * 1000).format('DD.MM.YYYY')}`}
                                </div>
                            }

                            <StudentsList>
                                {users.map((u, j) => {
                                    let isSelected = selectedEmailsSet.has(u.email);
                                    return (
                                        <StudentItem key={`u_${j}_${u.id}`} selected={isSelected} onClick={() => {
                                            if (isSelected == true) {
                                                return;
                                            }
                                            onNewSelect(u);
                                        }}>
                                            <img src={u.avatar}
                                                 style={{width: 60, height: 60, borderRadius: 1000, marginRight: 10}}/>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <span>
                                                    {`${u.fullname}`}
                                                </span>
                                                <span>
                                                    {isSelected == true ?
                                                        <img style={{height: 15, width: 15, marginLeft: 15}}
                                                             src={ch_box_image}/> : null}
                                                </span>
                                            </div>
                                        </StudentItem>
                                    )
                                })}
                            </StudentsList>

                            {/*{c.summary == undefined ? null :*/}
                            {/*    <div dangerouslySetInnerHTML={{__html: c.summary}}></div>*/}
                            {/*}*/}
                            {/*{JSON.stringify(c)}*/}
                        </CourseItem>
                    )
                })}
            </ListPlaceholder>
        </Wrapper>
    );
}

const StudentsList = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const StudentItem = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const Wrapper = styled.div`
  min-height: 300px;
  max-height: 70vh;
  overflow-y: auto;
`;

const ListPlaceholder = styled.div`

`;

const CourseName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

`;

const CourseItem = styled.div`
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: white;
`;