import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {getUser} from "../../chat/panels/UserInfoSpan";
import CommonHelper from "../../../helpers/CommonHelper";

export default function OccupiedInfoTool(props) {
    const {
        occupantId
    } = props;

    const [user, setUser] = useState(undefined);

    useEffect(() => {
        getUser(occupantId).then(x => {
            setUser(x);
        })
    }, [occupantId]);


    if (user == undefined) {
        return '...';
    }

    let name = CommonHelper.getUserName(user);


    return (
        <Wrapper>
            <TopPlaceholder>
                Доступ к редактированию запрещен
            </TopPlaceholder>
            <p>
                В данный момент вы не можете редактировать.
            </p>
            <p>
                {`Модуль редактирует ${name} (${user.email})`}
            </p>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 20px;
  background: #f0808034;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const TopPlaceholder = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;