import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TextProcessingAPI from "../../../api/TextProcessingAPI";
import './style.css'
import sound_img from '../../../assets/img/sound.svg'

import {PieChart, Pie, Legend, Cell} from 'recharts'

const colors = [
    '#EF476F',
    '#FFD166',
    '#06D6A0',
    '#118AB2',
    '#073B4C',
    '#0CB0A9',
    '#1E4D5C',
    '#325D6B',
    '#1094B0',
    '#83D483',
    '#E8BE5D'
];

export default function TextNlpTool(props) {
    const {
        text = ''
    } = props;
    const [words, setWords] = useState([]);
    const [sentences, setSentences] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (text == undefined) {
            return;
        }
        setLoading(true);
        TextProcessingAPI.getWordsFromText(text).then(d => {
            setWords(d.words);
            setSentences(d.sentences);
            setLoading(false);
        })
    }, [text]);

    if (loading == true) {
        return (
            <Code/>
        )
    }

    if (words == undefined || words.length == 0) {
        return null;
    }
    let levelsMap = {};
    for (let i in words) {
        let w = words[i];
        let level = w?.level;
        if (level == undefined) {
            continue;
        }
        if (levelsMap[level] == undefined) {
            levelsMap[level] = {
                level: level,
                value: 0
            }
        }
        levelsMap[level].value = +levelsMap[level].value + 1;
    }
    console.log('levelsMap = ', levelsMap);
    console.log('levelsArr = ', Object.values(levelsMap));
    console.log('sentences = ', sentences);
    let lArr = Object.values(levelsMap).sort((a, b) => (+b.value - +a.value));

    return (
        <Wrapper>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <PieChart width={200} height={200}>
                    <Pie nameKey="level" dataKey="value" data={lArr} fill="#82ca9d" label={true}>
                        {
                            lArr.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]}/>
                            ))
                        }
                    </Pie>
                    <Legend layout="vertical" verticalAlign="middle" align="right"/>
                </PieChart>
            </div>

            <div style={{padding: 10, textAlign: 'center'}}>
                {lArr.map((a, i) => {
                    return (
                        <span key={a.level} style={{marginRight: 15}}>
                            <span style={{fontWeight: 'bold', opacity: '0.5'}}>
                                {`${a.level.toUpperCase()}: `}
                            </span>
                            <span>
                                {`${a.value}`}
                            </span>
                        </span>
                    )
                })}
            </div>

            <div style={{padding: 10}}>
                <OriginalTextPlaceholder>
                    {text}
                </OriginalTextPlaceholder>
            </div>

            <div style={{padding: 10}}>
                <OriginalTextPlaceholder>
                    {sentences.map((a, i) => {
                        let {words} = a;
                        return (
                            <div key={`aa${i}-${a.position}`} >
                                {words.map((b, j) => {
                                    return (
                                        <span key={`dd-${j}`} style={{marginLeft: 3}} className={`xword ${b.level}`} >
                                            {b.token}
                                        </span>
                                    )
                                })}
                            </div>
                        )
                    })}
                </OriginalTextPlaceholder>
            </div>

            <TablePlaceholder>

                <Table>
                    <Tbody>
                        {words.map((a, i) => {
                            let {audioUrls = []} = a;
                            return (
                                <Tr key={a.word}>
                                    <Td>
                                        {a.word}
                                    </Td>
                                    <Td style={{width: 30, textAlign: 'center'}}>
                                        {a.number}
                                    </Td>
                                    <Td style={{width: 60, fontSize: 12, opacity: 0.5}}>
                                        {a.pos}
                                    </Td>
                                    <Td style={{width: 30, textAlign: 'center'}}>
                                        {a.level == undefined ? '' : a.level.toUpperCase()}
                                    </Td>
                                    <Td style={{width: 80}}>
                                        {audioUrls.map((xx, j) => {
                                            return (
                                                <AudioImg src={sound_img} key={j} onClick={() => {
                                                    let audio = new Audio(xx);
                                                    audio.play();
                                                }}>
                                                </AudioImg>
                                            )
                                        })}
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>

            </TablePlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TablePlaceholder = styled.div`
  padding: 10px;
`;

const OriginalTextPlaceholder = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: whitesmoke;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;

const AudioImg = styled.img`
  margin-right: 7px;
  height: 22px;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const Tbody = styled.tbody`

`;