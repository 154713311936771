import React, {useEffect, useState} from 'react';
import {Set} from 'immutable';
import styled from 'styled-components';
import {Button} from "../../../ui/Button";

import Spin from 'arui-feather/spin'
import {GoodFittedImage} from "./MultipleChoiceExerciseEditorTool";

export default function PlayChoiceExerciseTool(props) {
    const {
        imageUrl,
        videoUrl,

        submitButtonLabel = 'Ответить',
        defaultSelectedIds = [],
        taskText = '',
        isMultiple = true,
        options = [],
        onSave = ids => {

        },
        loading = false
    } = props;

    const [selectedSet, setSelectedSet] = useState(defaultSelectedIds.reduce((st, x) => st.add(x), Set()));
    const canSubmit = (selectedSet.toArray().length > 0);

    useEffect(() => {
        // console.log('selection changed!');
        let ids = selectedSet.toArray();
        onSave(ids);
    }, [selectedSet]);

    console.log('PlayChoiceExerciseTool: options = ', options);

    return (
        <Wrapper>

            {taskText == undefined || taskText == '' ? null :
                <TopTaskPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: taskText.replace(/\n/g, '<br/>')}} ></div>
                </TopTaskPlaceholder>
            }


            {(imageUrl == undefined || imageUrl == '') ? null :
                <ImagePlaceholder>
                    <GoodFittedImage src={imageUrl}/>
                </ImagePlaceholder>
            }

            {(videoUrl == undefined || videoUrl == '') ? null :
                <Video controls={true}>
                    <source src={videoUrl}/>
                </Video>
            }

            <OptionsPlaceholder>
                {options.map((opt, i) => {
                    let isSelected = selectedSet.has(opt.id);
                    return (
                        <OptItem key={opt.id} selected={isSelected} onClick={() => {
                            if (isMultiple == true) {
                                if (isSelected == true) {
                                    setSelectedSet(selectedSet.delete(opt.id));
                                } else {
                                    setSelectedSet(selectedSet.add(opt.id))
                                }
                            } else {
                                setSelectedSet(Set().add(opt.id));
                            }
                        }}>
                            {opt.text}
                        </OptItem>
                    )
                })}
            </OptionsPlaceholder>

            {true == true ? null :
                <BottomPlaceholder>
                    <Button disabled={!canSubmit} onClick={() => {
                        if (canSubmit == false) {
                            return;
                        }
                        onSave(selectedSet.toArray())
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            submitButtonLabel
                        }
                    </Button>
                </BottomPlaceholder>
            }


        </Wrapper>
    );
}

const ImagePlaceholder = styled.div`
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 400px;
`;


const Wrapper = styled.div`
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-sizing: border-box;
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MaxSpan = styled.div`
  max-height: 40px;
  height: 40px;
  overflow-y: auto;
  box-sizing: border-box;
`;

const OptItem = styled.div`
  margin-bottom: 10px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
  background: ${props => (props.selected == true ? 'wheat' : 'white')};
  cursor: pointer;
  border-radius: 4px;
  

  :hover {
    border: 2px solid blue;
  }
`;

const TopTaskPlaceholder = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px dashed whitesmoke;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
`;

const OptionsPlaceholder = styled.div`

`;
