import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from "axios";
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../constants/config";
import FirebaseAPI from '../../api/FirebaseAPI'
import ReactHelper from "../../helpers/ReactHelper";

import ads_video_json from '../../assets/lottie/video-advertisement.json'
import LottieLoader from "../loaders/LottieLoader";

const getFixedUrl = xUrl => {
    // if (xUrl.indexOf('ru-') == -1) {
    //     return `https://drive.sabir.pro/aws_cache/?url=${xUrl}`;
    // }
    return xUrl;
}

const getPrettyTime = t => {
    let a = Math.round(t);
    let minutes = Math.floor(a / 60.0);
    let seconds = Math.floor(a - 60 * minutes);
    let gs = s => {
        if (+s < 10) {
            return `0${s}`
        }
        return `${s}`;
    }
    return `${gs(minutes)}:${gs(seconds)}`;
}

const hashCode = function (s) {
    return s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a
    }, 0);
}

export default function SyncVideoPlayer(props) {
    const {
        url,
        avatar,
        roomPrefix = '',
        currentUser
    } = props;

    let canControl = (currentUser?.userRole == 'teacher');
    const [loading, setLoading] = useState(true);
    const [playing, setPlaying] = useState(undefined);
    const [seekPosition, setSeekPosition] = useState(undefined);

    const [loadingProgress, setLoadingProgress] = useState(undefined);
    const fileRef = useRef();
    const videoRef = useRef();

    const rightControlRef = useRef();
    const leftControlRef = useRef();
    const lineControlRef = useRef();

    const urlHash = hashCode(url);
    const roomName = `${roomPrefix}_sync_v_${urlHash}`;

    useEffect(() => {
        let rUrl = getFixedUrl(url);
        axios.get(rUrl, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setLoadingProgress(percentCompleted);
            }
        }).then(d => d.data).then(data => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            fileRef.current = downloadUrl;
            setLoading(false);
            FirebaseAPI.subscribeSyncVideoOnChanges(roomName, chData => {
                console.log('chData: -> chData = ', chData);
                if (chData == undefined) {
                    return;
                }
                console.log('subscribeSyncVideoOnChanges: chData = ', chData);
                if (chData.position != seekPosition) {
                    // console.log('!!!!! changing position! chData.position = ', chData.position);
                    setSeekPosition(chData.position);
                }
                if (chData.playing != playing) {
                    setPlaying(chData.playing);
                }
            }).then(d => {
                if (d != undefined) {
                    setPlaying(d.playing);
                    setSeekPosition(d.position);
                    // console.log('chData: -> d = ', d);
                }
            });
        });
    }, []);

    useEffect(() => {
        FirebaseAPI.initFirebase();
    }, [urlHash]);

    useEffect(() => {
        if (canControl == true) {
            return;
        }
        try {
            videoRef.current.currentTime = seekPosition;
        } catch (exc) {
        }
    }, [seekPosition]);

    useEffect(() => {
        if (canControl == true) {
            return;
        }
        try {
            if (playing == true) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        } catch (exc) {
        }
    }, [playing]);

    ReactHelper.useInterval(() => {
        try {
            let currentTime = videoRef.current.currentTime;
            let duration = videoRef.current.duration;
            let perc = 100.0 * currentTime / duration;
            leftControlRef.current.innerText = `${getPrettyTime(currentTime)}`;
            rightControlRef.current.innerText = `${getPrettyTime(duration)}`;
            lineControlRef.current.style.width = `${perc}%`;
        } catch (exc) {

        }
    }, 200);

    if (loading == true) {
        return (
            <LoadingWrapper>

                <div style={{height: 'calc(100% - 120px)', width: '100%'}}>
                    <LottieLoader json={ads_video_json}/>
                </div>
                <div style={{
                    width: '100%',
                    height: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {loadingProgress && `loading... ${loadingProgress.toFixed(1)}%`}
                </div>
            </LoadingWrapper>
        )
    }

    return (
        <Wrapper>
            <VideoPlaceholder style={{height: (canControl == true) ? '100%' : 'calc(100% - 50px)'}}>

                <MyVideo
                    ref={videoRef}
                    controls={canControl}
                    width={'100%'}
                    height={'100%'}
                    poster={avatar || DEFAULT_VIDEO_PLACEHOLDER}
                    onPlay={async () => {
                        if (canControl == false) {
                            return;
                        }
                        console.log('onPlay occured!');
                        let cPos = videoRef.current.currentTime;
                        await FirebaseAPI.updateSyncVideoState(roomName, true, cPos);
                    }}
                    onPause={async () => {
                        if (canControl == false) {
                            return;
                        }
                        console.log('onPause occured!');
                        let cPos = videoRef.current.currentTime;
                        await FirebaseAPI.updateSyncVideoState(roomName, false, cPos);
                    }}
                    onSeeked={async evt => {
                        if (canControl == false) {
                            return;
                        }
                        console.log('onSeeked: evt = ', evt);
                        let pos = evt.target.currentTime;
                        try {
                            await FirebaseAPI.updateSyncVideoState(roomName, playing, pos);
                        } catch (exc) {

                        }
                    }}
                >
                    <source src={fileRef.current}/>
                </MyVideo>

            </VideoPlaceholder>

            {canControl == true ? null :
                <PlayerBottomPlaceholder>
                    <PlayerSidePlaceholder>
                        <span ref={leftControlRef}></span>
                    </PlayerSidePlaceholder>

                    <ProgressLinePlaceholder>
                        <ProgressLine ref={lineControlRef}/>
                    </ProgressLinePlaceholder>

                    <PlayerSidePlaceholder>
                        <span ref={rightControlRef}></span>
                    </PlayerSidePlaceholder>
                </PlayerBottomPlaceholder>
            }
        </Wrapper>
    );
}

const PlayerSidePlaceholder = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;


const ProgressLinePlaceholder = styled.div`
  position: relative;
  height: 20px;
  background: grey;
  flex: 1;
`;

const ProgressLine = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #8C63A9;
`;

const MyVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: lightgrey;
`;

const PlayerBottomPlaceholder = styled.div`
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  background: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  background: lightgrey;
  box-sizing: border-box;
`;

const LoadingWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;