import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AssistantsAPI from "../../../api/AssistantsAPI";

import NiceModal from "../../modals/NiceModal";
import {Input, Textarea} from "../../ira/ui/Inputs";
import toast from "react-hot-toast";

export default function UserConversationTool(props) {
    const {
        userId,
        conversationId
    } = props;


    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const messagesEndRef = useRef(null);

    const [newText, setNewText] = useState('');

    useEffect(() => {
        if (userId == undefined) {
            return;
        }
        setLoading(true);
        AssistantsAPI.getConversationMessages(userId, conversationId).then((pld) => {
            setMessages(pld);
            setLoading(false);
        });
    }, [userId]);

    let fMessages = messages.filter(x => (x.role != 'system'));
    let noMessages = (fMessages.length == 0 && loading == false);



    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    return (
        <Wrapper>

            <TopPlaceholder>

            </TopPlaceholder>

            <ContentPlaceholder>

                {noMessages == true ?
                    <div style={{textAlign: 'center', padding: 10}} >
                        Write your task to AI Assistant.
                    </div>
                    :
                    <MessagesPlaceholder>
                        {fMessages.filter(x => (x.role != 'system')).map((a, i) => {
                            return (
                                <MessageItem className={`mt-2 mb-2`} key={`t${a.id}${i}`}>
                                    <NamePlaceholder>
                                        <Name>
                                            {(a.role == 'user' ? 'me' : a.role) || 'assistant'}
                                        </Name>
                                        {/*<span style={{marginLeft: '10px', fontSize: '10px', float: 'right', opacity: 0.4, fontStyle: 'italic'}}>*/}
                                        {/*    {moment(a.timestamp).format('YYYY-MM-DD HH:mm:ss')}*/}
                                        {/*</span>*/}
                                    </NamePlaceholder>
                                    <div>
                                        <div dangerouslySetInnerHTML={{__html: `${a.content}`.replace(/\n/g, '<br/>')}} ></div>
                                    </div>

                                </MessageItem>
                            )
                        })}
                        <div ref={messagesEndRef} />
                    </MessagesPlaceholder>
                }



            </ContentPlaceholder>

            <BottomPlaceholder>
                <TypingPlaceholder>
                    <textarea style={{flex: 1}} value={newText} onChange={evt => {
                        setNewText(evt.target.value);
                    }}></textarea>
                    <SendButton onClick={async () => {
                        if (newText.trim() == '' || loading == true) {
                            return;
                        }
                        setLoading(true);
                        try{
                            let pld = await AssistantsAPI.createConversationMessage(userId, conversationId, newText);
                            toast.success('Message sent');
                            let arr = await AssistantsAPI.getConversationMessages(userId, conversationId);
                            setMessages(arr);
                            setNewText('');
                        }catch(exc){
                            toast.error('Something went wrong');
                        }

                        setLoading(false);
                    }}>
                        {loading == true ? '...' : 'Send'}
                    </SendButton>
                </TypingPlaceholder>
            </BottomPlaceholder>
        </Wrapper>
    );
}

const SendButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: center;
  background: #085BFF;
  color: white;

  :hover {
    opacity: 0.75;
  }
`;

const Name = styled.span`
  font-weight: bold;
`;

const MessagesPlaceholder = styled.div`
  max-height: 100%;
    overflow-y: auto;
  
`;

const NamePlaceholder = styled.div`

`;

const MessageItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: whitesmoke;
`;


const TopPlaceholder = styled.div`
  
`;

const ContentPlaceholder = styled.div`
  height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  padding-left: 10px;
  padding-right: 10px;
`;

const BottomPlaceholder = styled.div`
  height: 80px;
  border-top: 1px solid #e0e0e0;
`;

const TypingPlaceholder = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const ModalInnerPlaceholder = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 10px;
`;