import * as types from '../ActionTypes.js'
import FoldersAPI from '../../api/FoldersAPI.js';

let loadFolders_ = () => {
    return {
        type: types.SMART_LOAD_FOLDERS
    }
}
let loadFoldersSuccess = (folders) => {
    return {
        type: types.SMART_LOAD_FOLDERS_SUCCESS,
        folders: folders
    }
}
let loadFoldersFailed = (error) => {
    return {
        type: types.SMART_LOAD_FOLDERS_FAIL,
        error: error
    }
}

//thunk
export function getMyAllCompactFolders() {
    return (dispatch, getState) => {
        dispatch(loadFolders_());
        return FoldersAPI.getAllShortFolders().then(
            folders => dispatch(loadFoldersSuccess(folders)),
            error => dispatch(loadFoldersFailed(error))
        )
    }
}

export function getFolder(id) {
    return (dispatch, getState) => {
        dispatch(loadFolders_());
        return FoldersAPI.getFolder(id).then(
            folder => dispatch(loadFoldersSuccess([folder])),
            error => dispatch(loadFoldersFailed(error))
        )
    }
}

export function getChildren(id) {
    return (dispatch, getState) => {
        dispatch(loadFolders_());
        return FoldersAPI.getChildren(id).then(
            folders => dispatch(loadFoldersSuccess(folders)),
            error => dispatch(loadFoldersFailed(error))
        )
    }
}

let createFolder_ = () => {
    return {
        type: types.SMART_CREATE_FOLDER
    }
}
let createFolderSuccess = (folder, parentId) => {
    return {
        type: types.SMART_CREATE_FOLDER_SUCCESS,
        folder: folder,
        parentId: parentId
    }
}
let createFolderFailed = (error) => {
    return {
        type: types.SMART_CREATE_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function createFolder(parentId, data) {
    return (dispatch, getState) => {
        dispatch(createFolder_());
        return FoldersAPI.createFolder(parentId, data).then(
            folder => dispatch(createFolderSuccess(folder, parentId)),
            error => dispatch(createFolderFailed(error))
        )
    }
}

//eca
let updateFolder_ = () => {
    return {
        type: types.SMART_UPDATE_FOLDER
    }
}
let updateFolderSuccess = (folder) => {
    return {
        type: types.SMART_UPDATE_FOLDER_SUCCESS,
        folder: folder
    }
}
let updateFolderFailed = (error) => {
    return {
        type: types.SMART_UPDATE_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function updateFolder(data) {
    return (dispatch, getState) => {
        dispatch(updateFolder_());
        return FoldersAPI.updateFolder(data).then(
            folder => dispatch(updateFolderSuccess(folder)),
            error => dispatch(updateFolderFailed(error))
        )
    }
}


let deleteFolder_ = () => {
    return {
        type: types.SMART_DELETE_FOLDER
    }
}
let deleteFolderSuccess = (id, parentId) => {
    return {
        type: types.SMART_DELETE_FOLDER_SUCCESS,
        id: id,
        parentId: parentId
    }
}
let deleteFolderFailed = (error) => {
    return {
        type: types.SMART_DELETE_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function deleteFolder(parentId, id) {
    return (dispatch, getState) => {
        dispatch(deleteFolder_());
        return FoldersAPI.deleteFolder(parentId, id).then(
            () => dispatch(deleteFolderSuccess(id, parentId)),
            error => dispatch(deleteFolderFailed(error))
        )
    }
}