import * as types from '../ActionTypes.js'
import PostsAPI from '../../api/PostsAPI.js';

let createPost_ = () => {
    return {
        type: types.CREATE_POST
    }
}
let createPostSuccess = (post) => {
    return {
        type: types.CREATE_POST_SUCCESS,
        post: post
    }
}
let createPostFailed = (error) => {
    return {
        type: types.CREATE_POST_FAIL,
        error: error
    }
}

//thunk
export function createPost(data) {
    return (dispatch, getState) => {
        dispatch(createPost_());
        return PostsAPI.createPost(data).then(
            post => dispatch(createPostSuccess(post)),
            error => dispatch(createPostFailed(error))
        )
    }
}

//eca
let updatePost_ = () => {
    return {
        type: types.UPDATE_POST
    }
}
let updatePostSuccess = (post) => {
    return {
        type: types.UPDATE_POST_SUCCESS,
        post: post
    }
}
let updatePostFailed = (error) => {
    return {
        type: types.UPDATE_POST_FAIL,
        error: error
    }
}

//thunk
export function updatePost(data) {
    return (dispatch, getState) => {
        dispatch(updatePost_());
        return PostsAPI.updatePost(data).then(
            post => dispatch(updatePostSuccess(post)),
            error => dispatch(updatePostFailed(error))
        )
    }
}

let loadPosts_ = () => {
    return {
        type: types.LOAD_POSTS
    }
}
let loadPostsSuccess = (posts) => {
    return {
        type: types.LOAD_POSTS_SUCCESS,
        posts: posts
    }
}
let loadPostsFailed = (error) => {
    return {
        type: types.LOAD_POSTS_FAIL,
        error: error
    }
}

//thunk
export function loadPosts() {
    return (dispatch, getState) => {
        dispatch(loadPosts_());
        return PostsAPI.getAllPosts().then(
            posts => dispatch(loadPostsSuccess(posts)),
            error => dispatch(loadPostsFailed(error))
        )
    }
}

let deletePost_ = () => {
    return {
        type: types.DELETE_POST
    }
}
let deletePostSuccess = (id) => {
    return {
        type: types.DELETE_POST_SUCCESS,
        id: id
    }
}
let deletePostFailed = (error) => {
    return {
        type: types.DELETE_POST_FAIL,
        error: error
    }
}

//thunk
export function deletePost(id) {
    return (dispatch, getState) => {
        dispatch(deletePost_());
        return PostsAPI.deletePost(id).then(
            () => dispatch(deletePostSuccess(id)),
            error => dispatch(deletePostFailed(error))
        )
    }
}