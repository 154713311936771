import * as types from '../ActionTypes.js'

export function switchDisplayIconsState(value) {
    if (value) {
        return  {
            type: types.HIDE_ICONS
        }
    } else if (!value) {
        return {
            type: types.SHOW_ICONS
        }
    } else {
        return {
            type: undefined
        }
    }
}