import * as types from '../ActionTypes'

const initialState = {
    iconsState: true
}

const DisplayHelpersReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case types.SHOW_ICONS:
            return {
                ...state,
                iconsState: true
            }
        case types.HIDE_ICONS:
            return {
                ...state,
                iconsState: false
            }
        default:
            return state
    }
}

export default DisplayHelpersReducer;