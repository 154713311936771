import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";
import loader_json from "../../assets/lottie/under_construction.json";
import GroupsPanel from "../groups/panels/GroupsPanel";

export default function StudentIndexApp(props) {


    return (
        <TeacherTemplate>

            <GroupsPanel/>

            {/*<ConstructionPlaceholder>*/}
            {/*    <LottieLoader*/}
            {/*        json={loader_json}*/}
            {/*    />*/}
            {/*    <div style={{marginTop: -100}} >*/}
            {/*        Инструкции появятся в ближайшее время...*/}
            {/*    </div>*/}
            {/*</ConstructionPlaceholder>*/}
        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;