import axios from 'axios'
import {USERS_AUDIO_UPLOAD_ENDPOINT} from "../constants/config";

const RecorderAPI = {

    async uploadUserAudio(blob, onProgress = p => {

    }) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(USERS_AUDIO_UPLOAD_ENDPOINT, formData, {
            onUploadProgress: progressEvent => {
                try {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                } catch (exc) {

                }
            }
        })).data;
        return pld;
    }


}

export default RecorderAPI;
