import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SearchAPI = {

    async searchContentItems({access, text, pageSize, page, type}) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/content-search`, {
            params: {
                access,
                text,
                pageSize,
                page,
                type
            }
        })).data;
        return pld;
    }


}

export default SearchAPI;
