import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

function isGoodLetter(s = '') {
    let alphabet = `aeiouyаеёиоуыэюя`;
    return (alphabet.indexOf(s.toLowerCase()) > -1);
}

export default function EmphasisEditor(props) {
    const {
        positions = [],
        text = '',
        onChange = x => {

        }
    } = props;

    if (text == undefined || text == '') {
        return null;
    }

    let letters = [];
    for (let i = 0; i < text.length; i++) {
        letters.push({
            letter: text[i],
            position: i
        });
    }

    console.log('render: EmphasisEditor: text = ', text);
    console.log('render: EmphasisEditor: letters = ', letters);

    return (
        <Wrapper>

            {letters.map((a, i) => {
                let isSpace = (a.letter == ' ');
                let isSelected = (positions.indexOf(i) > -1);
                let gl = isGoodLetter(a.letter);
                return (
                    <React.Fragment key={`x${i}_${a.letter}`}>
                        {isSpace == true ? <Space></Space> :
                            <React.Fragment>
                                {gl == true ?
                                    <GoodLetter isSelected={isSelected} onClick={() => {
                                        let newPositions = positions;
                                        if (isSelected == true) {
                                            newPositions = newPositions.filter(x => (x != a.position));
                                        } else {
                                            newPositions = newPositions.concat([a.position]);
                                        }
                                        newPositions = newPositions.sort();
                                        onChange(newPositions);
                                    }}>
                                        <LetterInner>
                                            {a.letter}
                                        </LetterInner>
                                    </GoodLetter>
                                    :
                                    <Letter>
                                        <LetterInner>
                                            {a.letter}
                                        </LetterInner>
                                    </Letter>
                                }

                            </React.Fragment>
                        }

                    </React.Fragment>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 10px;
`;


const Letter = styled.span`
  position: relative;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 1px;
  margin-right: 1px;
`;

const GoodLetter = styled(Letter)`
  border: 1px dashed lightgrey;
  cursor: pointer;
  
  background: ${props => (props.isSelected == true ? 'lightgreen' : 'white')};
  font-weight: ${props => (props.isSelected == true ? 'bold' : 'normal')};
  
  :hover {
    background: lightgrey;
  }
`;

const LetterInner = styled.span`

`;

const Space = styled.span`
  display: inline-block;
  width: 25px;
`;