import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import AllPostsPanel from '../posts/panels/AllPostsPanel';
import { translate } from '../../utils/Translate';
import { BlueButton } from '../ira/ui/Buttons';
import NiceModal from '../modals/NiceModal';
import CreatePostTool from '../posts/tools/CreatePostTool';
import { useMappedState } from 'redux-react-hook';

export default function PostsApp(props) {
    const [tab, setTab] = useState('public');
    const [createPostModal, setCreatePostModal] = useState(false);

    const { currentUser } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    return (
        <TeacherTemplate active={'posts'}>

            <TopPlaceholder>
                <TopLeft>
                    {
                        [
                            {
                                label: translate('all_posts'),
                                value: 'public'
                            },
                            {
                                label: translate('my_posts'),
                                value: 'my'
                            },
                        ].map((x, i) => {
                            return (
                                <TopRightTabItem selected={(x.value == tab)} key={i} onClick={() => {
                                    setTab(x.value);
                                }}>
                                    {x.label}
                                </TopRightTabItem>
                            )
                        })
                    }
                </TopLeft>
                <TopRight>
                    {['admin', 'teacher'].includes(currentUser?.userRole) == false ? null :
                        <BlueButton onClick={() => setCreatePostModal(true)}>{translate('create_post')}</BlueButton>
                    }
                </TopRight>
            </TopPlaceholder>

            <AllPostsPanel
                tab={tab}
            />

            {createPostModal == false ? null :
                <NiceModal onClose={() => setCreatePostModal(false)}>
                    <CreatePostTool onClose={() => setCreatePostModal(false)} />
                </NiceModal>
            }

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;

const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: ${props => (props.hasLogo ? 0 : 15)}px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;