import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {Input, Textarea} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";

import Spin from 'arui-feather/spin'

import moment from 'moment'
import DateTimePicker from "../../ui/DateTimePicker";
import Tabs, {TabItem} from "../../ui/Tabs";
import {translate} from "../../../utils/Translate";

export default function UpdateTaskForm(props) {
    const {
        canDelete = false,
        loading = false,
        onSave = d => {

        },
        onDelete = () => {

        },

        hasExtraInfo = false

    } = props;

    const [requestsAttachment, setRequestsAttachment] = useState(props?.requestsAttachment || false);
    const [name, setName] = useState(props?.name || '');
    const [deadlineTimestamp, setDeadlineTimestamp] = useState(props?.deadlineTimestamp || +moment().add(14, 'days'));
    const [description, setDescription] = useState(props?.description || '');


    return (
        <Wrapper>

            <Tabs
                tabs={[{
                    label: 'Общая информация',
                    content: (
                        <React.Fragment>
                            <Row>
                                <Label>
                                    Название задания (можно оставить пустым)
                                </Label>
                                <Input value={name} placeholder={''} onChange={evt => {
                                    setName(evt.target.value);
                                }}/>

                            </Row>

                            <Row>
                                <Label>
                                    {translate("tasks_deadline")}
                                </Label>
                                <CalPlaceholder>
                                    <DateTimePicker
                                        timestamp={deadlineTimestamp}
                                        onChange={t => {
                                            if (+t <= +new Date()) {
                                                return;
                                            }
                                            setDeadlineTimestamp(t);
                                        }}
                                    />
                                </CalPlaceholder>
                            </Row>

                            <BottomRow>

                                <BlueButton
                                    style={{marginBottom: 10}}
                                    onClick={() => {
                                        onSave({
                                            name: name,
                                            deadlineTimestamp: deadlineTimestamp
                                        })
                                    }}>
                                    <Spin visible={loading}
                                          theme={'alfa-on-color'}
                                    />
                                    {loading == true ? null : 'Save'}
                                </BlueButton>

                                {canDelete == false ? null :
                                    <DeleteSpan onClick={() => {
                                        if (window.confirm('Вы уверены?') == false) {
                                            return;
                                        }
                                        onDelete();
                                    }}>
                                        {translate('delete_task')}
                                    </DeleteSpan>
                                }

                            </BottomRow>
                        </React.Fragment>
                    )
                }, hasExtraInfo == false ? undefined : {
                    label: 'Дополнительно',
                    content: (
                        <TabItem>
                            <Row>
                                <Label>
                                    Сопроводительный текст к заданию
                                </Label>
                                <Textarea value={description}
                                          onChange={evt => {
                                              setDescription(evt.target.value);
                                          }}></Textarea>
                            </Row>
                            <Row>
                                <Label>
                                    Запросить прикрепление файла от учеников
                                </Label>
                                <CheckRow>
                                    <CheckFileItem selected={(requestsAttachment == true)} onClick={() => {
                                        setRequestsAttachment(true);
                                    }}>
                                        Запрашивать
                                    </CheckFileItem>
                                    <CheckFileItem selected={(requestsAttachment == false)} onClick={() => {
                                        setRequestsAttachment(false);
                                    }}>
                                        Не запрашивать
                                    </CheckFileItem>
                                </CheckRow>
                            </Row>

                            <BottomRow>

                                <BlueButton
                                    style={{marginBottom: 10}}
                                    onClick={() => {
                                        onSave({
                                            description: description,
                                            requestsAttachment: requestsAttachment
                                        })
                                    }}>
                                    <Spin visible={loading}
                                          theme={'alfa-on-color'}
                                    />
                                    {loading == true ? null : translate('save')}
                                </BlueButton>

                            </BottomRow>


                        </TabItem>
                    )
                }].filter(x => (x != undefined))}
            />

        </Wrapper>
    );
}

const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid lightgrey;
  //width: 320px;
`;

const CheckFileItem = styled.div`
  flex: 1;
  background: ${props => (props.selected == true ? '#FFC02C' : '')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  color: red;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const CalPlaceholder = styled.div`
  height: 300px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding-left: 3px;
  padding-right: 3px;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
  font-weight: bold;
  margin-bottom: 5px;
`;