import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";

import loader_json from '../../assets/lottie/under_construction.json'
import TreadmillsPanel from "../treadmills_exercises/panels/TreadmillsPanel";
import AllActiveUsersStatsTool from "../stats/tools/AllActiveUsersStatsTool";

export default function TeacherStatsApp(props) {

    return (
        <TeacherTemplate active={'stats'}>


            <Wrapper>

                <AllActiveUsersStatsTool/>

                {/*<ConstructionPlaceholder>*/}
                {/*    <LottieLoader*/}
                {/*        json={loader_json}*/}
                {/*    />*/}
                {/*    <div style={{marginTop: -100}}>*/}
                {/*        Инструкции появятся в ближайшее время...*/}
                {/*    </div>*/}
                {/*</ConstructionPlaceholder>*/}

            </Wrapper>


        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;