import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";

export default function ViewIdiomTool(props) {
    const {
        items = [],
        name
    } = props;

    const [index, setIndex] = useState(0);

    if (items.length == 0) {
        return null;
    }

    const currentMaterial = items[index];
    let idiomComment = currentMaterial?.comment;
    if (idiomComment != undefined) {
        idiomComment = idiomComment.replace('<b>Значение</b>', '').replace('\n', '<br/>');
    }

    return (
        <Wrapper>

            <TopHeadingPlaceholder>
                {name}
            </TopHeadingPlaceholder>

            <InnerWrapper>
                <LeftPlaceholder>

                    <VideoPlaceholder>
                        <ReactPlayer
                            width={'100%'}
                            height={'100%'}
                            light={currentMaterial?.avatar || DEFAULT_VIDEO_PLACEHOLDER}
                            controls={true}
                            playing={true}
                            url={currentMaterial?.url}/>
                    </VideoPlaceholder>

                    <TranscriptPlaceholder>
                        {currentMaterial?.transcript}
                    </TranscriptPlaceholder>

                </LeftPlaceholder>

                <RightPlaceholder>
                    <ItemsPlaceholder>

                        <IdiomCommentPlaceholder>
                            <div dangerouslySetInnerHTML={{__html: idiomComment}}></div>
                        </IdiomCommentPlaceholder>

                        {items.map((a, i) => {
                            return (
                                <ItemPlaceholder
                                    selected={(index == i)}
                                    key={a.id} onClick={() => {
                                    setIndex(i);
                                }}>

                                    <ItemAva src={a?.avatar || DEFAULT_VIDEO_PLACEHOLDER}>

                                    </ItemAva>

                                    <ItemInfo>
                                        <ItemInfoName>
                                            {a.name}
                                        </ItemInfoName>
                                    </ItemInfo>

                                </ItemPlaceholder>
                            )
                        })}

                    </ItemsPlaceholder>
                </RightPlaceholder>
            </InnerWrapper>


        </Wrapper>
    );
}

const IdiomCommentPlaceholder = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

const TopHeadingPlaceholder = styled.div`
  text-align: center;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  font-weight: bold;
  font-size: 16px;
`;

const TranscriptPlaceholder = styled.div`
  background: whitesmoke;
  padding: 5px;
  font-size: 12px;
`;

const ItemPlaceholder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid ${props => (props.selected == true ? 'wheat' : 'white')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  padding: 5px;
  margin-bottom: 5px;
`;

const ItemInfo = styled.div`

`;

const ItemInfoName = styled.div`

`;

const ItemAva = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${props => (props.src)}");
  margin-right: 5px;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 360px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 30px);
  overflow-y: auto;
`;

const InnerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const LeftPlaceholder = styled.div`
  width: 400px;
`;

const RightPlaceholder = styled.div`
  flex: 1;
  padding-left: 10px;
  box-sizing: border-box;
`;

const ItemsPlaceholder = styled.div`

`;