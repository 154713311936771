import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {selectFiles} from "../../bulk_uploader/BulkDndPlaceholder";
import upl_img from '../../../assets/img/file-upload.svg'
import UploadAPI from "../../../api/UploadAPI";
import {translate} from "../../../utils/Translate";

export default function SimpleImageUploader(props) {
    const {
        types,
        mode = 'system',
        onSave = url => {

        },
        placeholder = 'upload file',
        anotherPlaceholder = 'upload another file',
    } = props;

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState(props?.url);

    return (
        <Wrapper>

            <FilePicker onClick={async () => {
                if (uploading == true) {
                    return;
                }
                let myFiles = await selectFiles('image');
                setUploading(true);
                let pld;
                console.log('myFiles = ', myFiles);
                let fl = myFiles[0];
                if (fl == undefined || fl?.type == undefined || fl?.type.indexOf('image') == -1) {
                    return;
                }
                pld = await UploadAPI.uploadImage(myFiles[0], pr => {
                    setProgress(pr);
                });
                setUploading(false);
                setProgress(undefined);
                setUploaded(true);
                console.log('pld = ', pld);
                setUploadedUrl(pld?.mini_url);
                onSave(pld?.mini_url);
            }}>
                <FilePickerImg src={upl_img}/>
                <FileUploadSpan>
                    {uploadedUrl == undefined ? placeholder : anotherPlaceholder}
                </FileUploadSpan>
            </FilePicker>

            {uploading == false ? null :
                <div>
                    {`${translate('loading')} ${progress == undefined ? '' : `${Math.round(progress)}%`}`}
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;


const FileUploadSpan = styled.div`

`;

const FilePicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.85;
  }
`;


const FilePickerImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;