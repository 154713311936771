import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
import LottieLoader from "../loaders/LottieLoader";

import loader_json from '../../assets/lottie/under_construction.json'
import InstructionsPanel from "../instructions/panels/InstructionsPanel";

export default function GuestInstructionsApp(props) {

    return (
        <Wrapper>
            <InstructionsPanel/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 820px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
  @media(max-width: 820px){
    width: 100%;
    padding: 0px;
  }
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;