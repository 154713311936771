
import {Set, Map} from 'immutable'

function isFilteredFolder(c, getChildFolders = c => [], isGoodFolder = () => true) {
    // console.log('isFilteredFolder | c = ', c);
    if (isGoodFolder(c)) {
        return true;
    }
    let childFolders = getChildFolders(c);
    // console.log('c, childFolders = ', c, childFolders);
    for (let i in childFolders) {
        let cc = childFolders[i];
        if (isFilteredFolder(cc, getChildFolders, isGoodFolder) == true) {
            return true;
        }
    }
    return false;
}

const FoldersHelper = {

    getFilteredFoldersFromLeafsSet(allFolders, leafFoldersIdsSet = Set()) {

    },

    getFilteredFolders(allFolders, modulesIds = []) {
        console.log('getFilteredFolders: allFolders, modulesIds = ', allFolders, modulesIds);
        // console.log('getFilteredFolders: allFolders = ', allFolders);
        let modulesIdsSet = modulesIds.reduce((st, m) => st.add(m), Set());
        let hasFromSet = c => ((c.modulesIds || []).filter(xx => modulesIdsSet.has(xx)).length > 0);
        let leafFolders = allFolders.filter(xx => hasFromSet(xx));
        console.log('leafFolders = ', leafFolders);
        let leafFoldersSet = leafFolders.reduce((st, c) => st.add(c.id), Set());
        console.log('leafFoldersSet = ', leafFoldersSet);
        // let isGoodFolder = c => (leafFoldersSet.has(c.id) || ((c.childrenIds || []).filter(xx => leafFoldersSet.has(xx)) > -1));
        let isGoodFolder = c => {
            let f = (leafFoldersSet.has(c.id));
            // console.log('isGoodFolder: c = ', c, f);
            return f;
        };
        let foldersMap = allFolders.reduce((mp, c) => mp.set(c.id, c), Map());
        let getChildFolders = c => (c.childrenIds || []).map(cId => foldersMap.get(cId)).filter(x => (x != undefined));
        let arr = [];
        for (let i in allFolders) {
            let c = allFolders[i];
            if (isFilteredFolder(c, getChildFolders, isGoodFolder)) {
                console.log('getFilteredFolders: adding folder: c = ', c);
                arr.push(c);
            }
        }
        console.log('==>>> arr = ', arr);
        return arr;
    },

    getSearchFoldersMap(allFoldersMap, searchModulesIds = []) {
        console.log('getSearchFoldersMap: searchModulesIds = ', searchModulesIds);
        let folders = this.getFilteredFolders(allFoldersMap.toArray(), searchModulesIds);
        console.log('getSearchFoldersMap: filtered = folders = ', folders);
        return folders.reduce((mp, c) => mp.set(c.id, c), Map());
    },

    getLimboFoldersAndModulesMap(allFoldersMap, allModulesMap = Map()) {
        console.log('getLimboFoldersAndModulesMap: allFoldersMap = ',  allFoldersMap);
        let modules = allModulesMap.toArray().filter(x => (x?.isLimbo == true));
        console.log('getLimboFoldersAndModulesMap: modules = ', modules);
        let modulesIds = modules.map(x => x.id);
        let folders = this.getFilteredFolders(allFoldersMap.toArray(), modulesIds);
        console.log('getSearchFoldersMap: filtered = folders = ', folders);
        let cMap = folders.reduce((mp, c) => mp.set(c.id, c), Map());
        return {
            foldersMap: cMap,
            modules: modules
        };
    },

};

export default FoldersHelper;
