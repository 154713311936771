import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import uuid from 'uuid';
import styled from 'styled-components';

import crossImg from '../../../assets/img/close_x.svg'

export default function MatchEditorTool(props) {
    const {
        onChange = newItems => {

        },
        items = [],
        mode = 'match' // can me "match" or "order"
    } = props;


    return (
        <Wrapper>

            <ListItemsPlaceholder>

                {items.map((x, i) => {
                    return (
                        <ListItem key={`x_${i}_${x.id}`}>

                            <ItemControlsPlaceholder>
                                <CrossImg src={crossImg} onClick={() => {
                                    let newItems = items.filter(z => (z.id != x.id));
                                    onChange(newItems);
                                }}/>
                            </ItemControlsPlaceholder>

                            {mode != 'match' ? null :
                                <LeftItemPart>
                                    <Input value={x.leftText} onChange={evt => {
                                        let newItems = items.map((z, i) => (z.id == x.id ? ({
                                            ...z,
                                            leftText: evt.target.value
                                        }) : z));
                                        onChange(newItems);
                                    }}/>
                                </LeftItemPart>
                            }

                            <RightItemPart>
                                <Input value={x.text} onChange={evt => {
                                    let newItems = items.map((z, i) => (z.id == x.id ? ({
                                        ...z,
                                        text: evt.target.value
                                    }) : z));
                                    onChange(newItems);
                                }}/>
                            </RightItemPart>



                        </ListItem>
                    )
                })}
            </ListItemsPlaceholder>

            <AddPlaceholder>
                <AddSpan onClick={() => {
                    onChange(items.concat([
                        {
                            id: uuid(),
                            leftText: '',
                            text: ''
                        }
                    ]))
                }}>
                    добавить
                </AddSpan>
            </AddPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const AddSpan = styled.span`
  cursor: pointer;
`;

const AddPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CrossImg = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  outline: none;
  border-radius: 4px;
  padding-left: 4px;

  :focus {
    border-color: blue;
  }
`;

const LeftItemPart = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
`;

const RightItemPart = styled.div`
  flex: 1;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
`;

const ItemControlsPlaceholder = styled.div`
  width: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const ListItemsPlaceholder = styled.div`

`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;
