import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {translate} from "../../../utils/Translate";
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import Spin from "arui-feather/spin";
import toast from "react-hot-toast";

export default function UpdateMarkForm(props) {
    const {
        userId,
        taskId,
        close = () => {

        },
    } = props


    const [missed, setMissed] = useState(false);
    const [goodWork, setGoodWork] = useState(false);
    const [mark, setMark] = useState('');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setLoading(true);
        GroupStatsAPI.getTaskMark(taskId, userId).then(x => {
            if (x !== undefined  && x.data !== undefined) {
                setMissed(x?.data?.missed === undefined ? false : x?.data?.missed);
                setGoodWork(x?.data?.goodWork === undefined ? false : x?.data?.goodWork);
                setMark(x?.data?.mark === undefined ? '' : x?.data?.mark);
            }
            setLoading(false);
        })
    }, [taskId, userId])

    if (loading) {
        return (
            <Loading>
                <Spin visible={true}/>
            </Loading>
        )
    }

    const scaleTypes = ['ABCDF', '100', 'binary'];
    let currentScale = scaleTypes[0];
    let abcdf = [1, 2, 3, 4, 5];

    return(
        <Wrapper>
            <TopPlaceholder>
                <LinePlaceholder onClick={() => {
                    if (missed === false) {
                        setGoodWork(false);
                    }
                    setMissed(!missed);
                }}>
                    <Checkbox value={missed} color={'#56CCFF'} onClick={() => {
                        if (missed === false) {
                            setGoodWork(false);
                        }
                        setMissed(!missed);
                    }}/>
                    <div>Пропуск по уважительной причине</div>
                </LinePlaceholder>

                <LinePlaceholder onClick={() => {
                    if (goodWork === false) {
                        setMissed(false);
                    }
                    setGoodWork(!goodWork)
                }}>
                    <Checkbox value={goodWork} color={'#8CE77E'} onClick={() => {
                        if (goodWork === false) {
                            setMissed(false);
                        }
                        setGoodWork(!goodWork)
                    }}/>
                    <div>Хорошая работа на занятии</div>
                </LinePlaceholder>
            </TopPlaceholder>

            <BottomPlaceholder>
                <div>Оценка</div>
                <BottomInnerPlaceholder>
                    <MarkPlaceholder>
                        <Mark value={mark} onChange={(evt) => {
                            let s = evt.target.value;
                            if ((s >= 0 && s <= 5 && s.length < 5) || s === '') {
                                setMark(s);
                            }
                        }}></Mark>
                    </MarkPlaceholder>
                    <SaveButton onClick={async () => {
                        if (mark[mark.length - 1] === '.' || (mark == 0 && mark.length > 1)) {
                            toast.error('Оценка введена неверно')
                        } else {
                            setSaving(true);
                            try {
                                await GroupStatsAPI.updateTaskMark(taskId, userId, {
                                    goodWork: goodWork,
                                    missed: missed,
                                    mark: mark
                                });
                            } catch (exc) {

                            }
                            setSaving(false)
                            close();
                        }
                    }}>
                        <Spin visible={saving}/>
                        {translate('save')}
                    </SaveButton>
                </BottomInnerPlaceholder>
            </BottomPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  font-size: 18px;
`;

const Loading = styled.div`
  width: 320px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 20px;
  padding-top: 10px;
`;

const LinePlaceholder = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid ${props => (props.color === undefined ? 'grey' : props.color)};
  background-color: ${props => (props.value === true ? props.color : 'transparent')};
  cursor: pointer;
`;

const BottomPlaceholder = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const BottomInnerPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const MarkPlaceholder = styled.div`
  
`;

const ListOption = styled.option`
  text-align: center;
  cursor: pointer;
`;

const Mark = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  font-size: 20px;
  width: 87px;
  
  :focus {
    outline: none;
  }
`;

const SaveButton = styled.div`
  width: fit-content;
  border-radius: 1000px;
  background-color: #ff9900;
  color: white;
  padding: 6px 12px;
  font-size: 16px;
  cursor: pointer;
`;