import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import LogoDiv from "../templates/LogoDiv";
import {useMappedState} from "redux-react-hook";
import SlidesAPI from "../../api/SlidesAPI";
import ViewSlideTool from "../slides/tools/ViewSlideTool";
import LottieLoader from "../loaders/LottieLoader";

import lottie_json from '../../assets/lottie/freelancer-working-on-laptop.json'
import {translate} from "../../utils/Translate";
import ExercisePanel from "../exercises/panels/ExercisePanel";
import DialogExercisePanel from "../dialogs/panels/DialogExercisePanel";
import QuestionnairePanel from "../questionnaires/panels/QuestionnairePanel";

export default function ShareFolderUnitApp(props) {
    let {folderId, unitId} = props.match.params;

    const [slide, setSlide] = useState();
    const [loading, setLoading] = useState(false);

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    useEffect(() => {
        setLoading(true);
        SlidesAPI.getSlide(unitId).then(d => {
            setSlide(d);
            setTimeout(() => {
                setLoading(false);
            }, 1800)
        })
    }, [unitId]);

    return (
        <Wrapper>

            {loading == false ? null :
                <LoaderPlaceholder>
                    <LottieLoader
                        json={lottie_json}
                    />
                    <LoaderTextPlaceholder>
                        {translate('loading')}
                    </LoaderTextPlaceholder>
                </LoaderPlaceholder>
            }


            {loading == true ? null :
                <SlidePanelWrapper>
                    <ViewSlideTool
                        canCopyToBuffer={false}
                        canEdit={false}
                        {...slide}
                    />
                </SlidePanelWrapper>
            }

        </Wrapper>
    );
}

const LoaderTextPlaceholder = styled.div`
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`

`;

const SlidePanelWrapper = styled.div`
  width: 720px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;

  //border: 1px solid lightgrey;
  padding: 20px;
  border-radius: 4px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 1120px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;


const NamePlaceholder = styled.div`
  margin-left: 20px;
  box-sizing: border-box;
  font-weight: bold;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  background: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  font-weight: bold;
`;