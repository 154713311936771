import axios from "axios";
import {API_ENDPOINT} from "../constants/config";

const FoldersAPI = {

    async getFolder(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/folders/${id}`)).data;
        return pld;
    },

    async moveToFolder(type = 'exercise', folderId, contentItemId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/move-to-folder/${folderId}?type=${type}&contentItemId=${contentItemId}`)).data;
        return pld;
    },



    // async getAllShortFolders() { // loads short information about folders available for me // will be refactored
    //     let pld = (await axios.get(`${API_ENDPOINT}/v3/folders`)).data.result;
    //     return pld;
    // },
    //
    async getAllShortFolders() { // loads short information about folders available for me // will be refactored
        let pld = (await axios.get(`${API_ENDPOINT}/v3/folders/all`)).data;
        return pld;
    },


    async createFolder(parentId, data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/folders`, data, {
            params: {
                parentId: parentId
            }
        })).data;
        return pld;
    },

    async updateFolder(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/folders/${data.id}`, data)).data;
        return pld;
    },

    async deleteFolder(parentId, id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/folders/${id}`, {}, {
            params: {parentId: parentId}
        })).data;
        return pld;
    },

    async cloneFolder(currentFolderId, targetParentId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/folders/clone`, {
            currentFolderId: currentFolderId,
            targetParentId: targetParentId
        })).data;
        return pld;
    },

    async getChildren(id) { // we always get children with all content (not large request)
        let pld = (await axios.get(`${API_ENDPOINT}/v3/folders/${id}/children`, {
            params: {
                extended: 1
            }
        })).data;
        return pld;
    }

}

export default FoldersAPI;
