import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Textfit} from 'react-textfit'

import {DEFAULT_TEXT_SLIDE_BACKGROUND} from "../config";
import ImageSlide from "./ImageSlide";

let getH = () => {
    try {
        return document.getElementsByClassName('treadmill_text_slide_placeholder')[0].clientHeight;
    } catch (exc) {

    }
}

let getW = () => {
    try {
        return document.getElementsByClassName('treadmill_text_slide_placeholder')[0].clientWidth;
    } catch (exc) {

    }
}

let pauseMediaIfNeeded = (media, currentTime, mediaReplay, isPaused, playbackRate = 10, setPlayTimes = (x) => {}) => {
    if (media == undefined || currentTime == undefined || currentTime < 0 || isPaused == undefined) {
        return;
    }
    let mediaDuration = media.duration;

    if (isNaN(mediaDuration)) {
        media.onloadedmetadata = () => {
            pauseMediaIfNeeded(media, currentTime, mediaReplay, isPaused, playbackRate, setPlayTimes);
        };
        return;
    }

    let actualMediaDuration = mediaDuration / (playbackRate / 10);

    const newPlayTimes = Math.floor(currentTime / actualMediaDuration);
    setPlayTimes(newPlayTimes);

    if (isPaused == false) {
        if (mediaReplay?.shouldReplay == true) {

            if (actualMediaDuration * mediaReplay?.times > currentTime) {
                media.currentTime = currentTime % actualMediaDuration;
                media.play();
            } else {
                media.currentTime = 0;
                media.pause();
            }

        } else {

            if (actualMediaDuration > currentTime) {
                media.currentTime = currentTime * (playbackRate / 10);
                media.play();
            } else {
                media.currentTime = 0;
                media.pause();
            }

        }
    } else {
        media.pause();
    }
}

export default function TextSlide(props) {
    const {
        id,
        coverBackground = false,
        hasCountDown = false,
        isPreview = false,
        audio = undefined,
        audioTime = 0,
        audioSrc,
        audioVolume = 10,
        audioPlaybackRate = 10,
        currentTime,
        isPaused = false,
        timeBeforeSlideEnd = 0,
        slideHeight = undefined,
        hasBackgroundColor = false,
        textBackgroundColor = '#000000',
        backgroundColor = DEFAULT_TEXT_SLIDE_BACKGROUND,
        text = '',

        textColor = 'white',
        textSize = 12, // in percents

        textPosition = 'center', // top, center, bottom
        autoFit = false,

        changeSize = d => {

        }
    } = props;

    const wrapperRef = useRef();
    const [height, setHeight] = useState(slideHeight === undefined ? undefined : slideHeight);
    const [width, setWidth] = useState();
    const [playTimes, setPlayTimes] = useState(0);

    let actualTime = currentTime - +props.from;
    let hasText = (text != undefined && text.replace(/ /g, '') != '') || (hasCountDown == true);
    let hasAudio = audioSrc != undefined && audioSrc != '';

    useEffect(() => {
        if (height === undefined) {
            setHeight(getH());
        }
        if (width === undefined) {
            setWidth(getW());
        }
        if (audio) {
            if (audioSrc) {
                audio.src = audioSrc;
            } else {
                audio?.pause();
            }
        }
    }, [id]);

    useEffect(() => {
        if (audio && audioSrc) {
            pauseMediaIfNeeded(audio, audioTime, props?.audioReplay, isPaused, audioPlaybackRate, (x) => {
                setPlayTimes(x);
            });
        }
    }, [audioTime, id]);

    useEffect(() => {
        if (audio && audioSrc) {
            if (actualTime == 0 && isPaused == false) {
                audio?.play();
            }
            pauseMediaIfNeeded(audio, actualTime, props?.audioReplay, isPaused, audioPlaybackRate, (x) => {
                setPlayTimes(x);
            });
        }
    }, [audioSrc, id])

    useEffect(() => {
        if (audio && audioSrc) {
            audio.volume = audioVolume / 10;
        }
        if (audio && audioSrc) {
            audio.playbackRate = audioPlaybackRate / 10;
        }
    }, [audio, audioVolume, audioPlaybackRate, id]);

    useEffect(() => {
        if (isPaused === false && audio && audioSrc) {
            pauseMediaIfNeeded(audio, actualTime, props?.audioReplay, isPaused, audioPlaybackRate, (x) => {
                setPlayTimes(x);
            });
        } else {
            audio?.pause();
        }
    }, [isPaused, id])

    const handleAudioEnd = () => {
        if (props?.audioReplay?.shouldReplay == true) {
            setPlayTimes((prevTimes) => {
                if (prevTimes < +props?.audioReplay?.times - 1) {
                    audio.currentTime = 0;
                    if (isPaused === false && audioSrc) {
                        audio.play();
                    }
                    return prevTimes + 1;
                } else {
                    audio.pause();
                    return 0;
                }
            })
        }
    }

    useEffect(() => {
        if (audio) {
            audio.addEventListener('ended', handleAudioEnd);
        }

        return () => {
            if (audio) {
                audio.removeEventListener('ended', handleAudioEnd);
            }
        }
    }, [playTimes, id])

    let hasInput = props.inputType == 'text';
    let manualFontSize = height * textSize / 100.0;
    let lineHeight = 1.2 * manualFontSize;
    if (hasInput) {
        manualFontSize *= 4 / 5.0;
    }

    return (
        <Wrapper ref={wrapperRef} className={'treadmill_text_slide_placeholder'} style={{fontSize: 'unset'}}>
            {autoFit === false ?
                <ImageSlide {...props} overlayBackground={backgroundColor}>
                    {coverBackground == false ? null :
                        <Cover backgroundColor={backgroundColor} />
                    }
                    {hasText == false ? null :
                        <TextLayerPlaceholder textPosition={textPosition}>
                            {(text == undefined || text == '') ? null :
                                <Text style={{
                                    fontSize: `${manualFontSize}px`,
                                    lineHeight: `${lineHeight}px`,
                                    color: textColor,
                                    backgroundColor: hasBackgroundColor ? textBackgroundColor : 'transparent'
                                }}>
                                    <div dangerouslySetInnerHTML={{__html: `${text}`}}></div>
                                </Text>
                            }
                        </TextLayerPlaceholder>
                    }
                    {hasAudio === false || audio != undefined || isPreview ? null :
                        <audio key={id} className={'treadmill_text_slide_audio'} autoPlay={!isPaused} preload="none" playsInline>
                            <source src={`${audioSrc + `#t=${audioTime > 0 ? audioTime : 0}`}`}/>
                        </audio>
                    }
                </ImageSlide>
                :
                <Textfit className='auto_fit_text_container' mode="multi" max="150"
                         style={{
                             height,
                             width,
                             color: textColor,
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center'
                         }}
                         onReady={() => {
                             let s = document.getElementsByClassName('auto_fit_text_container')[0].style.fontSize.replace(/px/g, '') * 100.0 / height;
                             if (s % Math.trunc(s) < 0.9) {
                                 changeSize(Math.floor(s));
                             } else {
                                 changeSize(Math.round(s));
                             }
                         }}>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: `${text}`}}></div>
                    </div>
                    {hasCountDown === false || window.isNaN(timeBeforeSlideEnd) ? null :
                        <div>
                            {Math.floor(timeBeforeSlideEnd + 1)}
                        </div>
                    }
                </Textfit>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* background-color: ${props => (props.background == undefined ? DEFAULT_TEXT_SLIDE_BACKGROUND : props.background)}; */
  background-color: white;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #6e6e6e;
  }
`;

const TextLayerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => ({ 'top': 'flex-start', 'bottom': 'flex-end', 'center': 'center' }[props.textPosition])};
  position: relative;
  z-index: 5;
`;

const Text = styled.div`
  user-select: none;
  text-align: center;
`;

const Cover = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
    position: absolute;
    z-index: 2;
`;