import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {API_ENDPOINT} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";

import axios from 'axios'
import IraLoginForm from "../auth/forms/IraLoginForm";
import IraSignupForm from "../auth/forms/IraSignupForm";
import IraRecoverPasswordForm from "../auth/forms/IraRecoverPasswordForm";

import bgImg from '../../assets/img/bg_orange.svg';

const teacherCanSignup = (window.location.href.indexOf('teacher') > -1);

export default function LoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();
    const [mode, setMode] = useState('login');
    const [recovering, setRecovering] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [recoveringSent, setRecoveringSent] = useState(false);

    const [error, setError] = useState(undefined);


    return (
        <Wrapper>
            <Inner>

                {/*<TopLogoImg src={require('../../assets/images/logo_wide_transp.svg')}/>*/}

                {mode == 'login' && <IraLoginForm loading={loading}
                                                  error={error}
                                                  onSignupClick={() => {
                                                      setMode('signup');
                                                  }}
                                                  onSubmit={async data => {
                                                      console.log('--->>>   onSubmit: data = ', data);
                                                      setError(undefined);
                                                      let pld = await dispatch(usersActions.logIn(data));
                                                      console.log('pld = ', pld);
                                                      if (pld.error != undefined) {
                                                          setError(pld.error);
                                                          // if (pld.error.code == 101) {
                                                          //     window.alert('Неверный логин или пароль');
                                                          // }
                                                          // if (pld.error.code == 403) {
                                                          //     setError('Неверный логин или пароль')
                                                          // }
                                                      }
                                                  }}
                                                  onForgotPasswordClick={() => {
                                                      setMode('recover');
                                                  }}

                />}

                {mode == 'signup' &&
                <IraSignupForm canSelectRole={teacherCanSignup} loading={registering} onLoginClick={() => {
                    setMode('login');
                }}
                               onSubmit={async d => {
                                   setRegistering(true);
                                   setRegistering(true);
                                   let pld = (await axios.post(`${API_ENDPOINT}/users/create`, d)).data;
                                   if (pld.error != undefined) {
                                       window.alert(pld.error.message);
                                       setRegistering(false);
                                       return;
                                   }
                                   setRegistering(false);
                                   await dispatch(usersActions.logIn(d));
                               }}
                />}

                {mode == 'recover' && <IraRecoverPasswordForm
                    recoveringSent={recoveringSent}
                    loading={recovering} onSubmit={async d => {
                    let {email} = d;
                    setRecovering(true);
                    let pld = (await axios.post(`${API_ENDPOINT}/recover`, {email: email})).data;
                    setRecovering(false);
                    setRecoveringSent(true);
                }}
                    onLoginClick={() => {
                        setMode('login');
                    }}
                />}

            </Inner>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #E5E5E5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${bgImg});
  font-family: Montserrat;
`;

const Inner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TopLogoImg = styled.img`
  margin-bottom: 38px;
`;
