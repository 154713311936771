import * as types from '../ActionTypes.js'
import AccessGroupsAPI from '../../api/AccessGroupsAPI.js';

let createAccessGroup_ = () => {
    return {
        type: types.CREATE_ACCESS_GROUP
    }
}
let createAccessGroupSuccess = (accessGroup) => {
    return {
        type: types.CREATE_ACCESS_GROUP_SUCCESS,
        accessGroup: accessGroup
    }
}
let createAccessGroupFailed = (error) => {
    return {
        type: types.CREATE_ACCESS_GROUP_FAIL,
        error: error
    }
}

//thunk
export function createAccessGroup(data) {
    return (dispatch, getState) => {
        dispatch(createAccessGroup_());
        return AccessGroupsAPI.createAccessGroup(data).then(
            accessGroup => dispatch(createAccessGroupSuccess(accessGroup)),
            error => dispatch(createAccessGroupFailed(error))
        )
    }
}

//eca
let updateAccessGroup_ = () => {
    return {
        type: types.UPDATE_ACCESS_GROUP
    }
}
let updateAccessGroupSuccess = (accessGroup) => {
    return {
        type: types.UPDATE_ACCESS_GROUP_SUCCESS,
        accessGroup: accessGroup
    }
}
let updateAccessGroupFailed = (error) => {
    return {
        type: types.UPDATE_ACCESS_GROUP_FAIL,
        error: error
    }
}

//thunk
export function updateAccessGroup(data) {
    return (dispatch, getState) => {
        dispatch(updateAccessGroup_());
        return AccessGroupsAPI.updateAccessGroup(data).then(
            accessGroup => dispatch(updateAccessGroupSuccess(accessGroup)),
            error => dispatch(updateAccessGroupFailed(error))
        )
    }
}

let loadAccessGroups_ = () => {
    return {
        type: types.LOAD_ACCESS_GROUPS
    }
}
let loadAccessGroupsSuccess = (accessGroups) => {
    return {
        type: types.LOAD_ACCESS_GROUPS_SUCCESS,
        accessGroups: accessGroups
    }
}
let loadAccessGroupsFailed = (error) => {
    return {
        type: types.LOAD_ACCESS_GROUPS_FAIL,
        error: error
    }
}

//thunk
export function loadAccessGroups() {
    return (dispatch, getState) => {
        dispatch(loadAccessGroups_());
        return AccessGroupsAPI.getAllAccessGroups().then(
            accessGroups => dispatch(loadAccessGroupsSuccess(accessGroups)),
            error => dispatch(loadAccessGroupsFailed(error))
        )
    }
}

let deleteAccessGroup_ = () => {
    return {
        type: types.DELETE_ACCESS_GROUP
    }
}
let deleteAccessGroupSuccess = (id) => {
    return {
        type: types.DELETE_ACCESS_GROUP_SUCCESS,
        id: id
    }
}
let deleteAccessGroupFailed = (error) => {
    return {
        type: types.DELETE_ACCESS_GROUP_FAIL,
        error: error
    }
}

//thunk
export function deleteAccessGroup(id) {
    return (dispatch, getState) => {
        dispatch(deleteAccessGroup_());
        return AccessGroupsAPI.deleteAccessGroup(id).then(
            () => dispatch(deleteAccessGroupSuccess(id)),
            error => dispatch(deleteAccessGroupFailed(error))
        )
    }
}