import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import ls from 'local-storage'
import transcoding_lottie from "../../assets/lottie/transcoding_lottie.json";
import progress_lottie from "../../assets/lottie/uploading_progress_lottie.json";
import fireworkJson from "../../assets/lottie/firework_lottie.json";
import Lottie from "react-lottie-player";
import ReactPlayer from "react-player";
import {useInterval} from "../treadmills_exercises/treadmill/tools/TreadmillPlayerTool";
import UploadAPI from "../../api/UploadAPI";

export default function SimpleAutoVideoUploader(props) {
    const {
        file,
        onReady = d => {

        },
        showName = true
    } = props;
    let fl = (file == undefined) ? undefined : file;
    let {name, type, size} = fl;
    let key = `u-file-${name}-${type}-${size}`;
    let val = ls(key);

    const [isAsking, setIsAsking] = useState(false);
    const [status, setStatus] = useState('new');

    const [initialized, setInitialized] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(undefined);
    const [info, setInfo] = useState(undefined);
    const [askingStatus, setAskingStatus] = useState(false);

    let transcodingStatus = info?.status;

    let isUploaded = (status == 'uploaded');
    let isTranscoding = (transcodingStatus == 'new');
    let isShowingFirework = (isUploaded == true && askingStatus == false);

    let isReady = (transcodingStatus == 'finished');
    let thumbnailUrl = (isReady == false) ? undefined : info?.avatar;
    let url = (isReady == false) ? undefined : info?.url;

    useEffect(() => {
        if (status == 'new') {
            // let formData = new FormData();
            // formData.append('file', file);
            UploadAPI.uploadVideo(file, p => {
                if (status != 'uploading') {
                    setStatus('uploading');
                }
                console.log('progress = ', p);
                setUploadingProgress(p);
            }).then(pld => {
                setStatus('uploaded');
                console.log('file has been uploaded!: pld = ', pld);
                setInfo(pld);
                if (pld?.status == 'finished') {
                    ls(key, ({...pld, t: +new Date()}));
                }
                setTimeout(() => {
                    setAskingStatus(true);
                }, 1200);
            });
        }
    }, [status, initialized]);

    useEffect(() => {
        if (url != undefined) {
            onReady(info);
        }
    }, [url]);

    useInterval(() => {
        if (askingStatus == false || isAsking == true || isReady == true) {
            return;
        }
        setIsAsking(true);
        UploadAPI.getVideoUploadStatus(info?.hash).then(pld => {
            setInfo(pld);
            setIsAsking(false);
            if (pld.status == 'finished') {
                ls(key, {...info, t: +new Date()});
            }
        })
    }, 1000);


    if (status == 'uploading' || isTranscoding || isShowingFirework) {
        return (
            <UploadingPlaceholder>
                <ProgressPlaceholder>
                    {isShowingFirework == true ?
                        <Lottie
                            loop
                            animationData={fireworkJson}
                            play
                            style={{width: 120, height: 120}}
                        />
                        :
                        <React.Fragment>
                            {isTranscoding == false ?
                                <Lottie
                                    animationData={progress_lottie}
                                    goTo={Math.round(uploadingProgress * 2.75)}
                                    style={{width: 120, height: 120}}
                                />
                                :
                                <Lottie
                                    animationData={transcoding_lottie}
                                    play
                                    style={{width: 120, height: 120}}
                                />
                            }
                        </React.Fragment>
                    }
                </ProgressPlaceholder>
                {showName &&
                <UploadingFileName>
                    {file.name}
                </UploadingFileName>
                }


            </UploadingPlaceholder>
        )
    }

    if (isReady == true) {
        return (
            <ReadyPlaceholder>
                <PlayerPlaceholder>
                    <ReactPlayer
                        width={240}
                        height={'100%'}
                        light={thumbnailUrl}
                        controls={true}
                        url={url}/>
                </PlayerPlaceholder>
                {showName &&
                <UploadingFileName>
                    {file.name}
                </UploadingFileName>
                }
            </ReadyPlaceholder>
        )
    }

    return (
        <Wrapper>
            {file.name}
        </Wrapper>
    );
}


const PlayerPlaceholder = styled.div`
  width: 240px;
  height: 180px;
`;

const ReadyPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const UploadingPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgressPlaceholder = styled.div`
  width: 120px;
  height: 120px;
  margin-right: 10px;
`;

const UploadingFileName = styled.div`
  box-sizing: border-box;
  padding-left: 10px;
  word-break: break-all;
`;