import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/AccessGroupsActions'

import {useDispatch, useMappedState} from "redux-react-hook";

import Select from 'react-select'
import NiceModal from "../../modals/NiceModal";
import AccessGroupsPanel from "./AccessGroupsPanel";

export default function AccessGroupsDropdownPanel(props) {
    const {
        selectedIds = [],
        onChange = newIds => {

        },
        placeholder = 'Select access groups'
    } = props;

    const {
        groups = [],
        user,
        loading
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            groups: state.accessGroups.accessGroupsMap.toArray(),
            user: currentUser,
            loading: state.accessGroups.loading
        }
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadAccessGroups());
    }, []);

    const [groupsSettingsModalVisible, setGroupsSettingsModalVisible] = useState(false);

    const [selectedId, setSelectedId] = useState(undefined);
    let email = user?.email;
    let myGroups = groups.filter(x => (x.creatorId == user?.id));
    let sharedGroups = groups.filter(x => (x.emails != undefined && x.emails.indexOf(email) > -1)).filter(x => (x.emails != undefined && x.emails.indexOf(user.email) > -1));
    const selectedGroup = (setSelectedId == undefined) ? undefined : groups.filter(x => (x.id == selectedId))[0];
    const options = groups.map((a, i) => ({label: a.name, value: a.id}));

    const selectedOptions = selectedIds.map(x => (options.filter(xx => xx.value == x)[0])).filter(x => (x != undefined));

    return (
        <Wrapper>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: "space-between"
            }}>

                <div style={{flex: 1}} >
                    <Select
                        placeholder={placeholder}
                        value={selectedOptions}
                        isMulti={true}
                        options={options}
                        onChange={a => {
                            console.log('a = ', a);
                            let newIds = a.map(x => x.value);
                            onChange(newIds);
                        }}
                    />
                </div>

                <div style={{marginLeft: 15, cursor: 'pointer', fontSize: 12, opacity: 0.5}} onClick={() => {
                    setGroupsSettingsModalVisible(true);
                }}>
                    Настроить группы доступа
                </div>

            </div>

            {groupsSettingsModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setGroupsSettingsModalVisible(false);
                }}>

                    <SlideInnerSettingsGroups>

                        <SubHeading>
                            Настройка групп доступа
                        </SubHeading>

                        <GrContent>

                            <AccessGroupsPanel/>

                        </GrContent>

                    </SlideInnerSettingsGroups>

                </NiceModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const SlideInnerSettingsGroups = styled.div`
  padding: 10px;
  box-sizing: border-box;
  width: calc(100vw - 120px);
  max-height: calc(100vh - 100px);
`;

const SubHeading = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const GrContent = styled.div`

`;