import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AssistantsAPI from "../../../api/AssistantsAPI";

import NiceModal from "../../modals/NiceModal";
import {Input, Textarea} from "../../ira/ui/Inputs";
import toast from "react-hot-toast";

export default function AiAssistantsTool(props) {
    const {
        userId
    } = props;

    const [name, setName] = useState(props.name || '');
    const [system, setSystem] = useState(props.system || '');

    const [assistants, setAssistants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        if (userId == undefined) {
            return;
        }
        setLoading(true);
        AssistantsAPI.getUserConversations(userId).then((pld) => {
            setAssistants(pld);
            setLoading(false);
        });
    }, [userId]);

    return (
        <Wrapper>

            {assistants.map((a, i) => {
                return (
                    <div className={`mt-2 mb-2`} key={a.id}>
                        {moment(a.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                )
            })}

            <div className={'mt-2 mb-2'}>
                <button className={'ui button basic'} onClick={() => {
                    setAddModalVisible(true);
                }}>
                    <i className={'icon upload'}></i> add assistant
                </button>
            </div>

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <ModalInnerPlaceholder className={'mt-2 mb-2'}>

                        <div className={'mb-2'}>
                            <Input
                                value={name}
                                placeholder={'Name'}
                                onChange={evt => {
                                    setName(evt.target.value);
                                }}
                            />
                        </div>

                        <div className={'mb-2'}>
                            <Textarea
                                value={system}
                                placeholder={'Assistant description'}
                                onChange={evt => {
                                    setSystem(evt.target.value);
                                }}
                            />
                        </div>

                        <div className={'mb-2'}>
                            <button className={'ui button primary'} onClick={async () => {
                                setLoading(true);
                                await AssistantsAPI.createConversation(userId, {
                                    name: name,
                                    system: system
                                });
                                let arr = await AssistantsAPI.getUserConversations(userId);
                                setAssistants(arr);
                                setLoading(false);
                                setAddModalVisible(false);
                                toast.success('Assistant added');
                            }}>
                                <i className={'icon upload'}></i> Add
                            </button>
                        </div>


                    </ModalInnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ModalInnerPlaceholder = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 10px;
`;