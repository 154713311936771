import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import mute_img from '../../../assets/img/mute.png'
import SpeechAPI from "../../../api/SpeechAPI";

const renderReference = (reference) => {
    if (reference == undefined) {
        return null;
    }
    let {type, url, transcript} = reference;
    if (type == 'video') {
        return (
            <VideoPlaceholder>
                <Video controls={true}>
                    <source src={url}/>
                </Video>
            </VideoPlaceholder>
        )
    }
    return null;
}

export default function VocabularyItemPanel(props) {
    const {
        item,
        yandexVoiceEnabled = true
    } = props;

    if (item == undefined) {
        return null;
    }
    let {reference, name, meanings} = item;

    return (
        <Wrapper className={''}>

            <Name>
                {name}
                {' '}
                {/*{yandexVoiceEnabled == false ? null :*/}
                {/*    <AudioButton onClick={() => {*/}
                {/*        SpeechAPI.forvoSpeak(item.name);*/}
                {/*    }}>*/}
                {/*        <MuteImage src={mute_img}/>*/}
                {/*    </AudioButton>*/}
                {/*}*/}
            </Name>

            <MeaningsPlaceholder>
                {meanings.map((m, k) => {
                    return (
                        <MeaningItem key={k}>
                            {m}
                        </MeaningItem>
                    )
                })}
            </MeaningsPlaceholder>

            {reference == undefined ? null :
                <ReferencePlaceholder>
                    {renderReference(reference)}
                </ReferencePlaceholder>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
  background-color: white;
`;

const Name = styled.div`
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
`;

const AudioButton = styled.div`
    display: inline-block;
    cursor: pointer;
    opacity: 0.6;
    vertical-align: middle;
    :hover{
      opacity: 1;
    }
`;

const MuteImage = styled.img`
    width: 24px;
    height: 24px;
    vertical-align: text-top;
`;

const AudioPlaceholder = styled.div`
    text-align: center;
    padding: 5px;
`;

const MeaningsPlaceholder = styled.div`
    
`;

const ReferencePlaceholder = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid whitesmoke;
`;

const MeaningItem = styled.div`
    
`;

const VideoPlaceholder = styled.div`
    background-color: black;
    width: 100%;
    height: 320px;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;