import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';

import ls from 'local-storage'
import axios from 'axios'

import RecursiveFoldersTree from "../tools/RecursiveFoldersTree";

import * as actions from '../../../redux/actions/FoldersActions'

import * as dialogsActions from '../../../redux/actions/DialogsActions'
import * as treadmillsActions from '../../../redux/actions/TreadmillsActions'
import * as drochkaExercisesActions from '../../../redux/actions/DrochkaExercisesActions'

import * as slidesActions from '../../../redux/actions/SlidesActions'
import * as usersActions from '../../../redux/actions/UsersActions'

import Sidebar from 'arui-feather/sidebar'
// import TaskPreviewPanel from "./TaskPreviewPanel";

import {translate} from "../../../utils/Translate";
import DropdownDotsMenu from "../../ui/DropdownDotsMenu";
import NiceModal from "../../modals/NiceModal";
import UpdateFolderForm from "../tools/UpdateFolderForm";
import {API_ENDPOINT} from "../../../constants/config";
import SimpleSlideForm from "../../slides/forms/SimpleSlideForm";

import {Code} from "react-content-loader";
import CommonHelper from "../../../helpers/CommonHelper";
import FoldersHelper from "../../../helpers/FoldersHelper";
import * as exercisesActions from "../../../redux/actions/ExercisesActions";
import ExerciseInfoForm from "../../exercises/forms/ExerciseInfoForm";
import UpdateDialogForm from "../../dialogs/forms/UpdateDialogForm";
import DrochkaInfoForm from "../../drochka_exercise/forms/DrochkaInfoForm";
import UpdateTreadmillForm from "../../treadmills_exercises/treadmill/forms/UpdateTreadmillForm";

function getRootFolders(foldersMap, access, limboModules = [], usersMap = Map(), currentUserId) {
    let allFolders = foldersMap.toArray();
    if (access == 'private') {
        allFolders = allFolders.filter(x => (x?.creatorId == currentUserId));
    }
    let allChildrenIdsSet = allFolders.reduce((st, c) => st.union((c?.childrenIds || []).reduce((sst, c_) => sst.add(c_), Set())), Set());
    let orphans = allFolders.filter(x => !allChildrenIdsSet.has(x.id)).filter(x => (x?.type == 'folder' && (x?.access == access || access == 'limbo')));
    if (access != 'limbo') {
        return orphans;
    }
    let usersIds = limboModules.map(x => x.userId).reduce((st, x) => st.add(x), Set()).toArray();
    let users = usersIds.map(xx => (usersMap.get(xx))).filter(x => (x != undefined));
    let arr = [];
    for (let i in users) {
        let u = users[i];
        let cont = {
            id: u.id,
            childrenIds: [],
            name: CommonHelper.getUserName(u),
            type: 'folder',
            creatorId: u.id,
            isNameFolder: true
        };
        let allChildrenIdsSet = allFolders.filter(xx => (xx.creatorId == u.id)).reduce((st, c) => st.union((c?.childrenIds || []).reduce((sst, c_) => sst.add(c_), Set())), Set());
        let orphans = allFolders.filter(xx => (xx.creatorId == u.id)).filter(x => !allChildrenIdsSet.has(x.id)).filter(x => (x?.type == 'folder' && (x?.access == access || access == 'limbo')));
        cont.childrenIds = orphans.map(xx => xx.id);
        arr.push(cont);
    }
    return arr.sort((a, b) => (b.childrenIds.length - a.childrenIds.length)).filter(x => (x.childrenIds.length > 0));
}

export default function FoldersTreePanel(props) {
    const {
        searchMode = false,
        filteredModulesIds = [],
        access = 'private', // private, public, hybrid
        contentType = 'treadmills', // private, public, hybrid
        accessGroupId // for privacy
    } = props;


    const dispatch = useDispatch();
    const [expandedSet, setExpandedSet] = useState((ls(`expanded_new_folders_${contentType}`) == undefined ? [] : ls(`expanded_new_folders_${contentType}`)).reduce((st, ss) => st.add(ss), Set()));

    const [loadingItemId, setLoadingItemId] = useState(undefined);
    const [selectedId, setSelectedId] = useState(undefined);

    const [addFolderVisible, setAddFolderVisible] = useState(false);
    const [parentFolderId, setParentFolderId] = useState(undefined);
    const [addType, setAddType] = useState('folder');
    const [selectedEditFolderId, setSelectedEditFolderId] = useState(undefined);
    const [selectedModuleFolderId, setSelectedModuleFolderId] = useState(undefined);

    const [creationAccess, setCreationAccess] = useState('private');

    const {
        foldersMap,
        loading,
        rootFolders,
        getChildFolders,
        selectedSlide,
        selectedSlideId,
        language,
        currentUserId,
        realFilteredModulesIds = []
    } = useMappedState(useCallback(state => {
        // let allModulesMap =
        let globalFoldersMap = state.folders.foldersMap;
        let realFilteredModulesIds = filteredModulesIds;
        let limboModules = [];
        let uMap = state.users.usersMap;
        console.log('---->>>>> uMap = ', uMap);
        if (access == 'limbo') {
            let dd_ = FoldersHelper.getLimboFoldersAndModulesMap(globalFoldersMap, state.slides.slidesMap);
            console.log('limbo: dd_ = ', dd_);
            globalFoldersMap = dd_.foldersMap;
            limboModules = dd_.modules;
            let searchSet = filteredModulesIds.reduce((st, x) => st.add(x), Set());
            realFilteredModulesIds = limboModules.map(x => x.id);
            if (searchMode == true) {
                realFilteredModulesIds = realFilteredModulesIds.filter(xx => searchSet.has(xx))
            }
        }
        let cMap = (searchMode == true) ? FoldersHelper.getSearchFoldersMap(state.folders.foldersMap, realFilteredModulesIds) : globalFoldersMap;
        let rootFolders = getRootFolders(cMap, access, limboModules, state.users.usersMap, state.users.currentUserId);
        rootFolders = rootFolders.filter(x => (x.contentType == contentType));
        return {
            realFilteredModulesIds: realFilteredModulesIds,
            currentUserId: state.users.currentUserId,
            language: state.language.language,
            rootFolders: rootFolders,
            loading: state.folders.loading || state.slides.loading || state.treadmills.loading || state.exercises.loading,
            foldersMap: cMap,
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId),
            selectedSlideId: state.slides.selectedSlideId,
            getChildFolders: contId => {
                let c = cMap.get(contId);
                let u = rootFolders.filter(xx => (xx.id == contId))[0];
                if (c == undefined) {
                    c = u;
                }
                if (c == undefined) {
                    return [];
                }
                let {childrenIds = []} = c;
                return childrenIds.map(x => cMap.get(x)).filter(x => (x != undefined))
            }
        }
    }, [searchMode, filteredModulesIds]));

    useEffect(() => {
        dispatch(slidesActions.loadSlides());
        dispatch(actions.getMyAllCompactFolders());
    }, [access]);

    let selectedBlock = foldersMap.get(selectedId);
    let selectedEditFolder = foldersMap.get(selectedEditFolderId);
    let selectedModuleFolder = foldersMap.get(selectedModuleFolderId);
    let isGlobalLoading = (rootFolders.length == 0 && loading == true);

    console.log('FoldersTreePanel: render: filteredModulesIds = ', filteredModulesIds);
    console.log('FoldersTreePanel: render: searchMode = ', searchMode);

    return (
        <Wrapper>

            <TopNamePlaceholder>
                <TopLeft>
                    {translate(`${access}`, language)}
                </TopLeft>
                <TopRight>
                    {access != 'private' ? null :
                        <DropdownDotsMenu
                            items={[
                                {
                                    name: translate('create_subfolder', language),
                                    onClick: () => {
                                        setAddFolderVisible(true);
                                        setParentFolderId(undefined);
                                        setAddType('folder');
                                        setCreationAccess('private')
                                    }
                                }
                            ]}
                        />
                    }
                    {(access != 'limbo' || currentUserId != 'jnM2pCK62I' || currentUserId != 'Ao0iOinTto') ? null :
                        <DropdownDotsMenu
                            items={[
                                {
                                    name: translate('create_subfolder', language),
                                    onClick: () => {
                                        setAddFolderVisible(true);
                                        setParentFolderId(undefined);
                                        setAddType('folder');
                                        setCreationAccess('limbo')
                                    }
                                }
                            ]}
                        />
                    }
                </TopRight>
            </TopNamePlaceholder>

            {/*{isGlobalLoading == false ? null :*/}
            {/*    <Code/>*/}
            {/*}*/}

            <React.Fragment key={`x${searchMode}_${filteredModulesIds.join('_')}`}>
                <RecursiveFoldersTree
                    searchMode={searchMode || (access == 'limbo')}
                    filteredModulesIds={realFilteredModulesIds}
                    folders={rootFolders}
                    expandedSet={expandedSet}
                    loadingItemId={loadingItemId}
                    getChildFolders={getChildFolders}
                    expandToggle={async exId => {
                        console.log('expandToggle: exId = ', exId);

                        let isExpanded = !expandedSet.has(exId);
                        let newExpandedSet = expandedSet.has(exId) ? expandedSet.delete(exId) : expandedSet.add(exId);
                        ls('expanded_new_folders', newExpandedSet.toArray());
                        setExpandedSet(newExpandedSet);
                        if (isExpanded) {
                            setLoadingItemId(exId);
                            await dispatch(actions.getChildren(exId)); // подгружаем по мере необходимости контент детей
                            setLoadingItemId(undefined);
                        }
                    }}
                    onFolderClick={cId => {
                        let c = foldersMap.get(cId);
                        console.log('onFolderClick: c = ', c);
                        // тут, напрмиер, можно открыть превью контейнера
                        // я тут пример сделал - вы завяжите на логику отображения превью сайдбара
                        if (c?.type == 'module') {
                            setSelectedId(cId);
                        }
                    }}
                    onModuleClick={slideId => {
                        if (selectedSlideId == slideId) {
                            dispatch(slidesActions.selectSlide(undefined));
                        } else {
                            dispatch(slidesActions.selectSlide(slideId));
                        }
                    }}
                    renderRightBlockFunction={(cId, parId) => {
                        let c = foldersMap.get(cId);
                        if (c == undefined) {
                            return;
                        }
                        let hasChildren = (c.childrenIds != undefined && c.childrenIds.length > 0);
                        return (
                            <DropdownDotsMenu
                                items={[
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            name: translate('create_subfolder', language),
                                            onClick: () => {
                                                setAddFolderVisible(true);
                                                setParentFolderId(cId);
                                                setAddType('folder');
                                            }
                                        },
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            name: translate('edit', language),
                                            onClick: () => {
                                                setSelectedEditFolderId(cId)
                                            }
                                        },
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            // name: translate('create_module', language),
                                            name: 'Создать упражнение',
                                            onClick: () => {
                                                setSelectedModuleFolderId(cId);
                                            }
                                        },
                                    hasChildren == true || access == 'limbo' || (c.creatorId != currentUserId) ? null : {
                                        name: translate('delete_folder', language),
                                        onClick: async () => {
                                            if (window.alert('Are you sure?') == false) {
                                                return;
                                            }
                                            await dispatch(actions.deleteFolder(parId, cId));
                                        }
                                    },
                                    hasChildren == true ? null : {
                                        name: translate('share_folder', language),
                                        onClick: async () => {
                                            CommonHelper.linkTo(`/share/folder/${cId}`, true);
                                            // await dispatch(actions.deleteFolder(parId, cId));
                                        }
                                    }
                                ].filter(x => (x != undefined))}
                            />
                        )
                    }}
                />
            </React.Fragment>


            <Sidebar visible={(selectedBlock != undefined)}
                     width={Math.min(960, window.innerWidth)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>
                {selectedBlock == undefined ? null :
                    <div>
                        {/*<TaskPreviewPanel id={selectedId}/>*/}
                    </div>
                }
            </Sidebar>

            {addFolderVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddFolderVisible(false);
                    setParentFolderId(undefined);
                    setAddType(undefined);
                }}>

                    <AddFolderInner>

                        <Heading>
                            {translate('create_folder')}
                        </Heading>

                        <UpdateFolderForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(actions.createFolder(parentFolderId, {
                                    ...d,
                                    type: addType,
                                    access: creationAccess,
                                    contentType: contentType
                                }));
                                let newExpandedSet = expandedSet.add(pld?.folder?.id).add(parentFolderId);
                                setExpandedSet(newExpandedSet);
                                setAddFolderVisible(false);
                                setParentFolderId(undefined);
                                setAddType(undefined);
                            }}
                        />
                    </AddFolderInner>

                </NiceModal>
            }

            {selectedEditFolder == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedEditFolderId(undefined);
                }}>

                    <AddFolderInner>

                        <Heading>
                            {'Edit'}
                        </Heading>

                        <UpdateFolderForm
                            {...selectedEditFolder}
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(actions.updateFolder({
                                    id: selectedEditFolder.id,
                                    ...d
                                }));
                                let newExpandedSet = expandedSet.add(pld?.folder?.id).add(parentFolderId);
                                setExpandedSet(newExpandedSet);
                                setSelectedEditFolderId(undefined);
                            }}
                        />

                    </AddFolderInner>

                </NiceModal>
            }

            {selectedModuleFolder == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedModuleFolderId(undefined);
                }}>

                    <AddFolderInner>

                        {/*<Heading>*/}
                        {/*    {'Создание'}*/}
                        {/*</Heading>*/}

                        {contentType != 'exercises' ? null :
                            <div>
                                <ExerciseInfoForm
                                    loading={loading}
                                    onSave={async d => {
                                        let pld = await dispatch(exercisesActions.createExercise({
                                            ...d,
                                            creatorId: currentUserId
                                        }));
                                        let {exercisesIds = []} = selectedModuleFolder;
                                        let newExercisesIds = exercisesIds.concat([pld?.exercise?.id]);
                                        await dispatch(actions.updateFolder({
                                            id: selectedModuleFolder?.id,
                                            exercisesIds: newExercisesIds
                                        }));
                                        setSelectedModuleFolderId(undefined);
                                    }}
                                />
                            </div>
                        }

                        {contentType != 'dialogs' ? null :
                            <div>
                                <UpdateDialogForm
                                    loading={loading}
                                    onSave={async d => {
                                        let pld = await dispatch(dialogsActions.createDialog({
                                            ...d,
                                            creatorId: currentUserId
                                        }));
                                        let {dialogsIds = []} = selectedModuleFolder;
                                        let newExercisesIds = dialogsIds.concat([pld?.dialog?.id]);
                                        await dispatch(actions.updateFolder({
                                            id: selectedModuleFolder?.id,
                                            dialogsIds: newExercisesIds
                                        }));
                                        setSelectedModuleFolderId(undefined);
                                    }}
                                />
                            </div>
                        }

                        {contentType != 'drochka' ? null :
                            <div>
                                <DrochkaInfoForm
                                    loading={loading}
                                    onSave={async d => {
                                        let pld = await dispatch(drochkaExercisesActions.createExercise({
                                            ...d,
                                            creatorId: currentUserId
                                        }));
                                        let {drochkaExercisesIds = []} = selectedModuleFolder;
                                        let newExercisesIds = drochkaExercisesIds.concat([pld?.exercise?.id]);
                                        await dispatch(actions.updateFolder({
                                            id: selectedModuleFolder?.id,
                                            drochkaExercisesIds: newExercisesIds
                                        }));
                                        setSelectedModuleFolderId(undefined);
                                    }}
                                />
                            </div>
                        }

                        {contentType != 'treadmills' ? null :
                            <div>
                                <UpdateTreadmillForm
                                    loading={loading}
                                    onUpdate={async d => {
                                        console.log('UpdateTreadmillForm: d = ', d);
                                        let pld = await dispatch(treadmillsActions.createTreadmill({
                                            ...d,
                                            creatorId: currentUserId
                                        }));
                                        let {treadmillsIds = []} = selectedModuleFolder;
                                        let newExercisesIds = treadmillsIds.concat([pld?.treadmill?.id]);
                                        await dispatch(actions.updateFolder({
                                            id: selectedModuleFolder?.id,
                                            treadmillsIds: newExercisesIds
                                        }));
                                        setSelectedModuleFolderId(undefined);
                                    }}
                                />
                            </div>
                        }

                        {/*<SimpleSlideForm*/}
                        {/*    loading={loading}*/}
                        {/*    onSave={async d => {*/}
                        {/*        await dispatch(slidesActions.createSlide(d, selectedModuleFolder?.id));*/}
                        {/*        await dispatch(actions.getMyAllCompactFolders());*/}
                        {/*        setSelectedModuleFolderId(undefined);*/}
                        {/*    }}*/}
                        {/*/>*/}

                    </AddFolderInner>

                </NiceModal>
            }


        </Wrapper>
    );
}


const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

const AddFolderInner = styled.div`
  box-sizing: border-box;
  width: 420px;
  background: white;
  padding: 20px;
`;

const TopNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-weight: bold;
`;

const TopRight = styled.div`

`;

const Wrapper = styled.div`
  padding: 10px;
`;
