import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import FilmsCitationTool from "../citation/FilmsCitationTool";
import TreadmillsPanel from "../treadmills_exercises/panels/TreadmillsPanel";
import PolotnoTool from "../polotno/tools/PolotnoTool";
import BulkDndPlaceholder from "../bulk_uploader/BulkDndPlaceholder";
import AccessGroupsPanel from "../access_groups/panels/AccessGroupsPanel";
import {useMappedState} from "redux-react-hook";
import Tabs, {TabItem} from "../ui/Tabs";
import AllTagsPanel from "../tags/panels/AllTagsPanel";
import AllOxwordsPanel from "../oxwords/panels/AllOxwordsPanel";
import CompareTool from "../compare/CompareTool";
import AiTools from "../gpt/tools/AiTools";
import AllPostsPanel from "../posts/panels/AllPostsPanel";
import TeacherAccessesTool from "../teacher_access/tools/TeacherAccessesTool";
import TeachersStatsTool from "../stats/tools/TeachersStatsTool";
// import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
// import GroupsPanel from "../groups/panels/GroupsPanel";

const ADMIN_EMAIL = 'lirmakym@mail.ru';

export default function TeacherToolsApp(props) {
    const [tab, setTab] = useState('access');

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    return (
        <TeacherTemplate active={'tools'}>
            {/*<GroupsPanel/>*/}
            {/*<ContainersTreePanel/>*/}

            <TopPlaceholder>
                <TopLeft>
                    {
                        [
                            {
                                label: 'Доступы',
                                value: 'access'
                            },
                            // {
                            //     label: 'Цитирование',
                            //     value: 'citation'
                            // },
                            {
                                label: 'Фото редактор',
                                value: 'polotno'
                            },
                            {
                                label: 'Сравнение текстов',
                                value: 'compare'
                            },

                            {
                                label: 'AI инструменты',
                                value: 'ai'
                            },


                            // {
                            //     label: 'Массовая загрузка',
                            //     value: 'bulk_uploader'
                            // },
                            // {
                            //     label: 'Резалка YouTube',
                            //     value: 'youtube'
                            // },
                            // {
                            //     label: 'Тредмилы',
                            //     value: 'treadmills'
                            // },
                            {
                                label: 'Слова',
                                value: 'oxwords'
                            },

                            (currentUser?.email != ADMIN_EMAIL) ? null :
                                {
                                    label: 'Tags',
                                    value: 'tags'
                                },
                            (currentUser?.email != ADMIN_EMAIL) ? null :
                                {
                                    label: 'Посты',
                                    value: 'posts'
                                },
                            (currentUser?.email != ADMIN_EMAIL) ? null :
                                {
                                    label: 'Доступ на платформу',
                                    value: 'teachers_access'
                                },
                            (currentUser?.email != ADMIN_EMAIL) ? null :
                                {
                                    label: 'Статистика преподавателей',
                                    value: 'teachers_stats'
                                }
                        ].filter(x => (x != undefined)).map((x, i) => {
                            return (
                                <TopRightTabItem selected={(x.value == tab)} key={i} onClick={() => {
                                    setTab(x.value);
                                }}>
                                    {x.label}
                                </TopRightTabItem>
                            )
                        })
                    }
                </TopLeft>
                <TopRight>

                </TopRight>
            </TopPlaceholder>

            <TabsPlaceholder>

                {tab != 'citation' ? null :
                    <div>
                        <FilmsCitationTool/>
                    </div>
                }

                {tab != 'oxwords' ? null :
                    <NarrowWrapper>
                        <AllOxwordsPanel/>
                    </NarrowWrapper>
                }

                {tab != 'ai' ? null :
                    <NarrowWrapper>
                        <AiTools/>
                    </NarrowWrapper>
                }

                {tab != 'teachers_access' ? null :
                    <NarrowWrapper>
                        <TeacherAccessesTool/>
                    </NarrowWrapper>
                }





                {tab != 'bulk_uploader' ? null :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        padding: 20
                    }}>
                        <BulkDndPlaceholder/>
                    </div>
                }

                {tab != 'treadmills' ? null :
                    <div>
                        <TreadmillsPanel/>
                    </div>
                }

                {tab != 'polotno' ? null :
                    <PolotnoPlaceholder>
                        <PolotnoTool/>
                    </PolotnoPlaceholder>
                }

                {tab != 'access' ? null :
                    <div>
                        <AccessGroupsPanel/>
                    </div>
                }

                {tab != 'compare' ? null :
                    <div>
                        <CompareTool />
                    </div>
                }

                {tab != 'posts' ? null :
                    <div>
                        <AllPostsPanel />
                    </div>
                }

                {tab != 'teachers_stats' ? null :
                    <div>
                        <TeachersStatsTool />
                    </div>
                }






                {tab != 'tags' ? null :
                    <TagsInnerPlaceholder>
                        <Tabs
                            tabs={[
                                {
                                    label: 'Grammar tags',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'grammar'}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Content tags',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'subject'}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Глобальные темы',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'uni_global_theme'}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Модули',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'uni_module'}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Уровни',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'uni_level'}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Названия юнитов',
                                    content: (
                                        <TabItem>
                                            <AllTagsPanel
                                                prefix={'uni_unit'}
                                            />
                                        </TabItem>
                                    )
                                }

                            ]}
                        />
                    </TagsInnerPlaceholder>
                }

            </TabsPlaceholder>


        </TeacherTemplate>
    );
}

const NarrowWrapper = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 800px;
  
  @media (max-width: 720px){
    width: 100%
  }
`;

const PolotnoPlaceholder = styled.div`
  width: calc(100vw - 300px);
  box-sizing: border-box;
  height: calc(100vh - 70px);
  border-left: 1px solid whitesmoke;
  
  @media (max-width: 720px){
    width: 100%;
    border-left: 0px;
  }
`;

const TagsInnerPlaceholder = styled.div`
  width: 820px;
  margin: 0 auto;
`;

const Wrapper = styled.div`

`;

const TabsPlaceholder = styled.div`
  box-sizing: border-box;
  //padding: 15px;
`;


const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: ${props => (props.hasLogo ? 0 : 15)}px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;
