import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    scale: {},

    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TreadmillScaleReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.UPDATE_SCALE:
        case types.LOAD_SCALE:
            return startLoading(state, action)

        case types.UPDATE_SCALE_FAIL:
        case types.LOAD_SCALE_FAIL:
            return stopLoading(state, action)

        case types.UPDATE_SCALE_SUCCESS:
            return {
                ...state,
                loading: false,
                scale: action.scale
            }

        case types.LOAD_SCALE_SUCCESS:
            return {
                ...state,
                loading: false,
                scale: action.scale
            }

        default:
            return state;

    }

}

export default TreadmillScaleReducer;