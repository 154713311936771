import DialogsAPI from '../../api/DialogAPI'

import * as types from '../ActionTypes'

let loadDialogs_ = () => {
    return {
        type: types.LOAD_DIALOGS
    }
}
let loadDialogsSuccess = (dialogs) => {
    return {
        type: types.LOAD_DIALOGS_SUCCESS,
        dialogs: dialogs
    }
}
let loadDialogsFailed = (error) => {
    return {
        type: types.LOAD_DIALOGS_FAIL,
        error: error
    }
}

//thunk
export function loadAllDialogs() {
    return (dispatch, getState) => {
        dispatch(loadDialogs_());
        return DialogsAPI.getAllDialogs().then(
            dialogs => dispatch(loadDialogsSuccess(dialogs)),
            error => dispatch(loadDialogsFailed(error))
        )
    }
}

export function loadDialogById(id, userId) {
    return (dispatch, getState) => {
        dispatch(loadDialogs_());
        return DialogsAPI.getDialog(id, userId).then(
            dialog => dispatch(loadDialogsSuccess([dialog])),
            error => dispatch(loadDialogsFailed(error))
        )
    }
}

let createDialog_ = () => {
    return {
        type: types.CREATE_DIALOG
    }
}
let createDialogSuccess = (dialog) => {
    return {
        type: types.CREATE_DIALOG_SUCCESS,
        dialog: dialog
    }
}
let createDialogFailed = (error) => {
    return {
        type: types.CREATE_DIALOG_FAIL,
        error: error
    }
}

//thunk
export function createDialog(data) {
    return (dispatch, getState) => {
        dispatch(createDialog_());
        return DialogsAPI.createDialog(data).then(
            dialog => dispatch(createDialogSuccess(dialog)),
            error => dispatch(createDialogFailed(error))
        )
    }
}


let updateDialog_ = () => {
    return {
        type: types.UPDATE_DIALOG
    }
}
let updateDialogSuccess = (dialog) => {
    return {
        type: types.UPDATE_DIALOG_SUCCESS,
        dialog: dialog
    }
}
let updateDialogFailed = (error) => {
    return {
        type: types.UPDATE_DIALOG_FAIL,
        error: error
    }
}

//thunk
export function updateDialog(data) {
    return (dispatch, getState) => {
        dispatch(updateDialog_());
        return DialogsAPI.updateDialog(data).then(
            dialog => dispatch(updateDialogSuccess(dialog)),
            error => dispatch(updateDialogFailed(error))
        )
    }
}

let deleteDialog_ = () => {
    return {
        type: types.DELETE_DIALOG
    }
}
let deleteDialogSuccess = (id) => {
    return {
        type: types.DELETE_DIALOG_SUCCESS,
        id: id
    }
}
let deleteDialogFailed = (error) => {
    return {
        type: types.DELETE_DIALOG_FAIL,
        error: error
    }
}

//thunk
export function deleteDialog(id) {
    return (dispatch, getState) => {
        dispatch(deleteDialog_());
        return DialogsAPI.deleteDialog(id).then(
            () => dispatch(deleteDialogSuccess(id)),
            error => dispatch(deleteDialogFailed(error))
        )
    }
}
