import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

const sar = a => (a == undefined ? [] : a);

const getSortedArr = arr => {
    let map = {};
    for (let i in arr) {
        let a = arr[i];
        map[`${a}`] = 1;
    }
    let arr1 = Object.keys(map);

    return arr1.sort((a, b) => {
        let s1 = a.toLowerCase();
        let s2 = b.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function HighlightEditorTool(props) {
    const {
        text = '',
        selWords = [],
        onChange = newWords => {

        }
    } = props;

    if (text == undefined || text.trim() == '') {
        return null;
    }

    let re = /([^\s-.,;:!?()[\]{}<>"]+)/g;
    let words = text.match(re);
    if (
        (words).length == 0) {
        return null;
    }
    let currentWordNumber = 0, currentWordPosition = 0, currentShit = '';
    let elements = [], flags = [];
    let s = text;
    while (s[0] != words[0][0]) {
        s = s.slice(1);
    }
    let prefix = text.slice(0, text.indexOf(words[0]));
    if (prefix != '') {
        elements.push(<Shit>{prefix}</Shit>);
        flags.push(0);
    }
    // console.log('render: s = ', s);
    // console.log('render: s.length = ', s.length);

    for (let i in s) {
        let char = s[i];
        let currentWordChar = words[currentWordNumber][currentWordPosition];
        if (char != currentWordChar) {
            currentShit = currentShit + char;
            // console.log('incrementing shit = ', currentShit);
        } else {
            if (currentShit != '') {
                elements.push({word: currentShit, isShit: true});
                flags.push(0);
            }
            if (sar(words[currentWordNumber]).length - 1 == currentWordPosition) {
                elements.push({word: words[currentWordNumber], isShit: false});
                flags.push(1);
                if (sar(words).length - 1 == currentWordNumber) {
                    let end = '';
                    for (let j = +i + 1; +j < +s.length; j++) {
                        end = end + s[j];
                    }
                    if (end.length > 0) {
                        elements.push({word: end, isShit: true});
                        flags.push(0);
                    }
                    break;
                }
                currentWordNumber = Math.min(+currentWordNumber + 1, sar(words).length - 1);
                currentWordPosition = 0;
            } else {
                currentWordPosition = currentWordPosition + 1;
            }
            currentShit = '';
        }
    }

    return (
        <Wrapper style={{textAlign: isHebrew(text) ? 'right' : 'left'}} dir={isHebrew(text) ? 'rtl' : 'ltr'}>
            {elements.map((el, k) => {
                let w = (el?.word || '').toLowerCase();
                let {isShit} = el;
                return (
                    <React.Fragment key={`${k}_${w}`}>
                        {isShit && <Shit>
                            {el.word}
                        </Shit>}
                        {!isShit && <Word
                            selected={selWords.indexOf(w) > -1}
                            onClick={() => {
                                let restWords = selWords.filter(x => (x != w)).filter(x => (x != ''));
                                if (selWords.indexOf(w) > -1) {
                                    onChange(getSortedArr(restWords));
                                } else {
                                    onChange(getSortedArr(restWords.concat([w])));
                                }
                            }}>
                            {el.word}
                        </Word>}
                    </React.Fragment>

                )
            })}
        </Wrapper>
    );
}

const Word = styled.div`
  display: inline-block;
  box-sizing: border-box;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 3px;
  padding-left: ${props => (props.selected == true ? 3 : 0)}px;
  padding-right: ${props => (props.selected == true ? 3 : 0)}px;
  background-color: ${props => (props.selected == true ? '#BFE692' : 'transparent')};
  color: ${props => (props.selected == true ? 'black' : props.wordColor)};
  border: 1px solid transparent;

  :hover {
    opacity: 0.8;
    background-color: #D5FFA6;
    border: 1px solid #84AF4E;
    color: black;
  }
`;

const Shit = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div`

`;