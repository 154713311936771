import React, {PropTypes} from 'react';
import styled from 'styled-components';

import TranslatableText from './TranslatableText'
import YandexTranslatePanel from "./YandexTranslatePanel";
import SpeechAPI from "../../../api/SpeechAPI";

import close_img from '../../../assets/img/close.png'
import mute_img from '../../../assets/img/mute.png'

class TranslatableParagraph extends React.Component {

    static defaultProps = {
        text: `
            Green tables. orchards
        `,

        overlayBackgroundColor: 'rgba(0, 0, 0, 0.6)',
        textColor: 'white',
        userId: undefined,
        lessonId: undefined,
        wordColor: 'black',
        // yandexVoiceEnabled: false
        yandexVoiceEnabled: true
    }

    static propTypes = {}

    state = {
        selectedWord: undefined,
        currentLineNumber: undefined
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    addVocabularyItem = () => {

    }

    getExtraContent = (k) => {
        let {overlayBackgroundColor, textColor, userId, lessonId, yandexVoiceEnabled} = this.props;
        let {currentLineNumber, selectedWord} = this.state;
        if (k != currentLineNumber) {
            return null;
        }
        let selectable = (userId != undefined);
        console.log('getExtraContent: userId = ', userId);
        return (
            <ExtraCotent>
                <ExtraInner>
                    <OverlayWrapper onClick={() => {
                        // this.setState({
                        //     selectedWord: undefined,
                        //     currentLineNumber: undefined
                        // });
                    }}>
                        <BubbleContentPlaceholder style={{
                            backgroundColor: overlayBackgroundColor,
                            color: textColor
                        }} onClick={evt => {
                            // this.setState({
                            //     selectedWord: this.state.selectedWord,
                            //     currentLineNumber: this.state.currentLineNumber,
                            // });
                            // evt.preventDefault();
                        }}>
                            <BubTopPlaceholder>
                                <BubNamePlaceholder>
                                    <SelectedWord>
                                        {selectedWord}
                                        {/*{' '}*/}
                                        {/*{yandexVoiceEnabled == false ? null :*/}
                                        {/*    <MuteImage src={mute_img} onClick={() => {*/}
                                        {/*        SpeechAPI.forvoSpeak(selectedWord);*/}
                                        {/*    }}/>*/}
                                        {/*}*/}
                                    </SelectedWord>
                                </BubNamePlaceholder>
                                <BubClosePlaceholder>
                                    <CloseImage
                                        src={close_img}
                                        onClick={() => {
                                            this.setState({
                                                selectedWord: undefined,
                                                currentLineNumber: undefined
                                            });
                                        }}
                                    />
                                </BubClosePlaceholder>
                            </BubTopPlaceholder>
                            <YandexTranslatePanel
                                userId={userId}
                                lessonId={lessonId}
                                word={selectedWord} selectable={selectable}/>
                        </BubbleContentPlaceholder>
                    </OverlayWrapper>

                </ExtraInner>
            </ExtraCotent>
        )
    }

    render = () => {
        let textsList = this.props.text.split('\n');
        let {selectedWord} = this.state;
        let {selectable, yandexVoiceEnabled, wordColor, userId} = this.props;

        return (
            <Wrapper className={''}>

                {selectedWord == undefined ? null :
                    <Overlay onClick={() => {
                        this.setState({
                            selectedWord: undefined,
                            currentLineNumber: undefined
                        });
                    }}/>
                }

                {textsList.map((txt, k) => {
                    return (
                        <Line key={k}>
                            <TranslatableText selectedWord={selectedWord}
                                              extraContent={this.getExtraContent(k)}
                                              yandexVoiceEnabled={yandexVoiceEnabled}
                                              wordColor={wordColor}
                                              userId={userId}
                                              text={txt} onWordClick={w => {
                                this.setState({
                                    selectedWord: w,
                                    currentLineNumber: k
                                });
                            }}/>
                        </Line>
                    )
                })}

            </Wrapper>
        )
    }

}

const Wrapper = styled.div`

`;

const BubTopPlaceholder = styled.div`

`;

const BubClosePlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 40px;
  text-align: right;
`;

const CloseImage = styled.img`
  display: inline-block;
  width: 18px;
  height: 18px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const BubNamePlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% - 40px);
`;

const SelectedWord = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const ExtraCotent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
`;

const ExtraInner = styled.div`
  position: relative;
  width: 100%;
`;

const Line = styled.div`
  margin-bottom: 5px;
`;

const MuteImage = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0.5;
  vertical-align: sub;

  :hover {
    opacity: 1;
  }
`;

const OverlayWrapper = styled.div`
  position: fixed;
  z-index: 100000;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 10px;
  max-height: 100vh;
  overflow-y: auto;
  color: white;
`;

const BubbleContentPlaceholder = styled.div`
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 320px;
  padding: 10px;
  position: relative;
  @media (max-width: 320px) {
    width: 100%;
  }
`;

const ClosePlaceholder = styled.div`
  position: absolute;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${100000 - 1};
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;


//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//TranslatableParagraph = connect(mapStateToProps, mapDispatchToProps)(TranslatableParagraph)

export default TranslatableParagraph