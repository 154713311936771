import {useDispatch, useMappedState} from "redux-react-hook";
import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import TeacherTemplate from "../templates/TeacherTemplate";
import ls from "local-storage";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";

export default function StudentDiaryApp(props) {
    const {userId, groups = []} = useMappedState(useCallback(state => {
        return {
            userId: state.users.currentUserId,
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            })
        }
    }))

    let dispatch = useDispatch();

    useEffect(() => {
        if (groups !== undefined) {
            dispatch(usersActions.loadUserById(userId));
            ls('gr_mode', 'students');
            CommonHelper.linkTo(`/diary/${groups[0]?.id}`);
        }

    }, [groups])


    return (
        <TeacherTemplate active={'diary'}>

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;
