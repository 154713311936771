// export const DEFAULT_TEXT_SLIDE_BACKGROUND = '#000000';
export const DEFAULT_TEXT_SLIDE_BACKGROUND = 'rgba(0, 0, 0, 0.99)';

export const SAMPLE_ITEMS = [
    {
        text: 'Sample text',
        imageSrc: 'https://www.englishpatient.org/english-patient-files/84c760f2-0db8-44d2-9bc0-b1c40c0bce16.jpg',
        from: 0,
        to: 2,
        textSize: 32
    },
    {
        from: 2,
        to: 50,
        videoSrc: 'https://www.englishpatient.org/english-patient-files/b97c0a23-51c8-44c6-b5c9-797f94dc1e01.mp4'
    },
    {
        from: 50,
        to: 90,
        hasCountDown: true,
        text: 'Приготовьтесь'
    },
    {
        text: 'Sample text 2',
        imageSrc: 'https://www.englishpatient.org/english-patient-files/84c760f2-0db8-44d2-9bc0-b1c40c0bce16.jpg',
        from: 90,
        to: 120,
        textPosition: 'top'
    },
    {
        text: 'Sample text 3',
        imageSrc: 'https://www.englishpatient.org/english-patient-files/84c760f2-0db8-44d2-9bc0-b1c40c0bce16.jpg',
        from: 120,
        to: 160,
        textPosition: 'bottom'
    }
];