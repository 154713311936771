import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {Input} from "../../ira/ui/Inputs";
import {useDebouncedCallback} from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroller';
import SearchAPI from "../../../api/SearchAPI";
import {translate} from "../../../utils/Translate";
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";

import tick from '../../../assets/img/green_check.svg'

import not_found_json from '../../../assets/lottie/not-found.json'
import search_json from '../../../assets/lottie/search-analysis.json'
import LottieLoader from "../../loaders/LottieLoader";
import ls from "local-storage";
import VerfactoTracker from "../../../api/TrackerAPI";
import ReactPlayer from "react-player";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import DialogExercisePanel from "../../dialogs/panels/DialogExercisePanel";
import TestsListInfoTool from "../../tests/tools/TestsListInfoTool";
import CommonHelper from "../../../helpers/CommonHelper";
import TreadmillPanel from "../../treadmills_exercises/panels/TreadmillPanel";
import QuestionnairePanel from "../../questionnaires/panels/QuestionnairePanel";
import tick_img from "../../../assets/img/green_check.svg";

export default function MaterialSearchPanel(props) {
    const {
        pageSize = 10,
        type = 'materials',
        onSelect = m => {

        },
        isMultiSelect = false,
        selectedIds = []
    } = props;
    const loadingRef = useRef(false);
    const pageRef = useRef(0);

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    const [items, setItems] = useState([]);
    const [page, setPage] = useState();
    const [total, setTotal] = useState(0);

    const [expandedId, setExpandedId] = useState();

    const debounced = useDebouncedCallback((value) => {
        setText(value);
    }, 1000);

    useEffect(() => {
        if (text == undefined || text.length <= 2 || loadingRef.current == true) {
            return;
        }
        pageRef.current = 0;
        loadingRef.current = true;
        SearchAPI.searchContentItems({
            text: text,
            page: page,
            pageSize: pageSize,
            type: type
        }).then(pld => {
            console.log('loaded! pld = ', pld);
            loadingRef.current = false;
            setTotal(pld.total);
            setItems(items.concat(pld.items));
        });
    }, [text]);
    let hasSomeText = (text != undefined && text.length > 2);
    let notFound = (hasSomeText && loadingRef.current == false && items.length == 0);
    let expandedItem = items.filter(xx => (xx.id == expandedId))[0];

    // console.log('expandedItem = ', expandedItem);
    // console.log('MaterialSearchPanel: type = ', type);
    // console.log('MaterialSearchPanel: selectedIds = ', selectedIds);


    return (
        <Wrapper>

            <TopSearchPlaceholder>
                <Input
                    placeholder={translate('search_phrase')}
                    onChange={evt => {
                        console.log('on text change: evt.target.value = ', evt.target.value);
                        setPage(0);
                        setItems([]);
                        setText(evt.target.value);
                    }}
                />
            </TopSearchPlaceholder>

            {hasSomeText == false ?
                <VoidStatePlaceholder>
                    {/*<div style={{height: 50}} >*/}
                    {/*    Please type a search phrase*/}
                    {/*</div>*/}
                    <LottieLoader
                        json={search_json}
                    />
                </VoidStatePlaceholder>
                :
                <React.Fragment>
                    {notFound == true ?
                        <VoidStatePlaceholder>
                            <LottieLoader
                                json={not_found_json}
                            />
                            <div style={{height: 50}}>
                                No results found
                            </div>
                        </VoidStatePlaceholder>
                        :
                        <ListPlaceholder>

                            <InfiniteScroll
                                useWindow={false}
                                loadMore={pg => {
                                    console.log('loadMore');
                                    console.log('items.length, total = ', items.length, total);
                                    console.log('loadingRef.current = ', loadingRef.current);
                                    if (loadingRef.current == true || items.length >= total) {
                                        return;
                                    }
                                    loadingRef.current = true;
                                    // console.log('pg = ', pg);
                                    // setPage(+page + 1);
                                    SearchAPI.searchContentItems({
                                        text: text,
                                        page: pageRef.current,
                                        pageSize: pageSize,
                                        type: type
                                    }).then(pld => {
                                        console.log('loaded! pld = ', pld);
                                        loadingRef.current = false;
                                        pageRef.current = +pageRef.current + 1;
                                        setTotal(pld.total);
                                        setItems(items.concat(pld.items));
                                    });
                                }}
                                hasMore={items.length < total && items.length > 0}
                                loader={loadingRef.current && <div className="loader" key={0}>Loading ...</div>}

                            >
                                {items.map((a, i) => {
                                    let isMultiSelected = (isMultiSelect == true && selectedIds.indexOf(a.id) > -1);
                                    let isSelected = (selectedIds.indexOf(a.id) > -1);
                                    return (
                                        <React.Fragment key={`${a.id}-${i}`}>
                                            <ListItem expanded={expandedId == a.id}>
                                                {isMultiSelected == false ? null :
                                                    <div style={{position: 'absolute', top: 5, right: 5}}>
                                                        <img src={tick} style={{height: 16}}/>
                                                    </div>
                                                }
                                                <ItemLeft>
                                                    <ItemAvatar src={a.avatar || DEFAULT_VIDEO_PLACEHOLDER}/>
                                                </ItemLeft>
                                                <ItemRight>
                                                    <ItemName>
                                                        {a.name}
                                                    </ItemName>
                                                    {(a.transcript == undefined || a.transcript == '') ? null :
                                                        <TranscriptPlaceholder>
                                                            {a.transcript.length > 300 ? `${a.transcript.slice(0, 300)}...` : a.transcript}
                                                        </TranscriptPlaceholder>
                                                    }
                                                    <ControlsPlaceholder>
                                                        <ButtonSpan onClick={() => {
                                                            if (type == 'tests') {
                                                                CommonHelper.linkTo(`/tests/${a.id}`, true);
                                                                return;
                                                            }
                                                            if (expandedId == a.id) {
                                                                setExpandedId(undefined);
                                                            } else {
                                                                setExpandedId(a.id);
                                                            }
                                                        }}>
                                                            Просмотреть
                                                        </ButtonSpan>
                                                        <ButtonSpan onClick={() => {
                                                            onSelect(a);
                                                        }}>
                                                            {isSelected == false ? null :
                                                                <img src={tick_img}
                                                                     style={{height: 18, marginRight: 5}}/>
                                                            }
                                                            {translate('select')}
                                                        </ButtonSpan>
                                                    </ControlsPlaceholder>
                                                </ItemRight>
                                            </ListItem>
                                            {a.id != expandedId ? null :
                                                <ExpandedPlaceholder>
                                                    {type != 'materials' ? null :
                                                        <VideoPlaceholder>
                                                            <SelVideoContentPlaceholder>
                                                                <ReactPlayer
                                                                    width={'100%'}
                                                                    height={'100%'}
                                                                    playing={true}
                                                                    light={expandedItem?.avatar || DEFAULT_VIDEO_PLACEHOLDER}
                                                                    controls={true}
                                                                    url={expandedItem?.url}
                                                                />
                                                            </SelVideoContentPlaceholder>
                                                            <SelVideoContentTranscript>
                                                                {expandedItem?.transcript}
                                                            </SelVideoContentTranscript>
                                                        </VideoPlaceholder>
                                                    }

                                                    {type != 'exercises' ? null :
                                                        <div>
                                                            <ExercisePanel
                                                                id={expandedItem?.id}
                                                            />
                                                        </div>
                                                    }

                                                    {type != 'treadmills' ? null :
                                                        <div style={{width: '100%', height: 420}}>
                                                            <TreadmillPanel
                                                                id={expandedItem?.id}
                                                                isPreview={true}
                                                            />
                                                        </div>
                                                    }

                                                    {type != 'cards' ? null :
                                                        <div>
                                                            <DrochkaExercisePanel
                                                                id={expandedItem?.id}
                                                            />
                                                        </div>
                                                    }

                                                    {type != 'dialogs' ? null :
                                                        <div>
                                                            <DialogExercisePanel
                                                                id={expandedItem?.id}
                                                            />
                                                        </div>
                                                    }

                                                    {type != 'questionnaires' ? null :
                                                        <div>
                                                            <QuestionnairePanel
                                                                id={expandedItem?.id}
                                                            />
                                                        </div>
                                                    }


                                                    {type != 'tests' ? null :
                                                        <div>
                                                            <TestsListInfoTool
                                                                ids={[expandedItem?.id]}
                                                            />
                                                        </div>
                                                    }


                                                </ExpandedPlaceholder>
                                            }

                                        </React.Fragment>
                                    )
                                })}
                            </InfiniteScroll>

                        </ListPlaceholder>
                    }
                </React.Fragment>
            }

        </Wrapper>
    );
}

const VideoPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const SelVideoContentPlaceholder = styled.div`
  height: 320px;
  width: 100%;
  background: whitesmoke;
`;

const SelVideoContentTranscript = styled.div`
  max-height: 140px;
  overflow-y: auto;
  font-size: 10px;
  font-style: italic;
  box-sizing: border-box;
  font-weight: normal;
  margin-top: 10px;
`;

const TranscriptPlaceholder = styled.div`
  max-height: 140px;
  overflow-y: auto;
  font-size: 10px;
  font-style: italic;
  box-sizing: border-box;
  font-weight: normal;
  margin-top: 10px;
`;

const ControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
`;

const ButtonSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ExpandedPlaceholder = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: #fffefe;
`;

const VoidStatePlaceholder = styled.div`
  width: 100%;
  height: min(400px, 100vh - 120px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const ListPlaceholder = styled.div`
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid lightgrey;
`;

const ItemsListPlaceholder = styled.div`
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

const ListItem = styled.div`
  margin-bottom: 10px;
  box-sizing: border-box;
  background: ${props => (props.expanded == true ? '#fff8eb' : 'white')};
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
`;

const ItemRight = styled.div`
  flex: 1;
`;

const ItemName = styled.div`
  font-weight: bold;
`;


const ItemLeft = styled.div`
  width: 140px;
  margin-right: 20px;
  height: 80px;
`;

const ItemAvatar = styled.div`
  width: 100%;
  height: 100%;
  background: lightgrey;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
`;

const TopSearchPlaceholder = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
`;

