import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
import CoursesViewPanel from "../courses/panels/CoursesViewPanel";

export default function CoursesApp(props) {

    return (
        <TeacherTemplate active={'courses'}>

            <CoursesViewPanel
                creatorMode={true}
                showShareButton={true}
                canCopyToBuffer={true}
            />

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;