import {useMappedState} from "redux-react-hook";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import TasksHelper from "../../../helpers/TasksHelper";
import moment from "moment/moment";
import NiceModal from "../../modals/NiceModal";
import LessonsList from "../../lessons/tools/LessonsList";
import {isMobile} from "polotno/utils/screen";
import UpdateMarkForm from "../../ledger/forms/UpdateMarkForm";
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import {Tooltip} from "recharts";

function getAvr(data, task, currentUserId) {
    return TasksHelper.getTaskAverageMark(data, task, currentUserId);
}

function getTooltipTitle(color) {
    if (color === '' || color === undefined)
        return ''
    else if (color === '#8CE77E')
        return "Хорошая работа на занятии"
    else if (color === '#56CCFF')
        return "Пропуск занятия по уважительной причине"
    else
        return ''
}

function getBorderColor(data, taskId) {
    if (data === undefined || taskId === undefined)
        return null;

    for (let dt of data) {
        if (taskId === dt?.taskId)
            return dt?.data?.goodWork ? '#8CE77E' : dt?.data?.missed ? '#56CCFF' : '';
    }

    return null;
}

function getTeacherMark(data, taskId) {
    if (data === undefined || taskId === undefined) {
        return -1;
    }

    let info = data.filter(x => x?.taskId === taskId)[0];

    if (info === undefined) {
        return -1;
    } else {
        return info?.data?.mark === undefined || info?.data?.mark === '' ? -1 : info?.data?.mark;
    }
}

export default function LessonTasksMarksList(props) {
    const {
        currentUser,
        lessonId,
        data,
        lessons,
        userRole = 'student',
        marksInfo,
        recalcMarks = () => {

        }
    } = props;

    const [selectedModalData, setSelectedModalData] = useState(undefined);
    const [markModal, setMarkModal] = useState(undefined);

    const {
        tasks
    } = useMappedState(useCallback(state => {
        return {
            tasks: state.tasks.tasksMap.toArray().filter(x => (x.lessonId == lessonId)).sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            })
        }
    }));

    return (
        <Wrapper>
            <TasksListPlaceholder>
                {tasks.map((a, i) => {
                    let teacherMark = getTeacherMark(marksInfo, a.id);
                    let borderColor = getBorderColor(marksInfo, a.id);
                    return (
                        <TaskItem key={a.id}>
                            <TaskItemTopPlaceholder
                                selected={true}
                                style={{borderBottom: (true) ? `1px solid whitesmoke` : 'none'}}
                            >
                                <TaskItemInfo>
                                    <TaskName onClick={() => {
                                        setSelectedModalData(a);
                                    }}>
                                        {/*{`${i + 1}) `}*/}
                                        {(a?.name == undefined || a?.name == '') ? 'Без названия' : a.name}
                                    </TaskName>
                                </TaskItemInfo>

                                <InfoPlaceholder>

                                    <DatesPlaceholder>
                                        {moment(a.createdTimestamp).format('DD/MM/YYYY')}
                                    </DatesPlaceholder>

                                    <DatesPlaceholder>
                                        {moment(a.deadlineTimestamp).format('DD/MM/YYYY')}
                                    </DatesPlaceholder>

                                    {userRole === 'student' ?
                                        <DatesPlaceholder
                                            style={{border: borderColor === '' ? '' : `2px solid ${borderColor}`}}
                                            title={`${getTooltipTitle(borderColor)}`}
                                        >

                                            <InnerMarkPlaceholder>
                                                <div>
                                                    {getAvr(data, a, currentUser.id) <= 0 ? null
                                                        :
                                                        `${(+getAvr(data, a, currentUser.id)).toFixed(1)}`
                                                    }
                                                    {' '}
                                                    {teacherMark <= 0 ? null :
                                                        `(${teacherMark})`
                                                    }
                                                </div>
                                            </InnerMarkPlaceholder>

                                        </DatesPlaceholder>

                                        :

                                        <TeacherDatesPlaceholder
                                            style={{border: borderColor === '' ? '' : `2px solid ${borderColor}`}}
                                            onClick={() => { setMarkModal(a);}}
                                        >

                                            <InnerMarkPlaceholder>
                                                <div>
                                                    {getAvr(data, a, currentUser.id) <= 0 ? null
                                                        :
                                                        `${(+getAvr(data, a, currentUser.id)).toFixed(2)}`
                                                    }
                                                    {' '}
                                                    {teacherMark <= 0 ? null :
                                                        `(${teacherMark})`
                                                    }
                                                </div>
                                            </InnerMarkPlaceholder>

                                        </TeacherDatesPlaceholder>
                                    }

                                    <DatesPlaceholder style={{display: isMobile() ? 'none' : null}}/>


                                </InfoPlaceholder>

                            </TaskItemTopPlaceholder>

                        </TaskItem>
                    )
                })}

                {markModal === undefined ? null :

                    <NiceModal onClose={() => {
                        setMarkModal(undefined);
                    }}>
                        <UpdateMarkForm
                            userId={currentUser.id}
                            taskId={markModal?.id}
                            close={() => {
                                setMarkModal(undefined);
                                recalcMarks();
                            }}
                        />
                    </NiceModal>

                }

            </TasksListPlaceholder>

            {selectedModalData == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedModalData(undefined);
                }}>
                    <SelectedModalInnerPlaceholder>

                        <LessonsList
                            canEditLessons={false}
                            userId={currentUser.id}
                            lessons={lessons}
                            selectedTaskId={selectedModalData.id}
                            selectedLessonId={lessonId}
                            tasks={tasks}
                            forDiary={true}
                        />

                    </SelectedModalInnerPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  @media (max-width: 720px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const TasksListPlaceholder = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  width: 100%;
  
  :last-child {
    padding-bottom: 0;
  }
`;

const TaskItem = styled.div`
  background: white;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC;

  :last-child {
    border-bottom: none;
  }
  
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const TaskItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: white;
  padding-left: 15px;
`;

const TaskItemInfo = styled.div`
  cursor: pointer;
  flex: 1;
  margin-right: 20px;

  :hover {
    opacity: 0.8;
  }
`;

const TaskItemControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const InnerMarkPlaceholder = styled.div`

`;

const MarkPl = styled.div`

  :hover {
    border: 2px solid #6081e3;
  }
    
`;

const InfoPlaceholder = styled.div`
  display: flex;
  //gap: 15px;
  padding-right: 0px;
  align-items: center;
  
  //border-left: 1px solid lightgrey;
  height: 100%;
`;

const TaskName = styled.div`
  color: #767676;

  @media (max-width: 660px) {
    width: 130px;
    
  }
`;

const DatesPlaceholder = styled.div`
  width: 100px;
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-left: 15px;

  border-left: 1px solid #ECECEC;
  height: 100%;
  
  @media (max-width: 720px) {
    border-bottom: 1px solid #ECECEC;
    border-top: 1px solid #ECECEC;
  }
`;

const TeacherDatesPlaceholder = styled.div`
  width: 100px;
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-left: 15px;
  cursor: pointer;

  border-left: 1px solid #ECECEC;
  height: 100%;

  :hover {
    border: 1px solid #6081e3;
  }
  
  @media (max-width: 720px) {
    border-bottom: 1px solid #ECECEC;
    border-top: 1px solid #ECECEC;
  }
`;

const SelectedModalInnerPlaceholder = styled.div`
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  width: 920px;
  @media (max-width: 940px) {
    width: calc(80vw - 2px);
  }
`;
