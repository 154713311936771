import * as types from '../ActionTypes.js'
import OxWordsAPI from '../../api/OxWordsAPI';

let createOxWord_ = () => {
    return {
        type: types.CREATE_OXWORD
    }
}
let createOxWordSuccess = (oxword) => {
    return {
        type: types.CREATE_OXWORD_SUCCESS,
        oxword: oxword
    }
}
let createOxWordFailed = (error) => {
    return {
        type: types.CREATE_OXWORD_FAIL,
        error: error
    }
}

//thunk
export function createOxWord(data) {
    return (dispatch, getState) => {
        dispatch(createOxWord_());
        return OxWordsAPI.createOxWord(data).then(
            oxword => dispatch(createOxWordSuccess(oxword)),
            error => dispatch(createOxWordFailed(error))
        )
    }
}

//eca
let updateOxWord_ = () => {
    return {
        type: types.UPDATE_OXWORD
    }
}
let updateOxWordSuccess = (oxword) => {
    return {
        type: types.UPDATE_OXWORD_SUCCESS,
        oxword: oxword
    }
}
let updateOxWordFailed = (error) => {
    return {
        type: types.UPDATE_OXWORD_FAIL,
        error: error
    }
}

//thunk
export function updateOxWord(data) {
    return (dispatch, getState) => {
        dispatch(updateOxWord_());
        return OxWordsAPI.updateOxWord(data).then(
            oxword => dispatch(updateOxWordSuccess(oxword)),
            error => dispatch(updateOxWordFailed(error))
        )
    }
}

let loadOxWords_ = () => {
    return {
        type: types.LOAD_OXWORDS
    }
}
let loadOxWordsSuccess = (oxwords) => {
    return {
        type: types.LOAD_OXWORDS_SUCCESS,
        oxwords: oxwords
    }
}
let loadOxWordsFailed = (error) => {
    return {
        type: types.LOAD_OXWORDS_FAIL,
        error: error
    }
}

//thunk
export function loadOxWords() {
    return (dispatch, getState) => {
        dispatch(loadOxWords_());
        return OxWordsAPI.getAllOxWords().then(
            oxwords => dispatch(loadOxWordsSuccess(oxwords)),
            error => dispatch(loadOxWordsFailed(error))
        )
    }
}

let deleteOxWord_ = () => {
    return {
        type: types.DELETE_OXWORD
    }
}
let deleteOxWordSuccess = (id) => {
    return {
        type: types.DELETE_OXWORD_SUCCESS,
        id: id
    }
}
let deleteOxWordFailed = (error) => {
    return {
        type: types.DELETE_OXWORD_FAIL,
        error: error
    }
}

//thunk
export function deleteOxWord(id) {
    return (dispatch, getState) => {
        dispatch(deleteOxWord_());
        return OxWordsAPI.deleteOxWord(id).then(
            () => dispatch(deleteOxWordSuccess(id)),
            error => dispatch(deleteOxWordFailed(error))
        )
    }
}