import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
import CoursesViewPanel from "../courses/panels/CoursesViewPanel";
import PaywallAllQuestionnairesViewTool from "../paywall/tools/PaywallAllQuestionnairesViewTool";

export default function PQApp(props) {

    return (
        <TeacherTemplate active={'pq'}>

           <PaywallAllQuestionnairesViewTool/>

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;