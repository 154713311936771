import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AssistantsAPI from "../../../api/AssistantsAPI";

import NiceModal from "../../modals/NiceModal";
import {Input, Textarea} from "../../ira/ui/Inputs";
import toast from "react-hot-toast";
import AiAssistantsTool from "./AiAssistantsTool";
import UserConversationTool from "./UserConversationTool";

import {Code} from 'react-content-loader'

export default function UserAllConversationsTool(props) {
    const {
        userId,
        conversationId
    } = props;


    const [selectedId, setSelectedId] = useState(undefined);
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId == undefined) {
            return;
        }
        setLoading(true);
        AssistantsAPI.getUserConversations(userId).then((items) => {
            let sortedItems = items.sort((a, b) => (+b.timestamp - +a.timestamp));
            setConversations(sortedItems);
            setLoading(false);
            if (sortedItems.length > 0) {
                setSelectedId(sortedItems[0].id);
            }
        });
    }, [userId]);

    if (conversations.length == 0 && loading == true){
        return (
            <div>
                <Code/>
            </div>
        )
    }

    return (
        <Wrapper>

            <LeftPlaceholder>
                {conversations.map((a, i) => {
                    return (
                        <ConversationItem selected={(selectedId == a?.id)} key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            {a.name || moment(a.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                        </ConversationItem>
                    )
                })}

                <div className={''} style={{marginTop: 10, textDecoration: 'underline', paddingLeft: '5px', cursor: 'pointer'}} onClick={async () => {
                    setLoading(true);
                    let d = await AssistantsAPI.createConversation(userId);
                    console.log('new dialog = ', d);
                    setConversations([d, ...conversations]);
                    setSelectedId(d?.id);

                }}>
                    new dialog
                </div>

            </LeftPlaceholder>

            <RightPlaceholder key={`sel_${selectedId}`}>

                {selectedId == undefined ? null :
                    <UserConversationTool conversationId={selectedId} userId={userId}/>
                }

            </RightPlaceholder>

        </Wrapper>
    );
}

const ConversationItem = styled.div`
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  background: ${props => (props.selected ? 'blue' : 'white')};
  color: ${props => (props.selected ? 'white' : 'black')};

  cursor: pointer;

`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid #ccc;
`;

const LeftPlaceholder = styled.div`
  height: 100%;
  max-height: 100%;
  width: 160px;
`;

const RightPlaceholder = styled.div`
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  flex: 1;
  border: 1px solid #ccc;
`;