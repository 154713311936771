import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {FACELESS_AVATAR} from "../../constants/config";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";
import * as displayHelpers from "../../redux/actions/DisplayHelpersActions";

import toast, {Toaster} from 'react-hot-toast';

import ep_logo_wide_img from './images/ep_logo_wide.svg'

import library_svg from './images/icons/library.svg'
import ai_img from './images/ai_2.svg'
import video_svg from './images/icons/video-lesson.svg'
import cards_svg from './images/icons/cards.svg'
import quiz_test_svg from './images/icons/quiz-test.svg'
import dialog_svg from './images/icons/dialog.svg'
import questionnaire_svg from './images/icons/questionnaire.svg'
import treadmill_svg from './images/icons/treadmill.svg'
import task_management_svg from './images/icons/task-management.svg'
import chevron_svg from '../../../src/assets/img/chevron.svg'

import emoji_newspaper from './images/icons/emoji_newspaper.svg';
import emoji_home from './images/icons/emoji_home.svg';
import emoji_cal from './images/icons/emoji_cal.svg';
import smart_cap from './images/icons/smart_cap.svg';
import emoji_group from './images/icons/emoji_group.svg';
import emoji_courses from './images/icons/emoji_courses.svg';
import emoji_exercises from './images/icons/emoji_exercises.svg';
import emoji_tests from './images/icons/emoji_tests.svg';
import emoji_trainers from './images/icons/emoji_trainers.svg';
import emoji_events from './images/icons/emoji_events.svg';
import emoji_rating from './images/icons/emoji_rating.svg';
import legal_image from './images/icons/scale.png';
import emoji_tournaments from './images/icons/emoji_tournaments.svg';
import emoji_knol from './images/icons/emoji_knol.svg';
import emoji_friends from './images/icons/emoji_friends.svg';
import diary from './images/icons/diary.svg'
import LogoDiv from "./LogoDiv";
import {translate} from "../../utils/Translate";
import LangSwitcher from "../../utils/LangSwitcher";
import LangSwitcherDropdown from "../../utils/LangSwitcherDropdown";

import ls from 'local-storage'
import UIHelper from "../../helpers/UIHelper";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {isMobile} from "polotno/utils/screen";
import {getFixedUrl} from "../exercises/tools/ExerciseCardItemView";
import groupsAPI from "../../api/GroupsAPI";
import DraggableAiWindow from "../ai/tools/DraggableAiWindow";
import UserConversationsPanel from "../ai/panels/UserConversationsPanel";
import close_x_image from "../../assets/img/close_x.svg";
import PaywallQuestionnaireTool from "../paywall/tools/PaywallQuestionnaireTool";

export default function TeacherTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading, iconsState} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading,
        iconsState: state.icons.iconsState
    }), []));
    console.log("euglya", iconsState)

    const [materialsExpanded, setMaterialsExpanded] = useState(ls('mat_expanded') != 'no');
    const [menuVisible, setMenuVisible] = useState(false);
    const [aiModalVisible, setAiModalVisible] = useState(false);

    useEffect(() => {
        if (materialsExpanded == true) {
            ls('mat_expanded', 'yes');
        } else {
            ls('mat_expanded', 'no');
        }
    }, [materialsExpanded]);

    useEffect(() => {
        let chat = document.getElementsByClassName("woot--bubble-holder");
        if (chat.length > 0) {
            chat = chat[0]
        } else {
            chat = undefined
        }
        console.log("euglya", chat)
        if (chat != undefined) {
            if (iconsState == false) {
                chat.style.visibility = "hidden"
            } else {
                chat.style.visibility = "visible"
            }
        }

    }, [iconsState])

    if (currentUser == undefined) {
        return null;
    }

    const {email, firstName, lastName, avatar, name, userRole, lastRating} = currentUser;
    const ava = getFixedUrl((avatar == undefined) ? FACELESS_AVATAR : avatar);
    let isTeacher = (userRole == 'teacher');
    let isStudent = (userRole == 'student');
    let isAdmin = (userRole == 'admin');
    let isMethodist = (userRole == 'methodist');
    let links = {
        student: STUDENT_LINKS,
        teacher: TEACHER_LINKS,
        admin: ADMIN_LINKS
    }[userRole == undefined ? 'student' : userRole];
    if (isMethodist == true) {
        links = METHODIST_LINKS;
    }
    if (links == undefined) {
        links = [];
    }
    console.log('active = ', active);

    return (
        <Wrapper>
            {hasLeft == false ? null :
                <Left>

                    {/*<PayWallPlaceholder>*/}
                    {/*    <div style={{color: 'white'}} >*/}
                    {/*        <h1>*/}
                    {/*            {`До окончания работы платформы осталось 5 дней 6 часов 7 минут 15 секунд`}*/}
                    {/*        </h1>*/}
                    {/*    </div>*/}
                    {/*</PayWallPlaceholder>*/}

                    <LogoDiv/>

                    <UserWrapper>
                        <CurrentUserPlaceholder onClick={() => {

                        }}>
                            <CurrentUserAvatarPlaceholder src={ava} onClick={() => {
                                CommonHelper.linkTo('/profile');
                            }}/>
                            <CurrentUserInfo>
                                <UserName onClick={() => {
                                    CommonHelper.linkTo('/profile');
                                }}>
                                    {CommonHelper.getUserName(currentUser)}
                                </UserName>
                                <UserRating>
                                    <span style={{cursor: 'pointer'}} onClick={() => {
                                        CommonHelper.linkTo('/profile');
                                    }}>
                                        {CommonHelper.getRuNameOfRole(userRole)}
                                    </span>
                                    <span style={{marginLeft: 10, marginRight: 10}}>
                                        <LangSwitcherDropdown/>
                                    </span>
                                </UserRating>
                            </CurrentUserInfo>
                        </CurrentUserPlaceholder>
                        <Button onClick={() => {
                            setMenuVisible(!menuVisible);
                        }}>
                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="1" x2="23" y2="1" stroke="#FFA71B" stroke-width="2"
                                      stroke-linecap="round"/>
                                <line x1="1" y1="9" x2="23" y2="9" stroke="#FFA71B" stroke-width="2"
                                      stroke-linecap="round"/>
                                <line x1="1" y1="17" x2="23" y2="17" stroke="#FFA71B" stroke-width="2"
                                      stroke-linecap="round"/>
                            </svg>

                        </Button>
                    </UserWrapper>

                    <LeftContent>
                        {menuVisible == false && isMobile() ? null :
                            <TopLinksPlaceholder>
                                {renderLinks(links.filter(a => (a.isTop == true)), active)}

                                {userRole != 'teacher' ? null :
                                    <HidingLinksPlaceholder>
                                        <MaterialsPlaceholder>
                                            {renderLinks([MATERIALS_LINK], active, () => {
                                                console.log('materialsExpanded = ', materialsExpanded);
                                                setMaterialsExpanded(!materialsExpanded);
                                            })}
                                            {!isMobile() ? null :
                                                <img src={chevron_svg} style={{
                                                    maxWidth: '40px',
                                                    transform: materialsExpanded ? 'rotate(180deg)' : null,
                                                    opacity: '0.5',
                                                    marginRight: '5%'
                                                }}>

                                                </img>
                                            }
                                        </MaterialsPlaceholder>
                                        <HidingLinksMainPlaceholder expanded={materialsExpanded}
                                                                    style={{marginLeft: window.innerWidth < 720 ? '8%' : null}}>
                                            {renderLinks(EXERCISES_LINKS.filter(a => (a.isTop == true)), active)}
                                        </HidingLinksMainPlaceholder>
                                    </HidingLinksPlaceholder>
                                }
                            </TopLinksPlaceholder>
                        }

                        {menuVisible == false && isMobile() ? null :
                            <BottomLinksPlaceholder>
                                {renderLinks(links.filter(a => (a.isTop != true)), active)}
                            </BottomLinksPlaceholder>
                        }

                        {menuVisible == false && isMobile() ? null :

                            <VeryBottomLinksPlaceholder>
                                {isTeacher == false ? null :
                                    <BottomLink selected={(active == 'settings')} onClick={() => {
                                        CommonHelper.linkTo(`/tools`);
                                    }}>
                                        {translate('tools')}
                                    </BottomLink>
                                }

                                {/*{isTeacher == false ? null :*/}
                                {/*    <BottomLink selected={(active == 'settings')} onClick={() => {*/}
                                {/*        CommonHelper.linkTo(`/settings`);*/}
                                {/*    }}>*/}
                                {/*        {translate('settings')}*/}
                                {/*    </BottomLink>*/}
                                {/*}*/}

                                {/*<BottomLangSwitcherPlaceholder>*/}
                                {/*    <LangSwitcher/>*/}
                                {/*</BottomLangSwitcherPlaceholder>*/}

                                <BottomLink onClick={async () => {
                                    let pld = await dispatch(usersActions.logOut());
                                    if (pld.error == undefined) {
                                        CommonHelper.linkTo('/');
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 100);
                                    }
                                }}>
                                    {translate('logout')}
                                </BottomLink>
                            </VeryBottomLinksPlaceholder>
                        }
                    </LeftContent>
                </Left>
            }

            <Right id={'template_right_content'}>
                {props.children}
            </Right>

            <Toaster/>
            {!iconsState ? null :
                <>
                    <AiBubble onClick={() => {
                        setAiModalVisible(true);
                    }}>
                        <img src={ai_img} style={{height: '40px'}}/>
                    </AiBubble>

                    {aiModalVisible == false ? null :
                        <AiModalPlaceholder>
                            <AiModalInner>
                                <AiModalTop>
                                    <CloseImg
                                        src={close_x_image}
                                        onClick={() => {
                                            setAiModalVisible(false);
                                        }}
                                    />
                                </AiModalTop>
                                <AiModalContent>
                                    <UserConversationsPanel

                                    />
                                </AiModalContent>
                            </AiModalInner>
                        </AiModalPlaceholder>
                    }
                </>
            }

            {/*{(currentUser == undefined || currentUser?.userRole != 'teacher') ? null :*/}
            {/*    <PaywallQuestionnaireTool*/}
            {/*        userId={currentUser?.id}*/}
            {/*    />*/}
            {/*}*/}


        </Wrapper>
    );
}

const renderLinks = (links, active, onClick = () => {

}) => links.map((a, i) => {
    let isActive = (active == a.name);
    return (
        <LinkItem selected={isActive} key={`${a.name}_${i}`} onClick={() => {
            if (a.name == 'ideas') {
                try {
                    window.open('https://forms.yandex.ru/cloud/624293534e3d8c2d8172352c/', '_blank').focus();
                } catch (exc) {

                }
            } else {
                if (a.link == undefined) {
                    onClick();
                } else {
                    CommonHelper.linkTo(a.link);
                }

            }

        }}>
            {/*<LinkImage src={a.icon} selectedSrc={a.selectedIcon} selected={(isActive)}/>*/}
            <LinkImage src={a.emojiIcon} selectedSrc={a.emojiIcon} selected={(isActive)}/>
            <LinkLabel>
                {a.label}
            </LinkLabel>
        </LinkItem>
    )
});


const CloseImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;

  :hover {
    opacity: 0.7;
  }
`;

const AiModalTop = styled.div`
  height: 40px;
  width: 100%;
`;

const AiModalContent = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  background: white;
`;


const AiModalPlaceholder = styled.div`
  width: 600px;
  height: calc(max(100vh - 400px, 400px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 2000001;
`;

const AiModalInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const AiBubble = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  background: #FFA518;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  right: 20px;
  cursor: pointer;
  z-index: 2000000;
`;

const topHeight = 70;
const leftWidth = 300;

const TopLinksPlaceholder = styled.div`
  border-bottom: 1px solid rgba(133, 147, 168, 0.51);
  margin-bottom: 10px;
  padding-bottom: 10px;
  @media (max-width: 720px) {
    //display: flex;
    //flex-direction: row;
    //align-items: center;
    font-size: 12px;
    border-bottom: none !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const BottomLinksPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 12px;
  }
`;

const MaterialsPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const VeryBottomLinksPlaceholder = styled.div`
  box-sizing: border-box;
  padding-left: 30px;
  margin-bottom: 50px;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 22px;
    margin-bottom: 0px;
  }
`;

const BottomLink = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};

  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  :hover {
    color: #FFA71B;
  }
`;

const BottomLangSwitcherPlaceholder = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;
  color: #8593A8;
`;

const LinkItem = styled.div`
  padding-left: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};
  border-left: 8px solid ${props => (props.selected ? '#FFA71B' : 'transparent')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    border-left: 8px solid #FFA71B;
    color: #FFA71B;
  }

  @media (max-width: 720px) {
    border-left: none !important;

    height: 48px;
  }

`;

const LinkImage = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => (props.selected == true ? props.selectedSrc : props.src)});

  ${LinkItem}:hover & {
    background-image: url(${props => props.selectedSrc});
  }

  @media (max-width: 720px) {
    display: none;
  }

`;

const PayWallPlaceholder = styled.div`
  position: fixed;
  top: 0px;
  left: 300px;
  right: 0px;
  height: 100px;
  z-index: 200;
  background: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LinkLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 18px;
  }
`;


const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #EEF4F9;
  //font-family: Montserrat;
  font-family: sans-serif;
  @media (max-width: 720px) {
    flex-direction: column;
    height: auto;
  }
`;

const Left = styled.div`
  width: ${leftWidth}px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: white;
  @media (max-width: 720px) {
    width: 100vw;
    max-height: 100%;
    height: 100%;
    height: auto;
    flex-direction: column;
  }
`;

const LogoPlaceholder = styled.div`
  width: 100%;
  height: ${topHeight}px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const LeftContent = styled.div`
  height: calc(100vh - ${topHeight}px);
  max-height: calc(100vh - ${topHeight}px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 25px;
  @media (max-width: 720px) {
    height: auto;
    max-height: none;
    padding-top: 0px;
    overflow-y: hidden;
    //padding-top: 5px;
    //display: flex;
    //flex-direction: row;
  }
`;

const CurrentUserPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 720px) {
    width: auto;
  }
  //cursor: pointer;

  //:hover {
  //  opacity: 0.7;
  //}
`;

const CurrentUserAvatarPlaceholder = styled.div`
  height: 45px;
  width: 45px;
  background-color: lightgrey;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
  margin-right: 9px;
`;

const CurrentUserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
`;

const UserName = styled.div`
  color: #333333;
  font-weight: bold;
  cursor: pointer;
`;

const UserRating = styled.div`
  color: #939598;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Right = styled.div`
  height: 100vh;
  max-height: 100vh;
    // width: calc(100vw - ${leftWidth}px);
  overflow-y: auto;
  flex: 1;

  @media (max-width: 720px) {
    overflow-y: hidden;

    height: 0;
    max-height: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Button = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: block;
    //{position: absolute; right: 17%; top: 35px;}

  }
`;

const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  padding-bottom: 0px;

  @media (max-width: 720px) {
    margin-bottom: 15px;
  }

`;

const HidingLinksMainPlaceholder = styled.div`
  display: ${props => (props.expanded == true ? 'block' : 'none')};
`;

const HidingLinksPlaceholder = styled.div`

`;


const MATERIALS_LINK = {
    name: 'all-materials',
    label: translate('exercises_and_materials'),
    emojiIcon: library_svg,
    isTop: true
}

const EXERCISES_LINKS = [
    {
        name: 'cards',
        link: '/cards',
        label: translate('cards_exercises'),
        emojiIcon: cards_svg,
        isTop: true
    },
    {
        name: 'materials',
        link: '/materials',
        label: translate('video_library'),
        emojiIcon: video_svg,
        isTop: true
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: translate('exercises_library'),
        emojiIcon: task_management_svg,
        isTop: true
    },
    {
        name: 'dialogs',
        link: '/dialogs',
        label: translate('dialogs_exercises'),
        emojiIcon: dialog_svg,
        isTop: true
    },
    {
        name: 'questionnaires',
        link: '/questionnaires',
        label: translate('questionnaires_exercises'),
        emojiIcon: questionnaire_svg,
        isTop: true
    },
    {
        name: 'treadmills',
        link: '/treadmills',
        label: translate('treadmills_exercises'),
        emojiIcon: treadmill_svg,
        isTop: true
    },
    {
        name: 'tests',
        link: '/tests',
        label: translate('tests_exercises'),
        emojiIcon: quiz_test_svg,
        isTop: true
    }

]

const TEACHER_LINKS = [
    // {
    //     name: 'index',
    //     link: '/',
    //     label: 'Главная',
    //     emojiIcon: emoji_home,
    //     isTop: true
    // },
    {
        name: 'posts',
        link: '/',
        label: translate('posts'),
        emojiIcon: emoji_newspaper,
        isTop: true
    },

    {
        name: 'index',
        link: '/groups',
        label: translate('my_groups'),
        emojiIcon: emoji_group,
        isTop: true
    },

    // {
    //     name: 'students',
    //     link: '/students',
    //     label: 'Мои ученики',
    //     emojiIcon: smart_cap,
    //     isTop: true
    // },

    {
        name: 'courses',
        link: '/courses',
        label: translate('courses_and_modules'),
        emojiIcon: emoji_courses,
        isTop: true
    },


    // {
    //     name: 'schedule',
    //     link: '/schedule',
    //     label: 'Расписание',
    //     emojiIcon: emoji_cal,
    //     isTop: true
    // },

    // {
    //     name: 'exercises',
    //     link: '/exercises',
    //     label: 'Упражнения',
    //     emojiIcon: emoji_exercises,
    // },
    //
    // {
    //     name: 'tests',
    //     link: '/tests',
    //     label: 'Тесты',
    //     emojiIcon: emoji_tests
    // },
    //
    // {
    //     name: 'events',
    //     link: '/events',
    //     label: 'Мероприятия',
    //     emojiIcon: emoji_events,
    // },

    // {
    //     name: 'instructions',
    //     link: '/instructions',
    //     label: translate('instructions'),
    //     emojiIcon: emoji_knol
    // },
    //
    // {
    //     name: 'legal',
    //     link: '/legal',
    //     label: translate('legal_information'),
    //     emojiIcon: legal_image
    // },
    //
    // {
    //     name: 'ideas',
    //     link: '/ideas',
    //     label: translate('ideas'),
    //     emojiIcon: emoji_tournaments
    // }

    // {
    //     name: 'friends',
    //     link: '/friends',
    //     label: 'Мои друзья',
    //     emojiIcon: emoji_friends
    // },
    //
    // {
    //     name: 'rating',
    //     link: '/rating',
    //     label: 'Рейтинг',
    //     emojiIcon: require('./images/icons/emoji_rating.svg'),
    //     selectedIcon: require('./images/icons/sel_rating.svg'),
    //     icon: require('./images/icons/rating.svg'),
    // },

    // {
    //     name: 'tournaments',
    //     link: '/tournaments',
    //     label: 'Турниры',
    //     emojiIcon: require('./images/icons/emoji_tournaments.svg'),
    //     selectedIcon: require('./images/icons/sel_tournaments.svg'),
    //     icon: require('./images/icons/tournaments.svg'),
    // }
];


const STUDENT_LINKS = [
    {
        name: 'posts',
        link: '/',
        label: translate('posts'),
        emojiIcon: emoji_newspaper,
        isTop: true
    },
    {
        name: 'index',
        link: '/groups',
        label: translate('groups'),
        emojiIcon: emoji_cal,
        isTop: true
    },
    {
        name: 'homework',
        link: '/homework',
        label: translate('homework'),
        emojiIcon: emoji_home,
        isTop: true
    },
    {
        name: 'diary',
        link: `/diary`,
        label: translate('diary'),
        emojiIcon: diary,
        isTop: true
    },
    // UIHelper.isDictionaryAvailable() == false ? undefined :
    //     {
    //         name: 'vocabulary',
    //         link: '/vocabulary',
    //         label: translate('vocabulary'),
    //         emojiIcon: emoji_courses,
    //         isTop: true
    //     },
    // {
    //     name: 'legal',
    //     link: '/legal',
    //     label: translate('legal_information'), // todo: add translations
    //     emojiIcon: legal_image
    // },
    // {
    //     name: 'instructions',
    //     link: '/instructions',
    //     label: translate('instructions'),
    //     emojiIcon: emoji_knol
    // },
    // {
    //     name: 'exercises',
    //     link: '/exercises',
    //     label: 'Тренажерный зал',
    //     emojiIcon: emoji_exercises,
    //     isTop: true
    // },
    // {
    //     name: 'courses',
    //     link: '/courses',
    //     label: 'Курсы',
    //     emojiIcon: emoji_courses,
    //     isTop: true
    // },

    // {
    //     name: 'courses',
    //     link: '/courses',
    //     label: 'Мои курсы',
    //     emojiIcon: require('./images/icons/emoji_courses.svg'),
    //     selectedIcon: require('./images/icons/sel_courses.svg'),
    //     icon: require('./images/icons/courses.svg'),
    //     isTop: true
    // },
    // {
    //     name: 'groups',
    //     link: '/groups',
    //     label: 'Мои группы',
    //     emojiIcon: emoji_group,
    //     isTop: true
    // },

    // {
    //     name: 'trainers',
    //     link: '/trainers',
    //     label: 'Тренеры',
    //     emojiIcon: require('./images/icons/emoji_trainers.svg'),
    //     selectedIcon: require('./images/icons/sel_trainers.svg'),
    //     icon: require('./images/icons/trainers.svg'),
    // },


    // {
    //     name: 'events',
    //     link: '/events',
    //     label: 'Мероприятия',
    //     emojiIcon: require('./images/icons/emoji_events.svg'),
    //     selectedIcon: require('./images/icons/sel_events.svg'),
    //     icon: require('./images/icons/events.svg'),
    // },

    // {
    //     name: 'knowledge',
    //     link: '/knowledge',
    //     label: 'База знаний',
    //     emojiIcon: require('./images/icons/emoji_knol.svg'),
    //     selectedIcon: require('./images/icons/sel_knol.svg'),
    //     icon: require('./images/icons/knol.svg'),
    // },

    // {
    //     name: 'friends',
    //     link: '/friends',
    //     label: 'Мои друзья',
    //     emojiIcon: require('./images/icons/emoji_friends.svg'),
    //     selectedIcon: require('./images/icons/sel_friends.svg'),
    //     icon: require('./images/icons/friends.svg'),
    // },

    // {
    //     name: 'rating',
    //     link: '/rating',
    //     label: 'Рейтинг',
    //     emojiIcon: require('./images/icons/emoji_rating.svg'),
    //     selectedIcon: require('./images/icons/sel_rating.svg'),
    //     icon: require('./images/icons/rating.svg'),
    // },
].filter(x => (x != undefined));


const ADMIN_LINKS = [
    {
        name: 'index',
        link: '/',
        label: translate('main_page'),
        emojiIcon: emoji_cal,
        isTop: true
    },
    {
        name: 'schedule',
        link: '/schedule',
        label: 'Мое расписание',
        emojiIcon: emoji_home,
        isTop: true
    },
    {
        name: 'courses',
        link: '/courses',
        label: 'Курсы',
        emojiIcon: emoji_courses,
        isTop: true
    },
    {
        name: 'groups',
        link: '/groups',
        label: 'Мои группы',
        emojiIcon: emoji_group,
        isTop: true
    },

    {
        name: 'trainers',
        link: '/trainers',
        label: 'Тренеры',
        emojiIcon: emoji_trainers
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: 'Тренажерный зал',
        emojiIcon: emoji_exercises,
    },

    {
        name: 'events',
        link: '/events',
        label: 'Мероприятия',
        emojiIcon: emoji_events,
    },

    {
        name: 'knowledge',
        link: '/knowledge',
        label: 'База знаний',
        emojiIcon: require('./images/icons/emoji_knol.svg'),
        selectedIcon: require('./images/icons/sel_knol.svg'),
        icon: require('./images/icons/knol.svg'),
    },

    {
        name: 'friends',
        link: '/friends',
        label: 'Мои друзья',
        emojiIcon: require('./images/icons/emoji_friends.svg'),
        selectedIcon: require('./images/icons/sel_friends.svg'),
        icon: require('./images/icons/friends.svg'),
    },

    {
        name: 'rating',
        link: '/rating',
        label: 'Рейтинг',
        emojiIcon: emoji_rating,
    },

    {
        name: 'tournaments',
        link: '/tournaments',
        label: 'Турниры',
        emojiIcon: emoji_tournaments,
    }
];

const METHODIST_LINKS = [
    {
        name: 'index',
        link: '/',
        label: translate('main_page'),
        emojiIcon: require('./images/icons/emoji_cal.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    },
    {
        name: 'courses',
        link: '/courses',
        label: 'Courses',
        emojiIcon: require('./images/icons/emoji_courses.svg'),
        selectedIcon: require('./images/icons/sel_courses.svg'),
        icon: require('./images/icons/courses.svg'),
        isTop: true
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: 'Exercises',
        emojiIcon: require('./images/icons/emoji_exercises.svg'),
        selectedIcon: require('./images/icons/sel_exercises.svg'),
        icon: require('./images/icons/exercises.svg'),
    }
];
