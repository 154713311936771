import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

const OPTIONS = [
    {
        label: 'n',
        value: 'n'
    },
    {
        label: 'v',
        value: 'v'
    },
    {
        label: 'mv',
        value: 'mv'
    },
    {
        label: 'adj',
        value: 'adj'
    },
    {
        label: 'adv',
        value: 'adv'
    },
    {
        label: 'phv',
        value: 'phv'
    },
    {
        label: 'phr',
        value: 'phr'
    },
    {
        label: 'idiom',
        value: 'idiom'
    },
    {
        label: 'pron',
        value: 'pron'
    },
    {
        label: 'prep',
        value: 'prep'
    },
    {
        label: 'det',
        value: 'det'
    }
]

export default function WordTypesSelector(props) {
    const {
        types = [],
        onChange = newTypes => {

        }
    } = props;

    return (
        <Wrapper>

            <TypesPlaceholder>
                {OPTIONS.map((a, i) => {
                    let isSelected = (types.indexOf(a.value) > -1);
                    return (
                        <Item key={a.value} selected={isSelected} onClick={() => {
                            if (isSelected) {
                                onChange(types.filter(xx => ((xx == undefined ? '' : xx) != a.value)));
                            } else {
                                let newTypes = types.concat([a.value]);
                                newTypes = newTypes.sort();
                                onChange(newTypes);
                            }
                        }}>
                            {a.label}
                        </Item>
                    )
                })}
            </TypesPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Item = styled.div`
  margin: 3px;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  padding: 10px;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const TypesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
