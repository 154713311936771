import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import AiAssistantsTool from "../tools/AiAssistantsTool";

export default function UserAssistantsPanel(props) {
    const {userId, groups = []} = useMappedState(useCallback(state => {
        return {
            userId: state.users.currentUserId,
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            })
        }
    }))
    const dispatch = useDispatch();

    if (userId == undefined){
        return null;
    }

    return (
        <Wrapper>
            <AiAssistantsTool
                userId={userId}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;