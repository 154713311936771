import * as types from '../ActionTypes'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    foldersMap: Map(),

    selectedFolderId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

export function getGracefullyMergedFoldersMap(oldMap, newFolders) {
    let newMap = oldMap;
    for (let i in newFolders) {
        let newCont = newFolders[i];
        let oldFolder = oldMap.get(newCont.id);
        if (newCont == undefined) {
            continue;
        }
        if (oldFolder != undefined) {
            newMap = newMap.set(oldFolder.id, {...oldFolder, ...newCont});
        } else {
            newMap = newMap.set(newCont.id, newCont);
        }
    }
    return newMap;
}

const FoldersReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.SMART_CREATE_FOLDER:
        case types.SMART_UPDATE_FOLDER:
        case types.SMART_DELETE_FOLDER:
        case types.SMART_LOAD_FOLDERS:
            return startLoading(state, action)

        case types.SMART_CREATE_FOLDER_FAIL:
        case types.SMART_UPDATE_FOLDER_FAIL:
        case types.SMART_DELETE_FOLDER_FAIL:
        case types.SMART_LOAD_FOLDERS_FAIL:
            return stopLoading(state, action)

        case types.SMART_UPDATE_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                foldersMap: state.foldersMap.set(action.folder.id, action.folder)
            }

        case types.SMART_CREATE_FOLDER_SUCCESS:
            let parent = state.foldersMap.get(action?.parentId);
            let affectedFolders = [];
            if (parent != undefined) {
                let newIds = (parent?.childrenIds || []).concat([action.folder.id]);
                affectedFolders = affectedFolders.concat([{...parent, childrenIds: newIds}]) // adding to parent
            }
            affectedFolders = affectedFolders.concat([action.folder]);
            return {
                ...state,
                loading: false,
                foldersMap: getGracefullyMergedFoldersMap(state.foldersMap, affectedFolders)
            }

        case types.SMART_DELETE_FOLDER_SUCCESS:
            let dParent = state.foldersMap.get(action?.parentId);
            let aFolders = [];
            if (dParent != undefined) {
                let newIds = (dParent?.childrenIds || []).filter(x => (x != action.id)); // removing from parent
                aFolders = aFolders.concat([{...dParent, childrenIds: newIds}]);
            }
            return {
                ...state,
                loading: false,
                foldersMap: getGracefullyMergedFoldersMap(state.foldersMap, aFolders).delete(action.id)
            }

        case types.SMART_LOAD_FOLDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                foldersMap: getGracefullyMergedFoldersMap(state.foldersMap, action.folders)
            }

        default:
            return state;

    }

}

export default FoldersReducer;