import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import UsersAPI from "../../../api/UsersAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import {FACELESS_AVATAR} from "../../../constants/config";
import Tabs, {TabItem} from "../../ui/Tabs";
import AllVideoMaterialsPanel from "../../materials/panels/AllVideoMaterialsPanel";
import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";
import AllDrochkaExercisesPanel from "../../drochka_exercise/panels/AllDrochkaExercisesPanel";
import AllDialogsExercisesPanel from "../../dialogs/panels/AllDialogsExercisesPanel";
import AllQuestionnairesPanel from "../../questionnaires/panels/AllQuestionnairesPanel";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

export default function ViewAuthorTool(props) {
    const {
        userId,
        badgeMode = true
    } = props;
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        UsersAPI.getUser(userId).then(d => {
            console.log('user info = ', d);
            setUserInfo(d);
            setLoading(false);
        })
    }, [userId]);

    if (loading == true && userInfo == undefined) {
        return (<span>...</span>)
    }
    if (userInfo == undefined) {
        return null;
    }
    let name = CommonHelper.getUserName(userInfo);
    let {avatar, email} = userInfo;

    return (
        <Wrapper>

            {badgeMode == false ? <InfoSpan onClick={() => {
                    setModalVisible(true);
                }}>
                    About author
                </InfoSpan> :
                <InnerPlaceholder onClick={() => {
                    setModalVisible(true);
                }}>
                    <Avatar src={getFixedUrl(avatar || FACELESS_AVATAR)}/>
                    <UserInfo>
                        <UserName>
                            {name}
                        </UserName>
                        <div>
                            {email}
                        </div>
                    </UserInfo>
                </InnerPlaceholder>
            }


            <Sidebar
                width={Math.min(920, window.innerWidth)}
                onCloserClick={() => {
                    setModalVisible(false);
                }}
                visible={modalVisible}>
                {modalVisible == false ? null :
                    <InnerModalPlaceholder>
                        <div style={{textAlign: 'center'}}>
                            <Heading>
                                Information about author
                            </Heading>
                            <p>
                                {name}
                            </p>
                            <p>
                                {`email: ${email}`}
                            </p>
                            <p>
                                {`Дата регистрации ${moment(+userInfo.timestamp).format('DD.MM.YYYY')}`}
                            </p>
                        </div>

                        <Tabs tabs={[
                            {
                                label: 'Exericses',
                                content: (
                                    <TabItem>
                                        <AllExercisesPanel
                                            mode={'all'}
                                            creatorId={userId}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Cards',
                                content: (
                                    <TabItem>
                                        <AllDrochkaExercisesPanel
                                            mode={'all'}
                                            creatorId={userId}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Dialogs',
                                content: (
                                    <TabItem>
                                        <AllDialogsExercisesPanel
                                            mode={'all'}
                                            creatorId={userId}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Questionnaires',
                                content: (
                                    <TabItem>
                                        <AllQuestionnairesPanel
                                            mode={'all'}
                                            creatorId={userId}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Videos',
                                content: (
                                    <TabItem>
                                        <AllVideoMaterialsPanel
                                            mode={'all'}
                                            creatorId={userId}
                                        />
                                    </TabItem>
                                )
                            }
                        ]}/>
                    </InnerModalPlaceholder>
                }
            </Sidebar>

        </Wrapper>
    );
}

const InfoSpan = styled.div`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const InnerModalPlaceholder = styled.div`

`;

const Heading = styled.h3`

`;

const InnerPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
`;

const UserInfo = styled.div`

`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${props => props.src}');
  margin-right: 5px;
`;

const Wrapper = styled.div`

`;