import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Tabs, {TabItem} from "../../ui/Tabs";

import UpdateAccessGroupForm from "../forms/UpdateAccessGroupForm";

import * as actions from '../../../redux/actions/AccessGroupsActions'
// import {OutlinedButton} from "../../ui/Button";

import ls from 'local-storage'

import {useDispatch, useMappedState} from "redux-react-hook";
import * as slidesActions from "../../../redux/actions/SlidesActions";
import DropdownDotsMenu from "../../ui/DropdownDotsMenu";
import {translate} from "../../../utils/Translate";
import RecursiveContainersTree from "../../containers/tools/RecursiveContainersTree";
import {Set} from "immutable";


export default function SharedModulesTreePanel(props) {
    const {
        filteredModulesIds = [],
        searchMode = false
    } = props;
    const {

        groups = [],
        currentUser,
        selectedSlideId
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            groups: state.accessGroups.accessGroupsMap.toArray().filter(x => (x?.modulesIds != undefined && x?.modulesIds.length > 0)),
            currentUser: currentUser,
            selectedSlideId: state.slides.selectedSlideId,
        }
    }));
    const dispatch = useDispatch();
    const [expandedSet, setExpandedSet] = useState((ls('expanded_new_containers') == undefined ? [] : ls('expanded_new_containers')).reduce((st, ss) => st.add(ss), Set()));
    const [loadingItemId, setLoadingItemId] = useState(undefined);

    useEffect(() => {
        dispatch(actions.loadAccessGroups());
    }, []);

    // console.log('SharedModulesTreePanel: render: searchMode = ', searchMode);
    console.log('SharedModulesTreePanel: render: groups = ', groups);
    let xGroups = groups;
    if (searchMode == true){
        let xSet = filteredModulesIds.reduce((st, x) => st.add(x), Set());
        xGroups = xGroups.filter(xx => (xx.modulesIds || []).filter(xx => xSet.has(xx)).length > 0);
        // console.log('SharedModulesTreePanel: render: xGroups = ', xGroups);
    }

    return (
        <Wrapper>

            <TopNamePlaceholder>
                <TopLeft>
                    Shared
                </TopLeft>

            </TopNamePlaceholder>

            <RecursiveContainersTree
                filteredModulesIds={filteredModulesIds}
                searchMode={searchMode}
                isSharedTree={true}
                containers={xGroups.map(x => ({...x, type: 'folder'}))}
                expandedSet={expandedSet}
                loadingItemId={loadingItemId}
                expandToggle={async exId => {
                    console.log('expandToggle: exId = ', exId);
                    let isExpanded = !expandedSet.has(exId);
                    let newExpandedSet = expandedSet.has(exId) ? expandedSet.delete(exId) : expandedSet.add(exId);
                    ls('expanded_new_containers', newExpandedSet.toArray());
                    setExpandedSet(newExpandedSet);
                }}
                onContainerClick={cId => {

                }}
                onModuleClick={slideId => {
                    if (selectedSlideId == slideId) {
                        dispatch(slidesActions.selectSlide(undefined));
                    } else {
                        dispatch(slidesActions.selectSlide(slideId));
                    }
                }}
            />

        </Wrapper>
    );
}

const TopNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-weight: bold;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 10px;
`;
