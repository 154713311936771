import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Input} from "../../ira/ui/Inputs";
import {GreenButton} from "../../ira/ui/Buttons";
import Spin from "arui-feather/spin";
import {translate} from "../../../utils/Translate";
import Sidebar from "arui-feather/sidebar";
import UpdateAvatarTool from "../../avatar/tools/UpdateAvatarTool";
import NiceModal from "../../modals/NiceModal";
import Select from "react-select";
import {countries, educations, universities, faculties, languages} from "../../../utils/lists/lists"

const findValueInList = (value, list) => {
    for (let item of list) {
        if (item.value == value) {
            return item;
        }
    }
}

export default function UpdateProfileForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        hasTitle = true,
        isAbiturient = false
    } = props;

    const [firstName, setFirstName] = useState(props?.firstName ? props?.firstName : '');
    const [lastName, setLastName] = useState(props?.lastName ? props?.lastName : '');
    const [phone, setPhone] = useState(props?.phone ? props?.phone : '');
    const [country, setCountry] = useState(props?.country ? props?.country : '');
    const [nativeLanguage, setNativeLanguage] = useState(props?.nativeLanguage ? props?.nativeLanguage : '');
    const [education, setEducation] = useState(props?.education ? props?.education : '');
    const [university, setUniversity] = useState(props?.university ? props?.university : '');
    const [faculty, setFaculty] = useState(props?.faculty ? props?.faculty : '');
    const [avatar, setAvatar] = useState(props?.avatar);
    const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  

    const [city, setCity] = useState(props?.city ? props?.city : '');
    const [eduOrgName, setEduOrgName] = useState(props?.eduOrgName ? props?.eduOrgName : '');
    const [classNumber, setClassNumber] = useState(props?.classNumber ? props?.classNumber : '');
    const [teacherName, setTeacherName] = useState(props?.teacherName ? props?.teacherName : '');
    const [teacherEmail, setTeacherEmail] = useState(props?.teacherEmail ? props?.teacherEmail : '');
    const [foreignLang, setForeignLang] = useState(props?.foreignLang ? props?.foreignLang : '');

    console.log("euglya -> ", nativeLanguage, country, education)

    return (
        <Wrapper>

            {hasTitle == false ? null :
                <Heading>
                    {translate('profile_update')}
                </Heading>
            }

            <Row>
                <Label>
                    {translate('name')}
                </Label>
                <Input
                    value={firstName}
                    onChange={evt => {
                        setFirstName(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('last_name')}
                </Label>
                <Input
                    value={lastName}
                    onChange={evt => {
                        setLastName(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('phone')}
                </Label>
                <Input
                    value={phone}
                    onChange={evt => {
                        setPhone(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {/*Страна*/}
                    Country
                </Label>
                <Select
                        value={findValueInList(country, countries)}
                        options={countries}
                        onChange={x => {
                            setCountry(x.value);
                        }}
                />
            </Row>

            {/*<Row>*/}
            {/*    <Label>*/}
            {/*        Изучаемый на платформе язык*/}
            {/*    </Label>*/}
            {/*    <Select*/}
            {/*            value={findValueInList(foreignLang, languages)}*/}
            {/*            options={languages}*/}
            {/*            onChange={x => {*/}
            {/*                setForeignLang(x.value);*/}
            {/*            }}*/}
            {/*    />*/}
            {/*</Row>*/}

            {/*<Row>*/}
            {/*    <Label>*/}
            {/*        Родной язык*/}
            {/*    </Label>*/}
            {/*    <Select*/}
            {/*            value={findValueInList(nativeLanguage, languages)}*/}
            {/*            options={languages}*/}
            {/*            onChange={x => {*/}
            {/*                setNativeLanguage(x.value);*/}
            {/*            }}*/}
            {/*    />*/}
            {/*</Row>*/}

            {/*<Row>*/}
            {/*    <Label>*/}
            {/*        Учебное заведение*/}
            {/*    </Label>*/}
            {/*    <Select*/}
            {/*            value={findValueInList(education, educations)}*/}
            {/*            options={educations}*/}
            {/*            onChange={x => {*/}
            {/*                setEducation(x.value);*/}
            {/*                console.log("euglya ->", education)*/}
            {/*            }}*/}
            {/*    />*/}
            {/*</Row>*/}

            {/*{education != 'university' ? null :*/}
            {/*    <>*/}
            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Университет*/}
            {/*            </Label>*/}
            {/*            <Select*/}
            {/*                value={findValueInList(university, universities)}*/}
            {/*                options={universities}*/}
            {/*                onChange={x => {*/}
            {/*                    setUniversity(x.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}
            {/*    </>*/}
            {/*}*/}

            {/*{!university || education == 'school' || education == 'persStudent' ? null :*/}
            {/*    <>*/}
            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Факультет*/}
            {/*            </Label>*/}
            {/*            {}*/}
            {/*            <Select*/}
            {/*                value={findValueInList(faculty, faculties[university])}*/}
            {/*                options={faculties[university]}*/}
            {/*                onChange={x => {*/}
            {/*                    setFaculty(x.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}
            {/*    </>*/}
            {/*}*/}

            {/*{isAbiturient == false ? null :*/}
            {/*    <>*/}
            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Город*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={city}*/}
            {/*                onChange={evt => {*/}
            {/*                    setCity(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Полное название вашего образовательного учреждения*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={eduOrgName}*/}
            {/*                onChange={evt => {*/}
            {/*                    setEduOrgName(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Класс, в котором вы учитесь*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={classNumber}*/}
            {/*                onChange={evt => {*/}
            {/*                    setClassNumber(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Имя, отчество и фамилия учителя, который может подтвердить, что вы школьник 9-11 класса*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={teacherName}*/}
            {/*                onChange={evt => {*/}
            {/*                    setTeacherName(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Электронный адрес учителя, который может подтвердить, что вы школьник 9-11 класса*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={teacherEmail}*/}
            {/*                onChange={evt => {*/}
            {/*                    setTeacherEmail(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*        <Row>*/}
            {/*            <Label>*/}
            {/*                Иностранный язык, который вы изучаете (можно указать несколько)*/}
            {/*            </Label>*/}
            {/*            <Input*/}
            {/*                value={foreignLang}*/}
            {/*                onChange={evt => {*/}
            {/*                    setForeignLang(evt.target.value);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Row>*/}

            {/*    </>*/}
            {/*}*/}

            <Row>
                <Label>
                    {translate('avatar')}
                </Label>
                <div>
                    {avatar == undefined ? 'No avatar' :
                        <Avatar src={avatar}/>
                    }
                </div>
                <ChangeAvatarSpan onClick={() => {
                    setAvatarModalVisible(true);
                }}>
                    {/*изменить аватар*/}
                    change avatar
                </ChangeAvatarSpan>
            </Row>

            <BottomButtonPlaceholder>
                <GreenButton onClick={() => {
                    onSave({
                        firstName,
                        lastName,
                        phone,
                        avatar,

                        city,
                        eduOrgName,
                        teacherName,
                        teacherEmail,
                        classNumber: `${classNumber}`,
                        foreignLang: foreignLang,
                        nativeLanguage: nativeLanguage,
                        education: education,
                        university: university,
                        faculty: faculty,
                        country: country
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        translate('save')
                    }
                </GreenButton>
            </BottomButtonPlaceholder>

            {avatarModalVisible == false ? null :
                <NiceModal
                    width={window.innerWidth}
                    visible={avatarModalVisible}
                    onClose={() => {
                        setAvatarModalVisible(false);
                    }}
                >

                    <AvatarInner>
                        <UpdateAvatarTool
                            avatar={avatar}
                            onChange={s => {
                                setAvatar(s);
                                setAvatarModalVisible(false);
                            }}
                        />
                    </AvatarInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const AvatarInner = styled.div`
  width: 520px;
  max-height: calc(100vh - 120px);

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const ChangeAvatarSpan = styled.span`
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  } 
`;

const Avatar = styled.div`
  width: 160px;
  height: 160px;
  background-color: whitesmoke;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Heading = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
`;

const Wrapper = styled.div`

`;

const BottomButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;