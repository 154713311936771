import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TeacherAccessAPI = {


    async checkIfAccessGranted() {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/user_access`)).data;
            return (pld?.access == undefined ? true : pld.access);
        } catch (exc) {

        }
        return true;
    },

    async getAllTeacherAccesses() {
        let pld = (await axios.get(`${API_ENDPOINT}/user_access_items`)).data;
        return pld;
    },

    async createTeacherAccess(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/user_access_items`, data)).data;
        return pld;
    },

    async updateTeacherAccess(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/user_access_items`, data)).data;
        return pld;
    },

    async deleteTeacherAccess(id) {
        await axios.delete(`${API_ENDPOINT}/user_access_items/${id}`)
    }

}

export default TeacherAccessAPI;
