import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KvdbAPI from "../../../api/KvdbAPI";

import close_img from '../../../assets/img/x.svg'
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import {Input} from "../../ira/ui/Inputs";

export default function AllTagsPanel(props) {
    const {
        prefix = 'grammar'
    } = props;

    const [name, setName] = useState('');

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        KvdbAPI.getValue(`${prefix}_tags`, []).then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, [prefix]);

    if (items.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    const selectedItem = (selectedId == undefined) ? undefined : items.filter(xx => (xx.id == selectedId))[0];

    return (
        <Wrapper>

            <ItemsPlaceholder>
                {items.map((a, i) => {
                    return (
                        <Item key={a.id}>
                            <Name onClick={() => {
                                setSelectedId(a.id);
                                setName(a.name);
                            }}>
                                {a.name}
                            </Name>
                            <ControlsPlaceholder>
                                <CloseImg src={close_img} onClick={async () => {
                                    if (loading == true) {
                                        return;
                                    }
                                    setLoading(true);
                                    await KvdbAPI.saveValue(`${prefix}_tags`, items.filter(xx => (xx.id != a.id)));
                                    let arr = await KvdbAPI.getValue(`${prefix}_tags`, []);
                                    setItems(arr);
                                    setLoading(false);
                                }}/>
                            </ControlsPlaceholder>
                        </Item>
                    )
                })}
            </ItemsPlaceholder>

            <BottomPlaceholder>
                <BlueButton onClick={() => {
                    setAddVisible(true);
                }}>
                    Add
                </BlueButton>
            </BottomPlaceholder>

            {addVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddVisible(false);
                }}>

                    <InnerPlaceholder>
                        <div>
                            <Input value={name}
                                   placeholder={''}
                                   onChange={evt => {
                                       setName(evt.target.value);
                                   }}/>
                        </div>
                        <div style={{marginTop: 10,}}>
                            <GreenButton onClick={async () => {
                                setLoading(true);
                                await KvdbAPI.saveValue(`${prefix}_tags`, items.concat([{
                                    id: `t-${+new Date()}`,
                                    name: name
                                }]));
                                let arr = await KvdbAPI.getValue(`${prefix}_tags`, []);
                                setItems(arr);
                                setLoading(false);
                                setAddVisible(false);
                                setName('');
                            }}>
                                <Spin visible={loading}/>
                                {loading == true ? null :
                                    'Save'
                                }
                            </GreenButton>
                        </div>
                    </InnerPlaceholder>

                </NiceModal>
            }

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <div>
                            <Input value={name}
                                   placeholder={'Tag'}
                                   onChange={evt => {
                                       setName(evt.target.value);
                                   }}/>
                        </div>
                        <div style={{marginTop: 10}}>
                            <GreenButton onClick={async () => {
                                setLoading(true);
                                let newItems = items.map(a => (a.id == selectedItem?.id) ? ({
                                    ...selectedItem,
                                    name: name
                                }) : a);
                                await KvdbAPI.saveValue(`${prefix}_tags`, newItems);
                                let arr = await KvdbAPI.getValue(`${prefix}_tags`, []);
                                setItems(arr);
                                setLoading(false);
                                setSelectedId(undefined);
                                setName('');
                            }}>
                                <Spin visible={loading}/>
                                {loading == true ? null :
                                    'Save'
                                }
                            </GreenButton>
                        </div>
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  width: 420px;
  padding: 20px;
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const CloseImg = styled.img`
  height: 14px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ItemsPlaceholder = styled.div`

`;

const Name = styled.div`
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
`;

const ControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border-radius: 4px;
`;