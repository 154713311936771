import * as types from '../ActionTypes'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    materialsMap: Map(),

    selectedMaterialId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

function getGracefullyMergedContainersMap(oldMap, newContainers) {
    let newMap = oldMap;
    for (let i in newContainers) {
        let newCont = newContainers[i];
        let oldContainer = oldMap.get(newCont.id);
        if (newCont == undefined) {
            continue;
        }
        if (oldContainer != undefined) {
            newMap = newMap.set(oldContainer.id, {...oldContainer, ...newCont});
        } else {
            newMap = newMap.set(newCont.id, newCont);
        }
    }
    return newMap;
}

const MaterialsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_MATERIAL:
        case types.UPDATE_MATERIAL:
        case types.LOAD_MATERIALS:
        case types.DELETE_MATERIAL:
            return startLoading(state, action)

        case types.CREATE_MATERIAL_FAIL:
        case types.UPDATE_MATERIAL_FAIL:
        case types.LOAD_MATERIALS_FAIL:
        case types.DELETE_MATERIAL_FAIL:
            return stopLoading(state, action)

        case types.UPDATE_MATERIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                materialsMap: state.materialsMap.set(action.material.id, action.material)
            }

        case types.CREATE_MATERIAL_SUCCESS: // todo: add refresh in containers reducer
            return {
                ...state,
                loading: false,
                materialsMap: getGracefullyMergedContainersMap(state.materialsMap, [action.material])
            }

        case types.DELETE_MATERIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                materialsMap: state.materialsMap.delete(action.id)
            }

        case types.SELECT_MATERIAL:
            return {
                ...state,
                selectedMaterialId: action.id
            }

        case types.LOAD_MATERIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                materialsMap: getGracefullyMergedContainersMap(state.materialsMap, action.materials)
            }

        default:
            return state;

    }

}

export default MaterialsReducer;