import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import ContainerTypeImage from "../tools/ContainerTypeImage";
import {useMappedState} from "redux-react-hook";

import shared_img from '../../../assets/img/shared_group.svg'
import {getSortedSlides} from "../../apps/ShareApp";
import planet_img from '../../../assets/img/planet.svg'

const getSortedByName = arr => {
    console.log('ModulesListPanel: getSortedByName: arr = ', arr);
    try {
        let sortedArr = arr.sort((a, b) => {
            let s1 = `${a?.name}`.toLowerCase().trim();
            let s2 = `${b?.name}`.toLowerCase().trim();
            if (s1 > s2) {
                return 1;
            }
            if (s1 < s2) {
                return -1;
            }
            return 0;
        });
        console.log('sortedArr = ', sortedArr);
        return sortedArr;
    } catch (exc) {
        return arr;
    }
}

export default function ModulesListPanel(props) {
    const {
        modulesIds = [],
        onModuleClick = mId => {

        },
        deletedMode = false,
        allowEdit
    } = props;

    const {
        modules = [],
        selectedSlideId
    } = useMappedState(useCallback(state => {
        let modules = getSortedSlides(modulesIds.map(x => (state.slides.slidesMap.get(x))).filter(x => (x != undefined)));
        if (deletedMode == false) {
            modules = modules.filter(xx => (xx?.isDeleted != true))
        }
        return {
            selectedSlideId: state.slides.selectedSlideId,
            modules: getSortedByName(modules)
        }
    }));

    console.log('ModulesListPanel: modulesIds = ', modulesIds);
    console.log('ModulesListPanel: allowEdit = ', allowEdit);


    return (
        <Wrapper>

            {modules.map((a, i) => {
                let isSelected = (selectedSlideId == a.id);
                let hasShared = (a.accessGroupsIds != undefined && a.accessGroupsIds.length > 0);
                let isLimbo = a.isLimbo;
                return (
                    <Row key={a.id}
                         selected={isSelected}
                         onClick={() => {
                             onModuleClick(a.id);
                         }}>
                        {isSelected == true ? null :
                            <ContainerTypeImage
                                type={'module'}
                            />
                        }
                        <span>
                            {a.name}
                        </span>
                        {hasShared == false ? null :
                            <img src={shared_img} style={{height: 12, display: 'inline-block', marginLeft: 5}}/>
                        }
                        {isLimbo != true ? null :
                            <img src={planet_img} style={{height: 12, display: 'inline-block', marginLeft: 5}}/>
                        }
                    </Row>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 5px;
`;

const Row = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  background: ${props => (props.selected == true ? 'whitesmoke' : 'transparent')};
  border-radius: ${props => (props.selected == true ? '4px' : '0px')};
  border: ${props => (props.selected == true ? '1px solid lightgrey' : '1px solid transparent')};
  padding-left: ${props => (props.selected == true ? '4px' : '0px')};

  box-sizing: border-box;

  :hover {
    opacity: 0.8;
  }
`;