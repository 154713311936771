import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TeacherSettingsAPI = {

    async getAllUserSettings(userId) {
        let arr = (await axios.get(`${API_ENDPOINT}/kv/users/${userId}/items`)).data;
        return arr;
    },

    /*
        exerciseId
        let key = `exercise_${exerciseId}`
     */

    async getUserSettings(userId, type) {
        let pld = (await axios.get(`${API_ENDPOINT}/kv/users/${userId}/items/${type}`)).data;
        return pld;
    },


    async saveUserSettings(userId, type, data) {
        let pld = (await axios.post(`${API_ENDPOINT}/kv/users/${userId}/items/${type}`, {
            data: data
        })).data;
        return pld;
    },

    /*
        usersIds = ['jnM2pCK62I', 'vxid2WB2O0']
        exerciseId = QKfCsA0AOJ
        data = {
            maxAttemptsNumber: 5
        }
     */

    async saveUserSettingsForTestsAttempts(usersIds = [], exerciseId, data = {}) {
        if (usersIds.length == 0) {
            return;
        }
        for (let i in usersIds) {
            let userId = usersIds[i];
            await this.getUserSettings(userId, `exercise_attempts_settings_${exerciseId}`, data);
        }

    },


}

export default TeacherSettingsAPI;
