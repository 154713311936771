import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TopSquaresTool from "../../exercises/tools/TopSquaresTool";
import ExerciseCardItemView from "../../exercises/tools/ExerciseCardItemView";
import ExerciseAnswerInputTool from "../../exercises/tools/ExerciseAnswerInputTool";
import {GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";

import correct_img from '../../../assets/img/correct.svg';
import remove_img from '../../../assets/img/remove.svg';
import {translate} from "../../../utils/Translate";

const INT_TYPES = ['textinput', 'choice', 'record']

const getMark = (ans) => {
    if (ans == undefined) {
        return undefined;
    }

    let grand = ans.filter(x => x.isGrand);
    if (grand.length == 0) {
        return undefined;
    }

    return grand[0]?.autoMark;
}

export default function TestViewTool(props) {
    const {
        isFinished = false,
        showIfCorrect = false,
        questions = [],
        onAnswer = d => {

        },
        userAnswers = [],
        isPreview = false,
        name,
        description,

        onFinish = () => {

        }

    } = props;

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [copyrightText, setCopyrightText] = useState(undefined);

    let passedIds = userAnswers.map(x => x?.cardId).filter(x => x != undefined);
    let xQuestions = questions.filter(xx => (INT_TYPES.indexOf(xx.type) > -1));
    const mark = getMark(userAnswers);

    let canFinish = (xQuestions.length <= passedIds.length);

    console.log('TestViewTool: showIfCorrect = ', showIfCorrect);
    console.log('TestViewTool: canFinish = ', canFinish);

    return (
        <Wrapper>

            {errorMessage == undefined ? null :
                <NiceModal onClose={() => {
                    setErrorMessage(undefined);
                }}>
                    <ErrorInnerPlaceholder>
                        {errorMessage}
                    </ErrorInnerPlaceholder>
                </NiceModal>
            }

            <LeftPlaceholder>
                <TopPlaceholder>

                    <TopSquaresTool
                        cards={xQuestions}
                        passedIds={passedIds}
                        selectedId={undefined}
                        canSelect={false}
                        onSelect={xId => {

                        }}
                    />

                    {isFinished == true ?
                        <div>
                            {mark == undefined ? null :
                                <Mark>Оценка: {mark.toFixed(2)}</Mark>
                            }
                            {translate('test_finished')}
                        </div>
                        :
                        <div>
                            <GreenButton onClick={() => {
                                // if (canFinish == false) {
                                //     setErrorMessage('Вы можете завершить тест только после ответа на все вопросы');
                                //     return;
                                // }
                                if (window.alert('Вы уверены, что хотите завершить тест?') == false) {
                                    return;
                                }
                                onFinish();
                            }}>
                                Завершить тест
                            </GreenButton>
                        </div>
                    }


                </TopPlaceholder>

            </LeftPlaceholder>

            <RightPlaceholder>
                {(name == undefined || name == '') ? null :
                    <Heading>
                        {name}
                    </Heading>
                }

                {(description == undefined || description == '') ? null :
                    <Description>
                        {description}
                    </Description>
                }

                <ContentPlaceholder>

                    {questions.map((a, i) => {
                        let b = {
                            ...a,
                            isLikeExercise: false
                        }
                        if (b.type == 'textinput') {
                            b.answerType = 'typing';
                            b.isLikeExercise = true;
                        }
                        if (b.type == 'choice') {
                            b.answerType = 'select';
                            b.isLikeExercise = true;
                            b.selectOptions = a.options || [];
                        }
                        if (b.type == 'record') {
                            b.answerType = 'speaking';
                            b.isLikeExercise = true;
                        }
                        let selectedUserAnswerObj = userAnswers.filter(x => (x.cardId == a?.id))[0];
                        let answer = userAnswers.filter(x => (x.cardId == a?.id))[0];


                        return (
                            <QuestionItem key={a.id}>
                                <QuestionPlaceholder>
                                    {(b.taskText == undefined || b.taskText == '') ? null :
                                        <TaskTextPlaceholder>
                                            <div dangerouslySetInnerHTML={{__html: b.taskText}}></div>
                                        </TaskTextPlaceholder>
                                    }

                                    {b.type != 'html' ? null :
                                        <div>
                                            <div dangerouslySetInnerHTML={{__html: b.html}}></div>
                                        </div>
                                    }

                                    {/* {showIfCorrect == false ? null :
                                        <AssessmentSection>
                                            {selectedUserAnswerObj?.isCorrect == true ?
                                                <CorrectImg src={correct_img}/> : null}
                                            {selectedUserAnswerObj?.isCorrect == false ?
                                                <CorrectImg src={remove_img}/> : null}
                                        </AssessmentSection>
                                    } */}
                                </QuestionPlaceholder>

                                {b.type != 'image' ? null :
                                    <div>
                                        <OptImg src={b.url} />
                                        {((b.copyrightDescription == undefined) || (b.copyrightDescription.trim() == '')) ? null :
                                            <CopyrightPlaceholder className={'transcript_placeholder'}>
                                                <CopyrightSpan onClick={() => {
                                                    setCopyrightText(b.copyrightDescription);
                                                }}>
                                                    источник
                                                </CopyrightSpan>
                                            </CopyrightPlaceholder>
                                        }
                                    </div>
                                }

                                {b.isLikeExercise == false ? null :
                                    <div>
                                        <ExerciseCardItemView
                                            {...b}
                                        />
                                        <ExerciseAnswerInputTool
                                            autoSaveCheckboxes={true}
                                            showIfCorrect={showIfCorrect}
                                            {...props}
                                            {...b}
                                            {...answer}
                                            isPreview={isPreview}
                                            canAnswer={!isFinished}
                                            userAnswerText={selectedUserAnswerObj?.answer}
                                            onSave={(s, isCorrect) => {
                                                if (s == undefined || isFinished == true) {
                                                    return;
                                                }
                                                onAnswer({
                                                    cardId: a.id,
                                                    answer: s,
                                                    isCorrect: isCorrect
                                                })
                                            }}
                                        />
                                    </div>

                                }
                            </QuestionItem>
                        )
                    })}

                    {copyrightText == undefined ? null :
                        <NiceModal onClose={() => {
                            setCopyrightText(undefined);
                        }}>
                            <CpInnerPlaceholder>
                                {copyrightText}
                            </CpInnerPlaceholder>
                        </NiceModal>
                    }

                </ContentPlaceholder>

                {isFinished == true ?
                    <div>
                        {mark == undefined ? null :
                            <Mark>Оценка: {mark.toFixed(2)}</Mark>
                        }
                        Тест завершен
                    </div>
                    :
                    <div>
                        <GreenButton onClick={() => {
                            // if (canFinish == false) {
                            //     setErrorMessage('Вы можете завершить тест только после ответа на все вопросы');
                            //     return;
                            // }
                            if (window.alert('Вы уверены, что хотите завершить тест?') == false) {
                                return;
                            }
                            onFinish();
                        }}>
                            Завершить тест
                        </GreenButton>
                    </div>
                }

            </RightPlaceholder>


        </Wrapper>
    );
}

const OptImg = styled.div`
  background-color: white;
  width: 100%;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const CorrectImg = styled.img`
  width: 24px;
  height: 24px;
`;

const AssessmentSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  //margin-bottom: -15px;
`;

const ErrorInnerPlaceholder = styled.div`
  text-align: center;
  width: 520px;
  box-sizing: border-box;
  padding: 20px;
  font-weight: bold;
  color: red;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  @media (max-width: 1100px) {
    display: block;
  }
`;

const LeftPlaceholder = styled.div`
  width: 300px;
  margin-right: 20px;
  box-sizing: border-box;

`;

const RightPlaceholder = styled.div`
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`;

const TaskTextPlaceholder = styled.div`
  margin-top: 10px;
`;

const ContentPlaceholder = styled.div`

`;

const QuestionItem = styled.div`
  border: 1px solid whitesmoke;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const QuestionPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 16px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopyrightPlaceholder = styled.div`
  text-align: right;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CopyrightSpan = styled.span`
  font-size: 9px;
  opacity: 0.4;
  font-style: italic;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const CpInnerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 420px;
`;

const Mark = styled.div`
    font-size: 16px;
    font-weight: bold;
`;