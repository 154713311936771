import axios from 'axios'

const YANDEX_TRANSLATELOOKUP_API_KEY = 'dict.1.1.20150111T061533Z.349245bda1a12276.29f9baac4b0623f43ede19d011e5abd6d1b4c5ed';

const YANDEX_TRANSLATE_KEY = `trnsl.1.1.20190416T175249Z.64032f80aadec6cc.a85128d8b40c5f71ce56aa546289349f1a701f3f`;

const YandexTranslateAPI = {

    translateWord(word) {
        return new Promise((resolve, reject) => {
            axios.get('https://dictionary.yandex.net/api/v1/dicservice.json/lookup', {
                params: {
                    format: 'html',
                    lang: 'en-ru',
                    ui: 'ru',
                    text: word,
                    key: YANDEX_TRANSLATELOOKUP_API_KEY
                }
            }).then(pld => {
                console.log('translateWord: pld = ', pld);
                resolve(pld.data.def)
            }).catch(err => reject(err))
        })
    },

    translateTextAlternative(text) {
        return new Promise((resolve, reject) => {
            axios.get('https://translate.yandex.net/api/v1.5/tr.json/translate', {
                params: {
                    format: 'html',
                    lang: 'en-ru',
                    ui: 'ru',
                    text: text,
                    key: YANDEX_TRANSLATE_KEY
                }
            }).then(d => d.data).then(pld => {
                console.log('translateTextAlternative: pld = ', pld);
                if (pld.code == 200) {
                    resolve(pld.text == undefined ? [] : pld.text)
                } else {
                    resolve([]);
                }
            }).catch(err => reject(err))
        })
    }

}

export default YandexTranslateAPI
