import TestsAPI from '../../api/TestsAPI'

import * as types from '../ActionTypes'

let loadTests_ = () => {
    return {
        type: types.LOAD_TESTS
    }
}
let loadTestsSuccess = (tests) => {
    return {
        type: types.LOAD_TESTS_SUCCESS,
        tests: tests
    }
}
let loadTestsFailed = (error) => {
    return {
        type: types.LOAD_TESTS_FAIL,
        error: error
    }
}

//thunk
export function getTestsByIds(ids = []) {
    return (dispatch, getState) => {
        dispatch(loadTests_());
        return TestsAPI.getShortQuizesByIds(ids).then(
            tests => dispatch(loadTestsSuccess(tests)),
            error => dispatch(loadTestsFailed(error))
        )
    }
}

export function getAllTests() {
    return (dispatch, getState) => {
        dispatch(loadTests_());
        return TestsAPI.getAllTests().then(
            tests => dispatch(loadTestsSuccess(tests)),
            error => dispatch(loadTestsFailed(error))
        )
    }
}

let createTest_ = () => {
    return {
        type: types.CREATE_TEST
    }
}
let createTestSuccess = (test) => {
    return {
        type: types.CREATE_TEST_SUCCESS,
        test: test
    }
}
let createTestFailed = (error) => {
    return {
        type: types.CREATE_TEST_FAIL,
        error: error
    }
}

//thunk
export function createTest(data) {
    return (dispatch, getState) => {
        dispatch(createTest_());
        return TestsAPI.createQuiz(data).then(
            test => dispatch(createTestSuccess(test)),
            error => dispatch(createTestFailed(error))
        )
    }
}

// export function selectTest(id) {
//     return {
//         type: types.SELECT_TEST,
//         id: id
//     }
// }

let updateTest_ = () => {
    return {
        type: types.UPDATE_TEST
    }
}
let updateTestSuccess = (test) => {
    return {
        type: types.UPDATE_TEST_SUCCESS,
        test: test
    }
}
let updateTestFailed = (error) => {
    return {
        type: types.UPDATE_TEST_FAIL,
        error: error
    }
}

//thunk
export function updateTest(data) {
    return (dispatch, getState) => {
        dispatch(updateTest_());
        return TestsAPI.updateQuiz(data).then(
            test => dispatch(updateTestSuccess(test)),
            error => dispatch(updateTestFailed(error))
        )
    }
}

let deleteTest_ = () => {
    return {
        type: types.DELETE_TEST
    }
}
let deleteTestSuccess = (id) => {
    return {
        type: types.DELETE_TEST_SUCCESS,
        id: id
    }
}
let deleteTestFailed = (error) => {
    return {
        type: types.DELETE_TEST_FAIL,
        error: error
    }
}

//thunk
export function deleteTest(id) {
    return (dispatch, getState) => {
        dispatch(deleteTest_());
        return TestsAPI.deleteQuiz(id).then(
            () => dispatch(deleteTestSuccess(id)),
            error => dispatch(deleteTestFailed(error))
        )
    }
}
