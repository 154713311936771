import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DrochkaExercisesAPI from "../../../api/DrochkaExercisesAPI";
import ExercisesAPI from "../../../api/ExercisesAPI";
import DrochkaExerciseViewTool from "../tools/DrochkaExerciseViewTool";
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";

export default function DrochkaExercisePanel(props) {
    const {
        id,
        isPreview = false,
        userId
    } = props;

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [answersLoading, setAnswersLoading] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [answering, setAnswering] = useState(false);

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));
    let isCheckingMode = (currentUser?.userRole == 'teacher' && userId != currentUser?.id);

    useEffect(() => {
        setLoading(true);
        DrochkaExercisesAPI.getDrochkaExercise(id).then(d => {
            setData(d);
            setLoading(false);
            console.log('DrochkaExercisePanel: d = ', d);
            if (isPreview == true) {
                return;
            }
            setAnswersLoading(true);
            DrochkaExercisesAPI.getDrochkaExerciseAnswers(id, userId).then(a => {
                setUserAnswers(a);
                setAnswersLoading(false);
            });
        });
    }, [id]);

    if (data == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    let answer = userAnswers[0]?.answer;
    console.log('--->>> answer = ', answer);

    return (
        <Wrapper key={`x_${answersLoading}`}>

            <DrochkaExerciseViewTool
                {...data}
                isCheckingMode={isCheckingMode}
                userAnswer={answer}
                onSave={async url => {
                    setAnswering(true);
                    let ans = await ExercisesAPI.saveExerciseAnswer(id, id, url);
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}
            />

            <div style={{textAlign: 'center'}} >
                <ViewAuthorTool
                    badgeMode={false}
                    userId={data?.userId || data?.creatorId || data?.ownerId}
                />
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;