import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SpeechRecognitionAPI = {

    async recognize(url, language) {
        let pld = (await axios.post(`${API_ENDPOINT}/recognition/send?lang=${language}`, {url: url})).data;
        return pld;
    }

}

export default SpeechRecognitionAPI;
