import axios from "axios";
import {API_ENDPOINT} from "../constants/config";

const ContainersAPI = {

    async getContainer(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/containers/${id}`)).data.result;
        return pld;
    },

    // async getAllShortContainers() { // loads short information about containers available for me // will be refactored
    //     let pld = (await axios.get(`${API_ENDPOINT}/v3/containers`)).data.result;
    //     return pld;
    // },
    //
    async getAllShortContainers() { // loads short information about containers available for me // will be refactored
        let pld = (await axios.get(`${API_ENDPOINT}/v3/containers/all`)).data.result;
        return pld;
    },


    async createContainer(parentId, data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/containers`, data, {
            params: {
                parentId: parentId
            }
        })).data.result;
        return pld;
    },

    async updateContainer(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/containers/${data.id}`, data)).data.result;
        return pld;
    },

    async deleteContainer(parentId, id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/containers/${id}`, {}, {
            params: {parentId: parentId}
        })).data.result;
        return pld;
    },

    async cloneContainer(currentContainerId, targetParentId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/containers/clone`, {
            currentContainerId: currentContainerId,
            targetParentId: targetParentId
        })).data.result;
        return pld;
    },

    async getChildren(id) { // we always get children with all content (not large request)
        let pld = (await axios.get(`${API_ENDPOINT}/v3/containers/${id}/children`, {
            params: {
                extended: 1
            }
        })).data.result;
        return pld;
    }

}

export default ContainersAPI;
