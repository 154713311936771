import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';

export default function ConsentText(props) {
    const { userRole } = props;

    return (
        <Wrapper>

            {userRole == "teacher" ? 
                <>
                    <p>
                        1. НОЦ "Онлайн-платформы в языковом образовании" ТГУ информирует пользователей онлайн-платформ
                        "Английский пациент" и "СпикРус" о введении моратория на загрузку, использование на
                        онлайн-уроках и в качестве ресурсов для создания цифровых дидактических материалов на указанных
                        платформах материалов СМИ, содержащих упоминание о специальной военной операции, проводимой
                        Российской Федерацией, санкциях и иных её последствиях. Под запрет также попадает использование
                        (упоминание, демонстрация) на онлайн-платформах медиа-ресурсов, чья деятельность признана
                        экстремистской Верховным судом РФ. НОЦ "Онлайн-платформы в языковом образовании" ТГУ не несёт
                        ответственности за содержание авторских материалов и возможные деликтные последствия того или
                        иного поступка в результате действий пользователей онлайн-платформ, нарушивших законодательство
                        РФ и данный мораторий. Мораторий вводится также в связи невозможностью верификации качества
                        новостей (иных медиа-материалов) и как мера защиты от провокаций. Мораторий действует до
                        официального распоряжения о его снятии.
                    </p>
                    <p>
                        2. НОЦ ТГУ "Онлайн-платформы в языковом образовании" напоминает о нарушении закона Российской
                        Федерации об интеллектуальной собственности в случае использования (загрузки, демонстрации) на
                        онлайн-платформах полных видео- или аудиоматериалов. Такие материалы подлежат удалению
                        загрузившим их пользователем. За разъяснением данного вопроса можно обратиться к директору НОЦ
                        "Онлайн-платформы в языковом образовании" ТГУ Захаровой Н.В.
                    </p>

                    <p>
                        3. Поскольку в интернете участились случаи провокаций, просим Вас при обнаружении на
                        онлайн-платформах материалов или ссылок на сторонние ресурсы, содержащих экстремистскую,
                        противоправную и иную деструктивную информацию, незамедлительно сообщить об этом по адресу
                        электронной почты <a href={`mailto:info@tsuplatforms.ru`} >info@tsuplatforms.ru</a> или в виджет технической поддержки на платформе,
                        расположенный в правом нижнем углу.
                    </p>
                </> 
                : 
                <>
                    <p>
                        Уважаемые пользователи онлайн-платформ "Английский пациент" и "СпикРус"!
                    </p>
                    <p>
                        Поскольку в интернете участились случаи провокаций, просим Вас при обнаружении на
                        онлайн-платформах материалов или ссылок на сторонние ресурсы, содержащих экстремистскую,
                        противоправную и иную деструктивную информацию, незамедлительно сообщить об этом по адресу
                        электронной почты <a href={`mailto:info@tsuplatforms.ru`} >info@tsuplatforms.ru</a> или в виджет технической поддержки на платформе,
                        расположенный в правом нижнем углу.
                    </p>
                </>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;