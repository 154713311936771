import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import TestsAPI from "../../../api/TestsAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import copy_q from "../../../assets/img/copy_q.svg";

import close_x from "../../../assets/img/close_e.svg";

export default function TestsListInfoTool(props) {
    const {
        userId,
        canEdit = true,
        ids = [],
        canCopyTest = false,
        onCopy = id => {

        },
        canDelete = false,
        onDelete = delId => {

        }
    } = props;
    const [tests, setTests] = useState([]);
    useEffect(() => {
        TestsAPI.getShortQuizesByIds(ids).then(arr => {
            setTests(arr);
        });
    }, [ids]);

    let map = tests.reduce((mp, a) => mp.set(a.id, a), Map());
    let items = ids.map(x => map.get(x)).filter(a => (a != undefined));
    console.log('TestsListInfoTool: canEdit = ', canEdit);

    return (
        <Wrapper>
            {items.map((a, i) => {
                return (
                    <ItemRow key={`${a.id}_${i}`}>
                        <Span onClick={() => {
                            let testUrl = `/tests/${a.id}`;
                            if (userId != undefined) {
                                testUrl = `/tests/${a.id}/${userId}`;
                            }
                            CommonHelper.linkTo(testUrl, true);
                        }}>
                            {`${+i + 1}) ${a.name}`}
                        </Span>

                        {canCopyTest == false || canEdit == false ? null :
                            <div>
                                <CopyImg src={copy_q} onClick={() => {
                                    onCopy(a.id);
                                }}/>
                            </div>
                        }

                        {canDelete == false || canEdit == false ? null :
                            <div>
                                <CopyImg src={close_x} onClick={() => {
                                    onDelete(a.id);
                                }}/>
                            </div>
                        }


                    </ItemRow>
                )
            })}

            {/*{canEdit == false ? null :*/}
            {/*    <AddPlaceholder>*/}

            {/*    </AddPlaceholder>*/}
            {/*}*/}

        </Wrapper>
    );
}

const AddPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditPlusDiv = styled.div`
  position: absolute;
  right: -28px;
  bottom: -15px;
  width: 24px;
  height: 24px;
  background: ${props => (props.selected == true ? '#ffe6b7' : 'white')};
  border: 2px solid #FFA71B;
  border-radius: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    background: #faf4ea;
  }
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const Span = styled.span`
  border-bottom: 1px solid lightgrey;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ItemRow = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;