import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';

import ls from 'local-storage'

import RecursiveFoldersTree from "../tools/RecursiveFoldersTree";

import * as actions from '../../../redux/actions/FoldersActions'

import sel_icon from '../../../assets/img/select-circle-area.svg'

function getRootFolders(foldersMap, access, currentUserId) {
    let allFolders = foldersMap.toArray().filter(xx => (xx?.creatorId == currentUserId));
    let allChildrenIdsSet = allFolders.reduce((st, c) => st.union((c?.childrenIds || []).reduce((sst, c_) => sst.add(c_), Set())), Set());
    let orphans = allFolders.filter(x => !allChildrenIdsSet.has(x.id)).filter(x => (x?.type == 'folder' && x?.access == access));
    return orphans;
}

export default function PrivatePatientFolderSelectorPanel(props) {
    const {
        access = 'public', // private, public, hybrid
        accessGroupId, // for privacy
        onFolderSelect = cId => {

        }
    } = props;

    const dispatch = useDispatch();
    const [expandedSet, setExpandedSet] = useState((ls('expanded_new_patient_folders') == undefined ? [] : ls('expanded_new_patient_folders')).reduce((st, ss) => st.add(ss), Set()));

    const [loadingItemId, setLoadingItemId] = useState(undefined);
    const [selectedId, setSelectedId] = useState(undefined);


    const {
        foldersMap,
        loading,
        rootFolders,
        getChildFolders,
        selectedSlide,
        selectedSlideId,
        language,
        currentUserId
    } = useMappedState(useCallback(state => {
        return {
            currentUserId: state.users.currentUserId,
            language: state.language.language,
            rootFolders: getRootFolders(state.folders.foldersMap, 'private', state.users.currentUserId),
            loading: state.folders.loading || state.slides.loading,
            foldersMap: state.folders.foldersMap,
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId),
            selectedSlideId: state.slides.selectedSlideId,
            getChildFolders: contId => {
                let c = foldersMap.get(contId);
                if (c == undefined) {
                    return [];
                }
                let {childrenIds = []} = c;
                return childrenIds.map(x => state.folders.foldersMap.get(x)).filter(x => (x != undefined))
            }
        }
    }));

    useEffect(() => {
        dispatch(actions.getMyAllCompactFolders());
    }, []);

    return (
        <Wrapper>

            <RecursiveFoldersTree
                hoverHighlight={false}
                showModulesList={false}
                showContentItems={false}
                folders={rootFolders}
                expandedSet={expandedSet}
                loadingItemId={loadingItemId}
                getChildFolders={getChildFolders}
                expandToggle={async exId => {
                    console.log('expandToggle: exId = ', exId);
                    let isExpanded = !expandedSet.has(exId);
                    let newExpandedSet = expandedSet.has(exId) ? expandedSet.delete(exId) : expandedSet.add(exId);
                    ls('expanded_new_folders', newExpandedSet.toArray());
                    setExpandedSet(newExpandedSet);
                    if (isExpanded) {
                        setLoadingItemId(exId);
                        await dispatch(actions.getChildren(exId));
                        setLoadingItemId(undefined);
                    }
                }}
                onFolderClick={cId => {
                    let c = foldersMap.get(cId);
                }}
                renderRightBlockFunction={(cId, parId) => {
                    let c = foldersMap.get(cId);
                    if (c == undefined) {
                        return null;
                    }
                    let hasChildren = (c.childrenIds != undefined && c.childrenIds.length > 0);
                    if (hasChildren == true) {
                        return null;
                    }
                    return (
                        <SelImg src={sel_icon}
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    onFolderSelect(c.id);
                                }}
                        />
                    )
                }}
            />

        </Wrapper>
    );
}

const SelImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  padding: 10px;
`;
