import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TranslatableParagraph from "../translate/panels/TranslatableParagraph";
import MonthCalendar from "../calendars/MonthCalendar";
import DateTimePicker from "../ui/DateTimePicker";

import {ReactMic} from 'react-mic';
import RecordComponent from "../record/RecordComponent";
import ExercisePanel from "../exercises/panels/ExercisePanel";
import TestPanel from "../tests/panels/TestPanel";
import TeacherTemplate from "../templates/TeacherTemplate";
import LogoDiv from "../templates/LogoDiv";
import TestsAPI from "../../api/TestsAPI";
import {useDispatch, useMappedState} from "redux-react-hook";

import * as usersActions from '../../redux/actions/UsersActions'

export default function TestApp(props) {
    let {id, userId} = props.match.params;

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const {
        currentUser,
        student
    } = useMappedState(useCallback(state => {
        let uId = userId || state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            student: state.users.usersMap.get(uId)
        }
    }));

    useEffect(() => {
        setLoading(true);
        TestsAPI.getExercise(id).then(d => {
            setData(d);
            setLoading(false);
        });
        if (userId != undefined) {
            dispatch(usersActions.loadUserById(userId));
        }
    }, [id]);

    return (
        <TeacherTemplate
            hasLeft={false}
        >

            <Wrapper>

                <TopPlaceholder>

                    <TopLeftPlaceholder>
                        <LogoDiv/>

                        <NamePlaceholder>
                            {`Тест "${data?.name}"`}
                        </NamePlaceholder>
                    </TopLeftPlaceholder>

                    <TopRightPlaceholder>
                        {student?.email}
                    </TopRightPlaceholder>

                </TopPlaceholder>

                <TestWrapper>
                    <TestPanel
                        userId={userId}
                        id={id}
                    />
                </TestWrapper>

            </Wrapper>


        </TeacherTemplate>
    );
}

const NamePlaceholder = styled.div`
  margin-left: 20px;
  box-sizing: border-box;
  font-weight: bold;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  background: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  font-weight: bold;
`;

const TestWrapper = styled.div`
  box-sizing: border-box;
  width: 1120px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`

`;