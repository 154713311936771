import React, {useEffect, useState} from 'react';
import {Set} from 'immutable';
import styled from 'styled-components';
import {Button} from "../../../ui/Button";

import Spin from 'arui-feather/spin'

import {Textarea} from "../../../ui/Textarea";
import {GoodFittedImage} from "../../choice/tools/MultipleChoiceExerciseEditorTool";

export default function PlaySimpleInputExerciseTool(props) {
    const {
        imageUrl,
        videoUrl,
        submitButtonLabel = 'Ответить',
        taskText = '',
        onSave = ids => {

        },
        loading = false
    } = props;


    const [text, setText] = useState(props.defaultText == undefined ? '' : props.defaultText);
    const canSubmit = (text != undefined && text.length > 0);

    return (
        <Wrapper>

            <TopTaskPlaceholder>
                {taskText}
            </TopTaskPlaceholder>

            {(imageUrl == undefined || imageUrl == '') ? null :
                <ImagePlaceholder>
                    <GoodFittedImage src={imageUrl}/>
                </ImagePlaceholder>
            }

            {(videoUrl == undefined || videoUrl == '') ? null :
                <Video controls={true}>
                    <source src={videoUrl}/>
                </Video>
            }

            <InputPlaceholder>
                <Label>
                    Ваш ответ
                </Label>
                <Textarea value={text} onChange={evt => {
                    setText(evt.target.value)
                }}/>
            </InputPlaceholder>

            <BottomPlaceholder>
                <Button disabled={!canSubmit} onClick={() => {
                    onSave(text);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        submitButtonLabel
                    }
                </Button>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Label = styled.div`
  font-style: italic;
  font-size: 14px;
`;

const InputPlaceholder = styled.div`

`;

const ImagePlaceholder = styled.div`
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 400px;
`;


const Wrapper = styled.div`
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-sizing: border-box;
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptItem = styled.div`
  margin-bottom: 10px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
  background: ${props => (props.selected == true ? 'wheat' : 'white')};
  cursor: pointer;
  border-radius: 4px;

  :hover {
    border: 2px solid blue;
  }
`;

const TopTaskPlaceholder = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px dashed whitesmoke;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
`;

const OptionsPlaceholder = styled.div`

`;
