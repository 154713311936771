import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import replay_img from "../../../../assets/img/replay_button.svg";
import play_img from "../../../../assets/img/play_button.svg";
import pause_img from "../../../../assets/img/pause_button.svg";

function getTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return [
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
}

export default function SliderTreadmillTool(props) {
    const {
        treadmillTime,
        currentTime = 0,
        canRewind = false,
        restart = () => {

        },
        setTime = time => {

        },
        setPause = d => {

        }
    } = props;

    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        if (treadmillTime === currentTime) {
            setIsPaused(true);
        }
    }, [currentTime])

    let totalTime = getTime(treadmillTime);

    return (
        <Wrapper>
            {canRewind === false ? null :
                <PauseButton src={treadmillTime <= currentTime ? replay_img : isPaused ? play_img : pause_img}
                             onClick={() => {
                                 if (currentTime >= treadmillTime) {
                                     restart();
                                     setIsPaused(false);
                                     setPause(false);
                                 } else {
                                     setIsPaused(!isPaused);
                                     setPause(!isPaused)
                                 }
                             }}/>
            }

            <Slider type="range"
                    min="0"
                    max={treadmillTime}
                    canRewind={canRewind}
                    value={currentTime}
                    disabled={!canRewind}
                    onChange={(evt) => {
                        let time = evt.target.value;
                        if (canRewind === true) {
                            setTime(time);
                        }
                    }}
            />

            <TimerPlaceholder>
                {getTime(currentTime)} / {totalTime}
            </TimerPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
`;

const PauseButton = styled.img`
  cursor: pointer;
  opacity: 0.7;
  flex: 0;
  user-select: none;
  width: 24px;
  height: 24px;
  transform: scale(1.2);

  :hover {
    opacity: 1;
  }
`;

const Slider = styled.input`
  width: 100%;
  margin: 10px;
  flex: 3;
  -webkit-appearance: none;
  cursor: ${props => (props.canRewind === true ? 'pointer' : 'default')};

  :hover, :focus, :active {
    outline: none;
  }

  ::-webkit-slider-runnable-track {
    //height: 2px;
    //background-color: #ff9900;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #FFA71B;
    width: 16px;
    height: 16px;
    border-radius: 12px;
    //box-shadow: -500px 0 0 500px dodgerblue;
  }

  ::-webkit-slider-container {
    -webkit-appearance: none;
    border-radius: 2px;
    background-color: #e1e1e1;
    height: 4px;
  }

  ::-ms-fill-lower {
    //background-color: #ff9900;
    //border-radius: 2px;
    //height: 4px;
  }
`;

const TimerPlaceholder = styled.div`
  width: 100px;
  display: flex;
  text-align: right;
  justify-self: flex-end;
`;