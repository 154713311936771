import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'
import PaywallFormTool from "./PaywallFormTool";
import toast from "react-hot-toast";
import moment from 'moment';

import {QUESTIONS} from "./PaywallFormTool";

function getHostName() {
    try {
        return document.location.hostname;
    } catch (exc) {

    }
    return 'none';
}


export default function PaywallAllQuestionnairesViewTool(props) {
    const {
        userId
    } = props;

    const [loading, setLoading] = useState(false);
    const [arr, setArr] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.langplat.com/all-paywall-questionnaire-results`).then(x => {
            setArr(x.data || []);
            setLoading(false);
        });
    }, [userId]);

    if (arr == undefined || arr.length == 0) {
        return null;
    }

    return (
        <Wrapper>

            {/*table with answers on all questionnaires*/}

            <Table>
                <Thead>
                <Tr>
                    <Th>date</Th>
                    <Th>userId</Th>
                    <Th>hostname</Th>
                    {QUESTIONS.map((a, i) => {
                        return (
                            <Th key={i}>{a.question}</Th>
                        )
                    })}
                </Tr>
                </Thead>
                <tbody>
                    {arr.map((d, i) => {
                        return (
                            <Tr key={i}>
                                <Td>{moment(d.timestamp).format('DD.MM.YYYY')}</Td>
                                <Td>{d.userId}</Td>
                                <Td>{d.hostname}</Td>
                                {QUESTIONS.map((a, i) => {
                                    let value = d[a.name] || '';
                                    return (
                                        <td key={i}>{value}</td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </tbody>
            </Table>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 20px;
`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;