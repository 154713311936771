import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import PlayChoiceExerciseTool from "../choice/tools/PlayChoiceExerciseTool";
import PlaySimpleInputExerciseTool from "../simple_input/tools/PlaySimpleInputExerciseTool";
import PlaySimpleRecordExerciseTool from "../simple_record/PlaySimpleRecordExerciseTool";

export default function SmartQuizQuestionViewer(props) {
    const {
        type,
        onUserAnswer = (answer) => {

        }
    } = props;

    return (
        <Wrapper>

            {type != 'image' ? null :
                <OptImg src={props.url} />
            }

            {type != 'choice' ? null :
                <div>
                    <PlayChoiceExerciseTool {...props} />
                </div>
            }

            {type != 'textinput' ? null :
                <div>
                    <PlaySimpleInputExerciseTool {...props} />
                </div>
            }

            {type != 'record' ? null :
                <div>
                    <PlaySimpleRecordExerciseTool {...props} />
                </div>
            }

            {type != 'html' ? null :
                <div>
                    <div
                        dangerouslySetInnerHTML={{__html: (props.html == undefined ? '' : props.html.replace(/\n/g, '<br/>'))}}></div>
                </div>
            }

        </Wrapper>
    );
}

const OptImg = styled.div`
  background-color: white;
  width: 100%;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Wrapper = styled.div`

`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid whitesmoke;
  resize: vertical;
`;

const EssayPlaceholder = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 10px;
`;

const Button = styled.div`
  background-color: lightgrey;
  color: white;
  padding: 7px 15px;
  width: fit-content;
  border-radius: 3px;
  margin-top: 10px;
  
  :hover {
    opacity: 0.8;
  }
`;