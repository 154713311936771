import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const StatsAPI = {


    async getClassStats(classId) {
        let {data} = await axios.get(`${API_ENDPOINT}/stats/classes/${classId}/all-stats`);
        return data;
    },

    async getUserStats(userId) {
        let {data} = await axios.get(`${API_ENDPOINT}/stats/users/${userId}/all-stats`);
        return data;
    },


}

export default StatsAPI;
