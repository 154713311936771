import axios from 'axios'
import {PATIENT_API_ENDPOINT} from "../constants/config";

const API_ENDPOINT = 'https://patient-api.simbox.online';
const SITE = 'entsu';

const EXTRACTOR_PROMPT = `
    You are a tool helping to extract constructions from english language texts.
    You are given a text and you need to extract constructions from it.
    The output from the tool should be a list of constructions.
    The constructions should be in the following format:
    - The construction should be a string.
    - The construction should be a sequence of words.
    
    This should contain lists: 
        - verbs
        - subjects
        - objects
        - tags
        - vocabulary

    Also there must be level of grammar and words.
        A1, B1, etc.
   
    Example:
    Input: "I am a student."
    Output: {"grammar": "present simple", "verbs": ["am"], "subjects": ["I"], "objects": ["a student"], "tags": ["present simple", "verb to be", "subject", "object"], "vocabulary": ["student"], "level": "A1"}
    Input: "I am a student. He is a teacher."
    Output: {"grammar": "present simple", "verbs": ["am", "is"], "subjects": ["I", "He"], "objects": ["a student", "a teacher"], "tags": ["present simple", "verb to be", "subject", "object"], "vocabulary": ["student", "teacher"], "level": "A1"}
`;


const AssistantsAPI = {

    async getAiResponse(system, text) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/ai/single-request`, {
                system: system,
                text: text
            })).data;
            return pld;
        } catch (exc) {

        }
    },

    async getGrammarAndWords(text) {
        let system = EXTRACTOR_PROMPT;
        return await this.getAiResponse(system, text);
    },

    async getUserConversations(userId) {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations`)).data;
            return pld;
        } catch (exc) {

        }
    },

    async getConversation(userId, conversationId) {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations/${conversationId}`)).data;
            return pld;
        } catch (exc) {

        }
    },

    async createConversation(userId, data = {}) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations`, {
                ...data
            })).data;
            return pld;
        } catch (exc) {

        }
    },

    async updateConversation(userId, conversationId, data = {}) {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations/${conversationId}`, {
                ...data
            })).data;
            return pld;
        } catch (exc) {

        }
    },

    async createConversationMessage(userId, conversationId, text) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations/${conversationId}/messages`, {
                text: text
            })).data;
            return pld;
        } catch (exc) {

        }
    },


    async getConversationMessages(userId, conversationId) {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/ai/${SITE}/users/${userId}/conversations/${conversationId}`)).data;
            let messages = pld?.messages || [];
            return messages;
        } catch (exc) {

        }
    },




}

export default AssistantsAPI;
