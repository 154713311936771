import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    selectedTaskId: undefined,

    tasksMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TasksReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TASK:
        case types.UPDATE_TASK:
        case types.DELETE_TASK:
        case types.LOAD_TASKS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TASK_FAIL:
        case types.UPDATE_TASK_FAIL:
        case types.DELETE_TASK_FAIL:
        case types.LOAD_TASKS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TASK_SUCCESS:
        case types.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: getGracefullyMergedContainersMap(state.tasksMap, [action.task]),
                selectedTaskId: action?.task?.id
            }


        case types.DELETE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: state.tasksMap.delete(action.id),
            }


        case types.LOAD_TASKS_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksMap: getGracefullyMergedContainersMap(state.tasksMap, action.tasks)
            }

        case types.SELECT_TASK:
            return {
                ...state,
                selectedTaskId: action.id
            }

        case types.CREATE_LESSON:
        case types.DELETE_LESSON:
        case types.CREATE_GROUP:
        case types.DELETE_GROUP:
        case types.SELECT_LESSON:
            return {
                ...state,
                selectedTaskId: undefined
            }


        default:
            return state;
    }

}

export default TasksReducer;
