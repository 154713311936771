import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import PolotnoTool from "../../polotno/tools/PolotnoTool";
import UploadAPI from "../../../api/UploadAPI";

import Spin from 'arui-feather/spin'

export default function PhotoEditorPhotoPicker(props) {
    const polotnoRef = useRef();
    const [loading, setLoading] = useState(false);
    const {
        onSave = (u, copyrightString) => {

        },
        url,
        hasCopyrightSection = false,
        hasDirectUploaderSection = true
    } = props;

    useEffect(() => {
        if (url != undefined) {
            try {
                polotnoRef.current.setImageForEditing(url);
            } catch (exc) {

            }
        } else {
            try {
                polotnoRef.current.cleanCanvas();
            } catch (exc) {

            }
        }
    }, [url]);

    return (
        <Wrapper>
            <Left>
                <PolotnoTool
                    {...props}
                    hasCopyrightSection={hasCopyrightSection}
                    hasDirectUploaderSection={hasDirectUploaderSection}
                    ref={polotnoRef}
                />
            </Left>
            <Right>
                <SaveButton onClick={async () => {
                    setLoading(true);
                    try {
                        let dataUrl = polotnoRef.current.getDataUrl();
                        let res = await fetch(dataUrl);
                        let blob = await res.blob();
                        let pld = await UploadAPI.uploadImage(blob);
                        console.log('pld = ', pld);
                        onSave(pld.medium_url, polotnoRef.current.getCopyrightDescription());
                    } catch (exc) {
                    }
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : 'Save'}
                </SaveButton>
            </Right>
        </Wrapper>
    );
}

const SaveButton = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: whitesmoke;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
  background: whitesmoke;
`;

const Right = styled.div`
  width: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;