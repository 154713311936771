import {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment'

const UIHelper = {

    isProUniMode() {
        try {
            return (window.location.href.indexOf('prouni') > -1);
        } catch (exc) {

        }
        return false;
    },

    containsHebrew(str) {
        return (/[\u0590-\u05FF]/).test(str);
    },

    isDictionaryAvailable() {
        let goodItems = ['prouni', 'entsu', 'likool', 'localhost', 'langplat', 'kuz'];
        let f = false;
        let s = window.location.href;
        if (s.indexOf('ru-tsu') > -1) {
            return false;
        }
        for (let i in goodItems) {
            let g = goodItems[i];
            if (s.indexOf(g) > -1) {
                f = true;
            }
        }
        return f;
    },

    getDeadlineClassName(deadlineTimestamp) {
        if (deadlineTimestamp == undefined) {
            return '';
        }
        let now = +new Date();
        if (now > deadlineTimestamp) {
            return 'missed_deadline blink_me';
        }
        let day_3 = 3 * 86400000;
        if (now > deadlineTimestamp - day_3) {
            return 'coming_deadline blink_me';
        }
        return 'not_coming_deadline';
    }

};

export default UIHelper;
