import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    groupsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const GroupsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_GROUP:
        case types.UPDATE_GROUP:
        case types.DELETE_GROUP:
        case types.LOAD_GROUPS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_GROUP_FAIL:
        case types.UPDATE_GROUP_FAIL:
        case types.DELETE_GROUP_FAIL:
        case types.LOAD_GROUPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_GROUP_SUCCESS:
        case types.UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                groupsMap: getGracefullyMergedContainersMap(state.groupsMap, [action.group])
            }


        case types.DELETE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                groupsMap: state.groupsMap.delete(action.id),
            }


        case types.LOAD_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                groupsMap: getGracefullyMergedContainersMap(state.groupsMap, action.groups)
            }


        default:
            return state;
    }

}

export default GroupsReducer;
