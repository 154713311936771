import axios from 'axios'

import ls from 'local-storage'
import {DEFAULT_VF_ANALYTICS_ENDPOINT} from "./TrackerAPI";

const SESSION_TIMEOUT = 6 * 60 * 60 * 1000;
const DAEMON_TIMEOUT = 2 * 60 * 1000;

const SimpleAnalyticsAPI = {

    async trackSession(userId, userRole) {
        let key = `simple_analytics_${userId}`;
        let val = ls(key);
        if (val == undefined) {
            ls(key, {
                trackingTimestamp: +new Date(),
                lastActivityTimestamp: +new Date()
            })
        }
        let t = val?.lastActivityTimestamp;
        let now = +new Date();
        console.log('lastActivityTimestamp = ', t);
        if (t != undefined && +now - +t < +SESSION_TIMEOUT) {
            console.log('delta = ', +now - +t);
            return;
        }
        try {
            console.log('! tracking!!!');
            let pld = (await axios.post(`${DEFAULT_VF_ANALYTICS_ENDPOINT}/track-user-activity`, {
                userId: userId,
                userRole: userRole
            })).data;
            console.log('sessions has been tracked: pld = ', pld);
            ls(key, {
                trackingTimestamp: +new Date(),
                lastActivityTimestamp: +new Date()
            });
        } catch (exc) {
            console.log('can not track! exc = ', exc);
        }
    },

    async getAllTrackedSessions(userId) {
        try {
            let pld = (await axios.get(`${DEFAULT_VF_ANALYTICS_ENDPOINT}/track-user-activity/${userId}`)).data;
            return pld;
        } catch (exc) {

        }
        return [];
    },

    async runTracking(userId, userRole) {
        console.log('SimpleAnalyticsAPI: runTracking: userId, userRole = ', userId, userRole);
        if (userId == undefined) {
            return;
        }
        while (true) {
            try {
                await this.trackSession(userId, userRole);
            } catch (exc) {

            }
            await timeout(DAEMON_TIMEOUT);
        }
    }

}

const timeout = ms => new Promise(res => setTimeout(res, ms));

export default SimpleAnalyticsAPI;
