import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton} from "../../ira/ui/Buttons";

let regex = /\[\[[a-zA-Z0-9а-яА-ЯёЁ,;|\u0590-\u05FF -\\"_'’]*\]\]/g;

function checkIfCorrect(userAnswer, correctTextWithDelimiters = '') {
    if (userAnswer == undefined || userAnswer.length == 0 || correctTextWithDelimiters == undefined) {
        return false;
    }
    let arr = (correctTextWithDelimiters || '').split('|').map(x => x.toLowerCase().trim());
    let s = userAnswer.toLowerCase().trim();
    return arr.reduce((f, a) => (f || (a == s)), false);
}

function removeOne(items, x) {
    let arr = [];
    let f = false;
    for (let i in items) {
        let x_ = items[i];
        if (x_ == x && f == false) {
            f = true;
            continue;
        }
        arr.push(x_);
    }
    return arr;
}

function getRestWords(userAnswer, words) {
    let arr = [];
    let items = userAnswer.split('|').filter(x => (x != ''));
    let xArr = JSON.parse(JSON.stringify(items));
    for (let i in words) {
        let w = words[i];
        if (xArr.indexOf(w) == -1) {
            arr.push(w);
        } else {
            xArr = removeOne(xArr, w);
        }
    }
    return arr.sort((a, b) => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    });
}

function getWords(text) {
    try{
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        const found = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', ''));
        return found;
    }catch(exc){

    }
    return [];
}

function getWordsVariants(text) {
    try{
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        let found0 = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', ''));
        const found = found0.map(xx => xx.split('|')).reduce((xArr, a) => ([...xArr, ...a]), []).map(xx => xx.split('&')).reduce((xArr, a) => ([...xArr, ...a]), []).map(xx => xx.trim());
        return found;
    }catch(exc){
        console.log('getWordsVariants: exc = ', exc);
    }
    return [];
}

function getItems(text) {
    if (text.length < 3) {
        return [];
    }
    let words = getWords(text);
    let items = [];
    let currentItem = '';
    let startIndex = 0;
    let k = 0;
    for (let i = 1; i < text.length; i++) {
        let currChar = text[+i];
        let prevChar = text[+i - 1];
        if (`${prevChar}${currChar}` == '[[') {
            let ss = text.slice(+startIndex, +i - 1);
            items.push({
                type: 'out',
                text: ss
            });
        }
        if (`${prevChar}${currChar}` == ']]') {
            startIndex = +i + 1;
            items.push({
                type: 'word',
                text: words[k],
                index: k
            });
            k = +k + 1;
        }
    }
    items.push({
        type: 'out',
        text: text.slice(startIndex)
    })
    return items;
}

function getDefaultUserAnswer(s, words) {
    if (s == undefined || s.split('|').length != words.length) {
        return words.map(x => '').join('|');
    }
    return s;
}

function getUserWordByUserAnswer(s, index) {
    let arr = s.split('|');
    if (+index > arr.length - 1) {
        return ''
    }
    return arr[+index];
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function InputFillGapsExercise(props) {
    const {
        // text = 'This is a [[test|qq|ww]] exercise for the [[university]] team. This [[text]] will be parsed. This is a [[A]] [[A]].',
        text = '',
        onSave = (x, isCorrect) => {

        },
        canAnswer = true,
        showCorrectAnswer = false,
        loading = false
    } = props;
    const items = getItems(text);
    const words = getWords(text);

    const [userAnswer, setUserAnswer] = useState(getDefaultUserAnswer(props.userAnswer, words));
    const [updatedSomething, setUpdatedSomething] = useState(false);
    const [isShowAnswer, setIsShowAnswer] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    console.log('InputFillGapsExercise: props.userAnswer = ', props.userAnswer);
    
    useEffect(() => {
        setUserAnswer(getDefaultUserAnswer(props.userAnswer, getWords(props.text)));
    }, [props.text, props.userAnswer])

    let wordsVars = getWordsVariants(text);
    let bottomWords = getRestWords(userAnswer, wordsVars);

    // useEffect(() => {
    //     if (loading == true || props.canAnswer == false || updatedSomething == false) {
    //         return;
    //     }
    //     let answeredAll = (userAnswer.split('|').filter(x => (x.length > 0)).length == words.length);
    //     if (answeredAll == true) {
    //         let f = true;
    //         let xArr = userAnswer.split('|');
    //         for (let j in xArr) {
    //             // if (xArr[j] != words[j]) {
    //             if (!checkIfCorrect(xArr[j], words[j])) {
    //                 f = false;
    //             }
    //         }
    //         if (props.canAnswer == false) {
    //             return;
    //         }
    //         onSave(userAnswer, f);
    //     }
    // }, [userAnswer, props.canAnswer, updatedSomething]);

    let answeredAll = (userAnswer.split('|').filter(x => (x.length > 0)).length == words.length);

    return (
        <Wrapper>

            <TopPlaceholder style={{textAlign: isHebrew(text) ? 'right' : 'left'}} dir={isHebrew(text) ? 'rtl' : 'ltr'}>
                {items.map((a, i) => {
                    let {type} = a;
                    let uWord = (a.type == 'word') ? getUserWordByUserAnswer(userAnswer, a.index) : '';
                    // let isCorrect = (uWord != undefined && uWord.length > 0 && `${a.text}`.indexOf(uWord) > -1);
                    let isCorrect = checkIfCorrect(uWord, a.text);
                    return (
                        <ItemSpan key={i} isFinished={isFinished}>
                            {type == 'out' ? <span><span
                                dangerouslySetInnerHTML={{__html: `${a.text}`.replace(/\n/g, '<br/>')}}></span></span> : null}
                            {type == 'word' ? <Word
                                isCorrect={(showCorrectAnswer == false) ? undefined : isCorrect}
                                hasWord={uWord != ''}
                                onClick={() => {
                                    if (uWord != '' && (showCorrectAnswer == true)) {
                                        if (isShowAnswer.includes(a.text)) {
                                            setIsShowAnswer(
                                                isShowAnswer.filter(b => b !== a.text)
                                            )
                                        }
                                        else {
                                            setIsShowAnswer([
                                                ...isShowAnswer,
                                                a.text
                                            ])
                                        }
                                    }
                                }}
                                >
                                <span>
                                    <Input
                                        readOnly={uWord != '' && (showCorrectAnswer == true)}
                                        isFinished={isFinished}
                                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                        value={uWord}
                                        onChange={evt => {
                                            let s = evt.target.value;
                                            if (canAnswer == false) {
                                                return;
                                            }
                                            s = s.replace(/’/g, "'");
                                            let uArr = userAnswer.split('|');
                                            let newUArr = JSON.parse(JSON.stringify(uArr));
                                            newUArr[a.index] = s;
                                            setUserAnswer(newUArr.join('|'));
                                            setUpdatedSomething(true);
                                        }}
                                        />

                                        {isShowAnswer.includes(a.text) ? 
                                            <div> {a.text} </div>
                                            : 
                                            null
                                        }

                                </span>
                            </Word> : null}
                        </ItemSpan>
                    )
                })}
            </TopPlaceholder>

            {(answeredAll == false || canAnswer == false)  || isFinished ? null :
                <BottomAnswerPlaceholder>
                        <BlueButton onClick={async () => {
                            let f = true;
                            let xArr = userAnswer.split('|');
                            for (let j in xArr) {
                                if (!checkIfCorrect(xArr[j], words[j])) {
                                    f = false;
                                }
                            }
                            onSave(userAnswer, f);
                            setIsFinished(true)
                        }}>
                            <Spin visible={loading}/>
                                {loading == true ? null :
                                    'Ответить'
                                }
                        </BlueButton>
                </BottomAnswerPlaceholder>
            }

        </Wrapper>
    );
}

const Input = styled.input`
  outline: none;
  box-sizing: border-box;
  //width: 140px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  width: 100%;

  cursor: ${props => (props.isFinished ? "pointer" : "text")};

  :focus {
    border-color: blue;
  }
`;

const Wrapper = styled.div`

`;

const BottomAnswerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Word = styled.span`
  background: ${props => (props.isCorrect == undefined ? 'whitesmoke' : (props.isCorrect == true ? 'lightgreen' : 'coral'))};
  min-width: 60px;
  border-radius: 6px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  text-align: center;
  margin: 5px;
`;

const ItemSpan = styled.span`
  margin-left: 5px;
  right: 5px;
  cursor: ${props => (props.isFinished ? "pointer" : "default")};
`;

const TopPlaceholder = styled.div`
  padding: 10px;
`;

const BottomPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
`;

const BotWord = styled.div`
  display: inline-block;
  margin: 5px;
  border-radius: 6px;
  border: 1px dashed grey;
  background-color: whitesmoke;
  padding-left: 15px;
  padding-right: 15px;
  cursor: grab;

  :hover {
    background-color: floralwhite;
  }
`;