import * as types from '../ActionTypes.js'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    treadmillsMap: Map(),

    selectedTreadmillId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TreadmillsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_TREADMILL:
        case types.UPDATE_TREADMILL:
        case types.DELETE_TREADMILL:
        case types.LOAD_TREADMILLS:
            return startLoading(state, action)

        case types.CREATE_TREADMILL_FAIL:
        case types.UPDATE_TREADMILL_FAIL:
        case types.DELETE_TREADMILL_FAIL:
        case types.LOAD_TREADMILLS_FAIL:
            return stopLoading(state, action)

        case types.CREATE_TREADMILL_SUCCESS:
        case types.UPDATE_TREADMILL_SUCCESS:
            return {
                ...state,
                loading: false,
                treadmillsMap: state.treadmillsMap.set(action.treadmill.id, action.treadmill)
            }

        case types.DELETE_TREADMILL_SUCCESS:
            return {
                ...state,
                loading: false,
                treadmillsMap: state.treadmillsMap.delete(action.id)
            }

        case types.LOAD_TREADMILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                treadmillsMap: state.treadmillsMap.merge(action.treadmills.reduce((mp, ex) => mp.set(ex.id, ex), Map()))
            }

        default:
            return state;

    }

}

export default TreadmillsReducer;