import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import StatsAPI from "../../../api/StatsAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import './style.css'

export default function GroupStatsTool(props) {

    const {groupId} = props;

    const [loading, setLoading] = useState(false);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        if (groupId == undefined) {
            return;
        }
        setLoading(true);
        StatsAPI.getClassStats(groupId).then(data => {
            setStudents(data);
            setLoading(false);
        });
    }, [groupId])

    if (students.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let groupStats = {
        speakingSeconds: 0,
        treadmillsSeconds: 0,
        viewingSeconds: 0,
        listeningItemsNumber: 0,
        exercisesNumber: 0,
    };


    if (students.length > 0) {
        students.forEach(student => {
            groupStats.speakingSeconds += +student?.stats?.speakingSeconds;
            groupStats.treadmillsSeconds += +student?.stats?.treadmillsSeconds;
            groupStats.viewingSeconds += +student?.stats?.viewingSeconds;
            groupStats.listeningItemsNumber += +student?.stats?.listeningItemsNumber;
            groupStats.exercisesNumber += +student?.stats?.exercisesNumber;
        });

        groupStats.speakingSeconds = Math.floor(groupStats.speakingSeconds / students.length);
        groupStats.treadmillsSeconds = Math.floor(groupStats.treadmillsSeconds / students.length);
        groupStats.viewingSeconds = Math.floor(groupStats.viewingSeconds / students.length);
        groupStats.listeningItemsNumber = Math.floor(groupStats.listeningItemsNumber / students.length);
        groupStats.exercisesNumber = Math.floor(groupStats.exercisesNumber / students.length);
    }


    return (
        <Wrapper>

            <InnerWrapper>

                <TableWrapper>

                    <h2>Статистика группы</h2>

                    <table className={'student-table'}>
                        <thead>
                        <tr>
                            <th>Среднее время наговоров (без учета тредмила)</th>
                            <th>Среднее время наговоров (тредмил)</th>
                            <th>Среднее время воспр. видео</th>
                            <th>Среднее время воспр. аудио</th>
                            <th>Среднее кол-во вып. упр-й</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{formatTime(groupStats.speakingSeconds)}</td>
                            <td>{formatTime(groupStats.treadmillsSeconds)}</td>
                            <td>{formatTime(groupStats.viewingSeconds)}</td>
                            <td>{formatTime(groupStats.listeningItemsNumber)}</td>
                            <td>{groupStats.exercisesNumber}</td>
                        </tr>
                        </tbody>
                    </table>
                </TableWrapper>




                <TableWrapper>

                    <h2>Статистика студентов</h2>

                    <table className={'student-table'}>
                        <thead>
                        <tr>
                            <th>Студент</th>
                            <th>Общее время наговоров (без учета тредмила)</th>
                            <th>Общее время наговоров (тредмил)</th>
                            <th>Общее время воспр. видео</th>
                            <th>Общее время воспр. аудио</th>
                            <th>Общее кол-во вып. упр-й</th>
                        </tr>
                        </thead>
                        <tbody>
                        {students.map((student, i) => {
                            return (
                                <tr key={student.id}>
                                    <td>{CommonHelper.getUserName(student)}</td>
                                    <td>{formatTime(student?.stats?.speakingSeconds)}</td>
                                    <td>{formatTime(student?.stats?.treadmillsSeconds)}</td>
                                    <td>{formatTime(student?.stats?.viewingSeconds)}</td>
                                    <td>{formatTime(student?.stats?.listeningItemsNumber)}</td>
                                    <td>{student?.stats?.exercisesNumber}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </TableWrapper>


            </InnerWrapper>


        </Wrapper>
    );
}

const TableWrapper = styled.div`
  padding: 15px;
`;

const InnerWrapper = styled.div`
  background: white;
`;

const Wrapper = styled.div`
  padding: 20px;
`;

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}