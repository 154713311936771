import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import Select from 'react-select';

import {A1, A2, B1, B2, C1} from "./grammar_configs";

function getOptions(level) {
    let l = `${level}`.toUpperCase();
    if (l == 'A1') {
        return A1;
    }
    if (l == 'A2') {
        return A2;
    }
    if (l == 'B1') {
        return B1;
    }
    if (l == 'B2') {
        return B2;
    }
    if (l == 'C1') {
        return C1;
    }
    if (l == 'ANY'){
        return [...A1, ...A2, ...B1, ...B2, ...C1];
    }
    return [];
}

export default function SmartGrammarSelector(props) {
    const {
        level = undefined,
        allOptions = [],
        value = [], onChange = newVal => {

        }
    } = props;
    let options = (allOptions != undefined && allOptions.length > 0) ? allOptions : getOptions(level);
    console.log('options before: options = ', options);
    options = options.map(x => ({label: x, value: x}));
    // console.log('options after = ', options);
    console.log('SmartGrammarSelector: render: value = ',value);

    return (
        <Wrapper>
            <Select options={options}
                    isMulti={true}
                    value={options.filter(xx => (value.indexOf(xx?.value) > -1))}
                    onChange={x => {
                        console.log('onChange: x = ', x);
                        onChange((x || []).map(xx => xx.value));
                    }}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;