import TeacherTemplate from "../../templates/TeacherTemplate";
import GroupDiaryPanel from "./GroupDiaryPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useMappedState} from "redux-react-hook";
import Select from "react-select";
import { translate } from "../../../utils/Translate";

export default function GroupDiaryPanelApp(props) {
    const {

    } = props;

    let groupParams = props.match.params;
    let id = groupParams.id;

    const {
        group,
        groups = []
    } = useMappedState(useCallback(state => {
        return {
            group: state.groups.groupsMap.get(id),
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            })
        }
    }));

    let selectedGroup = id;
    let group_name = group?.name || '...';
    let groupsNames = groups.map(x => ({value: x.id, label: x.name}))

    return (
        <TeacherTemplate
            active={`diary/${selectedGroup}`}>

            <TopPlaceholderL>

                <TopLeft>

                    <BackSpan
                        style={{marginLeft: 0}}>
                        {translate('diary')}
                    </BackSpan>

                    <span style={{marginRight: 10}}>
                        <div style={{fontSize: '16px'}}>
                            {' / '}
                        </div>
                    </span>

                    <GSpan>
                        <span style={{fontWeight: 'bold', opacity: 0.65}}>
                            <div style={{marginLeft: 5, width: 220}}>
                                <Select
                                    isClearable={false}
                                    placeholder={group_name}
                                    options={groupsNames}
                                    value={groupsNames.filter(xx => (xx?.value == groupsNames))[0]}
                                    onChange={a => {
                                        CommonHelper.linkTo(`/diary/${a.value}`);
                                    }}
                                />
                            </div>
                        </span>
                    </GSpan>

                </TopLeft>

            </TopPlaceholderL>

            <GroupDiaryPanel
                id={id}
            />

        </TeacherTemplate>

    )
}

const TopPlaceholderL = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const BackSpan = styled.span`
  opacity: 0.9;
  font-size: 18px;
  margin-right: 10px;

  @media (max-width: 1300px) {
    font-size: 12px;
  }
  
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

const GSpan = styled.span`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;