import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import BrutalOverlayModal from "../modals/BrutalOverlayModal";
import TeacherAccessAPI from "../../api/TeacherAccessAPI";
import UsersAPI from "../../api/UsersAPI";

export default function TeacherAccessTool(props) {
    const {
        currentUser
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    const [hasAccess, setHasAccess] = useState(true);

    useEffect(() => {
        if (currentUser == undefined) {
            return;
        }
        TeacherAccessAPI.checkIfAccessGranted().then((access) => {
            setHasAccess(access);
        });
    }, [currentUser]);

    let isGranted = currentUser != undefined && (hasAccess == true);
    if (isGranted == true) {
        return null;
    }

    return (
        <BrutalOverlayModal hasClose={false}
                            zIndex={10000}
                            onClose={() => {
                                window.alert('Использовать платформу можно только после полчениения доступа.');
                            }}>
            <Wrapper>
                <ContentPlaceholder>
                    {/*<ConsentText/>*/}
                    <div>
                        Если вы потеряли доступ на платформу, это означает, что вы не работаете в университетах (ТГУ и
                        КУЗГТУ) или не заплатили за использование платформы.
                        Если вы участвовали программе "Содействие занятости" (второе полугодие 2024 г.), то вы получите
                        доступ на платформу в ближайшие сутки.
                    </div>
                </ContentPlaceholder>

                <ButtonsPlaceholder>

                    Если у вас есть вопросы, напишите в поддержку или на почту lirmakym@mail.ru

                    {/*<BlueButton onClick={async () => {*/}
                    {/*    await dispatch(actions.updateUser({*/}
                    {/*        ...currentUser,*/}
                    {/*        consentGranted: true*/}
                    {/*    }))*/}
                    {/*}}>*/}
                    {/*    Я прочитал (-ла) условия соглашения и принимаю их*/}
                    {/*</BlueButton>*/}

                    <div style={{marginTop: '20px'}} >
                        <ButtonSpan onClick={async () => {
                            await UsersAPI.logout();
                            window.alert('К сожалению, Вы не можете использовать платформу.');
                            window.location.reload();
                        }}>
                            Выход
                        </ButtonSpan>
                    </div>


                </ButtonsPlaceholder>

            </Wrapper>
        </BrutalOverlayModal>

    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentPlaceholder = styled.div`
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  width: 1020px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 20px;
  font-size: 24px;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonSpan = styled.span`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: underline;
  margin-top: 20px;
`;