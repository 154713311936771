import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {LineChart, XAxis, YAxis, CartesianGrid, Line} from 'recharts'

export default function AllActiveUsersStatsTool(props) {
    const [days, setDays] = useState([]);
    const [uData, setUData] = useState(undefined);

    useEffect(() => {
        axios.get(`https://api.analytics.entsu.englishpatient.org/users-number-by-days`, {
            timeout: 300 * 1000
        }).then(d => d.data.result).then(arr => {
            console.log('arr = ', arr);
            setDays(arr);
            axios.get('https://api.analytics.entsu.englishpatient.org/unique-users').then(d => d.data).then(x => {
                setUData(x);
            })
        });
    }, []);

    console.log('days = ', days);

    return (
        <Wrapper>

            <ChartPlaceholder>
                <LineChart width={700} height={380} data={days} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           tickFormatter={a => {
                               return (moment(a).format('DD.MM.YYYY'))
                           }}
                    />
                    <YAxis/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'number'} stroke="#D92A3E" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                </LineChart>
            </ChartPlaceholder>

            <ItemsPlaceholder>
                {days.map((a, i) => {
                    return (
                        <Row key={a.t}>
                            <div>
                                {moment(a.t).format('DD.MM.YYYY')}
                            </div>
                            <div>
                                {a.number}
                            </div>
                        </Row>
                    )
                })}
            </ItemsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 720px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid lightgrey;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 420px;
`;

const ItemsPlaceholder = styled.div`

`;