import React from 'react';
import styled from 'styled-components';
import Immutable from 'immutable'

import debounce from 'debounce'

import pencil_image from '../../assets/img/pencil_image.png'
import {isMobile} from "polotno/utils/screen";
// const pencil_image = require('../../assets/img/pencil_image.png');

class SimpleCanvasPanel extends React.Component {

    static defaultProps = {
        brushColor: 'pink',
        strokeWidth: 1,

        lines: [],
        debounceInterval: 300,
        onLinesChange: (newLines) => {

        }

    }

    state = {
        lines: Immutable.fromJS(this.props.lines),
        isDrawing: false
    }

    constructor(props) {
        super(props);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    componentDidMount() {

        // document.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        // document.removeEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseDown(mouseEvent) {
        if (mouseEvent.button != 0) {
            return;
        }

        const point = this.relativeCoordinatesForEvent(mouseEvent);

        this.setState(prevState => ({
            lines: prevState.lines.push(new Immutable.List([point])),
            isDrawing: true
        }));
    }

    handleMouseMove(mouseEvent) {
        if (!this.state.isDrawing) {
            return;
        }

        const point = this.relativeCoordinatesForEvent(mouseEvent);

        this.setState(prevState => ({
            lines: prevState.lines.updateIn([prevState.lines.size - 1], line => line.push(point))
        }), newState => {
            this.onDebouncedChange(this.state.lines.toJS())
        });
    }

    onDebouncedChange = debounce((newLines) => {
        this.props.onLinesChange(newLines);
    }, this.props.debounceInterval)

    handleMouseUp() {
        this.setState({isDrawing: false});
    }

    relativeCoordinatesForEvent(mouseEvent) {
        const boundingRect = this.drawArea.getBoundingClientRect();
        return new Immutable.Map({
            x: mouseEvent.clientX - boundingRect.left,
            y: mouseEvent.clientY - boundingRect.top,
        });
    }

    componentWillReceiveProps(nextProps) {
        // if (JSON.stringify(this.props.lines) != JSON.stringify(nextProps.lines.length)){
        //     this.setState({
        //         lines: Immutable.fromJS(nextProps.lines)
        //     });
        // }
        let getN = lns => lns.reduce((total, ln) => (+total + ln.length), 0);
        if (this.props.lines != undefined && nextProps.lines != undefined && getN(this.props.lines) != getN(nextProps.lines)) {
            this.setState({
                lines: Immutable.fromJS(nextProps.lines)
            });
        }

    }

    render() {
        let {brushColor, width, height, strokeWidth} = this.props;
        let st = {};
        if (width != undefined && height != undefined) {
            st = {width, height}
        }

        return (
            <Wrapper
                style={st}
                ref={r => this.drawArea = r}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
            >

                <Drawing lines={this.state.lines} brushColor={brushColor} strokeWidth={strokeWidth}/>

                {isMobile() == true ? null :
                    <RemovePlaceholder>
                        <RemoveButton onClick={() => {
                            this.props.onLinesChange([[{x: 0, y: 0}]]);
                        }}>
                            Delete drawings
                        </RemoveButton>
                    </RemovePlaceholder>
                }
            </Wrapper>
        );
    }
}

const RemovePlaceholder = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: none;
  z-index: 4;
`;

const Path = styled.path`
  fill: none;
  stroke-width: 1px;
  stroke: black;
  stroke-linejoin: round;
  stroke-linecap: round;
`;


const DrawingSvg = styled.svg`
  width: 100%;
  height: 100%;
  cursor: url(${pencil_image}), auto;
    // &:hover ${RemovePlaceholder}{
  //   display: block;
  // }
`;

const Wrapper = styled.div`
  //width: 400px;
  //height: 400px;
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  float: left;
  cursor: crosshair;
  position: relative;

  &:hover ${RemovePlaceholder} {
    display: block !important;
  }
`;

const RemoveButton = styled.div`
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 3px;

  :hover {
    background-color: blue;
    opacity: 1;
  }
`;


function Drawing({lines, brushColor, strokeWidth}) {
    return (
        <DrawingSvg>
            {lines.map((line, index) => (
                <DrawingLine key={index} line={line} brushColor={brushColor} strokeWidth={strokeWidth}/>
            ))}
        </DrawingSvg>
    );
}

function DrawingLine({line, brushColor, strokeWidth}) {
    const pathData = "M " +
        line
            .map(p => {
                return `${p.get('x')} ${p.get('y')}`;
            })
            .join(" L ");

    return <Path style={{stroke: brushColor, strokeWidth: strokeWidth}} d={pathData}/>;
}

export default SimpleCanvasPanel;
