import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import watchImage from './stopwatch.svg'
import TextSlide from "../slides/TextSlide";
import {SAMPLE_ITEMS} from "../config";
import VideoSlide from "../slides/VideoSlide";
import UserTextInputTool from "./UserTextInputTool";
import ExerciseAnswerInputTool from "../../../exercises/tools/ExerciseAnswerInputTool";
import NiceModal from "../../../modals/NiceModal";
import TreadmillRecordComponent from "../../../record/TreadmillRecordComponent";
import axios from "axios";
import {API_ENDPOINT} from "../../../../constants/config";
import SliderTreadmillTool from "./SliderTreadmillTool";
import mic from "../../../../assets/img/microphone.gif";
import keyboard_svg from "../../../../assets/img/pen_svg.svg";
import RoundCountDown from "./RoundCountDown";
import DiffHelper from "../../../../helpers/DiffHelper";
import { isMobile } from 'polotno/utils/screen';

export const getCurrentItem = (arr, t) => {
    for (let i in arr) {
        let {from, to} = arr[i];
        if (+t >= +from && +t < +to) {
            return arr[i];
        }
    }
    return undefined;
}

let getH = () => {
    try {
        return document.getElementsByClassName('treadmill_player_tool_slide_placeholder')[0].clientHeight;
    } catch (exc) {

    }
}

let regex = /\[\[[a-zA-Z0-9а-яА-Я,;|\u0590-\u05FF -\\"_']*\]\]/g;

function getVariants(text) {
    if (text == undefined || text.length < 3) {
        return [];
    }
    if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
        return [];
    }
    const found = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', ''));
    return found;
}

async function checkText(item, text) {
    let variants = getVariants(item.correctAnswer);
    if (variants.length === 0) {
        variants = [item.correctAnswer];
    }
    let correctAnswer = variants.join(' | ');
    let max = 0;
    let result;
    for (let variant of variants) {
        if (variant.trim() !== '') {
            let pld = DiffHelper.getScore(text, variant);

            if (pld?.resultScore >= max) {
                max = pld.resultScore;
                let mark;
                if (pld.resultScore >= item.scale.scale.a) {
                    mark = 5;
                } else if (pld.resultScore >= item.scale.scale.b) {
                    mark = 4;
                } else if (pld.resultScore >= item.scale.scale.c) {
                    mark = 3;
                } else {
                    mark = 2;
                }
                result = {
                    id: item.id,
                    answer: text,
                    correctAnswer: correctAnswer,
                    resultScore: pld.resultScore,
                    mark: mark
                }
            }
        } else {
            if (max === 0) {
                result = {
                    id: item.id,
                    answer: text,
                    correctAnswer: correctAnswer,
                    resultScore: 0,
                    mark: 2
                }
            }
        }
    }
    return result;
}

export default function TreadmillPlayerTool(props) {
    const {
        currentUser,
        dt = 1,
        audio,
        video,
        isFinished = false,
        treadmillTime,
        isPreview = false,
        shareMode = false,
        items = SAMPLE_ITEMS,
        timerValue = 0,
        settings,
        isPaused = false,
        isTeacher = false,
        onAnswer = (answer) => {

        },
        setTime = () => {

        },
        setPause = (val) => {

        },
        setCurrentTime = d => {

        },
        setCurrentSlide = d =>{

        },
        onMineAnswer = (id, blob, correctAnswer, language) => {

        },
        onVoiceAnswer = (id, blob) => {

        },
        onLoading = () => {

        },
        canAnswer = true,
        onAudioError = () => {

        }
    } = props;

    const [videoTime, setVideoTime] = useState(0);
    const time = useRef();
    const timerSpanRef = useRef();
    const [currentItem, setCurrentItem] = useState(items.length == 0 ? undefined : getCurrentItem(items, +timerValue.time));
    const [updT, setUpdT] = useState(0);
    const [copyrightText, setCopyrightText] = useState(undefined);
    const [answers, setAnswers] = useState([]);
    const [text, setText] = useState('');
    const [slideHeight, setSlideHeight] = useState();

    useEffect(() => {
        time.current = +timerValue?.time;
        setTime(time.current);
        setPause(false);
    },[]);

    useEffect(() => {
        setSlideHeight(getH());
        if (currentItem != undefined) {
            setCurrentSlide(currentItem.id);
        }
    }, [currentItem])

    useEffect(() => {
        time.current = +timerValue?.time;
        setCurrentTime(timerValue)
    }, [timerValue]);

    useEffect(() => {
        if (isPreview && time.current === treadmillTime) {
            setPause(true);
        }
    }, [time.current])

    useInterval(() => {
        try {
            if (!isPaused) {
                time.current = +time.current + dt;
                setCurrentTime({time: time.current});
            }
            let s = `${time.current.toFixed(0)} s`
            timerSpanRef.current.innerHTML = s;
            // setTime(time.current);
        } catch (exc) {

        }

        let item = getCurrentItem(items, time.current);
        if (currentItem == undefined || (item != undefined && currentItem != undefined && item.from != currentItem.from)) {
            setCurrentItem(item);
        } else {
            if (item == undefined && currentItem != undefined) {
                setCurrentItem(item);
            }
        }
        setUpdT(+new Date());

    }, dt * 1000);

    if (items.length == 0) {
        return null;
    }

    let isCurrentItemText = (currentItem != undefined && (currentItem.videoSrc == undefined || currentItem?.isPlug === true));
    let isCurrentItemVideo = (currentItem != undefined && currentItem.videoSrc != undefined && (currentItem.isPlug === undefined || currentItem?.isPlug === false));

    let timeFromSlideStart = (currentItem == undefined) ? undefined : (time.current - currentItem.from);
    let timeBeforeSlideEnd = (currentItem == undefined) ? undefined : (currentItem.to - time.current);
    let currentDuration = (currentItem == undefined) ? undefined : (currentItem.duration == undefined ? (currentItem.to - currentItem.from) : currentItem.duration);

    let currentProgress = (currentDuration == undefined || timeFromSlideStart == undefined) ? undefined : 100.0 * timeFromSlideStart / currentDuration;

    let hasAtLeastOneVoice = items.reduce((f, x) => (f || (x.inputType == 'voice')), false);
    let shouldRecord = currentItem !== undefined && ((currentItem.hasMine === true && (settings === undefined || settings?.recordMines === true)) || currentItem?.inputType === 'voice');
    let currentIcon = currentItem === undefined ? undefined : currentItem?.inputType === 'text' ? 'keyboard' : currentItem?.inputType === 'voice' || currentItem?.hasMine === true ? 'mic' : undefined;

    return (
        <Wrapper>
            <TopPlaceholder $mobile={isMobile()}>
                <TopLeftPlaceholder>
                    <WatchImage src={watchImage}/>
                    <TimerPlaceholder ref={timerSpanRef}></TimerPlaceholder>
                </TopLeftPlaceholder>
                <TopCenterPlaceholder>
                    {currentIcon === undefined ? null :
                        currentIcon === 'keyboard' ?
                            <HelpIcons src={keyboard_svg}/>
                            :
                            <MicrophoneIcon $url={mic} $mobile={isMobile()} />
                    }
                </TopCenterPlaceholder>
            </TopPlaceholder>
            <SubTopPlaceholder>
                {(currentDuration == undefined || currentItem.hasCountDown === undefined || currentItem.inputType === 'text' ||  currentItem?.hasCountDown === false) ? null :
                    currentProgress == undefined ? null :
                        <InnerProgress style={{width: `${currentProgress}%`}}>
                            {currentProgress < 5 ? null :
                                <span>
                                    {timeFromSlideStart.toFixed(0)}
                                </span>
                            }
                        </InnerProgress>

                }
            </SubTopPlaceholder>
            <BottomPlaceholder>
                <ContentSlidePlaceholder>
                    <SliderPlaceholder className={'treadmill_player_tool_slide_placeholder'}>
                        {(isCurrentItemText == false || isCurrentItemVideo == true) ? null :
                            <React.Fragment>
                                <TextSlide
                                    audio={audio}
                                    id={currentItem?.id}
                                    slideHeight={slideHeight}
                                    currentTime={time.current || 0}
                                    timeFromSlideStart={timeFromSlideStart}
                                    timeBeforeSlideEnd={timeBeforeSlideEnd}
                                    isPaused={isPaused}
                                    audioTime={timerValue.time - currentItem.from}
                                    {...currentItem}
                                />
                            </React.Fragment>
                        }
                        {isCurrentItemVideo == false ? null :
                            <React.Fragment key={`${currentItem.id}_${currentItem.from}`}>
                                <VideoSlide
                                    audio={audio}
                                    video={video}
                                    id={currentItem?.id}
                                    isPaused={isPaused}
                                    currentTime={time.current || 0}
                                    videoTime={timerValue.time - currentItem.from}
                                    timeFromSlideStart={timeFromSlideStart}
                                    timeBeforeSlideEnd={timeBeforeSlideEnd}
                                    {...currentItem}
                                />
                            </React.Fragment>
                        }
                    </SliderPlaceholder>

                    {currentItem == undefined || (currentItem.inputType != 'text') || isMobile() ? null :
                        <CenterContentInputPlaceholder>
                            <UserTextInputTool
                                {...currentItem}
                                time={+currentItem.to - +currentItem.from}
                                isPreview={isPreview}
                                onAnswer={async (t) => {
                                        if (currentItem?.textTranslate != true) {
                                            let ans = await checkText(currentItem, t);
                                            console.log('no translate', ans)
                                            onAnswer(ans);
                                        } else {
                                            let ans = {
                                                id: currentItem.id,
                                                answer: t,
                                                correctAnswer: currentItem?.correctAnswer,
                                                resultScore: -1,
                                                mark: -1
                                            }
                                            console.log('translate ', ans);
                                            onAnswer(ans);
                                        }
                                }
                            }>
                            </UserTextInputTool>
                            
                            {currentItem?.hasCountDown === false ? null :
                                <CountDownPlaceholder>
                                    <RoundCountDown timeLeft={currentItem.to - time.current}
                                                    timeTotal={currentItem.to - currentItem.from}/>
                                </CountDownPlaceholder>
                            }
                        </CenterContentInputPlaceholder>
                    }
                </ContentSlidePlaceholder>

                {currentItem == undefined || (currentItem.inputType != 'text') || isMobile() == false ? null :
                        <MobileTextInputPlaceholder>
                            <UserTextInputTool
                                {...currentItem}
                                time={+currentItem.to - +currentItem.from}
                                isPreview={isPreview}
                                onAnswer={async (t) => {
                                    if (shareMode === false) {
                                        if (currentItem?.textTranslate != true) {
                                            let ans = await checkText(currentItem, t);
                                            onAnswer(ans);
                                        } else {
                                            let ans = {
                                                id: currentItem.id,
                                                answer: t,
                                                correctAnswer: currentItem?.correctAnswer,
                                                resultScore: -1,
                                                mark: -1
                                            }
                                            onAnswer(ans);
                                        }
                                    }
                                }
                            }>
                            </UserTextInputTool>
                            
                            {currentItem?.hasCountDown === false ? null :
                                <CountDownPlaceholder>
                                    <RoundCountDown
                                        timeLeft={currentItem.to - time.current}
                                        timeTotal={currentItem.to - currentItem.from}
                                        maxSize={70}
                                    />
                                </CountDownPlaceholder>
                            }
                        </MobileTextInputPlaceholder>
                    }

                <CopyrightPlaceholder>
                    {currentItem?.copyrightDescription === undefined || currentItem?.copyrightDescription.trim() === '' ? null :
                        <CopyrightSpan onClick={() => {
                            setCopyrightText(currentItem.copyrightDescription);
                        }}>
                            источник
                        </CopyrightSpan>
                    }
                </CopyrightPlaceholder>

            </BottomPlaceholder>

            {currentItem === undefined || isTeacher || isPreview || shareMode || currentUser?.id === undefined || shouldRecord === false ? null :
                <div style={{width: '0px', height: '0px'}}>
                    <TreadmillRecordComponent
                        id={currentItem?.id}
                        recordingTime={currentItem?.to - +currentItem?.from}
                        setUrl={blob => {
                            if (currentItem?.inputType === 'voice') {
                                onVoiceAnswer(currentItem.id, blob);
                            }
                            if (currentItem?.hasMine === true) {
                                onMineAnswer(currentItem?.id, blob, currentItem?.mineAnswer, currentItem?.mineLanguage?.value);
                            }
                        }}
                        onError={onAudioError}
                    />
                </div>
            }

            {copyrightText == undefined ? null :
                <NiceModal onClose={() => {
                    setCopyrightText(undefined);
                }}>
                    <CpInnerPlaceholder>
                        {copyrightText}
                    </CpInnerPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  height: fit-content;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: white;
`;

const InnerProgress = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background-color: green;
  transition: 0.2s;
  text-align: right;
  box-sizing: border-box;
  color: white;
  padding-right: 5px;
  font-size: 8px;
  line-height: 12px;
`;

const WatchImage = styled.img`
  height: 36px;
  margin-right: 10px;
  
  @media (max-width: 720px) {
      max-height: 36px;
      padding: 7px;
  }
`;

const TimerPlaceholder = styled.div`
  font-size: 20px;
`;

const TopPlaceholder = styled.div`
  height: ${props => props.$mobile ? '40px' : '80px'};
  //background: lightgrey;
  background: white;
  border: 1px solid whitesmoke;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  //gap: calc(50% - 56px);
`;

const HelpIcons = styled.img`
  width: 48px;
  height: 48px;

  @media (max-width: 720px) {
      max-width: 40px;
      max-height: 40px;
      padding: 7px;
      box-sizing: border-box;
  }
  //margin-right: 10px;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const TopCenterPlaceholder = styled.div`
  //position: absolute;
  //left: calc(50% - 24px);
  //left: 50%;
  //scale: 1.5;
  margin-right: 20px;

  @media (max-width: 720px) {
    margin-right: 5px;
  }
`;

const BottomPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ContentSlidePlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SliderPlaceholder = styled.div`
  flex: 4;
  background-color: white;
`;

const CenterContentInputPlaceholder = styled.div`
  flex: 1;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  max-height: 20%;
`;

const CountDownPlaceholder = styled.div`
  aspect-ratio: 1 / 1;
  padding: 2px;
`;

const SubTopPlaceholder = styled.div`
  height: 10px;
  width: 100%;
  background-color: black;
  position: relative;
`;

const CpInnerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 420px;
`;

const CopyrightPlaceholder = styled.div`
  min-height: 12px;
  text-align: right;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
`;

const CopyrightSpan = styled.span`
  font-size: 9px;
  opacity: 0.4;
  font-style: italic;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const MicrophoneIcon = styled.div`
  height: ${props => props.$mobile ? '25px' : '50px'};
  width: ${props => props.$mobile ? '25px' : '50px'};
  background-image: url(${props => props.$url});
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  transform: scale(1.5);
`;

const MobileTextInputPlaceholder = styled.div`
    max-height: 70px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background-color: white;
`;

export function useInterval(callback, delay) {
    const savedCallback = useRef(callback)
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
    useEffect(() => {
        if (delay === null) {
            return
        }
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay])
}
