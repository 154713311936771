import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Input, Textarea} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import ChatGPTAPI from "../../../api/ChatGPTAPI";
import CommonHelper from "../../../helpers/CommonHelper";

export default function GrammarGapsTool(props) {
    const [loading, setLoading] = useState(false);
    const [userAnswer, setUserAnswer] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [extra, setExtra] = useState('');
    const [result, setResult] = useState(undefined);
    const [grammar, setGrammar] = useState('');
    const [n, setN] = useState('');
    const [level, setLevel] = useState('');

    let tasks = (result || []);

    return (
        <Wrapper>

            <Row className={'field'}>
                <Label>
                    Грамматическая тема
                </Label>
                <Textarea value={grammar}
                          style={{
                              lineHeight: '20px',
                              minHeight: '60px'
                          }}
                          placeholder={''}
                          onChange={(evt) => {
                              setGrammar(evt.target.value);
                              setResult(undefined);
                          }}/>
            </Row>

            <Row className={'field'}>
                <Label>
                    Количество заданий
                </Label>
                <Input value={n}
                       placeholder={''}
                       onChange={(evt) => {
                           setN(evt.target.value);
                           setResult(undefined);
                       }}/>
            </Row>

            <Row className={'field'}>
                <Label>
                    Уровень ученика
                </Label>
                <Input value={level}
                       placeholder={''}
                       onChange={(evt) => {
                           setLevel(evt.target.value);
                           setResult(undefined);
                       }}/>
            </Row>

            <Row className={'field'}>
                <Label>
                    Дополнительная инструкция
                </Label>
                <Textarea value={extra}
                          style={{
                              lineHeight: '20px',
                              minHeight: '60px'
                          }}
                          placeholder={''}
                          onChange={(evt) => {
                              setExtra(evt.target.value);
                              setResult(undefined);
                          }}/>
            </Row>

            {result != undefined || (level == '' || n == '') ? null :
                <Row>
                    {loading == true ? 'Загрузка...' :
                        <BlueButton onClick={async () => {
                            setLoading(true);
                            setResult(undefined);
                            let pld = await ChatGPTAPI.getGrammarExercise(grammar, level, n, extra);
                            setResult(pld);
                            setLoading(false);
                        }}>
                            Сгенерировать
                        </BlueButton>
                    }
                </Row>
            }


            {result == undefined ? null :
                <div>
                    <Label>
                        Результат:
                    </Label>
                    <ol>
                        {tasks.map((task, i) => {
                            let {options = [], answer} = task;
                            return (
                                <li key={i}>
                                    <h3>
                                        {task.text}
                                    </h3>
                                    <ul>
                                        {options.map((x, j) => {
                                            let isCorrect = (answer == x);
                                            return (
                                                <li key={j}>
                                                    {x}
                                                    {isCorrect == false ? null :
                                                        <span style={{marginLeft: 10}}> ✔️</span>}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
`;
