import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import FilmsSearchTool from "./FilmsSearchTool";

import {Textarea, Input} from "../ira/ui/Inputs";

export default function FilmsCitationTool(props) {
    const {
        onSelect = d => {

        }
    } = props;
    const [data, setData] = useState(undefined);

    return (
        <Wrapper>

            <div style={{padding: 10}} >
                {data &&
                <CitationQuotePlaceholder>
                    <div>
                        <Label>
                            Цитирование
                        </Label>
                        <Textarea value={data.quote}></Textarea>
                    </div>
                    <div>
                        <Label>
                            Ссылка на источник
                        </Label>
                        <Input value={data.url} />
                    </div>
                </CitationQuotePlaceholder>
                }
            </div>

            <FilmsSearchTool
                onSelect={d => {
                    setData(d);
                    onSelect(d);
                }}
            />

        </Wrapper>
    );
}

const Label = styled.div`
    font-weight: bold;
`;




const SelectSpan = styled.span`
  background: #8C63A9;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 180px;
  font-weight: bold;
  height: 40px;

  :hover {
    opacity: 0.8;
  }
`;


const Wrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;


const CitationQuotePlaceholder = styled.div`
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  background: white;
`;