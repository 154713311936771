import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import KvdbAPI from "../../../api/KvdbAPI";
import Select from "react-select";

export default function TagsSelectTool(props) {
    const {
        prefix = 'grammar',
        isDisabled,
        selectedTags = [],
        placeholder = 'Select tags',
        onChange = newIds => {

        }
    } = props;

    const [name, setName] = useState('');

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        KvdbAPI.getValue(`${prefix}_tags`, []).then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, [prefix]);

    if (items.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    const options = items.map((a, i) => ({label: a.name, value: a.name}));
    const selectedOptions = selectedTags.map(x => (options.filter(xx => xx.value == x)[0])).filter(x => (x != undefined));

    return (
        <Wrapper>

            <Select
                placeholder={placeholder}
                value={selectedOptions}
                isMulti={true}
                isDisabled={isDisabled}
                options={options}
                onChange={a => {
                    console.log('a = ', a);
                    let newIds = a.map(x => x.value);
                    onChange(newIds);
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;