import {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment'

let gn = (x, fld) => (x == undefined || x[fld] == undefined ? [] : x[fld]);

const TasksHelper = {

    getTaskAverageMark(answersMap, task, userId) {
        if (answersMap == undefined || task == undefined) {
            return 0;
        }
        let answers = (answersMap == undefined || answersMap[userId] == undefined ? [] : answersMap[userId]);
        let marksMap = answers.reduce((mp, x) => ({
            ...mp,
            [x.exerciseId]: x.mark
        }), {});
        let exes = gn(task, 'exercisesIds').map(xx => ({
            id: xx,
            type: 'EX'
        }))
            .concat(gn(task, 'testsIds').map(xx => ({
                id: xx,
                type: 'T'
            })))
            .concat(gn(task, 'drochkaExercisesIds').map(xx => ({
                id: xx,
                type: 'C'
            })))
            .concat(gn(task, 'treadmillsIds').map(xx => ({
                id: xx,
                type: 'TR'
            })));

        let marks = exes.map(ex => ({
            exerciseId: ex.id,
            mark: marksMap[ex.id],
            type: ex.type
        })).filter(x => (x.mark != undefined && x.mark > 0));
        let avr = 0;
        if (marks.length > 0) {
            avr = marks.reduce((sm, x) => (+sm + +x.mark), 0) / marks.length;
        }
        return avr;
    }


};

export default TasksHelper;
