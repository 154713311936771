/**
 * Created by sabir on 19.02.17.
 */

//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

//slides

export const CREATE_SLIDE = 'CREATE_SLIDE'
export const CREATE_SLIDE_SUCCESS = 'CREATE_SLIDE_SUCCESS'
export const CREATE_SLIDE_FAIL = 'CREATE_SLIDE_FAIL'

export const UPDATE_SLIDE = 'UPDATE_SLIDE'
export const UPDATE_SLIDE_SUCCESS = 'UPDATE_SLIDE_SUCCESS'
export const UPDATE_SLIDE_FAIL = 'UPDATE_SLIDE_FAIL'

export const DELETE_SLIDE = 'DELETE_SLIDE'
export const DELETE_SLIDE_SUCCESS = 'DELETE_SLIDE_SUCCESS'
export const DELETE_SLIDE_FAIL = 'DELETE_SLIDE_FAIL'

export const LOAD_SLIDES = 'LOAD_SLIDES'
export const LOAD_SLIDES_SUCCESS = 'LOAD_SLIDES_SUCCESS'
export const LOAD_SLIDES_FAIL = 'LOAD_SLIDES_FAIL'

export const SELECT_SLIDE = 'SELECT_SLIDE'


//links
export const LOAD_USER_LINKS = 'LOAD_USER_LINKS'
export const LOAD_USER_LINKS_SUCCESS = 'LOAD_USER_LINKS_SUCCESS'
export const LOAD_USER_LINKS_FAIL = 'LOAD_USER_LINKS_FAIL'

export const CREATE_USER_LINK = 'CREATE_USER_LINK'
export const CREATE_USER_LINK_SUCCESS = 'CREATE_USER_LINK_SUCCESS'
export const CREATE_USER_LINK_FAIL = 'CREATE_USER_LINK_FAIL'

export const UPDATE_USER_LINK = 'UPDATE_USER_LINK'
export const UPDATE_USER_LINK_SUCCESS = 'UPDATE_USER_LINK_SUCCESS'
export const UPDATE_USER_LINK_FAIL = 'UPDATE_USER_LINK_FAIL'

export const DELETE_USER_LINK = 'DELETE_USER_LINK'
export const DELETE_USER_LINK_SUCCESS = 'DELETE_USER_LINK_SUCCESS'
export const DELETE_USER_LINK_FAIL = 'DELETE_USER_LINK_FAIL'


//materials
export const LOAD_MATERIALS = 'LOAD_MATERIALS'
export const LOAD_MATERIALS_SUCCESS = 'LOAD_MATERIALS_SUCCESS'
export const LOAD_MATERIALS_FAIL = 'LOAD_MATERIALS_FAIL'

export const CREATE_MATERIAL = 'CREATE_MATERIAL'
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS'
export const CREATE_MATERIAL_FAIL = 'CREATE_MATERIAL_FAIL'

export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS'
export const UPDATE_MATERIAL_FAIL = 'UPDATE_MATERIAL_FAIL'

export const DELETE_MATERIAL = 'DELETE_MATERIAL'
export const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS'
export const DELETE_MATERIAL_FAIL = 'DELETE_MATERIAL_FAIL'

export const LOAD_ALL_MATERIALS_DATA_SUCCESS = 'LOAD_ALL_MATERIALS_DATA_SUCCESS'

export const SELECT_MATERIAL = 'SELECT_MATERIAL'

//materials groups
export const LOAD_MATERIAL_GROUPS = 'LOAD_MATERIAL_GROUPS'
export const LOAD_MATERIAL_GROUPS_SUCCESS = 'LOAD_MATERIAL_GROUPS_SUCCESS'
export const LOAD_MATERIAL_GROUPS_FAIL = 'LOAD_MATERIAL_GROUPS_FAIL'

//topics
export const LOAD_TOPICS = 'LOAD_TOPICS'
export const LOAD_TOPICS_SUCCESS = 'LOAD_TOPICS_SUCCESS'
export const LOAD_TOPICS_FAIL = 'LOAD_TOPICS_FAIL'

export const SELECT_TOPIC = 'SELECT_TOPIC'
export const UNSELECT_TOPIC = 'UNSELECT_TOPIC'

export const SELECT_MATERIAL_GROUP = 'SELECT_MATERIAL_GROUP'


//sessions
export const LOAD_USER_SESSIONS = 'LOAD_USER_SESSIONS'
export const LOAD_USER_SESSIONS_SUCCESS = 'LOAD_USER_SESSIONS_SUCCESS'
export const LOAD_USER_SESSIONS_FAIL = 'LOAD_USER_SESSIONS_FAIL'

export const LOAD_SESSION_DATA = 'LOAD_SESSION_DATA'
export const LOAD_SESSION_DATA_SUCCESS = 'LOAD_SESSION_DATA_SUCCESS'
export const LOAD_SESSION_DATA_FAIL = 'LOAD_SESSION_DATA_FAIL'

//ECG Viewer
export const SELECT_SESSION = 'SELECT_SESSION'
export const UNSELECT_SESSION = 'UNSELECT_SESSION'
export const SET_MARKER = 'SET_MARKER'
export const UNSET_MARKER = 'UNSET_MARKER'
export const UNSET_ALL_MARKERS = 'UNSET_ALL_MARKERS'

//file
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL'

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO'
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS'
export const UPLOAD_VIDEO_FAIL = 'UPLOAD_VIDEO_FAIL'

//download
export const PUT_MATERIALS_IN_DOWNLOADING_SET = 'PUT_MATERIALS_IN_DOWNLOADING_SET'
export const DOWNLOAD_MATERIAL = 'DOWNLOAD_MATERIAL'
export const DOWNLOAD_MATERIAL_SUCCESS = 'DOWNLOAD_MATERIAL_SUCCESS'
export const DOWNLOAD_MATERIAL_FAIL = 'DOWNLOAD_MATERIAL_FAIL'

export const INIT_CACHED_DOWNLOADED_MATERIALS = 'INIT_CACHED_DOWNLOADED_MATERIALS'
export const INIT_CACHED_DOWNLOADED_MATERIALS_SUCCESS = 'INIT_CACHED_DOWNLOADED_MATERIALS_SUCCESS'
export const INIT_CACHED_DOWNLOADED_MATERIALS_FAIL = 'INIT_CACHED_DOWNLOADED_MATERIALS_FAIL'


//pusher
export const PUSHER_MESSAGE_RECEIVED = 'PUSHER_MESSAGE_RECEIVED'

//player
export const SELECT_MATERIAL_TO_PLAY = 'SELECT_MATERIAL_TO_PLAY'
export const CLOSE_MATERIAL_PLAYER = 'CLOSE_MATERIAL_PLAYER'
export const OPEN_EDIT_MATERIAL_WINDOW = 'OPEN_EDIT_MATERIAL_WINDOW'
export const CLOSE_EDIT_MATERIAL_WINDOW = 'CLOSE_EDIT_MATERIAL_WINDOW'
export const OPEN_CREATE_MATERIAL_WINDOW = 'OPEN_CREATE_MATERIAL_WINDOW'
export const SET_FULLSCREEN_MATERIAL_PLAYER = 'SET_FULLSCREEN_MATERIAL_PLAYER'

export const SELECT_EXERCISE_TO_PLAY = 'SELECT_EXERCISE_TO_PLAY'
export const CLOSE_EXERCISE_PLAYER = 'CLOSE_EXERCISE_PLAYER'
export const OPEN_EDIT_EXERCISE_WINDOW = 'OPEN_EDIT_EXERCISE_WINDOW'
export const CLOSE_EDIT_EXERCISE_WINDOW = 'CLOSE_EDIT_EXERCISE_WINDOW'

export const SELECT_EXERCISE_CARD = 'SELECT_EXERCISE_CARD'

export const SELECT_PLAYLIST_MATERIALS = 'SELECT_PLAYLIST_MATERIALS'


//exercise
export const LOAD_EXERCISES = 'LOAD_EXERCISES'
export const LOAD_EXERCISES_SUCCESS = 'LOAD_EXERCISES_SUCCESS'
export const LOAD_EXERCISES_FAIL = 'LOAD_EXERCISES_FAIL'

export const SELECT_EXERCISE = 'SELECT_EXERCISE'

export const DELETE_EXERCISE = 'DELETE_EXERCISE'
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS'
export const DELETE_EXERCISE_FAIL = 'DELETE_EXERCISE_FAIL'

export const LOAD_EXERCISES_CARDS = 'LOAD_EXERCISES_CARDS'
export const LOAD_EXERCISES_CARDS_SUCCESS = 'LOAD_EXERCISES_CARDS_SUCCESS'
export const LOAD_EXERCISES_CARDS_FAIL = 'LOAD_EXERCISES_CARDS_FAIL'

export const CREATE_EXERCISE_CARD = 'CREATE_EXERCISE_CARD'
export const CREATE_EXERCISE_CARD_SUCCESS = 'CREATE_EXERCISE_CARD_SUCCESS'
export const CREATE_EXERCISE_CARD_FAIL = 'CREATE_EXERCISE_CARD_FAIL'

export const UPDATE_EXERCISE_CARD = 'UPDATE_EXERCISE_CARD'
export const UPDATE_EXERCISE_CARD_SUCCESS = 'UPDATE_EXERCISE_CARD_SUCCESS'
export const UPDATE_EXERCISE_CARD_FAIL = 'UPDATE_EXERCISE_CARD_FAIL'

export const DELETE_EXERCISE_CARD = 'DELETE_EXERCISE_CARD'
export const DELETE_EXERCISE_CARD_SUCCESS = 'DELETE_EXERCISE_CARD_SUCCESS'
export const DELETE_EXERCISE_CARD_FAIL = 'DELETE_EXERCISE_CARD_FAIL'


export const LOAD_EXERCISES_GROUPS = 'LOAD_EXERCISES_GROUPS'
export const LOAD_EXERCISES_GROUPS_SUCCESS = 'LOAD_EXERCISES_GROUPS_SUCCESS'
export const LOAD_EXERCISES_GROUPS_FAIL = 'LOAD_EXERCISES_GROUPS_FAIL'

export const CREATE_EXERCISE_GROUP = 'CREATE_EXERCISE_GROUP'
export const CREATE_EXERCISE_GROUP_SUCCESS = 'CREATE_EXERCISE_GROUP_SUCCESS'
export const CREATE_EXERCISE_GROUP_FAIL = 'CREATE_EXERCISE_GROUP_FAIL'

export const UPDATE_EXERCISE_GROUP = 'UPDATE_EXERCISE_GROUP'
export const UPDATE_EXERCISE_GROUP_SUCCESS = 'UPDATE_EXERCISE_GROUP_SUCCESS'
export const UPDATE_EXERCISE_GROUP_FAIL = 'UPDATE_EXERCISE_GROUP_FAIL'

export const DELETE_EXERCISE_GROUP = 'DELETE_EXERCISE_GROUP'
export const DELETE_EXERCISE_GROUP_SUCCESS = 'DELETE_EXERCISE_GROUP_SUCCESS'
export const DELETE_EXERCISE_GROUP_FAIL = 'DELETE_EXERCISE_GROUP_FAIL'


export const CREATE_EXERCISE = 'CREATE_EXERCISE'
export const CREATE_EXERCISE_SUCCESS = 'CREATE_EXERCISE_SUCCESS'
export const CREATE_EXERCISE_FAIL = 'CREATE_EXERCISE_FAIL'

export const UPDATE_EXERCISE = 'UPDATE_EXERCISE'
export const UPDATE_EXERCISE_SUCCESS = 'UPDATE_EXERCISE_SUCCESS'
export const UPDATE_EXERCISE_FAIL = 'UPDATE_EXERCISE_FAIL'

//scores
export const CREATE_USER_EXERCISE_SCORE = 'CREATE_USER_EXERCISE_SCORE'
export const CREATE_USER_EXERCISE_SCORE_SUCCESS = 'CREATE_USER_EXERCISE_SCORE_SUCCESS'
export const CREATE_USER_EXERCISE_SCORE_FAIL = 'CREATE_USER_EXERCISE_SCORE_FAIL'

export const UPDATE_USER_EXERCISE_SCORE = 'UPDATE_USER_EXERCISE_SCORE'
export const UPDATE_USER_EXERCISE_SCORE_SUCCESS = 'UPDATE_USER_EXERCISE_SCORE_SUCCESS'
export const UPDATE_USER_EXERCISE_SCORE_FAIL = 'UPDATE_USER_EXERCISE_SCORE_FAIL'

export const LOAD_USER_EXERCISE_SCORES = 'LOAD_USER_EXERCISE_SCORES'
export const LOAD_USER_EXERCISE_SCORES_SUCCESS = 'LOAD_USER_EXERCISE_SCORES_SUCCESS'
export const LOAD_USER_EXERCISE_SCORES_FAIL = 'LOAD_USER_EXERCISE_SCORES_FAIL'

//test

export const LOAD_TESTS = 'LOAD_TESTS'
export const LOAD_TESTS_SUCCESS = 'LOAD_TESTS_SUCCESS'
export const LOAD_TESTS_FAIL = 'LOAD_TESTS_FAIL'

export const CREATE_TEST = 'CREATE_TEST'
export const CREATE_TEST_SUCCESS = 'CREATE_TEST_SUCCESS'
export const CREATE_TEST_FAIL = 'CREATE_TEST_FAIL'

export const UPDATE_TEST = 'UPDATE_TEST'
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS'
export const UPDATE_TEST_FAIL = 'UPDATE_TEST_FAIL'

export const DELETE_TEST = 'DELETE_TEST'
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS'
export const DELETE_TEST_FAIL = 'DELETE_TEST_FAIL'


// sabir sidebar refactoring
export const SMART_CREATE_CONTAINER = 'SMART_CREATE_CONTAINER'
export const SMART_CREATE_CONTAINER_SUCCESS = 'SMART_CREATE_CONTAINER_SUCCESS'
export const SMART_CREATE_CONTAINER_FAIL = 'SMART_CREATE_CONTAINER_FAIL'

export const SMART_UPDATE_CONTAINER = 'SMART_UPDATE_CONTAINER'
export const SMART_UPDATE_CONTAINER_SUCCESS = 'SMART_UPDATE_CONTAINER_SUCCESS'
export const SMART_UPDATE_CONTAINER_FAIL = 'SMART_UPDATE_CONTAINER_FAIL'

export const SMART_DELETE_CONTAINER = 'SMART_DELETE_CONTAINER'
export const SMART_DELETE_CONTAINER_SUCCESS = 'SMART_DELETE_CONTAINER_SUCCESS'
export const SMART_DELETE_CONTAINER_FAIL = 'SMART_DELETE_CONTAINER_FAIL'

export const SMART_LOAD_CONTAINERS = 'SMART_LOAD_CONTAINERS'
export const SMART_LOAD_CONTAINERS_SUCCESS = 'SMART_LOAD_CONTAINERS_SUCCESS'
export const SMART_LOAD_CONTAINERS_FAIL = 'SMART_LOAD_CONTAINERS_FAIL'

export const SET_LANGUAGE = 'SET_LANGUAGE';

//groups
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'

export const LOAD_GROUPS = 'LOAD_GROUPS'
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS'
export const LOAD_GROUPS_FAIL = 'LOAD_GROUPS_FAIL'

// treadmills

export const CREATE_TREADMILL = 'CREATE_TREADMILL'
export const CREATE_TREADMILL_SUCCESS = 'CREATE_TREADMILL_SUCCESS'
export const CREATE_TREADMILL_FAIL = 'CREATE_TREADMILL_FAIL'

export const UPDATE_TREADMILL = 'UPDATE_TREADMILL'
export const UPDATE_TREADMILL_SUCCESS = 'UPDATE_TREADMILL_SUCCESS'
export const UPDATE_TREADMILL_FAIL = 'UPDATE_TREADMILL_FAIL'

export const DELETE_TREADMILL = 'DELETE_TREADMILL'
export const DELETE_TREADMILL_SUCCESS = 'DELETE_TREADMILL_SUCCESS'
export const DELETE_TREADMILL_FAIL = 'DELETE_TREADMILL_FAIL'

export const LOAD_TREADMILLS = 'LOAD_TREADMILLS'
export const LOAD_TREADMILLS_SUCCESS = 'LOAD_TREADMILLS_SUCCESS'
export const LOAD_TREADMILLS_FAIL = 'LOAD_TREADMILLS_FAIL'

//  treadmill custom scale

export const LOAD_SCALE = 'LOAD_SCALE'
export const LOAD_SCALE_SUCCESS = 'LOAD_SCALE_SUCCESS'
export const LOAD_SCALE_FAIL = 'LOAD_SCALE_FAIL'

export const UPDATE_SCALE = 'UPDATE_SCALE'
export const UPDATE_SCALE_SUCCESS = 'UPDATE_SCALE_SUCCESS'
export const UPDATE_SCALE_FAIL = 'UPDATE_SCALE_FAIL'

// access groups

export const CREATE_ACCESS_GROUP = 'CREATE_ACCESS_GROUP'
export const CREATE_ACCESS_GROUP_SUCCESS = 'CREATE_ACCESS_GROUP_SUCCESS'
export const CREATE_ACCESS_GROUP_FAIL = 'CREATE_ACCESS_GROUP_FAIL'

export const UPDATE_ACCESS_GROUP = 'UPDATE_ACCESS_GROUP'
export const UPDATE_ACCESS_GROUP_SUCCESS = 'UPDATE_ACCESS_GROUP_SUCCESS'
export const UPDATE_ACCESS_GROUP_FAIL = 'UPDATE_ACCESS_GROUP_FAIL'

export const DELETE_ACCESS_GROUP = 'DELETE_ACCESS_GROUP'
export const DELETE_ACCESS_GROUP_SUCCESS = 'DELETE_ACCESS_GROUP_SUCCESS'
export const DELETE_ACCESS_GROUP_FAIL = 'DELETE_ACCESS_GROUP_FAIL'

export const LOAD_ACCESS_GROUPS = 'LOAD_ACCESS_GROUPS'
export const LOAD_ACCESS_GROUPS_SUCCESS = 'LOAD_ACCESS_GROUPS_SUCCESS'
export const LOAD_ACCESS_GROUPS_FAIL = 'LOAD_ACCESS_GROUPS_FAIL'

//search
export const SEARCH_QUERY_CHANGE = 'SEARCH_QUERY_CHANGE'


//classes

export const LOAD_CLASSES = 'LOAD_CLASSES'
export const LOAD_CLASSES_SUCCESS = 'LOAD_CLASSES_SUCCESS'
export const LOAD_CLASSES_FAIL = 'LOAD_CLASSES_FAIL'

export const LOAD_CLASS_LINKS = 'LOAD_CLASS_LINKS'
export const LOAD_CLASS_LINKS_SUCCESS = 'LOAD_CLASS_LINKS_SUCCESS'
export const LOAD_CLASS_LINKS_FAIL = 'LOAD_CLASS_LINKS_FAIL'

export const UPDATE_CLASS = 'UPDATE_CLASS'
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS'
export const UPDATE_CLASS_FAIL = 'UPDATE_CLASS_FAIL'

export const CREATE_CLASS = 'CREATE_CLASS'
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS'
export const CREATE_CLASS_FAIL = 'CREATE_CLASS_FAIL'

export const DELETE_CLASS = 'DELETE_CLASS'
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS'
export const DELETE_CLASS_FAIL = 'DELETE_CLASS_FAIL'

export const LOAD_ASSIGNMENTS = 'LOAD_ASSIGNMENTS'
export const LOAD_ASSIGNMENTS_SUCCESS = 'LOAD_ASSIGNMENTS_SUCCESS'
export const LOAD_ASSIGNMENTS_FAIL = 'LOAD_ASSIGNMENTS_FAIL'


export const SELECT_CLASS = 'SELECT_CLASS'
export const SELECT_CLASS_USER = 'SELECT_CLASS_USER'
export const UNSELECT_CLASS_USER = 'UNSELECT_CLASS_USER'
export const UNSELECT_CLASS = 'UNSELECT_CLASS'


//feed

export const LOAD_FEEDS = 'LOAD_FEEDS'
export const LOAD_FEEDS_SUCCESS = 'LOAD_FEEDS_SUCCESS'
export const LOAD_FEEDS_FAIL = 'LOAD_FEEDS_FAIL'

export const LOAD_FEED_ITEMS = 'LOAD_FEED_ITEMS'
export const LOAD_FEED_ITEMS_SUCCESS = 'LOAD_FEED_ITEMS_SUCCESS'
export const LOAD_FEED_ITEMS_FAIL = 'LOAD_FEED_ITEMS_FAIL'

export const UPDATE_FEED_ITEM = 'UPDATE_FEED_ITEM'
export const UPDATE_FEED_ITEM_SUCCESS = 'UPDATE_FEED_ITEM_SUCCESS'
export const UPDATE_FEED_ITEM_FAIL = 'UPDATE_FEED_ITEM_FAIL'

export const CREATE_FEED_ITEM = 'CREATE_FEED_ITEM'
export const CREATE_FEED_ITEM_SUCCESS = 'CREATE_FEED_ITEM_SUCCESS'
export const CREATE_FEED_ITEM_FAIL = 'CREATE_FEED_ITEM_FAIL'


export const DELETE_FEED_ITEM = 'DELETE_FEED_ITEM'
export const DELETE_FEED_ITEM_SUCCESS = 'DELETE_FEED_ITEM_SUCCESS'
export const DELETE_FEED_ITEM_FAIL = 'DELETE_FEED_ITEM_FAIL'


export const SELECT_FEED_ITEM = 'SELECT_FEED_ITEM'
export const SELECT_FEED_ITEM_TO_EDIT = 'SELECT_FEED_ITEM_TO_EDIT'
export const UNSELECT_FEED_ITEM = 'UNSELECT_FEED_ITEM'


//articles
export const LOAD_ARTICLES = 'LOAD_ARTICLES'
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS'
export const LOAD_ARTICLES_FAIL = 'LOAD_ARTICLES_FAIL'

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL'

export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL'

export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'


export const SELECT_ARTICLE = 'SELECT_ARTICLE'
export const UNSELECT_ARTICLE = 'UNSELECT_ARTICLE'
export const OPEN_ARTICLE_EDITOR = 'OPEN_ARTICLE_EDITOR'
export const CLOSE_ARTICLE_EDITOR = 'CLOSE_ARTICLE_EDITOR'


//notes
export const LOAD_NOTES = 'LOAD_NOTES'
export const LOAD_NOTES_SUCCESS = 'LOAD_NOTES_SUCCESS'
export const LOAD_NOTES_FAIL = 'LOAD_NOTES_FAIL'

export const CREATE_NOTE = 'CREATE_NOTE'
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS'
export const CREATE_NOTE_FAIL = 'CREATE_NOTE_FAIL'

export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL'

export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL'

export const LOAD_NOTES_GROUPS = 'LOAD_NOTES_GROUPS'
export const LOAD_NOTES_GROUPS_SUCCESS = 'LOAD_NOTES_GROUPS_SUCCESS'
export const LOAD_NOTES_GROUPS_FAIL = 'LOAD_NOTES_GROUPS_FAIL'

export const SELECT_NOTE = 'SELECT_NOTE'
export const SELECT_NOTE_GROUP = 'SELECT_NOTE_GROUP'
export const UNSELECT_NOTE = 'UNSELECT_NOTE'
export const UNSELECT_NOTE_GROUP = 'UNSELECT_NOTE_GROUP'


//recording
export const INIT_RECORDER = 'INIT_RECORDER'
export const INIT_RECORDER_SUCCESS = 'INIT_RECORDER_SUCCESS'
export const INIT_RECORDER_FAIL = 'INIT_RECORDER_FAIL'


export const START_RECORDING = 'START_RECORDING'
export const STOP_RECORDING = 'STOP_RECORDING'

//memospeak
export const OPEN_MEMOSPEAK = 'OPEN_MEMOSPEAK'
export const CLOSE_MEMOSPEAK = 'CLOSE_MEMOSPEAK'
export const SET_MEMOSPEAK_SLIDE_NUMBER = 'SET_MEMOSPEAK_SLIDE_NUMBER'

export const LOAD_MEMOSPEAK_USER_ANSWERS = 'LOAD_MEMOSPEAK_USER_ANSWERS'
export const LOAD_MEMOSPEAK_USER_ANSWERS_SUCCESS = 'LOAD_MEMOSPEAK_USER_ANSWERS_SUCCESS'
export const LOAD_MEMOSPEAK_USER_ANSWERS_FAIL = 'LOAD_MEMOSPEAK_USER_ANSWERS_FAIL'

export const CREATE_MEMOSPEAK_USER_ANSWER = 'CREATE_MEMOSPEAK_USER_ANSWER'
export const CREATE_MEMOSPEAK_USER_ANSWER_SUCCESS = 'CREATE_MEMOSPEAK_USER_ANSWER_SUCCESS'
export const CREATE_MEMOSPEAK_USER_ANSWER_FAIL = 'CREATE_MEMOSPEAK_USER_ANSWER_FAIL'

export const UPDATE_MEMOSPEAK_USER_ANSWER = 'UPDATE_MEMOSPEAK_USER_ANSWER'
export const UPDATE_MEMOSPEAK_USER_ANSWER_SUCCESS = 'UPDATE_MEMOSPEAK_USER_ANSWER_SUCCESS'
export const UPDATE_MEMOSPEAK_USER_ANSWER_FAIL = 'UPDATE_MEMOSPEAK_USER_ANSWER_FAIL'

//cards
export const LOAD_USER_EXERCISE_CARDS_ANSWERS = 'LOAD_USER_EXERCISE_CARDS_ANSWERS'
export const LOAD_USER_EXERCISE_CARDS_ANSWERS_SUCCESS = 'LOAD_USER_EXERCISE_CARDS_ANSWERS_SUCCESS'
export const LOAD_USER_EXERCISE_CARDS_ANSWERS_FAIL = 'LOAD_USER_EXERCISE_CARDS_ANSWERS_FAIL'

export const SAVE_USER_EXERCISE_CARD_ANSWER = 'SAVE_USER_EXERCISE_CARD_ANSWER'
export const SAVE_USER_EXERCISE_CARD_ANSWER_SUCCESS = 'SAVE_USER_EXERCISE_CARD_ANSWER_SUCCESS'
export const SAVE_USER_EXERCISE_CARD_ANSWER_FAIL = 'SAVE_USER_EXERCISE_CARD_ANSWER_FAIL'

//notifications
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL'

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL'

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS'
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS'
export const LOAD_NOTIFICATIONS_FAIL = 'LOAD_NOTIFICATIONS_FAIL'

//ui
export const SELECT_SETTINGS_MODE = 'SELECT_SETTINGS_MODE'
export const UNSELECT_SETTINGS_MODE = 'UNSELECT_SETTINGS_MODE'


//translate
export const SET_TRANSLATE_WORD = 'SET_TRANSLATE_WORD'

//drochka
export const CREATE_DROCHKA_EXERCISE = 'CREATE_DROCHKA_EXERCISE'
export const CREATE_DROCHKA_EXERCISE_SUCCESS = 'CREATE_DROCHKA_EXERCISE_SUCCESS'
export const CREATE_DROCHKA_EXERCISE_FAIL = 'CREATE_DROCHKA_EXERCISE_FAIL'

export const UPDATE_DROCHKA_EXERCISE = 'UPDATE_DROCHKA_EXERCISE'
export const UPDATE_DROCHKA_EXERCISE_SUCCESS = 'UPDATE_DROCHKA_EXERCISE_SUCCESS'
export const UPDATE_DROCHKA_EXERCISE_FAIL = 'UPDATE_DROCHKA_EXERCISE_FAIL'

export const DELETE_DROCHKA_EXERCISE = 'DELETE_DROCHKA_EXERCISE'
export const DELETE_DROCHKA_EXERCISE_SUCCESS = 'DELETE_DROCHKA_EXERCISE_SUCCESS'
export const DELETE_DROCHKA_EXERCISE_FAIL = 'DELETE_DROCHKA_EXERCISE_FAIL'

export const LOAD_DROCHKA_EXERCISES = 'LOAD_DROCHKA_EXERCISES'
export const LOAD_DROCHKA_EXERCISES_SUCCESS = 'LOAD_DROCHKA_EXERCISES_SUCCESS'
export const LOAD_DROCHKA_EXERCISES_FAIL = 'LOAD_DROCHKA_EXERCISES_FAIL'

//films
export const CREATE_FILM = 'CREATE_FILM'
export const CREATE_FILM_SUCCESS = 'CREATE_FILM_SUCCESS'
export const CREATE_FILM_FAIL = 'CREATE_FILM_FAIL'

export const UPDATE_FILM = 'UPDATE_FILM'
export const UPDATE_FILM_SUCCESS = 'UPDATE_FILM_SUCCESS'
export const UPDATE_FILM_FAIL = 'UPDATE_FILM_FAIL'

export const DELETE_FILM = 'DELETE_FILM'
export const DELETE_FILM_SUCCESS = 'DELETE_FILM_SUCCESS'
export const DELETE_FILM_FAIL = 'DELETE_FILM_FAIL'

export const LOAD_FILMS = 'LOAD_FILMS'
export const LOAD_FILMS_SUCCESS = 'LOAD_FILMS_SUCCESS'
export const LOAD_FILMS_FAIL = 'LOAD_FILMS_FAIL'

//chat
export const INIT_CHAT = 'INIT_CHAT'
export const INIT_CHAT_SUCCESS = 'INIT_CHAT_SUCCESS'
export const INIT_CHAT_FAIL = 'INIT_CHAT_FAIL'

export const LOAD_CHAT_DIALOGS = 'LOAD_CHAT_DIALOGS'
export const LOAD_CHAT_DIALOGS_SUCCESS = 'LOAD_CHAT_DIALOGS_SUCCESS'
export const LOAD_CHAT_DIALOGS_FAIL = 'LOAD_CHAT_DIALOGS_FAIL'

export const LOAD_DIALOG_MESSAGES = 'LOAD_DIALOG_MESSAGES'
export const LOAD_DIALOG_MESSAGES_SUCCESS = 'LOAD_DIALOG_MESSAGES_SUCCESS'
export const LOAD_DIALOG_MESSAGES_FAIL = 'LOAD_DIALOG_MESSAGES_FAIL'

export const CONNECT_TO_CHAT = 'CONNECT_TO_CHAT'
export const CONNECT_TO_CHAT_SUCCESS = 'CONNECT_TO_CHAT_SUCCESS'
export const CONNECT_TO_CHAT_FAIL = 'CONNECT_TO_CHAT_FAIL'

export const SELECT_CHAT_DIALOG = 'SELECT_CHAT_DIALOG'
export const UNSELECT_CHAT_DIALOG = 'UNSELECT_CHAT_DIALOG'

export const CONSUME_MESSAGE = 'CONSUME_MESSAGE'

//courses
export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL'

export const UPDATE_COURSE = 'UPDATE_COURSE'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'

export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL'

export const LOAD_COURSES = 'LOAD_COURSES'
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS'
export const LOAD_COURSES_FAIL = 'LOAD_COURSES_FAIL'

export const SELECT_COURSE = 'SELECT_COURSE'

//course units

export const CREATE_COURSE_UNIT = 'CREATE_COURSE_UNIT'
export const CREATE_COURSE_UNIT_SUCCESS = 'CREATE_COURSE_UNIT_SUCCESS'
export const CREATE_COURSE_UNIT_FAIL = 'CREATE_COURSE_UNIT_FAIL'

export const UPDATE_COURSE_UNIT = 'UPDATE_COURSE_UNIT'
export const UPDATE_COURSE_UNIT_SUCCESS = 'UPDATE_COURSE_UNIT_SUCCESS'
export const UPDATE_COURSE_UNIT_FAIL = 'UPDATE_COURSE_UNIT_FAIL'

export const DELETE_COURSE_UNIT = 'DELETE_COURSE_UNIT'
export const DELETE_COURSE_UNIT_SUCCESS = 'DELETE_COURSE_UNIT_SUCCESS'
export const DELETE_COURSE_UNIT_FAIL = 'DELETE_COURSE_UNIT_FAIL'

export const LOAD_COURSE_UNITS = 'LOAD_COURSE_UNITS'
export const LOAD_COURSE_UNITS_SUCCESS = 'LOAD_COURSE_UNITS_SUCCESS'
export const LOAD_COURSE_UNITS_FAIL = 'LOAD_COURSE_UNITS_FAIL'

export const SELECT_COURSE_UNIT = 'SELECT_COURSE_UNIT'

//online lesson sessions

export const CREATE_ONLINE_LESSON_SESSION = 'CREATE_ONLINE_LESSON_SESSION'
export const CREATE_ONLINE_LESSON_SESSION_SUCCESS = 'CREATE_ONLINE_LESSON_SESSION_SUCCESS'
export const CREATE_ONLINE_LESSON_SESSION_FAIL = 'CREATE_ONLINE_LESSON_SESSION_FAIL'

export const UPDATE_ONLINE_LESSON_SESSION = 'UPDATE_ONLINE_LESSON_SESSION'
export const UPDATE_ONLINE_LESSON_SESSION_SUCCESS = 'UPDATE_ONLINE_LESSON_SESSION_SUCCESS'
export const UPDATE_ONLINE_LESSON_SESSION_FAIL = 'UPDATE_ONLINE_LESSON_SESSION_FAIL'

export const LOAD_ONLINE_LESSON_SESSIONS = 'LOAD_ONLINE_LESSON_SESSIONS'
export const LOAD_ONLINE_LESSON_SESSIONS_SUCCESS = 'LOAD_ONLINE_LESSON_SESSIONS_SUCCESS'
export const LOAD_ONLINE_LESSON_SESSIONS_FAIL = 'LOAD_ONLINE_LESSON_SESSIONS_FAIL'

export const DELETE_ONLINE_LESSON_SESSION = 'DELETE_ONLINE_LESSON_SESSION'
export const DELETE_ONLINE_LESSON_SESSION_SUCCESS = 'DELETE_ONLINE_LESSON_SESSION_SUCCESS'
export const DELETE_ONLINE_LESSON_SESSION_FAIL = 'DELETE_ONLINE_LESSON_SESSION_FAIL'

export const SELECT_ONLINE_LESSON = 'SELECT_ONLINE_LESSON'
export const UNSELECT_ONLINE_LESSON = 'UNSELECT_ONLINE_LESSON'

// lessons

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS'
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL'

export const UPDATE_LESSON = 'UPDATE_LESSON'
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS'
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL'

export const DELETE_LESSON = 'DELETE_LESSON'
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS'
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL'

export const LOAD_LESSONS = 'LOAD_LESSONS'
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS'
export const LOAD_LESSONS_FAIL = 'LOAD_LESSONS_FAIL'

export const SELECT_LESSON = 'SELECT_LESSON'

// tasks

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'

export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL'

export const LOAD_TASKS = 'LOAD_TASKS'
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS'
export const LOAD_TASKS_FAIL = 'LOAD_TASKS_FAIL'

export const SELECT_TASK = 'SELECT_TASK'

export const COPY_SLIDE_ITEM_TO_BUFFER = 'COPY_SLIDE_ITEM_TO_BUFFER'
export const COPY_EXERCISE_TO_BUFFER = 'COPY_EXERCISE_TO_BUFFER'
export const COPY_DROCHKA_EXERCISE_TO_BUFFER = 'COPY_DROCHKA_EXERCISE_TO_BUFFER'
export const COPY_TEST_TO_BUFFER = 'COPY_TEST_TO_BUFFER'
export const COPY_TREADMILL_TO_BUFFER = 'COPY_TREADMILL_TO_BUFFER'

// dialogs

export const CREATE_DIALOG = 'CREATE_DIALOG'
export const CREATE_DIALOG_SUCCESS = 'CREATE_DIALOG_SUCCESS'
export const CREATE_DIALOG_FAIL = 'CREATE_DIALOG_FAIL'

export const UPDATE_DIALOG = 'UPDATE_DIALOG'
export const UPDATE_DIALOG_SUCCESS = 'UPDATE_DIALOG_SUCCESS'
export const UPDATE_DIALOG_FAIL = 'UPDATE_DIALOG_FAIL'

export const DELETE_DIALOG = 'DELETE_DIALOG'
export const DELETE_DIALOG_SUCCESS = 'DELETE_DIALOG_SUCCESS'
export const DELETE_DIALOG_FAIL = 'DELETE_DIALOG_FAIL'

export const LOAD_DIALOGS = 'LOAD_DIALOGS'
export const LOAD_DIALOGS_SUCCESS = 'LOAD_DIALOGS_SUCCESS'
export const LOAD_DIALOGS_FAIL = 'LOAD_DIALOGS_FAIL'

//questionnaires

export const LOAD_QUESTIONNAIRES = 'LOAD_QUESTIONNAIRES'
export const LOAD_QUESTIONNAIRES_SUCCESS = 'LOAD_QUESTIONNAIRES_SUCCESS'
export const LOAD_QUESTIONNAIRES_FAIL = 'LOAD_QUESTIONNAIRES_FAIL'

export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE'
export const CREATE_QUESTIONNAIRE_SUCCESS = 'CREATE_QUESTIONNAIRE_SUCCESS'
export const CREATE_QUESTIONNAIRE_FAIL = 'CREATE_QUESTIONNAIRE_FAIL'

export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE'
export const UPDATE_QUESTIONNAIRE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SUCCESS'
export const UPDATE_QUESTIONNAIRE_FAIL = 'UPDATE_QUESTIONNAIRE_FAIL'

export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE'
export const DELETE_QUESTIONNAIRE_SUCCESS = 'DELETE_QUESTIONNAIRE_SUCCESS'
export const DELETE_QUESTIONNAIRE_FAIL = 'DELETE_QUESTIONNAIRE_FAIL'

// oxwords

export const CREATE_OXWORD = 'CREATE_OXWORD'
export const CREATE_OXWORD_SUCCESS = 'CREATE_OXWORD_SUCCESS'
export const CREATE_OXWORD_FAIL = 'CREATE_OXWORD_FAIL'

export const UPDATE_OXWORD = 'UPDATE_OXWORD'
export const UPDATE_OXWORD_SUCCESS = 'UPDATE_OXWORD_SUCCESS'
export const UPDATE_OXWORD_FAIL = 'UPDATE_OXWORD_FAIL'

export const LOAD_OXWORDS = 'LOAD_OXWORDS'
export const LOAD_OXWORDS_SUCCESS = 'LOAD_OXWORDS_SUCCESS'
export const LOAD_OXWORDS_FAIL = 'LOAD_OXWORDS_FAIL'

export const DELETE_OXWORD = 'DELETE_OXWORD'
export const DELETE_OXWORD_SUCCESS = 'DELETE_OXWORD_SUCCESS'
export const DELETE_OXWORD_FAIL = 'DELETE_OXWORD_FAIL'

export const SHOW_LEFT_PANEL = 'SHOW_LEFT_PANEL'
export const SHOW_MIDDLE_PANEL = 'SHOW_MIDDLE_PANEL'
export const SHOW_RIGHT_PANEL = 'SHOW_RIGHT_PANEL'
export const SHOW_COURSES_PANEL = 'SHOW_COURSES_PANEL'
export const SHOW_LESSONS_PANEL = 'SHOW_LESSONS_PANEL'


export const SMART_CREATE_FOLDER = 'SMART_CREATE_FOLDER'
export const SMART_CREATE_FOLDER_SUCCESS = 'SMART_CREATE_FOLDER_SUCCESS'
export const SMART_CREATE_FOLDER_FAIL = 'SMART_CREATE_FOLDER_FAIL'

export const SMART_UPDATE_FOLDER = 'SMART_UPDATE_FOLDER'
export const SMART_UPDATE_FOLDER_SUCCESS = 'SMART_UPDATE_FOLDER_SUCCESS'
export const SMART_UPDATE_FOLDER_FAIL = 'SMART_UPDATE_FOLDER_FAIL'

export const SMART_DELETE_FOLDER = 'SMART_DELETE_FOLDER'
export const SMART_DELETE_FOLDER_SUCCESS = 'SMART_DELETE_FOLDER_SUCCESS'
export const SMART_DELETE_FOLDER_FAIL = 'SMART_DELETE_FOLDER_FAIL'

export const SMART_LOAD_FOLDERS = 'SMART_LOAD_FOLDERS'
export const SMART_LOAD_FOLDERS_SUCCESS = 'SMART_LOAD_FOLDERS_SUCCESS'
export const SMART_LOAD_FOLDERS_FAIL = 'SMART_LOAD_FOLDERS_FAIL'

export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS'
export const CREATE_POST_FAIL = 'CREATE_POST_FAIL'

export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAIL = 'UPDATE_POST_FAIL'

export const DELETE_POST = 'DELETE_POST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL'

export const LOAD_POSTS = 'LOAD_POSTS'
export const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'
export const LOAD_POSTS_FAIL = 'LOAD_POSTS_FAIL'

// display icons (AI + chat)
export const SHOW_ICONS = 'SHOW_ICONS'
export const HIDE_ICONS = 'HIDE_ICONS'