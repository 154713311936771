import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
// import useDimensions from "react-cool-dimensions";
// import {useDimensions} from "../../helpers/CommonHelper";
import DrawableImage from "./DrawableImage";
import LoadingLottieLoader from "../loaders/LoadingWordLottieLoader";
import SmartFirebaseAPI from "../../api/SmartFirebaseAPI";
import useDimensions from "react-cool-dimensions";

const getWH = (containerSize, imageSize, state) => {
    if (imageSize == undefined || containerSize == undefined) {
        return {};
    }
    let k = imageSize.height / imageSize.width;
    let w = containerSize.width;
    let h = w * k;
    return {h, w};
}

const getPercentLines = (containerSize, imageSize, lines) => {
    let {w, h} = getWH(containerSize, imageSize);
    if (w == undefined) {
        return;
    }
    let resizedLines = [];
    for (let i in lines) {
        let line = lines[i];
        resizedLines.push(line.map(p => ({x: +p.x / w, y: +p.y / h})))
    }
    return resizedLines;
}

const getResizedLines = (containerSize, imageSize, lines) => {
    let {w, h} = getWH(containerSize, imageSize);
    if (w == undefined) {
        return;
    }
    let resizedLines = [];
    for (let i in lines) {
        let line = lines[i];
        resizedLines.push(line.map(p => ({x: +p.x * w, y: +p.y * h})))
    }
    return resizedLines;
}

export default function SyncDrawableImage(props) {
    const {
        src = 'https://www.englishpatient.org/english-patient-files/b449ce7f-fb7b-499d-8972-c7088922b196.jpeg',
        brushColor = 'blue',
        strokeWidth = 3,
        debounceInterval = 300,
        roomName = 'sabir_image'
    } = props;

    const [lines, setLines] = useState([]);
    const [imageSize, setImageSize] = useState(undefined);
    const [loading, setLoading] = useState(true);

    let k = undefined;
    if (imageSize != undefined) {
        k = imageSize.height / imageSize.width;
    }
    console.log('--->>> k = ', k);

    const {observe, unobserve, width, height} = useDimensions({});
    console.log('width, height = ', width, height);

    const containerSize = (width == undefined) ? undefined : {width, height};
    const realRoomName = `${roomName}_${`${src}`.replace(/[\W_]+/g, "")}`;

    console.log('=> width, height = ', width, height);

    useEffect(() => {
        SmartFirebaseAPI.initFirebase();
        SmartFirebaseAPI.subscribeImageOnChanges(realRoomName, chData => {
            console.log('chData = ', chData);
            if (chData == undefined) {
                return
            }
            setLines(chData.lines)
        }).then(d => {
            console.log('subscribeOnChanges: then: d = ', d);
            if (d == undefined) {
                setTimeout(() => {
                    setLoading(false);
                }, 800);
                return;
            }
            setLines(d.lines);
            setTimeout(() => {
                setLoading(false);
            }, 1200);
        });
        return () => {
            SmartFirebaseAPI.unsubscribeOnImageChanges(realRoomName);
        }
    }, []);

    let innerW = undefined, innerH = undefined;
    if (imageSize != undefined && containerSize != undefined) {
        let k_ = 1.0 * imageSize.width / imageSize.height;
        if (1.0 * imageSize.width / imageSize.height > 1.0 * containerSize.width / containerSize.height) {
            innerW = containerSize.width;
            innerH = 1.0 * innerW * k;
        } else {
            innerH = containerSize.height;
            innerW = 1.0 * innerH / k;
        }
    }


    return (
        <Wrapper ref={observe}>

            {containerSize == undefined ? null :
                <Inner style={{width: innerW, height: innerH}}>
                    <DrawableImage {...props}
                                   src={src}
                                   lines={getResizedLines(containerSize, imageSize, lines)}
                                   onLinesChange={newLines => {
                                       SmartFirebaseAPI.updateImageFireState(realRoomName, getPercentLines(containerSize, imageSize, newLines));
                                   }}
                                   onDimensionsLoaded={d => {
                                       setImageSize(d);
                                   }}/>
                    {loading == false ? null :
                        <PreloaderPlaceholder>
                            <LoadingLottieLoader/>
                        </PreloaderPlaceholder>
                    }
                </Inner>
            }

        </Wrapper>
    );
}

const Inner = styled.div`
  background-color: whitesmoke;
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreloaderPlaceholder = styled.div`
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  z-index: 10;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;
