import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ExercisesAPI from "../../../api/ExercisesAPI";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";

import down_img from '../../../assets/img/download.svg'
import DownloadSpanTool from "../../download/tools/DownloadSpanTool";

export default function SimpleStudentLessonTaskFileUploaderPanel(props) {
    const {
        studentId,
        taskId,
        lessonId,
        fileNamePrefix,

        editingMode

    } = props;

    const {
        currentUser,
        isTeacherWatching
    } = useMappedState(useCallback(state => {
        let cU = state.users.usersMap.get(state.users.currentUserId);
        return {
            currentUser: cU,
            isTeacherWatching: (cU?.userRole == 'teacher')
        }
    }));

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    useEffect(() => {
        setLoading(true);
        ExercisesAPI.getLessonTaskFileAnswer(taskId, studentId).then(pld => {
            setData(pld);
            setLoading(false);
        });
    }, [studentId, taskId, lessonId]);



    if (data == undefined && isTeacherWatching == true && loading == false) {
        return (
            <Wrapper>
                {editingMode == true ? 'Данное задание требует файла от студента' : 'Файл ученика еще не загружен'}
            </Wrapper>
        )
    }

    // if (data == undefined) {
    //     return null;
    // }

    return (
        <Wrapper>

            {data?.answer == undefined ? null :
                <FileDownloadSpan onClick={() => {

                }}>
                    <FilePickerImg src={down_img}/>
                    <DownloadSpanTool
                        url={data?.answer}
                        fileName={fileNamePrefix}
                    />
                </FileDownloadSpan>
            }


            {loading == true || isTeacherWatching == true ? null :
                <SimpleFileUploader
                    url={data?.answer}
                    mode={'user'}
                    onSave={async (xUrl) => {
                        setLoading(true);
                        await ExercisesAPI.saveLessonTaskFileAnswer(taskId, xUrl, {userId: studentId});
                        let pld = await ExercisesAPI.getLessonTaskFileAnswer(taskId, studentId);
                        setData(pld);
                        setLoading(false);
                    }}
                />
            }


        </Wrapper>
    );
}

const FileDownloadSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const FilePickerImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const Wrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;