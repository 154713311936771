import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';

import Spin from 'arui-feather/spin'

import delete_white_icon from '../../../assets/img/delete_white_icon.svg'
import delete_icon from '../../../assets/img/delete_icon.svg'
import chevron_icon from '../../../assets/img/chevron.svg'
import chevron_white_icon from '../../../assets/img/chevron_white.svg'

import edit_icon_black from '../../../assets/img/edit_icon.svg'
import edit_icon_white from '../../../assets/img/edit_white.svg'

import NiceConfirm from "../../modals/NiceConfirm";
import LessonTasksEditor from "./LessonTasksEditor";
import NiceModal from "../../modals/NiceModal";
import UpdateLessonForm from "../forms/UpdateLessonForm";

export default function LessonsList(props) {
    const {
        currentUserId,
        buffer,
        canEditLessons = true,

        userId,
        groupId,
        lessons = [],
        tasks = [],
        loading = false,
        tasksLoading = false,
        lessonsLoading = false,

        selectedLessonId,
        selectedTaskId,

        onLessonSelect = lessonId => {

        },

        onTaskSelect = taskId => {

        },

        onLessonChange = lesson => {

        },

        onTaskChange = task => {

        },

        onLessonDelete = lId => {

        },

        onTaskDelete = tId => {

        },

        onTaskAdd = d => {

        },

        onTaskCopiedToModule = () => {
            console.log('default: onTaskCopiedToModule occured! ');
        },
        user

    } = props;

    console.log('LessonsList: user = ', user);
    console.log('LessonsList: userId = ', userId);

    const [editLessonId, setEditLessonId] = useState(undefined);
    const [deleteLessonId, setDeleteLessonId] = useState(undefined);
    let selectedLessonTasks = (selectedLessonId == undefined) ? [] : tasks.filter(x => (x.lessonId == selectedLessonId));
    const onToggle = useCallback((a) => {
        if (a.id == selectedLessonId) {
            onLessonSelect(undefined);
        } else {
            onLessonSelect(a.id);
        }
    });

    const selectedEditLesson = (editLessonId == undefined) ? undefined : lessons.filter(xx => (xx.id == editLessonId))[0];


    return (
        <Wrapper>


            <LessonsListPlaceholder>
                {lessons.map((a, i) => {
                    let isSelected = (a.id == selectedLessonId);
                    return (
                        <LessonItem key={a.id} selected={isSelected}>
                            <LessonItemTopPlaceholder
                                selected={isSelected}
                                style={{borderBottom: (isSelected) ? `1px solid whitesmoke` : 'none'}}
                            >
                                <LessonItemInfo
                                    onClick={() => {
                                        onToggle(a);
                                    }}
                                >
                                    <div>
                                        {moment(a.startTimestamp).format('DD.MM.YYYY HH:mm')}
                                    </div>
                                    <div>
                                        {`Lesson №${lessons.length - i}`}
                                    </div>
                                </LessonItemInfo>
                                <LessonItemName
                                    onClick={() => {
                                        onToggle(a);
                                    }}
                                >
                                    {(a?.name == undefined || a?.name == '') ? 'Без названия' : a.name}
                                </LessonItemName>

                                <LessonItemControlsPlaceholder>

                                    <span style={{marginRight: 25}}>
                                        <Spin visible={loading && isSelected}/>
                                    </span>

                                    {/*<DeleteIcon*/}
                                    {/*    src={isSelected ? delete_white_icon : delete_icon}*/}
                                    {/*    style={{*/}
                                    {/*        marginRight: 25*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        setDeleteLessonId(a.id);*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                    {canEditLessons == false ? null :
                                        <ChevronIcon
                                            src={isSelected ? edit_icon_white : edit_icon_black}
                                            style={{
                                                marginRight: 15
                                            }}
                                            onClick={() => {
                                                setEditLessonId(a.id);
                                            }}
                                        />
                                    }

                                    <ChevronIcon
                                        src={isSelected ? chevron_white_icon : chevron_icon}
                                        style={{
                                            transform: `rotate(${isSelected == true ? 180 : 0}deg)`
                                        }}
                                        onClick={() => {
                                            onToggle(a);
                                        }}
                                    />


                                </LessonItemControlsPlaceholder>
                            </LessonItemTopPlaceholder>

                            {isSelected == false ? null :
                                <LessonItemContentPlaceholder>
                                    <LessonTasksEditor
                                        {...props}
                                        groupId={groupId}
                                        currentUserId={currentUserId}
                                        canEdit={canEditLessons}
                                        buffer={buffer}
                                        userId={userId}
                                        loading={tasksLoading}
                                        selectedTaskId={selectedTaskId}
                                        tasks={selectedLessonTasks}
                                        onTaskChange={d => {
                                            onTaskChange(d);
                                        }}
                                        onTaskDelete={taskId => {
                                            onTaskDelete(taskId);
                                        }}
                                        onTaskAdd={d => {
                                            onTaskAdd(d);
                                        }}
                                        onTaskSelect={taskId => {
                                            onTaskSelect(taskId);
                                        }}
                                        onTaskCopiedToModule={() => {
                                            console.log('onTaskCopiedToModule occured!');
                                            onTaskCopiedToModule();
                                        }}
                                    />
                                    {/*{JSON.stringify(a)}*/}
                                    {/*{JSON.stringify(selectedLessonTasks)}*/}
                                </LessonItemContentPlaceholder>
                            }

                        </LessonItem>
                    )
                })}
            </LessonsListPlaceholder>

            {selectedEditLesson == undefined ? null :
                <NiceModal
                    onClose={() => {
                        setEditLessonId(undefined);
                    }}
                >

                    <UpdateLessonForm
                        loading={loading}
                        {...selectedEditLesson}
                        canDelete={true}
                        onSave={d => {
                            onLessonChange({
                                ...d,
                                id: selectedEditLesson?.id
                            });
                            setEditLessonId(undefined);
                        }}
                        onDelete={() => {
                            setEditLessonId(undefined);
                            setDeleteLessonId(selectedEditLesson?.id);
                        }}
                    />

                </NiceModal>
            }

            {deleteLessonId == undefined ? null :
                <NiceConfirm
                    confirmText={'Confirm'}
                    cancelText={'Cancel'}
                    heading={'Delete lesson'}
                    subHeading={'Confirm that you want to delete'}
                    onCancel={() => {
                        setDeleteLessonId(undefined);
                    }}
                    onConfirm={() => {
                        setDeleteLessonId(undefined);
                        onLessonDelete(deleteLessonId);
                    }}

                >

                </NiceConfirm>
            }


        </Wrapper>
    );
}

const DeleteIcon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.6;

  :hover {
    opacity: 0.85;
  }
`;

const ChevronIcon = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.7;

  :hover {
    opacity: 0.85;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 720px){
    margin-bottom: 110px;
  }
`;

const LessonsListPlaceholder = styled.div`

`;

const LessonItem = styled.div`
  background: white;
  border: 1px solid whitesmoke;
  margin-bottom: 20px;
  border-radius: 4px;
  border: ${props => (props.selected == true ? '2px solid #FFC02C' : 'none')};

`;

const LessonItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: ${props => (props.selected == true ? '#FFC02C' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const LessonItemName = styled.div`
  width: 200px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const LessonItemInfo = styled.div`
  width: 200px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;


const LessonItemControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const LessonItemContentPlaceholder = styled.div`
  padding: 0px;
`;