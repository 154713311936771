import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import uuid from 'uuid';
import styled from 'styled-components';
import {ReactSortable} from "react-sortablejs";

import './style.css'

export default function MatchAndOrderAnswerTool(props) {
    const {
        onSave = (s, isCorrect) => {

        },
        canAnswer = true,
        answer = '',
        showCorrectAnswer = false,
        mode = 'match' // can me "match" or "order"
    } = props;
    // console.log('MatchAndOrderAnswerTool: answer = ', answer);

    // const [items, setItems] = useState(props.items ? props.items : []);

    // const [answerText, setAnswerText] = useState((props?.answer == undefined || props?.answer == '') ? (props?.items ? props?.items : []).map(aa => aa.id).join(',') : props?.answer);
    const [answerText, setAnswerText] = useState(props?.answer || '');

    // console.log('MatchAndOrderAnswerTool: answerText = ', answerText);
    // console.log('MatchAndOrderAnswerTool: showCorrectAnswer = ', showCorrectAnswer);

    let mp = (props?.items == undefined ? [] : props?.items).reduce((mmp, x) => ({...mmp, [x.id]: x}), {});

    const [items, setItems] = useState((answerText == undefined ? '' : answerText).split(',').map(xId => mp[xId]).filter(x => (x != undefined)));
    const [isShuffled, setIsShuffled] = useState(false);
    
    useEffect(() => {
        setItems((answerText == undefined ? '' : answerText).split(',').map(xId => mp[xId]).filter(x => (x != undefined)));
        setIsShuffled(false);
    }, [answerText])

    useEffect(() => {
        if ((items == undefined || items.length == 0) && props?.items != undefined && props?.items.length > 0 && !isShuffled && canAnswer) {
            // console.log('SHUFFLING: answer = ', answer);
            try {
                setItems(shuffle(JSON.parse(JSON.stringify(props?.items))));
                // items = shuffle(JSON.parse(JSON.stringify(props?.items)));
                setIsShuffled(true);
            } catch (exc) {
                setItems([]);
                // items = [];
                setIsShuffled(true);
            }
        }     
    }, [items])

    useEffect(() => {
        if (props?.items != undefined && props?.items?.length > 0) {
            try {
                if (props?.answer != answerText) {
                    // setAnswerText((props?.answer == undefined || props?.answer == '') ? (props?.items ? props?.items : []).map(aa => aa.id).join(',') : props?.answer);
                    // console.log('-->> setting answer text = ', props?.answer);
                    setAnswerText(props?.answer);
                }
                // setAnswerText((props?.answer == undefined || props?.answer == '') ? (props?.items ? props?.items : []).join(',') : props?.answer);
            } catch (exc) {

            }
        }
    }, [props?.answer]);

    useEffect(() => {
        let newItems = (answerText == undefined ? '' : answerText).split(',').map(xId => mp[xId]).filter(x => (x != undefined));
        if (newItems == undefined || newItems.length == 0 || answerText == answer) {
            return;
        }
        let isCorrect = true;
        try {
            for (let i in newItems) {
                if (props.items[i].id != newItems[i].id) {
                    isCorrect = false
                }
            }
        } catch (exc) {

        }
        // console.log('!! answerText = ', answerText);
        setItems(newItems);
        onSave(answerText, isCorrect);
    }, [answerText]);

    if (props?.items == undefined || props?.items.length == 0) {
        return null;
    }

    return (
        <Wrapper>

            {mode != 'match' ? null :
                <Left>
                    <ListItemsPlaceholder>
                        {props?.items.map((x, i) => {
                            return (
                                <ListItem key={`x_${i}_${x.id}`}
                                          style={{border: '1px solid white', backgroundColor: 'lightyellow'}}>
                                    <MaxSpan style={mode != 'match' ? {} : {height: "40px"}}>
                                        {x.leftText}
                                    </MaxSpan>
                                </ListItem>
                            )
                        })}
                    </ListItemsPlaceholder>
                </Left>
            }

            <Right>
                <ListItemsPlaceholder>
                    <ReactSortable list={items} setList={newItems => {
                        if (canAnswer == false) {
                            return;
                        }
                        setAnswerText(newItems.map(x => x.id).join(','));
                    }} style={mode != 'match' ? {display: "flex", flexWrap: "wrap"} : {}}>
                        {items.map((x, i) => {
                            let isCorrect = false;
                            try {
                                isCorrect = (props?.items[i].id == items[i].id);
                            } catch (exc) {

                            }
                            let bgColor = 'transparent';
                            if (showCorrectAnswer == true) {
                                if (isCorrect == true) {
                                    bgColor = `rgba(0, 255, 0, 0.3)`;
                                } else {
                                    bgColor = `rgba(255, 0, 0, 0.3)`;
                                }
                            }
                            return (
                                <ListItem key={`x_${i}_${x.id}`}
                                          style={{backgroundColor: bgColor}}
                                >
                                    <MaxSpan style={mode != 'match' ? {} : {height: "40px"}}>
                                        {x.text}

                                        {showCorrectAnswer == false ? null :
                                            <span style={{marginLeft: 5, fontSize: 8}}>
                                                        {isCorrect == true ? '✔️' : '❌'}
                                                    </span>
                                        }
                                    </MaxSpan>
                                </ListItem>
                            )
                        })}
                    </ReactSortable>

                </ListItemsPlaceholder>
            </Right>

        </Wrapper>
    );
}

function shuffle(array) {
    if (array == undefined) {
        return [];
    }
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

const MaxSpan = styled.div`
  overflow-y: auto;
`;

const Left = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const Right = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;


const ListItemsPlaceholder = styled.div`
  width: 100%;
`;

const ListItem = styled.div`
  align-items: center;
  box-sizing: border-box;
  margin: 5px;
  border: 1px dashed lightgrey;
  border-radius: 4px;
  padding: 10px;
  cursor: grab;
`;
