export const languages = [
    { "value": 'english', "label": 'английский' },
    { "value": 'russian', "label": 'русский' },
    { "value": 'chinese', "label": 'китайский' },
    { "value": 'hebrew', "label": 'иврит' },
    { "value": 'arabian', "label": 'арабский' },
    { "value": 'german', "label": 'немецкий' },
    { "value": 'french', "label": 'французский' },
]

export const countries = [
    { "value": "afghanistan", "label": "Афганистан" },
    { "value": "albania", "label": "Албания" },
    { "value": "algeria", "label": "Алжир" },
    { "value": "angola", "label": "Ангола" },
    { "value": "anguilla", "label": "Ангилья" },
    { "value": "antiguaandbarbuda", "label": "Антигуа и Барбуда" },
    { "value": "argentina", "label": "Аргентина" },
    { "value": "armenia", "label": "Армения" },
    { "value": "aruba", "label": "Аруба" },
    { "value": "australia", "label": "Австралия" },
    { "value": "austria", "label": "Австрия" },
    { "value": "azerbaijan", "label": "Азербайджан" },
    { "value": "bahamas", "label": "Багамы" },
    { "value": "bahrain", "label": "Бахрейн" },
    { "value": "bangladesh", "label": "Бангладеш" },
    { "value": "barbados", "label": "Барбадос" },
    { "value": "belarus", "label": "Беларусь" },
    { "value": "belgium", "label": "Бельгия" },
    { "value": "belize", "label": "Белиз" },
    { "value": "benin", "label": "Бенин" },
    { "value": "bhutane", "label": "Бутан" },
    { "value": "bih", "label": "Босния и Герцеговина" },
    { "value": "bolivia", "label": "Боливия" },
    { "value": "botswana", "label": "Ботсвана" },
    { "value": "brazil", "label": "Бразилия" },
    { "value": "bulgaria", "label": "Болгария" },
    { "value": "burkinafaso", "label": "Буркина-Фасо" },
    { "value": "burundi", "label": "Бурунди" },
    { "value": "cambodia", "label": "Камбоджи" },
    { "value": "cameroon", "label": "Камерун" },
    { "value": "canada", "label": "Канада" },
    { "value": "capeverde", "label": "Кабо-Верде" },
    { "value": "caymanislands", "label": "Острова Кайман" },
    { "value": "chad", "label": "Чад" },
    { "value": "chile", "label": "Чили" },
    { "value": "china", "label": "Китай" },
    { "value": "colombia", "label": "Колумбия" },
    { "value": "comoros", "label": "Коморы" },
    { "value": "congo", "label": "Конго" },
    { "value": "costarica", "label": "Коста-Рика" },
    { "value": "croatia", "label": "Хорватия" },
    { "value": "cyprus", "label": "Кипр" },
    { "value": "czech", "label": "Чехия" },
    { "value": "denmark", "label": "Дания" },
    { "value": "djibouti", "label": "Джибути" },
    { "value": "dominica", "label": "Доминика" },
    { "value": "dominicana", "label": "Доминиканская Республика" },
    { "value": "easttimor", "label": "Восточный Тимор" },
    { "value": "ecuador", "label": "Эквадор" },
    { "value": "egypt", "label": "Египет" },
    { "value": "england", "label": "Великобритания" },
    { "value": "equatorialguinea", "label": "Экваториальная Гвинея" },
    { "value": "eritrea", "label": "Эритрея" },
    { "value": "estonia", "label": "Эстония" },
    { "value": "ethiopia", "label": "Эфиопия" },
    { "value": "finland", "label": "Финляндия" },
    { "value": "france", "label": "Франция" },
    { "value": "frenchguiana", "label": "Французская Гвиана" },
    { "value": "gabon", "label": "Габон" },
    { "value": "gambia", "label": "Гамбия" },
    { "value": "georgia", "label": "Грузия" },
    { "value": "germany", "label": "Германия" },
    { "value": "ghana", "label": "Гана" },
    { "value": "greece", "label": "Греция" },
    { "value": "grenada", "label": "Гренада" },
    { "value": "guadeloupe", "label": "Гваделупа" },
    { "value": "guatemala", "label": "Гватемала" },
    { "value": "guinea", "label": "Гвинея" },
    { "value": "guineabissau", "label": "Гвинея-Бисау" },
    { "value": "guyana", "label": "Гайана" },
    { "value": "haiti", "label": "Гаити" },
    { "value": "honduras", "label": "Гондурас" },
    { "value": "hongkong", "label": "Гонконг" },
    { "value": "hungary", "label": "Венгрия" },
    { "value": "india", "label": "Индия" },
    { "value": "indonesia", "label": "Индонезия" },
    { "value": "ireland", "label": "Ирландия" },
    { "value": "israel", "label": "Израиль" },
    { "value": "italy", "label": "Италия" },
    { "value": "ivorycoast", "label": "Кот-д'Ивуар" },
    { "value": "jamaica", "label": "Ямайка" },
    { "value": "japan", "label": "Япония" },
    { "value": "jordan", "label": "Иордания" },
    { "value": "kazakhstan", "label": "Казахстан" },
    { "value": "kenya", "label": "Кения" },
    { "value": "kuwait", "label": "Кувейт" },
    { "value": "kyrgyzstan", "label": "Кыргызстан" },
    { "value": "laos", "label": "Лаос" },
    { "value": "latvia", "label": "Латвия" },
    { "value": "lesotho", "label": "Лесото" },
    { "value": "liberia", "label": "Либерия" },
    { "value": "lithuania", "label": "Литва" },
    { "value": "luxembourg", "label": "Люксембург" },
    { "value": "macau", "label": "Макао" },
    { "value": "madagascar", "label": "Мадагаскар" },
    { "value": "malawi", "label": "Малави" },
    { "value": "malaysia", "label": "Малайзия" },
    { "value": "maldives", "label": "Мальдивы" },
    { "value": "mauritania", "label": "Мавритания" },
    { "value": "mauritius", "label": "Маврикий" },
    { "value": "mexico", "label": "Мексика" },
    { "value": "moldova", "label": "Молдавия" },
    { "value": "mongolia", "label": "Монголия" },
    { "value": "montenegro", "label": "Черногория" },
    { "value": "montserrat", "label": "Монтсеррат" },
    { "value": "morocco", "label": "Марокко" },
    { "value": "mozambique", "label": "Мозамбик" },
    { "value": "myanmar", "label": "Мьянма" },
    { "value": "namibia", "label": "Намибия" },
    { "value": "nepal", "label": "Непал" },
    { "value": "netherlands", "label": "Нидерланды" },
    { "value": "newcaledonia", "label": "Новая Каледония" },
    { "value": "newzealand", "label": "Новая Зеландия" },
    { "value": "nicaragua", "label": "Никарагуа" },
    { "value": "niger", "label": "Нигер" },
    { "value": "nigeria", "label": "Нигерия" },
    { "value": "northmacedonia", "label": "Северная Македония" },
    { "value": "norway", "label": "Норвегия" },
    { "value": "oman", "label": "Оман" },
    { "value": "pakistan", "label": "Пакистан" },
    { "value": "panama", "label": "Панама" },
    { "value": "papuanewguinea", "label": "Папуа-Новая Гвинея" },
    { "value": "paraguay", "label": "Парагвай" },
    { "value": "peru", "label": "Перу" },
    { "value": "philippines", "label": "Филиппины" },
    { "value": "poland", "label": "Польша" },
    { "value": "portugal", "label": "Португалия" },
    { "value": "puertorico", "label": "Пуэрто-Рико" },
    { "value": "reunion", "label": "Реюньон" },
    { "value": "romania", "label": "Румыния" },
    { "value": "russia", "label": "Россия" },
    { "value": "rwanda", "label": "Руанда" },
    { "value": "saintkittsandnevis", "label": "Сент-Китс и Невис" },
    { "value": "saintlucia", "label": "Сент-Люсия" },
    {
        "value": "saintvincentandgrenadines",
        "label": "Сент-Винсент и Гренадины"
    },
    { "value": "salvador", "label": "Сальвадор" },
    { "value": "samoa", "label": "Самоа" },
    { "value": "saotomeandprincipe", "label": "Сан-Томе и Принсипи" },
    { "value": "saudiarabia", "label": "Саудовская Аравия" },
    { "value": "senegal", "label": "Сенегал" },
    { "value": "serbia", "label": "Сербия" },
    { "value": "seychelles", "label": "Сейшелы" },
    { "value": "sierraleone", "label": "Сьерра-Леоне" },
    { "value": "singapore", "label": "Сингапур" },
    { "value": "slovakia", "label": "Словакия" },
    { "value": "slovenia", "label": "Словения" },
    { "value": "solomonislands", "label": "Соломоновы острова" },
    { "value": "southafrica", "label": "Южная Африка" },
    { "value": "southkorea", "label": "Южная Корея" },
    { "value": "spain", "label": "Испания" },
    { "value": "srilanka", "label": "Шри-Ланка" },
    { "value": "suriname", "label": "Суринам" },
    { "value": "swaziland", "label": "Эсватини" },
    { "value": "sweden", "label": "Швеция" },
    { "value": "switzerland", "label": "Швейцария" },
    { "value": "taiwan", "label": "Тайвань" },
    { "value": "tajikistan", "label": "Таджикистан" },
    { "value": "tanzania", "label": "Танзания" },
    { "value": "thailand", "label": "Таиланд" },
    { "value": "*****", "label": "Тринидад и Тобаго" },
    { "value": "togo", "label": "Того" },
    { "value": "tonga", "label": "Тонга" },
    { "value": "tunisia", "label": "Тунис" },
    { "value": "turkey", "label": "Турция" },
    { "value": "turkmenistan", "label": "Туркменистан" },
    { "value": "turksandcaicos", "label": "Теркс и Кайкос" },
    { "value": "uganda", "label": "Уганда" },
    { "value": "ukraine", "label": "Украина" },
    { "value": "uruguay", "label": "Уругвай" },
    { "value": "usa", "label": "США" },
    { "value": "uzbekistan", "label": "Узбекистан" },
    { "value": "venezuela", "label": "Венесуэла" },
    { "value": "vietnam", "label": "Вьетнам" },
    { "value": "virginislands", "label": "Виргинские острова" },
    { "value": "zambia", "label": "Замбия" },
    { "value": "zimbabwe", "label": "Зимбабве" }
]

export const educations = [
    { "value": "school", "label": "Школа" },
    { "value": "university", "label": "Университет" },
    { "value": "persStudent", "label": "Частный обучающийся" }
]

export const universities = [
    { "value": "tsu", "label": "ТГУ" },
    { "value": "kemsu", "label": "КемГУ" },
    { "value": "tumsu", "label": "ТюмГУ" },
    { "value": "omsu", "label": "ОмГУ" },
    { "value": "nsu", "label": "НГУ" },
    { "value": "kuzsu", "label": "КузГТУ" },
]

export const faculties = {
    'tsu': [
        { "value": "Факультет журналистики", "label": "Факультет журналистики" },
        { "value": "Юридический институт", "label": "Юридический институт" },
        {
            "value": "Институт искусств и культуры",
            "label": "Институт искусств и культуры"
        },
        {
            "value": "Факультет исторических и политических наук",
            "label": "Факультет исторических и политических наук"
        },
        {
            "value": "Факультет физической культуры",
            "label": "Факультет физической культуры"
        },
        {
            "value": "Факультет инновационных технологий",
            "label": "Факультет инновационных технологий"
        },
        {
            "value": "Радиофизический факультет",
            "label": "Радиофизический факультет"
        },
        {
            "value": "Механико-математический факультет",
            "label": "Механико-математический факультет"
        },
        { "value": "Биологический институт", "label": "Биологический институт" },
        { "value": "Химический факультет", "label": "Химический факультет" },
        { "value": "Высшая ИТ-школа (HITs)", "label": "Высшая ИТ-школа (HITs)" },
        {
            "value": "Филологический факультет",
            "label": "Филологический факультет"
        },
        { "value": "Физический факультет", "label": "Физический факультет" },
        {
            "value": "Институт экономики и менеджмента",
            "label": "Институт экономики и менеджмента"
        },
        {
            "value": "Геолого-географический факультет",
            "label": "Геолого-географический факультет"
        },
        {
            "value": "Факультет иностранных языков",
            "label": "Факультет иностранных языков"
        },
        { "value": "Факультет психологии", "label": "Факультет психологии" },
        {
            "value": "Физико-технический факультет",
            "label": "Физико-технический факультет"
        },
        {   "value": "Философский факультет", "label": "Философский факультет" },
        {
            "value": "Институт прикладной математики и компьютерных наук",
            "label": "Институт прикладной математики и компьютерных наук"
        }
    ],
    'kemsu': [
        {
            "value": "Институт биологии, экологии и природных ресурсов",
            "label": "Институт биологии, экологии и природных ресурсов"
        },
        {
            "value": "Институт фундаментальных наук",
            "label": "Институт фундаментальных наук"
        },
        {
            "value": "Социально-психологический институт",
            "label": "Социально-психологический институт"
        },
        {
            "value": "Технологический институт пищевой промышленности",
            "label": "Технологический институт пищевой промышленности"
        },
        {
            "value": "Институт истории и международных отношений",
            "label": "Институт истории и международных отношений"
        },
        {
            "value": "Институт инженерных технологий",
            "label": "Институт инженерных технологий"
        },
        {
            "value": "Институт филологии, иностранных языков и медиакоммуникаций",
            "label": "Институт филологии, иностранных языков и медиакоммуникаций"
        },
        {
            "value": "Факультет физкультуры и спорта",
            "label": "Факультет физкультуры и спорта"
        },
        {
            "value": "Институт экономики и управления",
            "label": "Институт экономики и управления"
        },
        { "value": "Юридический институт", "label": "Юридический институт" },
        { "value": "Институт образования", "label": "Институт образования" }
    ],
    'tumsu': [
        { "value": "Институт биологии", "label": "Институт биологии" },
        {
            "value": "Институт государства и права",
            "label": "Институт государства и права"
        },
        {
            "value": "Школа перспективных исследований",
            "label": "Школа перспективных исследований"
        },
        {
            "value": "Институт математики и компьютерных наук",
            "label": "Институт математики и компьютерных наук"
        },
        { "value": "Институт наук о Земле", "label": "Институт наук о Земле" },
        {
            "value": "Институт психологии и педагогики",
            "label": "Институт психологии и педагогики"
        },
        {
            "value": "Институт физической культуры",
            "label": "Институт физической культуры"
        },
        {
          "value": "Институт социально-гуманитарных наук",
          "label": "Институт социально-гуманитарных наук"
        },
        { "value": "Институт химии", "label": "Институт химии" },
        {
            "value": "Физико-технический институт",
            "label": "Физико-технический институт"
        },
        {
            "value": "Финансово-экономический институт",
            "label": "Финансово-экономический институт"
        },
        {
            "value": "Школа естественных наук",
            "label": "Школа естественных наук"
        },
        {
            "value": "Школа исследований окружающей среды и общества",
            "label": "Школа исследований окружающей среды и общества"
        }
    ],
    'omsu':  [
        {
            "value": "Факультет цифровых технологий и кибербезопасности",
            "label": "Факультет цифровых технологий и кибербезопасности"
        },
        {
            "value": "Факультет культуры и искусств",
            "label": "Факультет культуры и искусств"
        },
        {
            "value": "Факультет истории, теологии и международных отношений",
            "label": "Факультет истории, теологии и международных отношений"
        },
        {
            "value": "Факультет филологии, переводоведения и медиакоммуникаций",
            "label": "Факультет филологии, переводоведения и медиакоммуникаций"
        },
        { "value": "Физический факультет", "label": "Физический факультет" },
        { "value": "Химический факультет", "label": "Химический факультет" },
        {
            "value": "Факультет экономики, психологии, менеджмента",
            "label": "Факультет экономики, психологии, менеджмента"
        },
        { "value": "Юридический факультет", "label": "Юридический факультет" }
    ],
    'nsu': [
        {
            "value": "Механико-математический факультет",
            "label": "Механико-математический факультет"
        },
        { "value": "Физический факультет", "label": "Физический факультет" },
        {
            "value": "Факультет естественных наук",
            "label": "Факультет естественных наук"
        },
        {
            "value": "Геолого-геофизический факультет",
            "label": "Геолого-геофизический факультет"
        },
        {
            "value": "Экономический факультет",
            "label": "Экономический факультет"
        },
        {
            "value": "Факультет информационных технологий",
            "label": "Факультет информационных технологий"
        },
        {
            "value": "Институт философии и права",
            "label": "Институт философии и права"
        },
        { "value": "Гуманитарный факультет", "label": "Гуманитарный факультет" },
        {
            "value": "Институт медицины и психологии В. Зельмана",
            "label": "Институт медицины и психологии В. Зельмана"
        }
    ],
    'kuzsu': [
        { "value": "Горный институт", "label": "Горный институт" },
        { "value": "Институт энергетики", "label": "Институт энергетики" },
        {
            "value": "Институт информационных технологий, машиностроения и автотранспорта",
            "label": "Институт информационных технологий, машиностроения и автотранспорта"
        },
        {
            "value": "Институт химических и нефтегазовых технологий",
            "label": "Институт химических и нефтегазовых технологий"
        },
        {
            "value": "Институт экономики и управления",
            "label": "Институт экономики и управления"
        },
        {   "value": "Строительный институт", "label": "Строительный институт" }
    ]
}